import { NgModule } from '@angular/core';
import { CommonModule as AngularCommon } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ConsultationRoutingModule } from './consultation-routing.module';

// import { HistoVersusConsultationService } from 'app/modules/consultation/services/histo-versus-consultation.service';
import { MathModule } from '../math/math.module';
import { HistoVersusConsultationService } from './services/histo-versus-consultation.service';

@NgModule({
  imports: [
    AngularCommon,
    RouterModule,
    FormsModule,
    TranslateModule.forChild(),
    ConsultationRoutingModule,
    MathModule,
  ],
  declarations: [],
  exports: [],
  providers: [HistoVersusConsultationService],
})
export class ConsultationModule {}
