import { Injectable } from '@angular/core';

import { RemoteInteraction } from './remote-interaction.service';
import { INDICATORS } from '../models/subindicators.model';
import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';
import { AnalysisResults } from '../models/analysis-results.model';
import { ComparisonGraph } from '../../common/models/comparison.model';

@Injectable({ providedIn: 'root' })
export class WalkComparisonService {
  public results: AnalysisResults;

  constructor(private remoteInteraction: RemoteInteraction) {
    // empty
  }

  public async getAllGraph(subindicator: string, results: AnalysisResults): Promise<ComparisonGraph[]> {
    return await Promise.all([
      this.getAverageSpeed(subindicator, results),
      this.getCycleVariability(subindicator, results),
      this.getDoubleStance(subindicator, results),
      this.getMeanStepDuration(subindicator, results),
      this.getRoliTronc(subindicator, results),
      this.getSwingMeanStrideDuration(subindicator, results),
    ]);
  }

  private async getAverageSpeed(subIndicator: string, results: AnalysisResults): Promise<ComparisonGraph> {
    const datas = await this.remoteInteraction.getJsonModel(results.models[INDICATORS[0] + '-' + subIndicator]);
    return this.createComparisonGraph(datas, results.AvgSpeed, i18nKey('Checkup.walk.averageSpeed'));
  }

  private async getCycleVariability(subIndicator: string, results: AnalysisResults): Promise<ComparisonGraph> {
    const datas = await this.remoteInteraction.getJsonModel(results.models[INDICATORS[1] + '-' + subIndicator]);

    return this.createComparisonGraph(datas, results.CycleVariability, i18nKey('Checkup.walk.cycleVariability'));
  }

  private async getDoubleStance(subIndicator: string, results: AnalysisResults): Promise<ComparisonGraph> {
    const datas = await this.remoteInteraction.getJsonModel(results.models[INDICATORS[2] + '-' + subIndicator]);

    return this.createComparisonGraph(datas, results.DoubleStance, i18nKey('Checkup.walk.doubleStance'));
  }

  private async getMeanStepDuration(subIndicator: string, results: AnalysisResults): Promise<ComparisonGraph> {
    const datas = await this.remoteInteraction.getJsonModel(results.models[INDICATORS[3] + '-' + subIndicator]);

    return this.createComparisonGraph(datas, results.MeanStepDuration, i18nKey('Checkup.walk.meanStepDuration'));
  }

  private async getRoliTronc(subIndicator: string, results: AnalysisResults): Promise<ComparisonGraph> {
    const datas = await this.remoteInteraction.getJsonModel(results.models[INDICATORS[4] + '-' + subIndicator]);

    return this.createComparisonGraph(datas, results.RoliTronc, i18nKey('Checkup.walk.roliTronc'));
  }

  private async getSwingMeanStrideDuration(subIndicator: string, results: AnalysisResults): Promise<ComparisonGraph> {
    const datas = await this.remoteInteraction.getJsonModel(results.models[INDICATORS[5] + '-' + subIndicator]);

    return this.createComparisonGraph(
      datas,
      results.SwingMeanStrideDuration,
      i18nKey('Checkup.walk.swingMeanStrideDuration'),
    );
  }

  private createComparisonGraph(datas: any, localstat: number, title: string) {
    const comparisonGraph = new ComparisonGraph();
    const dataset = this.transformJsonToArrayDesired(datas);
    dataset.push({ x: this.getMax(dataset) + 1, y: localstat, current: true, toCompare: false });
    comparisonGraph.graph = dataset;
    comparisonGraph.title = title;
    return comparisonGraph;
  }

  private transformJsonToArrayDesired(
    datas: number[][],
  ): Array<{ x: number; y: number; current: boolean; toCompare: boolean }> {
    return datas.map((data) => ({ x: data[1], y: data[2], current: false, toCompare: false }));
  }

  private getMax(datas: Array<{ x: number }>) {
    return Math.max(...datas.map((data) => data.x));
  }
}
