import { HttpParams } from '@angular/common/http';
import { HttpParameterCodec } from '@angular/common/http/http';

export class InterceptorHttpParams extends HttpParams {
  constructor(
    public interceptorConfig: { noNotificationOnError: boolean },
    options?: {
      /**
       * String representation of the HTTP parameters in URL-query-string format.
       * Mutually exclusive with `fromObject`.
       */
      fromString?: string;
      /** Object map of the HTTP parameters. Mutually exclusive with `fromString`. */
      fromObject?: {
        [param: string]: string | ReadonlyArray<string>;
      };
      /** Encoding codec used to parse and serialize the parameters. */
      encoder?: HttpParameterCodec;
    },
  ) {
    super(options);
  }
}
