<div class="main-container container" *ngIf="patient">
  <app-patient-detail-header
    [patient]="patient"
    (openDelegation)="onOpenDelegation()"
    (callDeletePatient)="onDeletePatient()"
    [hasCoursInProgress]="hasCoursInProgress"
    [hasQuestionnaryMandatary]="hasQuestionnaryMandatary"
    [idQuestionnaryMandatary]="idQuestionnaryMandatary"
  ></app-patient-detail-header>
  <div class="container-health container flex-column">
    <div class="div-title" [style.display]="'none'">
      <p class="title">{{ hasQuestionnaryMandatary }}</p>
    </div>
    <div class="div-title">
      <p class="title">{{ 'Patient.Profil.health' | translate }}</p>
    </div>
    <div class="content-health flex-row">
      <div class="flex-column col-sm-4">
        <p class="info-title">{{ 'Taille(cm)' | translate }}</p>
        <p class="info-value" *ngIf="patient.height; else elseBlock">{{ patient.height }} cm</p>
      </div>
      <div class="flex-column col-sm-4">
        <p class="info-title">{{ 'Poids(kg)' | translate }}</p>
        <p class="info-value" *ngIf="patient.weight; else elseBlock">{{ patient.weight }} kg</p>
      </div>
      <div class="flex-column col-sm-4">
        <p class="info-title">IMC</p>
        <p class="info-value" *ngIf="patient.height && patient.weight; else elseBlock">{{ imc }}</p>
      </div>

      <ng-template #elseBlock> - </ng-template>
    </div>
    <div class="flex-row div-modify">
      <button
        class="btn btn-tertiary"
        data-toggle="modal"
        data-target="#modalPatientUpdateHealth"
        data-backdrop="static"
        data-keyboard="false"
        data-focus="true"
        (click)="openModal()"
        *ngIf="rolesCheck.hasManagePatient | async"
      >
        {{ 'Modifier' | translate }}
      </button>
    </div>
    <ng-container *ngIf="canEditPatatt && (hasPatAtt || (!hasPatAtt && (rolesCheck.hasEditSyndrom | async)))">
      <mat-divider class="margin"></mat-divider>
      <div class="flex-row buttons-bottom">
        <app-patatt-synthesis [patient]="patient" *ngIf="hasPatAtt"></app-patatt-synthesis>
        <button
          class="btn btn-tertiary"
          (click)="addPatAtt()"
          *ngIf="!hasPatAtt && (rolesCheck.hasEditSyndrom | async)"
        >
          {{ 'Patient.Add_info_clinical_profile' | translate }}
        </button>
      </div>
    </ng-container>
  </div>
  <!--
  <div class="container-recent-courses container flex-column">
    <div class="div-title">
      <p class="title">{{ 'Patient.Profil.recent_course' | translate }}</p>
    </div>

    <div class="content-recent-courses flex-row wrap">
      <button class="btn btn-fifth mr-3 mt-3">
        {{ 'Patient.Profil.launch_course_fragility' | translate }} (FRIED)
      </button>
      <button class="btn btn-fifth mr-3 mt-3">
        {{ 'Patient.Profil.launch_course_geriatric' | translate }}
      </button>
      <button class="btn btn-fifth mr-3 mt-3">
        {{ 'Patient.Profil.launch_course_physiotherapy' | translate }}
      </button>
    </div>
  </div>
   -->

  <div class="container-result container flex-column">
    <div class="div-title">
      <p class="title">{{ 'Résultats' | translate }}</p>
    </div>

    <div class="content-result flex-row">
      <div class="flex-column col-sm-4">
        <p class="info-title">{{ 'Patient.Profil.date_last_test' | translate }}</p>
        <p class="info-value" *ngIf="patient.lasttestdate">
          {{ patient.lasttestdate | localdate }}
        </p>
      </div>
      <div class="flex-column col-sm-4">
        <p class="info-title">{{ 'Patient.Profil.type_last_test' | translate }}</p>
        <p class="info-value" *ngIf="lastTestType">{{ lastTestType }}</p>
      </div>
      <button class="btn btn-sixth" (click)="openHistory()" [disabled]="!checkCanViewResults()">
        {{ 'Patient.Profil.view_history' | translate }}
      </button>
    </div>
  </div>

  <app-patient-detail-accordeon
    [patient]="patient"
    (refreshPatient)="onRefreshInfomation()"
    (openDelegation)="onOpenDelegation()"
  ></app-patient-detail-accordeon>
</div>

<div
  class="modal fade"
  id="modalPatientUpdateHealth"
  tabindex="-1"
  role="dialog"
  aria-labelledby="modalPatientUpdateHealthTitle"
  aria-hidden="true"
  #basicModal
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalPatientUpdateHealthTitle">
          {{ 'Patient.Profil.patient_information' | translate }}
        </h5>
        <button #btnClose type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCloseModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-patient-creation-health-form *ngIf="isOk" [patient]="patient" (closeModal)="onCloseModal()">
        </app-patient-creation-health-form>
      </div>
    </div>
  </div>
</div>
