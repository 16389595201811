<div class="romberg-tuto-container">
  <div class="romberg-installation" style=" width: 100%;" *ngIf="firstStep">
    <div class="row align-items-center justify-content-between">
      <div class="col-lg-1"></div>
      <div class="col-lg-9" style="text-align : center;">
        <img src="assets/images/images/weight_installation.svg" width="100%;" alt="" />
      </div>
      <div class="col-lg-2 d-flex justify-content-end">
        <img
          (click)="goToNextStep()"
          src="assets/images/icons/right-chevron.svg"
          alt=""
          style="cursor: pointer; width: 4rem;"
        />
      </div>
    </div>
  </div>
  <div class="romberg-protocol" *ngIf="!firstStep">
    <div class="row align-items-center justify-content-between">
      <div class="col-lg-1">
        <img
          (click)="goToPreviousStep()"
          src="assets/images/icons/left-chevron.svg"
          alt=""
          style="width: 4rem; cursor: pointer;"
        />
      </div>
      <div class="col-lg-10 offset-lg-1">
        <div class="row">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-6">
                <p class="explanations">
                  <img
                    class="d-inline-block align-top"
                    width="20"
                    height="20"
                    src="assets/images/icons/icon-1.svg"
                    alt=""
                  />
                  {{ 'app.romberg.protocol.text1' | translate }}
                </p>
              </div>
              <div class="col-lg-5 offset-lg-1">
                <img src="assets/images/images/weight_protocole.svg" height="260px;" alt="" />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-6">
                <p class="explanations">
                  <img
                    class="d-inline-block align-top"
                    width="20"
                    height="20"
                    src="assets/images/icons/icon-2.svg"
                    alt=""
                  />
                  {{ 'app.romberg.protocol.text2' | translate }}
                </p>
              </div>
              <div class="col-lg-5 offset-lg-1">
                <img src="assets/images/images/weight_protocole.svg" height="260px;" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            {{ 'app.romberg.protocol.conseil' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-lg-12">
      <div class="page-footer buttons-component">
        <button (click)="goToMesure()" class="btn btn-danger">
          Fermer
        </button>
      </div>
    </div>
  </div> -->
</div>
