<div class="col-md-12 appreciation-container">
  <div class="col-md-10 information-container">
    <img class="d-inline-block align-top" width="20" height="20" src="assets/images/icons/info-circle.svg" alt="" />
    <span>{{ 'app.walk.appreciationInformation' | translate }}</span>
  </div>

  <div class="col-md-11 eval-container">
    <div class="title-container">
      <p class="title">
        {{ 'app.walk.appreciation.hesitantWalking' | translate }}
      </p>
    </div>
    <div class="selector-container">
      <div class="col-md-8 slider-container">
        <span class="number">{{ minHesitantWalking }}</span>
        <mat-slider
          [ngClass]="{ 'slider-grey': isHesitantWalkingNotEvaluated, 'slider-blue': !isHesitantWalkingNotEvaluated }"
          [min]="minHesitantWalking"
          [max]="maxHesitantWalking"
          [step]="step"
          [(ngModel)]="valueHesitantWalking"
          [thumbLabel]="thumbLabel"
          (input)="onValueHWChange($event)"
        >
        </mat-slider>
        <span class="number">{{ maxHesitantWalking }}</span>
      </div>

      <div class="col-md-2 checkbox-container">
        <mat-checkbox
          class="checkbox"
          [checked]="isHesitantWalkingNotEvaluated"
          [(ngModel)]="isHesitantWalkingNotEvaluated"
        >
          {{ 'app.walk.appreciation.notEvaluated' | translate }}
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div class="col-md-11 eval-container">
    <div class="title-container">
      <p class="title">
        {{ 'app.walk.appreciation.smallSteps' | translate }}
      </p>
    </div>
    <div class="selector-container">
      <div class="col-md-8 slider-container">
        <span class="number">{{ minSmallSteps }}</span>
        <mat-slider
          [ngClass]="{ 'slider-grey': isSmallStepsNotEvaluated, 'slider-blue': !isSmallStepsNotEvaluated }"
          [min]="minSmallSteps"
          [max]="maxSmallSteps"
          [step]="step"
          [(ngModel)]="valueSmallSteps"
          [thumbLabel]="thumbLabel"
          (input)="onValueSSChange($event)"
        >
        </mat-slider>
        <span class="number">{{ maxSmallSteps }}</span>
      </div>

      <div class="col-md-2 checkbox-container">
        <mat-checkbox class="checkbox" [checked]="isSmallStepsNotEvaluated" [(ngModel)]="isSmallStepsNotEvaluated">
          {{ 'app.walk.appreciation.notEvaluated' | translate }}
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div class="col-md-11 eval-container">
    <div class="title-container">
      <p class="title">{{ 'app.walk.appreciation.irregularWalking' | translate }}</p>
    </div>
    <div class="selector-container">
      <div class="col-md-8 slider-container">
        <span class="number">{{ minIrregularWalking }}</span>
        <mat-slider
          [ngClass]="{ 'slider-grey': isIrregularWalkingNotEvaluated, 'slider-blue': !isIrregularWalkingNotEvaluated }"
          [min]="minIrregularWalking"
          [max]="maxIrregularWalking"
          [step]="step"
          [(ngModel)]="valueIrregularWalking"
          [thumbLabel]="thumbLabel"
          (input)="onValueIWChange($event)"
        >
        </mat-slider>
        <span class="number">{{ maxIrregularWalking }}</span>
      </div>

      <div class="col-md-2 checkbox-container">
        <mat-checkbox
          class="checkbox"
          [checked]="isIrregularWalkingNotEvaluated"
          [(ngModel)]="isIrregularWalkingNotEvaluated"
        >
          {{ 'app.walk.appreciation.notEvaluated' | translate }}
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div class="col-md-11 eval-container">
    <div class="title-container">
      <p class="title">{{ 'app.walk.appreciation.asymmetricWalking' | translate }}</p>
    </div>
    <div class="selector-container">
      <div class="col-md-8 slider-container">
        <span class="number">{{ minAsymmetricWalking }}</span>
        <mat-slider
          [ngClass]="{
            'slider-grey': isAsymmetricWalkingNotEvaluated,
            'slider-blue': !isAsymmetricWalkingNotEvaluated
          }"
          [min]="minAsymmetricWalking"
          [max]="maxAsymmetricWalking"
          [step]="step"
          [(ngModel)]="valueAsymmetricWalking"
          [thumbLabel]="thumbLabel"
          (input)="onValueAWChange($event)"
        >
        </mat-slider>
        <span class="number">{{ maxAsymmetricWalking }}</span>
      </div>

      <div class="col-md-2 checkbox-container">
        <mat-checkbox
          class="checkbox"
          [checked]="isAsymmetricWalkingNotEvaluated"
          [(ngModel)]="isAsymmetricWalkingNotEvaluated"
        >
          {{ 'app.walk.appreciation.notEvaluated' | translate }}
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div class="col-md-11 eval-container">
    <div class="title-container">
      <p class="title">{{ 'app.walk.appreciation.armSwingLoss' | translate }}</p>
    </div>
    <div class="selector-container">
      <div class="col-md-8 slider-container">
        <span class="number">{{ minArmSwingLoss }}</span>
        <mat-slider
          [ngClass]="{ 'slider-grey': isArmSwingLossNotEvaluated, 'slider-blue': !isArmSwingLossNotEvaluated }"
          [min]="minArmSwingLoss"
          [max]="maxArmSwingLoss"
          [step]="step"
          [(ngModel)]="valueArmSwingLoss"
          [thumbLabel]="thumbLabel"
          (input)="onValueASLChange($event)"
        >
        </mat-slider>
        <span class="number">{{ maxArmSwingLoss }}</span>
      </div>

      <div class="col-md-2 checkbox-container">
        <mat-checkbox class="checkbox" [checked]="isArmSwingLossNotEvaluated" [(ngModel)]="isArmSwingLossNotEvaluated">
          {{ 'app.walk.appreciation.notEvaluated' | translate }}
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div class="col-md-11 eval-container">
    <div class="title-container">
      <p class="title">{{ 'app.walk.appreciation.unbalancedWalking' | translate }}</p>
    </div>
    <div class="selector-container">
      <div class="col-md-8 slider-container">
        <span class="number">{{ minUnbalancedWalking }}</span>
        <mat-slider
          [ngClass]="{
            'slider-grey': isUnbalancedWalkingNotEvaluated,
            'slider-blue': !isUnbalancedWalkingNotEvaluated
          }"
          [min]="minUnbalancedWalking"
          [max]="maxUnbalancedWalking"
          [step]="step"
          [(ngModel)]="valueUnbalancedWalking"
          [thumbLabel]="thumbLabel"
          (input)="onValueUWChange($event)"
        >
        </mat-slider>
        <span class="number">{{ maxUnbalancedWalking }}</span>
      </div>

      <div class="col-md-2 checkbox-container">
        <mat-checkbox
          class="checkbox"
          [checked]="isUnbalancedWalkingNotEvaluated"
          [(ngModel)]="isUnbalancedWalkingNotEvaluated"
        >
          {{ 'app.walk.appreciation.notEvaluated' | translate }}
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div class="col-md-11 d-flex">
    <div class="col-md-8"></div>
    <div class="col-md-2">
      <button class="btn btn-primary btn-valid" (click)="viewResults()">{{ 'button.validation' | translate }}</button>
    </div>
  </div>
</div>
