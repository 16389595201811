import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MaterialModule } from './material/material.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [],
  imports: [BrowserAnimationsModule, MaterialModule, ReactiveFormsModule],
  exports: [MaterialModule, BrowserAnimationsModule, ReactiveFormsModule],
})
export class SharedModule {}
