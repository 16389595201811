import { Injectable } from '@angular/core';
import { BehaviorSubject, from as fromPromise } from 'rxjs';
import { map } from 'rxjs/operators';

// import { LocalStorageEx, LocalStorageObject } from 'app/tools/localstorageobject.enum';
import { LocalDatePipe } from '../../time/pipes/localdate.pipe';

@Injectable()
export class HistoVersusConsultationService {
  // public bilanResultSelected$ = new BehaviorSubject<BilanResults>(null);

  private dateCompare = '';
  private isComparaisonEnabled = false;
  private canHistory: boolean = false;
  private writeMode$: BehaviorSubject<boolean>;

  // constructor(private bilanService: BilanDataService, private localDatePipe: LocalDatePipe) {
  constructor(private localDatePipe: LocalDatePipe) {
    // console.log('Mode histo', LocalStorageEx.histoBoolean);
    // if (LocalStorageEx.histoBoolean) {
    //   this.writeMode$ = new BehaviorSubject<boolean>(!LocalStorageEx.histoBoolean);
    // } else {
    this.writeMode$ = new BehaviorSubject<boolean>(true);
    // }
  }

  public setToReadOnly(value: boolean): void {
    console.log('Set to readonly', value);
    this.writeMode$.next(!value);
    // LocalStorageEx.histoBoolean = value;
  }
  /**
   * If the value is at true it means that we are in a consultation.
   * At the opposité if the value is at fale, it means we are in a old bilan.
   */
  public get writeMode(): BehaviorSubject<boolean> {
    return this.writeMode$;
  }
  /**
   * If the value is at true it means that we are in a consultation.
   * At the opposité if the value is at fale, it means we are in a old bilan.
   */
  public get writeModeValue(): boolean {
    return this.writeMode$.value;
  }

  public goToHistory(history: boolean): void {
    this.canHistory = history;
  }

  public get history(): boolean {
    return this.canHistory;
  }

  public setPageInConsultation(route: string) {
    // if (this.writeMode$.value) {
    //   LocalStorageEx.setObject(LocalStorageObject.CurrentPageInConsult, route);
    // }
  }

  public get comparaisonEnabled() {
    return this.isComparaisonEnabled;
  }

  public set comparaisonEnabled(value: boolean) {
    this.isComparaisonEnabled = value;
  }

  public comparaisonReset() {
    this.compareDate = null;
    this.comparaisonEnabled = false;
  }

  /**
   * bilanDateForcomparaison
   */
  // public bilanDateForcomparaison(patientId: number, bilanType: number) {
  //   return fromPromise(this.bilanService.getAssessments(patientId, bilanType, null)).pipe(
  //     map((listOfBilans) => {
  //       LocalStorageEx.bilanResults = listOfBilans;
  //       const listDate = listOfBilans.map((bilanResult) =>
  //         this.localDatePipe.parse(bilanResult.consultation.Date, 'dd/MM/yyyy HH:mm:ss'),
  //       );
  //       // add empty value
  //       listDate.unshift('');
  //       return listDate;
  //     }),
  //   );
  // }

  public get compareDate() {
    return this.dateCompare;
  }

  public set compareDate(newDate: string) {
    // if (this.dateCompare !== newDate && this.isComparaisonEnabled) {
    //   const bilanResults = LocalStorageEx.bilanResults.find((bilanResult) => {
    //     return this.localDatePipe.parse(bilanResult.consultation.Date, 'dd/MM/yyyy HH:mm:ss') === newDate;
    //   });
    //   this.bilanResultSelected$.next(bilanResults);
    // }
    // this.dateCompare = newDate;
  }
}
