<app-fried-history-navbar
  [patient]="patient"
  [returnTitle]="returnTitle"
  (selectPage)="onSelectPage($event)"
  *ngIf="!fromCourse"
></app-fried-history-navbar>
<app-custom-navbar
  [patient]="patient"
  [returnTitle]="returnTitle"
  (selectPage)="onSelectPage($event)"
  *ngIf="fromCourse"
></app-custom-navbar>
<app-fried-custom-navbar [testName]="testName" [testData]="testData" *ngIf="!fromCourse" [displayDoubleTask]="false">
</app-fried-custom-navbar>
<app-courses-breadcrumb-navbar
  [testName]="testName"
  [course]="course"
  *ngIf="fromCourse"
></app-courses-breadcrumb-navbar>
<div class="col-md-12">
  <div class="row">
    <div class="col-md-3 p-0" *ngIf="fromCourse">
      <app-fried-navbar
        [currentPage]="currentPageCourse"
        (selectPage)="onSelectPage($event)"
        [course]="course"
      ></app-fried-navbar>
    </div>
    <div class="col-md-9 main-content">
      <div class="row">
        <div class="col-md-11 simple-content-component" style="height: 80%;">
          <ng-container *ngFor="let question of questionnaryDetails.questions; let questionIndex = index">
            <div class="question-container" *ngIf="displyQuestion(questionIndex)">
              <h5
                class="question-family"
                *ngIf="
                  questionnaryDetails.questionFamilies &&
                  questionnaryDetails.questionFamilies.length > 0 &&
                  (questionIndex == 0 ||
                    questionnaryDetails.questionFamilies[question.familyIndex] !=
                      questionnaryDetails.questionFamilies[
                        questionnaryDetails.questions[questionIndex - 1].familyIndex
                      ])
                "
              >
                {{ questionnaryDetails.questionFamilies[question.familyIndex] }}
              </h5>
              <div class="question">{{ question.text }}</div>
            </div>
            <div class="answers-container flex-column justify-content-between" *ngIf="displyQuestion(questionIndex)">
              <div>
                <div class="answer">
                  {{ getAnsewrsValues(questionIndex) }}
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
