import { AnalysisResultsDetailByAge } from './analysis-results-detailByAge.model';
export class AnalysisResultsSemioInfo {
  public avgLocoLeft: number;
  public avgLocoRightLeft: number;
  public avgLocoRight: number;
  public rightStep: number;
  public leftStep: number;

  public uTurn: string;
  public detailByAge: AnalysisResultsDetailByAge[];
}
