<div class="col-md-12 flex-column assessment-container">
  <span class="text title">{{ title | translate }}</span>
  <span class="text value" *ngIf="!itemToCompare">{{ item.value | float: 3 }} {{ item.unit }}</span>
  <span class="text value" *ngIf="itemToCompare">
    {{ item.value }} {{ item.unit }} | {{ itemToCompare.value }} {{ itemToCompare.unit }}
  </span>
  <div class="custom-slider" *ngIf="!itemToCompare">
    <!--   <ngx-slider [(value)]="item.value" [options]="options"></ngx-slider> -->

    <span class="number">{{ item.min }}</span>
    <mat-slider
      [disabled]="true"
      [min]="min"
      [max]="max"
      [step]="step"
      [(ngModel)]="item.value"
      [ngClass]="{
        'assessment-container': !isOffLimit,
        'assessment-container-offlimit': isOffLimit
      }"
    >
      <input value="1" matSliderStartThumb />
      <input value="2" matSliderEndThumb />
    </mat-slider>

    <span class="number">{{ item.max }}</span>
  </div>
  <div class="custom-slider" *ngIf="itemToCompare">
    <ngx-slider [(value)]="minValue" [(highValue)]="highValue" [options]="options"></ngx-slider>
  </div>
</div>
