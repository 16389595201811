export class AssessmentRomberg {
  public type: RombergAssessmentType;
  public title: string;

  public nonEvaluated = true;
  public value: number | null = null;
}

export enum RombergAssessmentType {
  QualityEquiYO,
  QualityEquiYF,
  QualityWalk,
  Fall,
}
