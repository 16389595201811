import {
  Footwear,
  FootwearControllerService,
  NewRomberg,
  PatientDetail,
  QuestionnaireReplyControllerService,
  RombergControllerService,
  RombergWithRelations,
  UpdatePatient,
  WalkingAid,
  WalkingAidControllerService,
} from '@abilycare/dal-client';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  combineLatest,
  from as fromPromise,
  Observable,
  of,
  Subject,
  Subscription,
  timer,
} from 'rxjs';
import { bufferCount, catchError, delay, flatMap, map, retryWhen, takeUntil } from 'rxjs/operators';
import { AppConfigurationService } from 'src/app/app-configuration.service';
import { EtatYeux } from 'src/app/modules/abilycare-data-access/model/romberg-test.model';
import { ComparisonGraph } from 'src/app/modules/common/models/comparison.model';
import { ShoesSelectorValues, WiiBoardService, WiiBoardState } from 'src/app/modules/force-platform';
import { AnalysisParameters } from 'src/app/modules/romberg/models/analysis-parameters.model';
import { AnalysisResults } from 'src/app/modules/romberg/models/analysis-results.model';
import { AssessmentRomberg } from 'src/app/modules/romberg/models/assessment.model';
import { LongitudinalGraph } from 'src/app/modules/romberg/models/longitudinal.model';
import { RombergSummary } from 'src/app/modules/romberg/models/romberg-summary.model';
import { SUBINDICATORS } from 'src/app/modules/romberg/models/subindicators.model';
import { AnalysisResultsRepository } from 'src/app/modules/romberg/services/analysis-result-repository.service';
import { ForcePlatformOrchestrator } from 'src/app/modules/romberg/services/orchestrator.service';
import { RombergComparisonService } from 'src/app/modules/romberg/services/romberg-comparaison.service';
import { Timer } from 'src/app/modules/time';
import { LocalDatePipe } from 'src/app/modules/time/pipes/localdate.pipe';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import * as medicalConst from 'src/app/tools/medical.constants';
import { ModalComponent } from 'src/app/view/common/modal/modal.component';
import { State } from '../shared/models/state.model';
import { RombergTutorialComponent } from './romberg-tutorial/romberg-tutorial.component';
import { RemoteInteraction as RemoteInteractionRomberg } from 'src/app/modules/romberg/services/remote-interaction.service';
import { PatientDataService } from 'src/app/modules/abilycare-data-access/api/patient-data.service';
import { InformationConnexionComponent } from 'src/app/view/information-connexion/information-connexion.component';
import { KInventPlateService } from 'src/app/modules/force-platform/services/kinventplate.service';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-fried-romberg',
  templateUrl: './romberg.component.html',
  styleUrls: ['./romberg.component.scss'],
  providers: [
    KInventPlateService,
    WiiBoardService,
    ForcePlatformOrchestrator,
    AnalysisResultsRepository,
    RombergComparisonService,
  ],
})
export class RombergComponent implements OnInit, OnDestroy {
  @ViewChild('btnClose', { static: true }) btnClose: ElementRef;
  @ViewChild('btnOpenAssessmentModal', { static: true }) btnOpenAssessmentModal: ElementRef;
  @ViewChild('btnCloseAssessmentModal', { static: true }) btnCloseAssessmentModal: ElementRef;
  @ViewChild('btnCloseCalculationModal', { static: true }) btnCloseCalculationModal: ElementRef;
  @ViewChild('btnCloseGraphModal', { static: true }) btnCloseGraphModal: ElementRef;
  @ViewChild('btnOpenQuit', { static: true }) btnOpenQuit: ElementRef;
  @ViewChild('btnQuitBeforeSave', { static: true }) btnQuitBeforeSave: ElementRef;
  @ViewChild('rombergTutorial', { static: true }) rombergTutorial: RombergTutorialComponent;
  @ViewChild('plateMonitor', { static: false }) plateMonitor: InformationConnexionComponent;
  @ViewChildren(ModalComponent)
  public modals: QueryList<ModalComponent>;

  public shoesSelectorValues: ShoesSelectorValues;
  public visualAppreciations: Array<AssessmentRomberg>;

  public wiiConnected: boolean;

  public isTestYOAvailable = false;
  public isTestYORunning = false;
  public isTestYOFinish = false;
  public isTestYFAvailable = false;
  public isTestYFRunning = false;
  public isTestYFFinish = false;

  public isTestValidated = false;
  public isAlgorithmFinish: boolean = false;

  public dataRecorded = new Map();
  public nbMeasuresKInvent: number = 0;

  /** timer */
  public timerYO: Timer = new Timer(medicalConst.TIME_TO_ROMBERG);
  public timerYF: Timer = new Timer(medicalConst.TIME_TO_ROMBERG);

  public timeFinishYO: string;
  public timeFinishYF: string;

  public readonly boardReady: Observable<boolean>;
  public wiiState: Observable<WiiBoardState>;

  public patient: PatientDetail;

  private state: State = new State();
  private currentActiveRecording: EtatYeux;
  private currentRombergRecordingSession;
  private unsubscribe = new Subject<void>();
  private subscriptions: Subscription[] = [];

  public typeRapport: string = 'Romberg';

  // Cette date est fixe pour des raisons de scripts @TODO remettre la date de consultation
  private dateShared = '2020-03-27_10:30:12';

  private retryResponse = new Subject<boolean>();
  public waitMessage = new BehaviorSubject<string>('');

  public data: RombergSummary = new RombergSummary();
  private analyisResult: AnalysisResults;

  // Valeurs booléennes permettant de déterminer si on est en dehors des bornes pour chaque valeur
  public isOffLimitOpenedEyesScore: boolean = false;
  public isOffLimitClosedEyesScore: boolean = false;
  public isOffLimitOpenedEyesArea: boolean = false;
  public isOffLimitClosedEyesArea: boolean = false;
  public isOffLimitOpenedEyesSwayDensity: boolean = false;
  public isOffLimitClosedEyesSwayDensity: boolean = false;
  public isOffLimitOpenedEyesLateralVariance: boolean = false;
  public isOffLimitClosedEyesLateralVariance: boolean = false;
  public isOffLimitOpenedEyesAverageSpeed: boolean = false;
  public isOffLimitClosedEyesAverageSpeed: boolean = false;

  // Valeurs permettant de définir les limites de chaque valeur calculée
  public minEyesScore: number = 0;
  public maxEyesScore: number = 100;
  public minEyesArea: number = 0;
  public maxEyesArea: number = 15;
  public minEyesSwayDensity: number = 0;
  public maxEyesSwayDensity: number = 30;
  public minEyesLateralVariance: number = 0;
  public maxEyesLateralVariance: number = 5;
  public minEyesAverageSpeed: number = 0;
  public maxEyesAverageSpeed: number = 8;

  public graphTitle = this.translateService.instant('Checkup.Romberg.Breadcrumb.StatoKinesiGram');
  public isStatokinesigram = true;
  public isStatokinesigramStandard = true;
  public isStatokinesigramAsym = false;
  public isStabilogram = false;
  public isComparison = false;
  public isLongitudinal = false;

  public standardGraph: boolean = true;
  public asymetrieGraph: boolean = false;

  public recupComparaison: boolean = false;
  public comparisonDatas: ComparisonGraph[];
  public comparisonGraphSelected: ComparisonGraph;
  public typesOfComparisons: string[];
  public choiceOfComparison: string;

  public graphs: LongitudinalGraph[];
  public zoom: boolean;
  public graphSelected: LongitudinalGraph;

  public error: boolean = false;

  public calculResult: boolean = false;

  public returnTitle: string = i18nKey('Checkup.navbar.patient.back');
  public testName: string = i18nKey('navbar.rombergTest');

  public newPage: string = '';

  public readonly waitAnalyseName = 'WaitAnalyse';
  public fromBilan: boolean;

  public listTestRombergs: any;

  private rombergProxyBaseUri: string = this.appConfigurationService.rombergProxyBaseUri;

  public typePlate: string;

  public scoreProfil: number = 0;
  public openedEyesPrcLeftSupport: number = 0;
  public closedEyesPrcLeftSupport: number = 0;

  public optionsopenedEyesLeftSupport: Options;
  public minOpenedEyesLeftSupport: number = 0;
  public maxOpenedEyesLeftSupport: number = 100;

  public optionsclosedEyesLeftSupport: Options;
  public minClosedEyesLeftSupport: number = 0;
  public maxClosedEyesLeftSupport: number = 100;

  constructor(
    private wiiBoardService: WiiBoardService,
    private kInventPlateService: KInventPlateService,
    private translateService: TranslateService,
    private dataService: DataProviderService,
    private router: Router,
    private orchestrator: ForcePlatformOrchestrator,
    private analysisResultsRepository: AnalysisResultsRepository,
    private rombergComparaisonService: RombergComparisonService,
    private snackBarService: SnackBarService,
    private rombergService: RombergControllerService,
    private localDatePipe: LocalDatePipe,
    private httpClient: HttpClient,
    private appConfigurationService: AppConfigurationService,
    private footwearService: FootwearControllerService,
    private walkingAidService: WalkingAidControllerService,
    private route: ActivatedRoute,
    private remoteInteractionRombergService: RemoteInteractionRomberg,
    private patientDataService: PatientDataService,
    private questionnaireReplyService: QuestionnaireReplyControllerService,
  ) {
    this.wiiConnected = false;
    this.boardReady = this.wiiBoardService.wiiState.pipe(map((state) => state && state.wiiBoardConnected));
  }

  ngOnInit() {
    this.dataService.storeHeaderShowed(false);
    this.patient = this.dataService.retrievePatient();
    this.shoesSelectorValues = new ShoesSelectorValues();
    const sessionId = this.orchestrator.initSession();
    this.currentRombergRecordingSession = sessionId;

    this.route.queryParams.subscribe((params) => {
      this.fromBilan = params.fromBilan == 1;
    });

    //getScore profil Light
    this.questionnaireReplyService
      .questionnaireReplyControllerFindQuestionnaryByPatientIdAndQuestId(this.patient.id, 33)
      .subscribe((questReply) => {
        this.scoreProfil = questReply.scores[0];
      });

    this.typesOfComparisons = SUBINDICATORS;
    this.choiceOfComparison = this.typesOfComparisons[0];

    this.graphs = new Array<LongitudinalGraph>(5);
    this.graphs[0] = new LongitudinalGraph(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      this.translateService.instant('Checkup.Romberg.Graph.Longitu.Score'),
    );
    this.graphs[1] = new LongitudinalGraph(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      this.translateService.instant('Checkup.Romberg.Graph.Longitu.SpeedMoy'),
    );
    this.graphs[2] = new LongitudinalGraph(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      this.translateService.instant('Checkup.Romberg.Graph.Longitu.SwayDensiy'),
    );
    this.graphs[3] = new LongitudinalGraph(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      this.translateService.instant('Checkup.Romberg.Graph.Longitu.Suface'),
    );
    this.graphs[4] = new LongitudinalGraph(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      this.translateService.instant('Checkup.Romberg.Graph.Longitu.VarianceLateral'),
    );
    this.retryResponse.next(false);
    this.wiiState = timer(1, 1000).pipe(
      flatMap(() => this.wiiBoardService.wiiBoardGetFullStatus()),
      map((state, secondsLeft) => {
        this.wiiConnected = state.wiiBoardConnected;
        if (!state.acquisitionActive && secondsLeft !== 0) {
          state.nbPoints = 0;
        }
        if (this.isTestYORunning && !state.acquisitionActive && this.timerYO.secondes === 0) {
          this.isTestYORunning = false;
          this.isTestYOFinish = true;
          const dateFinish = new Date();
          this.timeFinishYO = dateFinish.getHours() + ':' + dateFinish.getMinutes();
          this.isTestYFAvailable = true;
        }
        if (this.isTestYFRunning && !state.acquisitionActive && this.timerYF.secondes === 0) {
          this.isTestYFRunning = false;
          this.isTestYFFinish = true;
          const dateFinish = new Date();
          this.timeFinishYF = dateFinish.getHours() + ':' + dateFinish.getMinutes();
        }
        this.validate();
        /* si aucun choix de yeux alors rien */
        /* Si choix de yeux, mais acquisitionActive false alors le test n'a pas commencer */
        /* Si choix de yeux, mais acquisitionActive true alors le test a commencer donc on grise les boutons */
        /* Si choix de yeux, mais acquisitionActive false (+ param indiquant que le test à été lancé) alors le test est terminé.
        On active le prochain test ou on lancer les résultats */
        return state;
      }),
    );

    this.subscriptions.push(
      combineLatest([this.wiiState, this.timerYO.totalSeconds$])
        .pipe(
          map(([state, secondsLeft]) => ({ state, secondsLeft })),
          takeUntil(this.unsubscribe),
        )
        .subscribe((val) => this.followRecording(val)),
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
  }

  // On click on another page, we check if test is started and display message
  public canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.isTestYFRunning || this.isTestYORunning) {
      this.btnOpenQuit.nativeElement.click();
      return false;
    }

    if (this.isTestYFFinish || this.isTestYOFinish) {
      this.btnQuitBeforeSave.nativeElement.click();
      return false;
    }

    return true;
  }

  @HostListener('window:unload', ['$event'])
  public unloadHandler(event): void {
    if (this.isTestYFRunning) {
      this.stopTest(1);
    }
    if (this.isTestYORunning) {
      this.stopTest(0);
    }
  }

  // On click on navigator button (before reload or quit), we check if test is started and display message
  @HostListener('window:beforeunload', ['$event'])
  public beforeUnloadHander(event: Event): boolean {
    if (this.isTestYFRunning || this.isTestYORunning) {
      this.btnOpenQuit.nativeElement.click();
      // this.stopTest();
      return false;
    }

    if (this.isTestYFFinish || this.isTestYOFinish) {
      this.btnQuitBeforeSave.nativeElement.click();
      return false;
    }
    return true;
  }

  onCloseModal() {
    this.btnClose.nativeElement.click();
  }

  public onShoesSelectorValuesChange(value: ShoesSelectorValues): void {
    this.shoesSelectorValues = value;
    this.validate();
  }

  private validate(): void {
    if (
      this.shoesSelectorValues.shoesType &&
      this.shoesSelectorValues.helpStability !== undefined &&
      //a remttre une fois on ajoute la synchro
      (this.wiiConnected || (this.plateMonitor.KinventConnected && this.plateMonitor.synchActivate)) &&
      //(this.wiiConnected || (this.plateMonitor.KinventConnected)) &&
      !this.isTestYFRunning &&
      !this.isTestYORunning
    ) {
      this.isTestYOAvailable = true;
      this.isTestYFAvailable = true;
    }
  }

  public restartTestYO() {
    this.isTestYOAvailable = false;
    this.isTestYORunning = false;
    this.isTestYOFinish = false;
    this.timerYO.stop();
    this.timerYO.resetReverse(medicalConst.TIME_TO_ROMBERG);
    /*
    this.plateMonitor.firstTimeStampLeft = 0;
    this.plateMonitor.firstTimeStampRight = 0;*/
  }

  public playTestYO() {
    console.log('playTestYO');
    if (this.isTestYOAvailable) {
      this.playTest(EtatYeux.YO);
      this.isTestYORunning = true;
      this.isTestYOAvailable = false;
      this.isTestYFAvailable = false;
    }
  }

  public restartTestYF() {
    this.isTestYFAvailable = false;
    this.isTestYFRunning = false;
    this.isTestYFFinish = false;
    this.timerYF.stop();
    this.timerYF.resetReverse(medicalConst.TIME_TO_ROMBERG);
    /*
    const datas = this.dataRecorded.get(0);
    //get last value saved for open eyes
    const lastDataRecorder = datas[datas.length - 1];
    this.plateMonitor.lastNewTimeStampRight = lastDataRecorder.timestamp;
    this.plateMonitor.lastNewTimeStampLeft = lastDataRecorder.timestamp;*/
  }

  public playTestYF() {
    console.log('playTestYF');
    if (this.isTestYFAvailable) {
      this.playTest(EtatYeux.YF);
      this.isTestYFRunning = true;
      this.isTestYOAvailable = false;
      this.isTestYFAvailable = false;
    }
  }

  private writeValuestoCSV(datas, yeux) {
    let fileContent: string = '';
    let columHearders = [
      'Plate',
      'original hexa',
      'original TIMESTAMP',
      'TIMESTAMP',
      'BottomLeftCalcul_SensorsKG',
      'BottomRightCalcul_SensorsKG',
      'TopLeftCalcul_SensorsKG',
      'TopRightCalcul_SensorsKG',
    ];
    fileContent = columHearders.join('\t') + '\n';
    //tri par timestamp avant création du fichier
    //datas = datas.sort((a, b) => a.timestamp - b.timestamp);
    datas.forEach((dataLine) => {
      const newData = [
        dataLine.plate,
        dataLine.originalTimeSTampHexa,
        dataLine.originalTimeSTamp,
        dataLine.timestamp,
        dataLine.rearLeft,
        dataLine.rearRight,
        dataLine.frontLeft,
        dataLine.frontRight,
      ];
      fileContent += newData.join('\t') + '\n';
    });
    const todayDate = this.localDatePipe.parse(new Date(), 'dd_MM_yyyyHH:mm:ss');
    const filename = todayDate + '_' + yeux;
    const blob = new Blob([fileContent], { type: 'text/plain;charset=utf-8;' });

    // Ici, vous pourriez envoyer le Blob à un serveur par exemple
    const formData = new FormData();
    formData.append('file', blob, 'filename.txt');
    /*
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }*/
  }

  public calculatePourcentageSupport(yeux, datas) {
    let prcSupportRightValues = [];
    let prcSupportLeftValues = [];
    let valueRight = 0;
    let valueLeft = 0;
    let value = 0;
    datas = datas.sort((a, b) => a.timestamp - b.timestamp);
    datas.forEach((data) => {
      //get somme des values for each plate
      if (data.plate == 'RIGHT') {
        valueRight = data.rearLeft + data.rearRight + data.frontLeft + data.frontRight;
        value = value + valueRight;
      } else if (data.plate == 'LEFT') {
        valueLeft = data.rearLeft + data.rearRight + data.frontLeft + data.frontRight;
        value = value + valueLeft;
      }
      if (valueRight > 0 && valueLeft > 0 && value > 0) {
        const prcSupportRight = (valueRight * 100) / value;
        const prcSupportLeft = (valueLeft * 100) / value;
        prcSupportRightValues.push(prcSupportRight);
        prcSupportLeftValues.push(prcSupportLeft);
        value = 0;
        valueLeft = 0;
        valueRight = 0;
      }
    });
    //get the average of each values
    if (yeux == 0)
      this.openedEyesPrcLeftSupport = prcSupportLeftValues.reduce((p, c) => p + c, 0) / prcSupportLeftValues.length;
    else if (yeux == 1)
      this.closedEyesPrcLeftSupport = prcSupportLeftValues.reduce((p, c) => p + c, 0) / prcSupportLeftValues.length;
  }

  private async doRecordingFromKIvent(yeux: EtatYeux) {
    console.log('call recording from KInvent from ', yeux);
    let datas = [];
    this.nbMeasuresKInvent = 0;
    await this.plateMonitor.record(yeux).then(() => {
      this.plateMonitor.measures.subscribe({
        next: (value) => {
          //console.log('value from doRecordingFromKIvent ', value);
          datas.push(value);
          this.nbMeasuresKInvent++;
        },
        complete: () => {
          //console.log("value measure is ", datas, " for yeux ", yeux);
          if (yeux == 0) {
            this.kInventPlateService.buildTempFolder(this.currentRombergRecordingSession).subscribe(() => {
              console.log('Romberg push Measure : Temporary repository created');
            });
          }
          this.dataRecorded.set(yeux, datas);
          this.saveOriginalData(yeux, datas);
          this.calculatePourcentageSupport(yeux, datas);
        },
      });
      //console.log('this.dataRecorded ', this.dataRecorded);
    });
  }

  public playTest(yeux: EtatYeux): void {
    console.log('playTest');
    this.currentActiveRecording = yeux;

    if (yeux === EtatYeux.YO) {
      // reset des etats (on recommence du début)
      this.timerYO.resetReverse(medicalConst.TIME_TO_ROMBERG);
      this.timerYO.startReverse();
    } else {
      // seul le test yeux fermé est mis à zéro
      this.timerYF.resetReverse(medicalConst.TIME_TO_ROMBERG);
      this.timerYF.startReverse();
    }
    // on désactive les bouton de demarrage de test
    // Réinitialiser le compteur
    console.log('doRecording');
    this.dateShared = new Date().toISOString();
    if (this.plateMonitor.KinventConnected) {
      this.typePlate = 'Kinvent';
      this.doRecordingFromKIvent(yeux);
    } else {
      this.typePlate = 'Wii';
      this.subscriptions.push(
        this.wiiBoardService
          .doRecording(
            this.currentRombergRecordingSession,
            this.dateShared,
            this.currentActiveRecording,
            medicalConst.TIME_TO_ROMBERG,
          )
          .subscribe(
            () => console.log('Démarrage de test pour les mesures'),
            (error) => {
              console.log(error);
            },
          ),
      );
    }
  }

  public followRecording(val: { state: WiiBoardState; secondsLeft: number }) {
    if (val.secondsLeft === 0 && !val.state.acquisitionActive) {
      this.stop();
    }
  }

  private showModal(modalName: string) {
    this.modals.filter((e) => e.name === modalName).forEach((e) => e.show());
  }
  private hideModal(modalName: string) {
    this.modals.filter((e) => e.name === modalName).forEach((e) => e.hide());
  }

  public runCalculation(appreciations): void {
    //console.log('Appreciations : ' + JSON.stringify(appreciations));
    this.visualAppreciations = appreciations;
    this.error = false;
    this.btnCloseAssessmentModal.nativeElement.click();
    if (!this.isAlgorithmFinish) {
      this.showModal(this.waitAnalyseName);
      this.subscriptions.push(
        this.pushMeasures()
          .pipe(
            retryWhen((errors) => this.askRetry(errors)),
            flatMap(() => this.waitForAnalysisResults()),
          )
          .subscribe(
            () => {
              this.hideModal(this.waitAnalyseName);
              this.isAlgorithmFinish = true;
              this.goToResult();
              this.snackBarService.show(this.translateService.instant('app.test.mesure.success'), 'success');
            },
            (error) => {
              console.error('RombergComponent : Err-Romberg-01 ', error);
              this.hideModal(this.waitAnalyseName);
              const errMsg = this.translateService.instant(error.message);
              this.snackBarService.show(errMsg, 'danger');
              //this.snackBarService.show('Err-Romberg-01 : ' + error.message, 'danger');
              this.calculResult = false;
            },
            () => {
              this.calculResult = false;
            },
          ),
      );
    }
  }

  public stopRecordingFromKInvent(yeux) {
    this.plateMonitor.stopRecord().then((value) => {
      if (yeux === EtatYeux.YF) this.restartTestYF();
      else if (yeux === EtatYeux.YO) this.restartTestYO();
    });
  }

  public stopTest(yeux: EtatYeux): void {
    if (this.plateMonitor.KinventConnected) {
      this.stopRecordingFromKInvent(yeux);
    } else {
      this.wiiBoardService
        .stopRecordingSOS()
        .then((value) => {
          if (yeux === EtatYeux.YF) this.restartTestYF();
          else if (yeux === EtatYeux.YO) this.restartTestYO();
        })
        .catch((error) => {
          this.hideModal(this.waitAnalyseName);
          console.error('RombergComponent : Err-Romberg-02 ', error);
          const errMsg = this.translateService.instant('Err-Romberg-02');
          this.snackBarService.show(errMsg, 'danger');
        });
    }
  }

  private askRetry(errors: Observable<any>): Observable<any> {
    // the delay is to handle time to display and hide box
    // (from previous try)
    console.error('askRetry');

    return errors.pipe(
      delay(1000),
      flatMap((err) => {
        console.error('askRetry', err);
        this.error = true;
        this.hideModal(this.waitAnalyseName);
        console.error('RombergComponent : Err-Romberg-03 ', err);
        const errMsg = this.translateService.instant('Err-Romberg-03');
        this.snackBarService.show(errMsg, 'danger');
        return this.retryResponse;
      }),
      map((response) => {
        console.error('askRetry', response);
        if (response) {
          return 'retry';
        } else {
          throw new Error('CancelRetry');
        }
      }),
    );
  }

  private saveOriginalData(yeux: number, datas: any) {
    //push original measure
    this.kInventPlateService
      .SaveOriginalDataByPlate(this.currentRombergRecordingSession, this.dateShared, yeux, datas)
      .subscribe(() => {
        console.log('Romberg push Measure : original measure pushed for ', yeux);
      });
  }

  private pushMeasures(): Observable<any[]> {
    this.dateShared = new Date().toISOString();
    if (this.plateMonitor.KinventConnected) {
      return of(EtatYeux.YF, EtatYeux.YO).pipe(
        flatMap((yeux) =>
          this.kInventPlateService.pushMeasure(
            this.currentRombergRecordingSession,
            this.dateShared,
            yeux,
            this.dataRecorded.get(yeux),
          ),
        ),
        bufferCount(2),
        catchError((err, caught) => {
          console.log('RombergComponent : Err-Romberg-04 ', err);
          const errMsg = this.translateService.instant('Err-Romberg-04');
          this.snackBarService.show(errMsg, 'danger');
          return of([]);
        }),
      );
    } else {
      return of(EtatYeux.YF, EtatYeux.YO).pipe(
        flatMap((yeux) => this.wiiBoardService.pushMeasure(this.currentRombergRecordingSession, this.dateShared, yeux)),
        bufferCount(2),
        catchError((err, caught) => {
          console.log('RombergComponent : Err-Romberg-01 ', err);
          const errMsg = this.translateService.instant('Err-Romberg-01');
          this.snackBarService.show(errMsg, 'danger');
          return of([]);
        }),
      );
    }
  }

  private waitForAnalysisResults(): Observable<AnalysisResults> {
    const parameters = this.buildAnalysisParameters();
    this.calculResult = true;
    return fromPromise(this.orchestrator.startDataAnalysis(this.currentRombergRecordingSession, parameters)).pipe(
      flatMap(() => fromPromise(this.orchestrator.getAnalysisResultsForSession(this.currentRombergRecordingSession))),
    );
  }

  private buildAnalysisParameters(): AnalysisParameters {
    const out = new AnalysisParameters();
    out.taille = this.patient.height;
    out.poids = this.patient.weight;
    out.date = new Date();
    out.sexe = this.patient.gender;
    // out.lateralisation = Handedness[this.patient.lateralization].slice(0, 1).toLocaleUpperCase();
    out.lateralisation = this.patient.lateralization;
    out.chaussure = this.shoesSelectorValues.shoesType;
    out.typebilan = 4;
    out.duree = medicalConst.TIME_TO_ROMBERG;
    return out;
  }

  public restartTest() {
    this.isTestValidated = false;
    this.isAlgorithmFinish = false;
    this.restartTestYO();
    this.restartTestYF();
  }

  resetModal() {
    this.rombergTutorial.reset();
  }

  public async goToResult() {
    if (this.isTestYOFinish && this.isTestYFFinish) {
      this.isTestValidated = true;
      console.log('goToResult');
      this.getPrcSupportValues();
      const results = this.analysisResultsRepository.get(this.currentRombergRecordingSession);
      results
        .then((value) => {
          this.analyisResult = value;
          this.checkLimitResults();
          this.data = this.buildSummary(value, this.shoesSelectorValues);
          this.getGraphComparaison();
          this.transformBilanToGraph();
        })
        .catch((error) => {
          console.log('RombergComponent : Err-Romberg-05 ', error);
          const errMsg = this.translateService.instant('Err-Romberg-05');
          this.snackBarService.show(errMsg, 'danger');
        });
      this.rombergService.rombergControllerFindAllRombergByPatient(this.patient.id).subscribe((res) => {
        this.listTestRombergs = res;
        this.addBilanToGraph(this.listTestRombergs);
      });
    }
  }

  public getPrcSupportValues() {
    // OpenedEyesLeftSupport
    this.minOpenedEyesLeftSupport = this.openedEyesPrcLeftSupport > 50 ? 100 - this.openedEyesPrcLeftSupport : 50;
    this.maxOpenedEyesLeftSupport = this.openedEyesPrcLeftSupport < 50 ? 100 - this.openedEyesPrcLeftSupport : 50;

    this.optionsopenedEyesLeftSupport = {
      floor: 0,
      ceil: 100,
      //showTicksValues: true,
      step: 10,
      readOnly: true,
      translate: (value: number): string => {
        if (value == 0) {
          return this.translateService.instant('Gauche');
        } else if (value == 100) {
          return this.translateService.instant('Droite');
        } else {
          return '';
        }
      },
      getPointerColor: (value: number): string => {
        if (value != 50) {
          return '#ec9a1f';
        }
        if (value == 50) {
          return '#70757a';
        }
      },
    };

    this.minClosedEyesLeftSupport = this.closedEyesPrcLeftSupport > 50 ? 100 - this.closedEyesPrcLeftSupport : 50;
    this.maxClosedEyesLeftSupport = this.closedEyesPrcLeftSupport < 50 ? 100 - this.closedEyesPrcLeftSupport : 50;

    this.optionsclosedEyesLeftSupport = {
      floor: 0,
      ceil: 100,
      //showTicksValues: true,
      step: 10,
      readOnly: true,
      translate: (value: number): string => {
        if (value == 0) {
          return this.translateService.instant('Gauche');
        } else if (value == 100) {
          return this.translateService.instant('Droite');
        } else {
          return '';
        }
      },
      getPointerColor: (value: number): string => {
        if (value != 50) {
          return '#ec9a1f';
        }
        if (value == 50) {
          return '#70757a';
        }
      },
    };
  }

  /**
   * Method which will check all romberg results to determine if they are off limit or not.
   */
  private checkLimitResults(): void {
    // Analysis Score
    if (this.analyisResult.ScoreYO < this.minEyesScore || this.analyisResult.ScoreYO > this.maxEyesScore) {
      this.isOffLimitOpenedEyesScore = true;
    }
    if (this.analyisResult.ScoreYF < this.minEyesScore || this.analyisResult.ScoreYF > this.maxEyesScore) {
      this.isOffLimitClosedEyesScore = true;
    }
    // Analysis Area
    if (this.analyisResult.SurfaceYO < this.minEyesArea || this.analyisResult.SurfaceYO > this.maxEyesArea) {
      this.isOffLimitOpenedEyesArea = true;
    }
    if (this.analyisResult.SurfaceYF < this.minEyesArea || this.analyisResult.SurfaceYF > this.maxEyesArea) {
      this.isOffLimitClosedEyesArea = true;
    }
    // Analysis Sway Density
    if (
      this.analyisResult.SwayDensityYO < this.minEyesSwayDensity ||
      this.analyisResult.SwayDensityYO > this.maxEyesSwayDensity
    ) {
      this.isOffLimitOpenedEyesSwayDensity = true;
    }
    if (
      this.analyisResult.SwayDensityYF < this.minEyesSwayDensity ||
      this.analyisResult.SwayDensityYF > this.maxEyesSwayDensity
    ) {
      this.isOffLimitClosedEyesSwayDensity = true;
    }
    // Analysis Lateral Variance
    if (
      this.analyisResult.VarianceLateraleYO < this.minEyesLateralVariance ||
      this.analyisResult.VarianceLateraleYO > this.maxEyesLateralVariance
    ) {
      this.isOffLimitOpenedEyesLateralVariance = true;
    }
    if (
      this.analyisResult.VarianceLateraleYF < this.minEyesLateralVariance ||
      this.analyisResult.VarianceLateraleYF > this.maxEyesLateralVariance
    ) {
      this.isOffLimitClosedEyesLateralVariance = true;
    }
    // Analysis Average Speed
    if (
      this.analyisResult.SpeedMoyYO < this.minEyesAverageSpeed ||
      this.analyisResult.SpeedMoyYO > this.maxEyesAverageSpeed
    ) {
      this.isOffLimitOpenedEyesAverageSpeed = true;
    }
    if (
      this.analyisResult.SpeedMoyYF < this.minEyesAverageSpeed ||
      this.analyisResult.SpeedMoyYF > this.maxEyesAverageSpeed
    ) {
      this.isOffLimitClosedEyesAverageSpeed = true;
    }
  }

  private buildSummary(results: AnalysisResults, preamble: ShoesSelectorValues): RombergSummary {
    console.log('buildSummary');
    const out = new RombergSummary();

    out.shoesType = preamble.shoesType;
    out.helpStability = preamble.helpStability;
    out.ScoreYF = results.ScoreYF;
    out.ScoreYO = results.ScoreYO;
    out.SpeedMoyYF = results.SpeedMoyYF;
    out.SpeedMoyYO = results.SpeedMoyYO;
    out.SurfaceYF = results.SurfaceYF;
    out.SurfaceYO = results.SurfaceYO;
    out.SwayDensityYF = results.SwayDensityYF;
    out.SwayDensityYO = results.SwayDensityYO;
    out.VarianceLateraleYF = results.VarianceLateraleYF;
    out.VarianceLateraleYO = results.VarianceLateraleYO;
    out.Duration = medicalConst.TIME_TO_ROMBERG;
    out.statsym = this.transformUrl(results.graphes.stasym);
    out.statok = this.transformUrl(results.graphes.statok);
    out.stabilo = this.transformUrl(results.graphes.stabilo);
    return out;
  }

  private transformUrl(url: string) {
    //return url.replace('https://', 'http://');
    return url;
  }

  public closeGraphModal() {
    this.rombergTutorial.reset();
    this.btnCloseGraphModal.nativeElement.click();
  }

  public openGraphModal() {
    this.graphTitle = this.translateService.instant('Checkup.Romberg.Breadcrumb.StatoKinesiGram');
    this.isStatokinesigram = true;
    this.isStatokinesigramStandard = true;
    this.isStatokinesigramAsym = false;
    this.isStabilogram = false;
    this.isComparison = false;
    this.isLongitudinal = false;
    this.standardGraph = true;
    this.asymetrieGraph = false;
  }

  public goToGraph(graph: string) {
    if (graph === 'statokinesigram') {
      this.graphTitle = this.translateService.instant('Checkup.Romberg.Breadcrumb.StatoKinesiGram');
      this.isStatokinesigram = true;
      this.isStatokinesigramStandard = true;
      this.isStatokinesigramAsym = false;
      this.isStabilogram = false;
      this.isComparison = false;
      this.isLongitudinal = false;
    } else if (graph === 'stabilogram') {
      this.graphTitle = this.translateService.instant('Checkup.Romberg.Breadcrumb.StabiloGram');
      this.isStatokinesigram = false;
      this.isStatokinesigramStandard = false;
      this.isStatokinesigramAsym = false;
      this.isStabilogram = true;
      this.isComparison = false;
      this.isLongitudinal = false;
    } else if (graph === 'comparison') {
      this.graphTitle = this.translateService.instant('Checkup.Romberg.Breadcrumb.Comparison');
      this.isStatokinesigram = false;
      this.isStatokinesigramStandard = false;
      this.isStatokinesigramAsym = false;
      this.isStabilogram = false;
      this.isComparison = true;
      this.isLongitudinal = false;
    } else if (graph === 'longitudinal') {
      this.graphTitle = this.translateService.instant('Checkup.Romberg.Breadcrumb.Longitudinal');
      this.isStatokinesigram = false;
      this.isStatokinesigramStandard = false;
      this.isStatokinesigramAsym = false;
      this.isStabilogram = false;
      this.isComparison = false;
      this.isLongitudinal = true;
    }
  }

  public zoomGraph() {
    this.zoom = true;
  }

  /**
   * Close the zoom page
   */
  public closeChart() {
    this.zoom = false;
  }

  public changeToStandard() {
    this.standardGraph = true;
    this.asymetrieGraph = false;
  }

  public changeToAsymetrie() {
    this.standardGraph = false;
    this.asymetrieGraph = true;
  }

  /**
   * Calls the service bilan-marche to get data
   */
  private async getGraphComparaison() {
    this.recupComparaison = false;
    this.comparisonDatas = await this.rombergComparaisonService.getAllGraph(
      this.choiceOfComparison,
      this.analyisResult,
    );

    this.recupComparaison = true;
  }

  public onSelectGraphComparaison(selection: string) {
    this.choiceOfComparison = selection;
    this.getGraphComparaison();
  }

  /**
   * Zoom on a graph
   * @param e object pointed
   */
  public comparisonChartClicked(e: ComparisonGraph): void {
    this.zoom = true;
    this.comparisonGraphSelected = e;
  }

  //add the existing bilan to graphs
  private addBilanToGraph(bilans: RombergWithRelations[]) {
    for (const bilan of bilans) {
      const date = bilan.date;
      this.graphs[4].graphYF.push({ x: date, y: Number(bilan.closedEyesLateralVariance) });
      this.graphs[4].graphYO.push({ x: date, y: Number(bilan.openedEyesLateralVariance) });
      this.graphs[3].graphYF.push({ x: date, y: Number(bilan.closedEyesArea) });
      this.graphs[3].graphYO.push({ x: date, y: Number(bilan.openedEyesArea) });
      this.graphs[2].graphYF.push({ x: date, y: Number(bilan.closedEyesSwayDensity) });
      this.graphs[2].graphYO.push({ x: date, y: Number(bilan.openedEyesSwayDensity) });
      this.graphs[1].graphYF.push({ x: date, y: Number(bilan.closedEyesAverageSpeed) });
      this.graphs[1].graphYO.push({ x: date, y: Number(bilan.openedEyesAverageSpeed) });
      this.graphs[0].graphYF.push({ x: date, y: bilan.closedEyesScore });
      this.graphs[0].graphYO.push({ x: date, y: bilan.openedEyesScore });
    }
  }

  // push the Romberg bilan values into eaach graph for each property
  private transformBilanToGraph() {
    const date = new Date();
    this.graphs[4].graphYF.push({ x: date, y: Number(this.data.VarianceLateraleYF) });
    this.graphs[4].graphYO.push({ x: date, y: Number(this.data.VarianceLateraleYO) });
    this.graphs[3].graphYF.push({ x: date, y: Number(this.data.SurfaceYF) });
    this.graphs[3].graphYO.push({ x: date, y: Number(this.data.SurfaceYO) });
    this.graphs[2].graphYF.push({ x: date, y: Number(this.data.SwayDensityYF) });
    this.graphs[2].graphYO.push({ x: date, y: Number(this.data.SwayDensityYO) });
    this.graphs[1].graphYF.push({ x: date, y: Number(this.data.SpeedMoyYF) });
    this.graphs[1].graphYO.push({ x: date, y: Number(this.data.SpeedMoyYO) });
    this.graphs[0].graphYF.push({ x: date, y: this.data.ScoreYF });
    this.graphs[0].graphYO.push({ x: date, y: this.data.ScoreYO });
  }

  /**
   * Zoom on a graph
   * @param e object pointed
   */
  public chartClicked(e: LongitudinalGraph): void {
    this.zoom = true;
    this.graphSelected = e;
  }
  private stop(): void {
    this.timerYO.stop();
    this.state.setStop();
  }

  // This method is call when we click on replay test
  public onReplayTest(): void {
    this.isTestYOFinish = false;
    this.isTestYFFinish = false;
    this.isAlgorithmFinish = false;
  }

  // This method is call when we click on modify appreciation
  public onModifyAppreciation(): void {
    this.btnOpenAssessmentModal.nativeElement.click();
  }

  public updateLastTestForPatient() {
    const updatePatient: UpdatePatient = {
      id: this.patient.id,
      lasttestdate: new Date(),
      lasttesttype: 2,
    };

    this.patientDataService.updatePatient(updatePatient).subscribe((data) => {
      this.patientDataService.getPatientById(this.patient.id).subscribe((patient) => {
        this.dataService.setPatient(patient);
      });
    });
  }

  /**
   * Method which wil get all data, create a romberg variable and save it in the database.
   */
  public validateAssessment(): void {
    /*
    const footwear: Footwear = this.footwearService
        .footwearControllerFindByAlias(this.shoesSelectorValues.shoesType, this.translateService.currentLang)
        .toPromise();
      console.log('find footwear', footwear);

      const helpStability: WalkingAid = this.walkingAidService
        .walkingAidControllerFindByAlias(this.shoesSelectorValues.helpStability, this.translateService.currentLang)
        .toPromise();
    */
    let romberg: NewRomberg = {
      shoesType: this.shoesSelectorValues.shoesType,
      stabilityAid: this.shoesSelectorValues.helpStability,
      testDuration: medicalConst.TIME_TO_ROMBERG,
      typePlate: this.typePlate,
      // Parse is used because we get string due to the use of trunc method.
      //selwa : remove parsfloat and toFixed(2) because of VarianceLateral
      openedEyesScore: this.analyisResult.ScoreYO,
      closedEyesScore: this.analyisResult.ScoreYF,
      openedEyesArea: this.analyisResult.SurfaceYO,
      closedEyesArea: this.analyisResult.SurfaceYF,
      openedEyesSwayDensity: this.analyisResult.SwayDensityYO,
      closedEyesSwayDensity: this.analyisResult.SwayDensityYF,
      openedEyesLateralVariance: this.analyisResult.VarianceLateraleYO,
      closedEyesLateralVariance: this.analyisResult.VarianceLateraleYF,
      openedEyesAverageSpeed: this.analyisResult.SpeedMoyYO,
      closedEyesAverageSpeed: this.analyisResult.SpeedMoyYF,
      openedEyesBalanceQuality: null,
      closedEyesBalanceQuality: null,
      walkingQuality: null,
      fall: false,
      openedEyesLeftSupport: this.openedEyesPrcLeftSupport ? this.openedEyesPrcLeftSupport : 0,
      closedEyesLeftSupport: this.closedEyesPrcLeftSupport ? this.closedEyesPrcLeftSupport : 0,
      scoreProfil: this.scoreProfil,
    };
    romberg = this.checkVisualAppreciations(romberg);

    this.rombergService.rombergControllerCreate(this.patient.id, romberg).subscribe(
      async (data) => {
        this.isTestYFFinish = false;
        this.isTestYOFinish = false;
        this.snackBarService.show(this.translateService.instant('app.romberg.successSave'), 'success');

        const id = data.id;

        await this.persistRombergBilan(romberg, id);
        await this.updateLastTestForPatient();
        await this.forwardReportWithNewId(data);
      },
      (error) => {
        console.log('RombergComponent : Err-Romberg-06 ', error);
        const errMsg = this.translateService.instant('Err-Romberg-06');
        this.snackBarService.show(errMsg, 'danger');
      },
    );
  }

  /**
   * Method which will get data romberg using the new ID and forward to report: fix bug AB-374.
   */
  public forwardReportWithNewId(data) {
    //once persist data we had to change the obj to get value from the new walking
    //get object walkAnalysisResult
    this.remoteInteractionRombergService.getAnalysisResultsForBilan(data.id).then((value) => {
      const obj = {
        rombergAnalysisResult: value,
        shoesSelectorValues: this.shoesSelectorValues,
        typeRapport: this.typeRapport,
        rombergAssessments: this.visualAppreciations,
        dateConsultation: data.date,
        typePlate: this.typePlate,
        patientId: this.patient.id,
        openedEyesLeftSupport: this.openedEyesPrcLeftSupport,
        closedEyesLeftSupport: this.closedEyesPrcLeftSupport,
      };

      this.router.navigateByUrl('/rapport', { state: obj });
    });
  }

  /**
   * Method which will persist data romberg in the server: change guid to bilanId.
   */
  private async persistRombergBilan(newRomberg: NewRomberg, id: number): Promise<any> {
    try {
      const guid: string = this.currentRombergRecordingSession;
      const url: string = this.rombergProxyBaseUri + '/analysis/' + guid + '/persist';

      const footwear: Footwear = await this.footwearService
        .footwearControllerFindByAlias(newRomberg.shoesType, this.translateService.currentLang)
        .toPromise();

      const helpStability: WalkingAid = await this.walkingAidService
        .walkingAidControllerFindByAlias(newRomberg.stabilityAid, this.translateService.currentLang)
        .toPromise();

      const body: any = {};
      //newRomberg.Duration = medicalConst.TIME_TO_ROMBERG;
      body.bilanId = id;
      body.duration = medicalConst.TIME_TO_ROMBERG;
      // TODO Set the correct ID
      body.stablizationHelpId = helpStability.id;
      body.footwearId = footwear.id;

      return this.httpClient.post(url, body).toPromise();
    } catch (error) {
      console.error('RombergComponent : Err-Romberg-07 ', error);
      const errMsg = this.translateService.instant('Err-Romberg-07');
      this.snackBarService.show(errMsg, 'danger');
      //this.notificationService.pushErrorNotifications('Error while persist romberg');
      return Promise.reject<any>(error);
    }
  }

  /**
   * Method to analyze each visual appreciations and check if they were evaluated or not.
   * If it's the case, the corresponding value is saved in the variable.
   */
  private checkVisualAppreciations(romberg: NewRomberg): NewRomberg {
    if (this.visualAppreciations[0].nonEvaluated === false) {
      romberg.openedEyesBalanceQuality = this.visualAppreciations[0].value;
    }
    if (this.visualAppreciations[1].nonEvaluated === false) {
      romberg.closedEyesBalanceQuality = this.visualAppreciations[1].value;
    }
    if (this.visualAppreciations[2].nonEvaluated === false) {
      romberg.walkingQuality = this.visualAppreciations[2].value;
    }
    if (this.visualAppreciations[3].value === 1) {
      romberg.fall = true;
    }
    return romberg;
  }

  public onSelectPage(newPage: string) {
    this.newPage = newPage;
  }

  public goTo() {
    this.isTestYFFinish = false;
    this.isTestYOFinish = false;
    this.router.navigate([this.newPage]);
  }
}
