<div class="comparison-container" *ngIf="results">
  <meta charset="utf-8" />
  <!--
  <div class="optionsBar row">
    <button
      *ngFor="let title of typesOfComparisons"
      class="btn btn-primary choice-button"
      (click)="onSelect(title)"
      type="button"
      [class.selected]="title === choiceOfComparison"
    >
      {{ title | translate }}
    </button>
  </div>
-->
  <div class="optionsBar row" *ngIf="resultsToCompare">
    <span>
      <img src="assets/images/icons/rounded-rectangle-green.svg" style="width:25px; height:30px;" />
      {{ testData.date | localdate: 'datetime' }}</span
    >
    <span>
      <img src="assets/images/icons/rounded-rectangle.svg" style="width:25px; height:30px;" />
      {{ walkToCompare.date | localdate: 'datetime' }}</span
    >
  </div>
  <div *ngIf="recup" class="test">
    <ng-template #zoompart>
      <div class="zoom" (click)="close()">
        <app-graph-horizontal [title]="graphSelected.title" [data]="graphSelected"></app-graph-horizontal>
      </div>
    </ng-template>

    <div *ngIf="!zoom; else zoompart" class="setOfGraph">
      <div *ngFor="let data of datas; let i = index" class="graphic2" (click)="chartClicked(data)">
        <app-graph-horizontal [title]="data.title" [data]="data"></app-graph-horizontal>
      </div>
    </div>
  </div>
</div>
