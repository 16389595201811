<nav class="navbar navbar-expand-lg sticky-top">
  <div class="row" style="width: 100%;">
    <div class="col-md-4 container-title flex-column">
      <h3>
        {{ 'Patient.History.List' | translate }}
      </h3>
    </div>
    <div class="col-md-8" *ngIf="patient">
      <div class="container-foldernumber" *ngIf="patient">
        {{ 'Patient.Profil.fragility_score' | translate }} :
        {{ lastScoreFragility ? lastScoreFragility : '-' }}
      </div>
    </div>
  </div>
</nav>
