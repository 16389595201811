<app-custom-navbar [patient]="patient" [returnTitle]="returnTitle"></app-custom-navbar>
<app-courses-breadcrumb-navbar [testName]="testName | translate"></app-courses-breadcrumb-navbar>
<div class="col-md-12">
  <div class="row">
    <div class="col-md-3 fried-navbar">
      <app-questionnary-list
        #questionnaryList
        (questionnarySelected)="onQuestionnarySelected($event)"
        (displayQuestionnary)="onDisplayQuestionnary($event)"
        [disabled]="startTest"
      ></app-questionnary-list>
    </div>

    <div class="col-md-9 description-container" *ngIf="questionnaryDisplayed && !startTest">
      <app-questionnary-infos
        [displayStart]="canDisplayStart"
        [questionnary]="questionnaryDisplayed"
        (startQuestionnary)="onStartQuestionnary($event)"
      ></app-questionnary-infos>
    </div>
    <div class="col-md-9 questionnary-container" *ngIf="startTest && questionnaryDetails">
      <app-questionnary-steps
        #questionnaryStep
        (validateQuestionnary)="onValidateQuestionnary($event)"
        (stopQuestionnary)="onStopQuestionnary()"
        [questionnaryDetails]="questionnaryDetails"
      >
      </app-questionnary-steps>
    </div>
  </div>
</div>

<div class="col-md-12 footer-content-component">
  <button
    *ngIf="!startTest"
    class="btn btn-primary"
    [disabled]="questionnariesList.length === 0"
    (click)="startQuestionnaries()"
  >
    {{ 'app.questionnary.start' | translate }}
  </button>
</div>
