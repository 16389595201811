<div class="col-md-12">
  <div class="row">
    <div class="col-md-3 p-0">
      Configuration de la tablette
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 simple-content-component">
      <app-grip-monitor [config]="true" #gripMonitor></app-grip-monitor>
    </div>
    <div class="col-md-4 simple-content-component">
      <app-information-connexion [config]="true"></app-information-connexion>
    </div>
  </div>
</div>
