<div class="main-container flex-column" *ngIf="currentPatient">
  <div class="flex-row header">
    <div class="info-patient flex-column wrap">
      <p class="name-patient">
        {{ currentPatient.firstName }} {{ currentPatient.lastName }} - {{ age }} {{ 'ans' | translate }}
      </p>
      <p class="folder-number-patient">
        {{ 'Patient.FolderNumber' | translate }} : {{ currentPatient.folderNumber ? currentPatient.folderNumber : '-' }}
      </p>
    </div>

    <div class="div-buttons flex-row">
      <button *ngIf="checkCanDelegate()" class="btn btn-secondary" (click)="clickOnDelegation()">
        <img src="assets/images/icons/delegate.svg" width="24" height="24" />
        {{ 'Déléguer' | translate }}
      </button>

      <button *ngIf="canDelete" class="btn btn-secondary" (click)="clickOnDeletePatient()">
        <i class="trash fa fa-trash"></i>
        {{ 'Patient.Delete' | translate }}
      </button>
    </div>
  </div>

  <div class="flex-row header-content">
    <div class="content flex-column">
      <div class="div-msg-info">
        <p class="msg-info">{{ 'Patient.Profil.start_or_choose_course' | translate }}</p>
      </div>

      <div class="div-buttons flex-row text-uppercase">
        <button
          class="btn btn-forth"
          data-toggle="modal"
          data-target="#QuestionnaireMandataryModal"
          (click)="launchCourse()"
          [disabled]="!checkCanRunTest() && finishLoadQuestionnary"
        >
          {{ 'Patient.Profil.launch_course' | translate }}
          <img src="/assets/images/icons/delegate.svg" width="20" height="20" *ngIf="hasCoursInProgress" />
        </button>
        <button
          class="btn btn-fifth"
          data-toggle="modal"
          data-target="#QuestionnaireMandataryModal"
          (click)="launchTest()"
          [disabled]="!checkCanRunTest() && finishLoadQuestionnary"
        >
          {{ 'Patient.Profil.launch_test_only' | translate }}
        </button>
        <button class="btn btn-fifth" (click)="goTo('/courses/fried/questionnary')" [disabled]="!checkCanRunTest()">
          {{ 'app.questionnary.start.bis' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal questionnaire mandatary -->
<div
  class="modal fade"
  id="QuestionnaireMandataryModal"
  *ngIf="hasQuestionnaryMandatary"
  tabindex="-1"
  role="dialog"
  aria-labelledby="QuestionnaireMandataryTitle"
  aria-hidden="true"
  #QuestionnaireMandataryModal
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="QuestionnaireMandataryTitle">
          {{ 'app.questionnary.mandatary' | translate }}
        </h5>
        <button #btnCloseQuestionnaireMandatary type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="confirm-text">
          {{ 'app.questionnary.mandatary.message' | translate }}
          <br />
          <ng-container *ngFor="let quest of questionaireMandatryIds">
            <br />
            <span class="title"> -{{ quest.name }}</span>
          </ng-container>
        </div>
      </div>

      <div class="flex-row justify-content-around modal-footer">
        <button class="btn btn-tertiary" (click)="goToQuestionnayMandatary()">{{ 'OUI' | translate }}</button>
        <button
          #btnCloseQuestionnaireMandatary
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          aria-label="Close"
        >
          {{ 'app.questionnary.cancel' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
