import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalysisResults } from 'src/app/modules/walk/models/analysis-results.model';

@Component({
  selector: 'app-walk-graphics',
  templateUrl: './walk-graphics.component.html',
  styleUrls: ['./walk-graphics.component.scss'],
})
export class WalkGraphicsComponent implements OnInit {
  @Input() resultsWalk: AnalysisResults;
  @Input() resultsWalkToCompare: AnalysisResults;
  @Output() changeTitle: EventEmitter<string> = new EventEmitter<string>();
  @Input() testData: any;
  @Input() walkToCompare: any;
  @Input() currentWalkRecordingSession;

  public title: string = '';
  public sourceImg: string = '';
  public expand: boolean = false;
  public graphicDescription: string = '';

  public displayLocogram: boolean = true;
  public displayRotagram: boolean = false;
  public displayComparaison: boolean = false;
  public displaySemiogram: boolean = false;
  public displayLongitudinal: boolean = false;

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.changeTitle.emit(this.translateService.instant('Checkup.Walk.Breadcrumb.Locogram'));
  }

  public goToLocogram(): void {
    this.displayRotagram = false;
    this.displayLocogram = true;
    this.changeTitle.emit(this.translateService.instant('Checkup.Walk.Breadcrumb.Locogram'));
  }

  public goToRotagram(): void {
    this.displayLocogram = false;
    this.displayComparaison = false;
    this.displayRotagram = true;
    this.changeTitle.emit(this.translateService.instant('Checkup.Walk.Breadcrumb.Rotagram'));
  }

  public goToComparaison(): void {
    this.displaySemiogram = false;
    this.displayRotagram = false;
    this.displayComparaison = true;
    this.changeTitle.emit(this.translateService.instant('Checkup.Walk.Breadcrumb.Comparison'));
  }

  public goToSemiogram(): void {
    this.displayComparaison = false;
    this.displayLongitudinal = false;
    this.displaySemiogram = true;
    this.changeTitle.emit(this.translateService.instant('Checkup.Walk.Breadcrumb.Semiogram'));
  }

  public goToLongitudinal(): void {
    this.displaySemiogram = false;
    this.displayLongitudinal = true;
    this.changeTitle.emit(this.translateService.instant('Checkup.Walk.Breadcrumb.Longitudinal'));
  }
}
