<mat-sidenav-container class="main-container">
  <mat-sidenav class="sidenav" #sidenav mode="side" opened="true">
    <!-- DIV CONTAIN HEADER LIST -->
    <div class="flex-column big-div">
      <div class="flex-row div-title header-block">
        <p class="title">
          {{ 'Mes_Patients' | translate }}
        </p>
        <img (click)="closeSidenav(sidenav)" src="assets/images/icons/left-chevron.svg" width="40" height="40" />
      </div>

      <div class="flex-row div-create-patient header-block">
        <button
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#modalPatientCreation"
          data-backdrop="static"
          data-keyboard="false"
          (click)="openModal()"
          [disabled]="(rolesCheck.hasManagePatient | async) === false"
        >
          <img src="assets/images/icons/add-user.svg" width="24" height="24" />
          {{ 'Créer' | translate }}
        </button>

        <button
          #btnDelegation
          *ngIf="checkCanDelegate()"
          class="btn btn-secondary"
          data-target="#patientDelegateModal"
          data-toggle="modal"
          data-backdrop="static"
          data-keyboard="false"
          [disabled]="patientIdListMultiple.length === 0"
          (click)="toggleDisplayDelegation()"
        >
          <img src="assets/images/icons/delegate.svg" width="24" height="24" />
          {{ 'Déléguer' | translate }}
        </button>
      </div>

      <div class="flex-row div-find-filter header-block">
        <div class="input-group col-sm-8">
          <span class="input-group-prepend">
            <div class="input-group-text border-right-0 bg-grey"><i class="fa fa-search"></i></div>
          </span>
          <input
            class="form-control py-2 border-left-0 border bg-grey"
            type="search"
            value=""
            id="input-search"
            placeholder="{{ 'Rechercher un patient' | translate }}"
            [(ngModel)]="searchText"
            autocomplete="off"
          />
        </div>
        <div>
          <button
            *ngIf="!hasFilterApplicated"
            class="btn-tertiary text-uppercase"
            type="button"
            data-toggle="modal"
            data-target="#modalPatientFilter"
            data-backdrop="static"
            data-keyboard="false"
          >
            <img src="assets/images/icons/filter.svg" width="22" height="33" />
            {{ 'Filtrer' | translate }}
          </button>
          <button
            *ngIf="hasFilterApplicated"
            class="btn-tertiary text-uppercase"
            type="button"
            data-toggle="modal"
            data-target="#modalPatientFilter"
            data-backdrop="static"
            data-keyboard="false"
          >
            <img src="assets/images/icons/filter_applicated.svg" width="22" height="33" />
            {{ 'Filtrer' | translate }}
          </button>
        </div>
      </div>
      <mat-divider></mat-divider>
    </div>
    <app-patient-list
      [searchPatient]="searchText"
      (clickDeletePatient)="onClickDeletePatient($event)"
      (patientDelegateListSelected)="onPatientDelegateListSelected($event)"
    ></app-patient-list>
  </mat-sidenav>

  <mat-sidenav-content class="sidenav-content">
    <div class="left-bar flex-column" *ngIf="!canHide">
      <img
        (click)="openSidenav(sidenav)"
        src="assets/images/icons/right-chevron.svg"
        width="40"
        height="40"
        alt="fermer"
      />
    </div>

    <div>
      <app-patient-detail
        (refreshInfomation)="onRefreshInformation()"
        (clickDeletePatient)="onClickDeletePatient($event)"
        (openDelegation)="onOpenDelegation($event)"
      ></app-patient-detail>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<button #btnDeletePatient [hidden]="false" data-target="#deleteUserModal" data-toggle="modal"></button>

<!-- Modal new patient -->
<div
  class="modal fade"
  id="modalPatientCreation"
  tabindex="-1"
  role="dialog"
  aria-labelledby="modalPatientCreationTitle"
  aria-hidden="true"
  #basicModal
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalPatientCreationTitle">
          {{ 'Patient.Profil.new_patient_information' | translate }}
        </h5>
        <button #btnClose type="button" class="close" (click)="closeModal()" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-patient-creation-form
          (closeModal)="onCloseModal($event)"
          (createMandQuest)="onCreateMandaQuest()"
          *ngIf="isModalOpened"
        ></app-patient-creation-form>
      </div>
    </div>
  </div>
</div>

<!-- Modal filter patient -->
<div
  class="modal fade"
  id="modalPatientFilter"
  tabindex="-1"
  role="dialog"
  aria-labelledby="modalPatientFilterTitle"
  aria-hidden="true"
  #basicModalFilter
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalPatientFilterTitle">
          {{ 'Filter.patients' | translate }}
        </h5>
        <button
          #btnCloseFilter
          type="button"
          class="close"
          (click)="closeModalFilter()"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <mat-list>
            <mat-list-item *ngFor="let item of checkboxList">
              <mat-checkbox
                name="{{ item.id }}"
                id="{{ item.id }}"
                [checked]="item.checked"
                [(ngModel)]="item.checked"
                [disabled]="item.disabled"
                [labelPosition]="item.labelPosition"
                (change)="listChange(item)"
                class="dropdown-item"
              >
                {{ item.name | translate }}
              </mat-checkbox>
              <ng-container *ngIf="displayPractionnerList && item.id == 4">
                <select class="form-control" name="practitioner" id="practitioner" [(ngModel)]="selectedPractitioner">
                  <option *ngFor="let practitioner of practitioners" [ngValue]="practitioner"
                    >{{ practitioner.name }}
                  </option>
                </select>
              </ng-container>
            </mat-list-item>
          </mat-list>
          <div class="flex-row div-find-filter modal-footer">
            <mat-checkbox
              class="btn-tertiary "
              name="selectChange"
              [(ngModel)]="allCheckboxChecked"
              [(indeterminate)]="allCheckboxIndeterminate"
              (change)="allCheckboxChange()"
              mat-button
            >
              <ng-container *ngIf="!allCheckboxChecked">{{ 'select.all' | translate }}</ng-container>
              <ng-container *ngIf="allCheckboxChecked">{{ 'deselect.all' | translate }}</ng-container>
            </mat-checkbox>
            <button class="btn btn-primary col-sm-4" (click)="onSubmit()" type="submit">
              {{ 'button.validation' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Modal delete Patient -->
<div
  class="modal fade"
  id="deleteUserModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="deleteUserModalTitle"
  aria-hidden="true"
  #deleteUserModal
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteUserModalTitle">
          {{ 'app.patient.delete.title' | translate }}
        </h5>
        <button #btnCloseDeletePatient type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="patientToDelete">
        <div class="confirm-text">
          {{ 'app.patient.confirmDelete' | translate }} {{ patientToDelete.firstName }} {{ patientToDelete.lastName }} ?
        </div>
      </div>

      <div class="flex-row justify-content-around modal-footer">
        <button class="btn btn-tertiary" (click)="deletePatient()">{{ 'OUI' | translate }}</button>
        <button #btnNoDelete class="btn btn-primary" (click)="closeModalDeletePatient()" autofocus>
          {{ 'Non' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal Delegate patient -->
<div
  class="modal fade"
  id="patientDelegateModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="patientDelegateModallTitle"
  aria-hidden="true"
  #patientDelegateModal
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="patientDelegateModalTitle">
          {{ 'Delegation' | translate }}
        </h5>
        <button
          #btnCloseDelegation
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="toggleDisplayDelegation()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-patient-delegate
          *ngIf="patientIdListDelegation.length > 0 && canDisplayDelegation"
          (selectPractitionner)="onSelectPractitionner($event)"
          (deletePractitionner)="onDeletePractitionner($event)"
          [patientIdList]="patientIdListDelegation"
        ></app-patient-delegate>
      </div>
      <div class="flex-row justify-content-around modal-footer">
        <button class="btn btn-tertiary" (click)="delegatePatientMultiple()">{{ 'apply' | translate }}</button>
        <button class="btn btn-primary" data-dismiss="modal" aria-label="Close" (click)="toggleDisplayDelegation()">
          {{ 'Annuler' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
