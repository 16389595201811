<div class="container" *ngIf="isOk">
  <form [formGroup]="formGroup" class="mx-auto col-sm-8 mt-5">
    <div class="form-group">
      <label for="practitioner">{{ 'Patient.Profil.speciality' | translate }}:</label>
      <div class="formy flex-row">
        <select
          class="form-control"
          id="speciality"
          formControlName="speciality"
          [compareWith]="compareFn"
          [attr.disabled]="editable ? '' : null"
        >
          <option *ngFor="let practitionerSpeciality of practitionerSpecialities" [ngValue]="practitionerSpeciality">
            {{ practitionerSpeciality.name }}
          </option>
        </select>
        <button *ngIf="editable" (click)="canEditSpeciality()" class="btn btn-tertiary">
          {{ 'Modifier' | translate }}
        </button>
      </div>
    </div>

    <div class="mx-auto container flex-row div-button" *ngIf="!editable">
      <button class="btn btn-primary" (click)="validate()">{{ 'button.validation' | translate }}</button>
      <button class="btn btn-primary" (click)="cancel()">{{ 'Annuler' | translate }}</button>
    </div>
  </form>
</div>
