import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';

export interface AssessmentItem {
  title: string;
  value: any;
  unit: string;
  min: number;
  max: number;
}

export const assessmentItems: AssessmentItem[] = [
  {
    title: i18nKey('app.walk.assessment.averageSpeed'),
    value: 0,
    unit: 'm/s',
    min: 0,
    max: 2,
  },
  {
    title: i18nKey('app.walk.assessment.averageStep'),
    value: 0,
    unit: 's',
    min: 0,
    max: 2,
  },
  {
    title: i18nKey('app.walk.assessment.variabilityDuration'),
    value: 0,
    unit: 's',
    min: 0,
    max: 2,
  },
  {
    title: i18nKey('app.walk.assessment.pelvicRoll'),
    value: 0,
    unit: 'rad/s',
    min: 0,
    max: 1,
  },
  {
    title: i18nKey('app.walk.assessment.walkingSymmetry'),
    value: 0,
    unit: '',
    min: -1,
    max: +1,
  },
  {
    title: i18nKey('app.walk.assessment.doublePressTime'),
    value: 0,
    unit: '%',
    min: 0,
    max: 50,
  },
];

export const assessmentItemsToCompare: AssessmentItem[] = [
  {
    title: i18nKey('app.walk.assessment.averageSpeed'),
    value: 0,
    unit: 'm/s',
    min: 0,
    max: 2,
  },
  {
    title: i18nKey('app.walk.assessment.averageStep'),
    value: 0,
    unit: 's',
    min: 0,
    max: 2,
  },
  {
    title: i18nKey('app.walk.assessment.variabilityDuration'),
    value: 0,
    unit: 's',
    min: 0,
    max: 2,
  },
  {
    title: i18nKey('app.walk.assessment.pelvicRoll'),
    value: 0,
    unit: 'rad/s',
    min: 0,
    max: 1,
  },
  {
    title: i18nKey('app.walk.assessment.walkingSymmetry'),
    value: 0,
    unit: '',
    min: -1,
    max: +1,
  },
  {
    title: i18nKey('app.walk.assessment.doublePressTime'),
    value: 0,
    unit: '%',
    min: 0,
    max: 50,
  },
];
