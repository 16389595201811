<app-custom-navbar
  [patient]="patient"
  [returnTitle]="returnTitle"
  (selectPage)="onSelectPage($event)"
></app-custom-navbar>
<app-courses-breadcrumb-navbar [testName]="testName | translate" [course]="course"></app-courses-breadcrumb-navbar>
<div class="col-md-12">
  <div class="row">
    <div class="col-md-3 p-0">
      <app-fried-navbar
        [currentPage]="currentPage"
        (selectPage)="onSelectPage($event)"
        [course]="course"
      ></app-fried-navbar>
    </div>
    <div class="col-md-9 fried-info" *ngIf="questionnaryDisplayed && !startTest">
      <app-questionnary-infos
        [displayStart]="canDisplayStart"
        [questionnary]="questionnaryDisplayed"
        (startQuestionnary)="onStartQuestionnary($event)"
        [course]="course"
      ></app-questionnary-infos>
    </div>
    <div class="col-md-9 fried-info" *ngIf="startTest && questionnaryDetails">
      <app-questionnary-steps
        #questionnaryStep
        (validateQuestionnary)="onValidateQuestionnary($event)"
        (stopQuestionnary)="onStopQuestionnary()"
        [questionnaryDetails]="questionnaryDetails"
        [course]="course"
      >
      </app-questionnary-steps>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 footer-content-component"></div>
  </div>
</div>
