import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { throwToolbarMixedModesError } from '@angular/material';
import { PatientDetail } from '@abilycare/dal-client';

@Component({
  selector: 'app-patatt-synthesis',
  templateUrl: './patatt-synthesis.component.html',
  styleUrls: ['./patatt-synthesis.component.scss'],
})
export class PatattSynthesisComponent implements OnInit {
  patientDetail: PatientDetail = null;

  @Input() set patient(patient: PatientDetail) {
    this.patientDetail = patient;
    this.patAttNumber = this.patientDetail.patattBrief.count;
    this.isHealthy = this.patientDetail.patattBrief.isHealthy;
    this.isUnknown = this.patientDetail.patattBrief.isUnknown;
  }

  patAttNumber: number;
  isHealthy: boolean;
  isUnknown: boolean;

  constructor(private router: Router) {}

  ngOnInit() {}

  openAllPatatt() {
    this.router.navigate(['PatAtt/', this.patientDetail.id]);
  }
}
