import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  DoCheck,
  ViewChild,
  ElementRef,
  Input,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
} from '@angular/core';
import { NavItem, navItems } from './nav-item.model';
import { Roles } from 'src/app/modules/core/roles.enum';
import { Router, NavigationEnd } from '@angular/router';
import { DataProviderService } from 'src/app/services/data-provider.service';

import { AuthService } from 'src/app/modules/core/services/authentification/auth.service';
import { ModalComponent } from '../common/modal/modal.component';
import { RolesCheckService } from 'src/app/modules/core/services/authentification/roles-check.service';
import { PractitionerDataService } from 'src/app/modules/abilycare-data-access/api/practitioner-data.service';
import { FileDownloadControllerService, Practitioner } from '@abilycare/dal-client';
import { NavbarService } from 'src/app/services/nav-bar.service';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, DoCheck {
  public items: NavItem[] = navItems;
  public isShowed: boolean;
  public versionModalVisible: boolean;
  @Output() logoutEvent = new EventEmitter();
  public hasRoleConfigurator: boolean = false;
  public hasNotification: boolean = false;
  public notifImg: string = 'assets/images/icons/notification-bell.svg';
  public practitionerMe: Practitioner;

  @ViewChild('btnCloseVersion', { static: true }) btnCloseVersion: ElementRef;
  @ViewChild('btnCloseMarkCE', { static: true }) btnCloseMarkCE: ElementRef;
  @ViewChild('noteVersionModal', { static: true }) noteVersionModal: ElementRef;

  constructor(
    private router: Router,
    private dataService: DataProviderService,
    public rolesCheck: RolesCheckService,
    private practitionerDataService: PractitionerDataService,
    private fileTransfertService: FileDownloadControllerService,
    private navbarService: NavbarService,
  ) {}

  reloadNavbar(): void {
    // Ajoutez ici le code pour recharger ou mettre à jour la navbar
    console.log('Navbar reloaded');
    this.ngOnInit();
  }

  onLinkClick(): void {
    this.navbarService.notifyReloadNavbar();
  }

  ngOnInit(): void {
    this.rolesCheck.hasRoleConfigurator.subscribe((value) => {
      this.hasRoleConfigurator = value;
    });

    this.practitionerDataService.getPractitionerMe().subscribe((practitioner) => {
      this.fileTransfertService.fileDownloadControllerListFiles().subscribe(
        (files) => {
          const exportFiles = files.filter((file) => file.includes('exp-' + practitioner.id));
          if (exportFiles.length > 0) {
            this.hasNotification = true;
            this.notifImg = 'assets/images/icons/notification-full.svg';
          }
        },
        (error) => {
          console.log('Error fileDownloadControllerListFiles()', error);
        },
      );
    });

    this.navbarService.reloadNavbar$.subscribe(() => {
      this.reloadNavbar();
    });
  }

  public closeVersionModal() {
    const modalElement = document.getElementById('noteVersionModal');
    // Open the modal logic here
    // For example, using a CSS class to display the modal
    modalElement.classList.remove('show');
    this.btnCloseVersion.nativeElement.click();
  }

  public showVersionModal() {
    const modalElement = document.getElementById('noteVersionModal');
    // Open the modal logic here
    // For example, using a CSS class to display the modal
    modalElement.classList.add('show');
  }

  public closeMarkModal() {
    this.btnCloseMarkCE.nativeElement.click();
  }

  ngDoCheck(): void {
    this.isShowed = this.dataService.isHeaderShowed();
  }

  goTo(page) {
    this.router.navigate([page]);
  }

  onSignOut() {
    this.logoutEvent.emit();
  }
}
