import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { UUID } from '../../core/services/uuid/uuid.service';
import { RemoteInteraction } from './remote-interaction.service';
import { AnalysisResults } from '../models/analysis-results.model';
import { AnalysisParameters } from '../models/analysis-parameters.model';
import { AnalysisState, JobState } from '../models/analysis-state.model';

@Injectable()
export class ForcePlatformOrchestrator {
  private resultsState: AnalysisState;

  public constructor(private uuid: UUID, private remote: RemoteInteraction) {}

  public initSession(): string {
    return this.uuid.next();
  }

  public async startDataAnalysis(recordingSessionId: string, parameters: AnalysisParameters): Promise<void> {
    const action2 = this.remote.startDataAnalysis(recordingSessionId, parameters);

    await Promise.all([action2]);
  }

  public async discardSessionData(recordingSessionId: string): Promise<void> {
    return this.remote.discardSessionData(recordingSessionId);
  }

  public isAnalysisComplete(recordingSessionId: string) {
    if (!this.resultsState) {
      return false;
    }
    if (this.resultsState.jobState === JobState.Running) {
      return false;
    }
    return true;
  }

  public async getAnalysisResultsForSession(recordingSessionId: string): Promise<AnalysisResults> {
    let state: AnalysisState;
    while (!state || state.jobState === JobState.Running) {
      state = await this.remote.getAnalysisState(recordingSessionId);
      await this.wait(1000);
    }
    if (state.jobState === JobState.Error) {
      throw new Error(state.errorCode);
    }
    const results = this.remote.getAnalysisResultsForSession(recordingSessionId);
    return results;
  }

  public async getAnalysisResultsForBilan(bilanId: number): Promise<AnalysisResults> {
    return this.remote.getAnalysisResultsForBilan(bilanId);
  }

  public async persistAnalysisResults(recordingSessionId: string, bilanId: number): Promise<void> {
    return this.remote.persistAnalysisResults(recordingSessionId, bilanId);
  }

  private async wait(millis: number) {
    return of(true)
      .pipe(delay(millis))
      .toPromise();
  }
}
