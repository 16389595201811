<section>
  <div class="logoSection">
    <div class="logo" style="color: white; ">Abily<span style="font-weight: lighter;">Care</span></div>
  </div>

  <div style="color: white; text-align: center; ">
    <button>
      <span style="text-align:center; color: crimson;"
        >Error login : L'établissement dans lequel vous êtes affecté est actuellement inactif, vous ne pouvez vous y
        connecter</span
      >
    </button>
    <br /><br />
    <button>
      <span style="text-align:center; color: crimson;"
        >Error login : Your establishment is currently inactive, you cannot connect to it</span
      >
    </button>
  </div>
</section>

<footer>
  <div class="navbar navbar-dark bg-dark">
    <a href="https://www.abilycare.com/">www.abilycare.com</a>
    <a href="#myModal" data-toggle="modal" data-target="#myModal" class="nav-link">{{
      'App.Template.CGU' | translate
    }}</a>
    <a href="mailto:support@abilycare.com">{{ 'Nous contacter' | translate }}</a>
  </div>

  <div class="modal fade" id="myModal">
    <div class="modal-dialog modal-large modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ 'CGU.Title' | translate }}</h5>
        </div>
        <div class="modal-body" [innerHTML]="cguHtml"></div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal">{{ 'Close' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</footer>
