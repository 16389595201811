<div class="col-md-12 appreciation-container">
  <div class="col-md-10 information-container">
    <img class="d-inline-block align-top" width="20" height="20" src="assets/images/icons/info-circle.svg" alt="" />
    <span>{{ 'app.walk.appreciationInformation' | translate }}</span>
  </div>

  <div class="col-md-11 eval-container">
    <div class="title-container">
      <p class="title">
        {{ 'app.romberg.appreciation.balance.quality.openedEyes' | translate }}
      </p>
    </div>
    <div class="selector-container">
      <div class="col-md-8 slider-container">
        <span class="number">{{ minOEBalanceQuality }}</span>
        <mat-slider
          [ngClass]="{ 'slider-grey': isOEBalanceQualityNotEvaluated, 'slider-blue': !isOEBalanceQualityNotEvaluated }"
          [min]="minOEBalanceQuality"
          [max]="maxOEBalanceQuality"
          [step]="step"
          [(ngModel)]="valueOEBalanceQuality"
          [thumbLabel]="thumbLabel"
          (input)="onValueOEBQChange($event)"
        >
        </mat-slider>
        <span class="number">{{ maxOEBalanceQuality }}</span>
      </div>

      <div class="col-md-2 checkbox-container">
        <mat-checkbox
          class="checkbox"
          [checked]="isOEBalanceQualityNotEvaluated"
          [(ngModel)]="isOEBalanceQualityNotEvaluated"
        >
          {{ 'app.walk.appreciation.notEvaluated' | translate }}
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div class="col-md-11 eval-container">
    <div class="title-container">
      <p class="title">
        {{ 'app.romberg.appreciation.balance.quality.closedEyes' | translate }}
      </p>
    </div>
    <div class="selector-container">
      <div class="col-md-8 slider-container">
        <span class="number">{{ minCEBalanceQuality }}</span>
        <mat-slider
          [ngClass]="{ 'slider-grey': isCEBalanceQualityNotEvaluated, 'slider-blue': !isCEBalanceQualityNotEvaluated }"
          [min]="minCEBalanceQuality"
          [max]="maxCEBalanceQuality"
          [step]="step"
          [(ngModel)]="valueCEBalanceQuality"
          [thumbLabel]="thumbLabel"
          (input)="onValueCEBQChange($event)"
        >
        </mat-slider>
        <span class="number">{{ maxCEBalanceQuality }}</span>
      </div>

      <div class="col-md-2 checkbox-container">
        <mat-checkbox
          class="checkbox"
          [checked]="isCEBalanceQualityNotEvaluated"
          [(ngModel)]="isCEBalanceQualityNotEvaluated"
        >
          {{ 'app.walk.appreciation.notEvaluated' | translate }}
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div class="col-md-11 eval-container">
    <div class="title-container">
      <p class="title">{{ 'app.romberg.appreciation.walking.quality' | translate }}</p>
    </div>
    <div class="selector-container">
      <div class="col-md-8 slider-container">
        <span class="number">{{ minQualityWalk }}</span>
        <mat-slider
          [ngClass]="{ 'slider-grey': isQualityWalkNotEvaluated, 'slider-blue': !isQualityWalkNotEvaluated }"
          [min]="minQualityWalk"
          [max]="maxQualityWalk"
          [step]="step"
          [(ngModel)]="valueQualityWalk"
          [thumbLabel]="thumbLabel"
          (input)="onValueQWChange($event)"
        >
        </mat-slider>
        <span class="number">{{ maxQualityWalk }}</span>
      </div>

      <div class="col-md-2 checkbox-container">
        <mat-checkbox class="checkbox" [checked]="isQualityWalkNotEvaluated" [(ngModel)]="isQualityWalkNotEvaluated">
          {{ 'app.walk.appreciation.notEvaluated' | translate }}
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div class="col-md-11 eval-container">
    <div class="title-container">
      <p class="title">{{ 'app.romberg.appreciation.fallen' | translate }}</p>
    </div>
    <div class="selector-container">
      <div class="col-md-8 slider-container">
        <mat-checkbox class="checkbox" [(ngModel)]="isFallenEvaluated" [checked]="isFallenEvaluated">
          {{ 'Oui' | translate }}
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div class="col-md-11 d-flex">
    <div class="col-md-8"></div>
    <div class="col-md-2">
      <button class="btn btn-primary btn-valid" (click)="viewResults()">{{ 'button.validation' | translate }}</button>
    </div>
  </div>
</div>
