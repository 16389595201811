<section>
  <div class="logoSection">
    <div class="logo" style="color: white; ">Abily<span style="font-weight: lighter;">Care</span></div>
  </div>

  <div class="loginPage">
    <button *ngIf="!loading" (click)="authentication()" class="btn btn-lg btn-primary btn-block start" type="submit">
      {{ 'Démarrer' | translate }}
    </button>
  </div>
</section>

<footer>
  <div class="navbar navbar-dark bg-dark">
    <a href="https://www.abilycare.com/">www.abilycare.com</a>
    <a href="#myModal" data-toggle="modal" data-target="#myModal" class="nav-link">{{
      'App.Template.CGU' | translate
    }}</a>
    <a href="mailto:support@abilycare.com">{{ 'Nous contacter' | translate }}</a>
  </div>

  <div class="modal fade" id="myModal">
    <div class="modal-dialog modal-large modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ 'CGU.Title' | translate }}</h5>
        </div>
        <div class="modal-body" [innerHTML]="cguHtml"></div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal">{{ 'Close' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</footer>
