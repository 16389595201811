import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'percentage' })
export class PercentageDisplayed implements PipeTransform {
  public transform(float: number): number {
    if (float) {
      const n: number = float * 100;
      return n;
    }
    return null;
  }
}
