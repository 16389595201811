<div class="main-container">
  <div class="title-container flex-column">
    <h6>Description du déficit:</h6>
    <div class="container-selected-symptom">
      <h6 *ngIf="selectedSymptom">{{ selectedSymptom.name }}</h6>
      <h6 *ngIf="sympDetails">{{ sympDetails.name }}</h6>
    </div>
  </div>

  <div class="form-group">
    <h6>{{ 'Patient.Location' | translate }}</h6>
    <select
      *ngIf="canEdit; else elseLocation"
      class="form-control"
      name="select-location"
      id="select-location"
      [(ngModel)]="selectedlocation"
      [compareWith]="compareItems"
    >
      <option *ngFor="let location of locationsList" [ngValue]="location"> {{ location.name }} </option>
    </select>
    <ng-template #elseLocation>
      <div class="container-selected-symptom">
        <h6 *ngIf="sympDetails.location; else elseNothing">{{ sympDetails.location }}</h6>
      </div>
    </ng-template>
  </div>

  <div class="form-group">
    <h6>{{ 'Latéralisation' | translate }}</h6>
    <select
      *ngIf="canEdit; else elseLateralization"
      class="form-control"
      name="select-lateralization"
      id="select-lateralization"
      [(ngModel)]="selectedLateralization"
      [compareWith]="compareItems"
    >
      <option *ngFor="let lateralization of lateralizationsList" [ngValue]="lateralization">
        {{ lateralization.name }}
      </option>
    </select>
    <ng-template #elseLateralization>
      <div class="container-selected-symptom">
        <h6 *ngIf="sympDetails.lateralization; else elseNothing">{{ sympDetails.lateralization }}</h6>
      </div>
    </ng-template>
  </div>

  <div class="form-group">
    <h6>{{ 'Patient.Temporality' | translate }}</h6>
    <select
      *ngIf="canEdit; else elseTemporality"
      class="form-control"
      name="select-temporality"
      id="select-temporality"
      [(ngModel)]="selectedTemporality"
      [compareWith]="compareItems"
    >
      <option *ngFor="let temporality of temporalitiesList" [ngValue]="temporality"> {{ temporality.name }}</option>
    </select>

    <ng-template #elseTemporality>
      <div class="container-selected-symptom">
        <h6 *ngIf="sympDetails.temporality; else elseNothing">{{ sympDetails.temporality }}</h6>
      </div>
    </ng-template>
  </div>

  <div class="form-group">
    <h6>{{ 'Patient.Rhythm' | translate }}</h6>
    <select
      *ngIf="canEdit; else elseRhythm"
      class="form-control"
      name="select-rhythm"
      id="select-rhythm"
      [(ngModel)]="selectedRhythm"
      [compareWith]="compareItems"
    >
      <option *ngFor="let rhythm of rhythmsList" [ngValue]="rhythm"> {{ rhythm.name }}</option>
    </select>

    <ng-template #elseRhythm>
      <div class="container-selected-symptom">
        <h6 *ngIf="sympDetails.rhythm; else elseNothing">{{ sympDetails.rhythm }}</h6>
      </div>
    </ng-template>
  </div>

  <div class="form-group">
    <h6>{{ 'Patient.Current' | translate }}</h6>
    <select
      class="form-control"
      name="select-current"
      id="select-current"
      [(ngModel)]="selectedCurrent"
      [compareWith]="compareItems"
    >
      <option *ngFor="let current of currentsList" [ngValue]="current"> {{ current.name }}</option>
    </select>
  </div>

  <div class="button-container flex-column">
    <button *ngIf="selectedSymptom || isTransferSelected; else elseTest" class="btn btn-primary" (click)="addOptions()">
      {{ 'Ajouter' | translate }}
    </button>

    <ng-template #elseTest>
      <button class="btn btn-primary" (click)="updateOption()">{{ 'Modifier' | translate }}</button>
    </ng-template>
  </div>
</div>

<ng-template #elseNothing>
  <h6>{{ 'Patient.Not_specified' | translate }}</h6>
</ng-template>
