import { Injectable } from '@angular/core';
import { RemoteInteraction } from './remote-interaction.service';
import { AnalysisResults } from '../models/analysis-results.model';

@Injectable()
export class AnalysisResultsRepository {
  public constructor(private remote: RemoteInteraction) {}

  /**
   * TODO enable caching in here if needed (mainly for performance reasons)
   * @param recordingId - the session id
   */
  public async get(recordingId: string): Promise<AnalysisResults> {
    return this.remote.getAnalysisResultsForSession(recordingId);
  }
}
