import { PatientDetail } from '@abilycare/dal-client';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'app-custom-navbar',
  templateUrl: './custom-navbar.component.html',
  styleUrls: ['./custom-navbar.component.scss'],
})
export class CustomNavbarComponent implements OnInit {
  @Input() patient: PatientDetail;
  @Input() returnTitle: string;
  @Output() selectPage: EventEmitter<string> = new EventEmitter<string>();

  constructor(private router: Router, private translateService: TranslateService) {}

  ngOnInit() {}

  goToHomePage() {
    const page = '/Patient/' + this.patient.id;
    this.selectPage.emit(page);
    this.router.navigate([page]);
  }

  getAge(): string {
    const years: number = moment().diff(this.patient.birthDate, 'years');
    if (years >= 0) return years.toString() + ' ' + this.translateService.instant('ans');
    return '-';
  }
}
