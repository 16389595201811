import { PatientDetail } from '@abilycare/dal-client';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, from as fromPromise, Observable, Subject, Subscription } from 'rxjs';
import { HistoVersusConsultationService } from 'src/app/modules/consultation';
import { ShoesSelectorValues } from 'src/app/modules/force-platform';
import {
  AnalysisResults,
  AnalysisResults as AnalysisResultsWalk,
} from 'src/app/modules/walk/models/analysis-results.model';
import { Assessment, WalkAssessmentType } from 'src/app/modules/walk/models/assessment.model';
import { AnalysisResultsRepository } from 'src/app/modules/walk/services/analysis-result-repository.service';
import { ForcePlatformOrchestrator } from 'src/app/modules/walk/services/orchestrator.service';
import { WalkOperation, WalkOperationService } from 'src/app/modules/walk/services/walk-operation.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { ModalComponent } from 'src/app/view/common/modal/modal.component';
import {
  AssessmentItem,
  assessmentItems,
  assessmentItemsToCompare,
} from '../walk-assessment/walk-assessment-item.model';
import { Location } from '@angular/common';

@Component({
  selector: 'app-walk-comparison',
  templateUrl: './walk-comparison.component.html',
  styleUrls: ['./walk-comparison.component.scss'],
  providers: [AnalysisResultsRepository, WalkOperationService, HistoVersusConsultationService],
})
export class WalkComparisonComponent implements OnInit, OnDestroy {
  public patient: PatientDetail;
  public returnTitle: string = i18nKey('Checkup.navbar.history.back');
  public shoesSelectorValues: ShoesSelectorValues;
  public walkAssessments: AssessmentItem[];

  public items: AssessmentItem[] = assessmentItems;

  public lang: string;

  public error: boolean = false;

  private subscriptions: Subscription[] = [];

  public resultsWalk: AnalysisResultsWalk = null;
  public typeRapport: string = 'Walk';
  public testName: string = i18nKey('navbar.walkTest');

  public assessments: Assessment[];

  public currentDate: string;
  public comparedAssessments$ = new BehaviorSubject<Assessment[]>([]);

  public newPage: string = '';

  public titleGraphic: string = '';

  public testData: any;
  public walkToCompare: any;

  public resultsWalkToCompare: AnalysisResultsWalk = null;
  public itemsToCompare: AssessmentItem[] = assessmentItemsToCompare;
  public displayComparison: boolean = false;

  constructor(
    private dataService: DataProviderService,
    private translateService: TranslateService,
    private router: Router,
    public histoVerusConsultationService: HistoVersusConsultationService,
    private analysisResultsRepository: AnalysisResultsRepository,
    private location: Location,
  ) {}

  ngOnInit() {
    this.lang = this.translateService.currentLang;
    this.patient = this.dataService.retrievePatient();
    const obj: any = this.location.getState();

    this.testData = obj[0];
    this.walkToCompare = obj[1];

    //data for main walk
    /*
    this.shoesSelectorValues = this.testData.shoesSelectorValues;
    this.operationService.setCurrentOperation(WalkOperation.Measure);
    this.retryResponse.next(false);
    this.assessments = this.prepareAssessments();
    this.comparedAssessments$.next([]);*/
    this.analysisResultsRepository.get(this.testData.id).then((res) => {
      this.resultsWalk = res;
      this.attributeResultToItems(this.resultsWalk, this.items);

      /*
      this.getAssessments.emit(this.items);
      this.getStepNumber.emit(this.getStepNumberFromResults(this.resultsWalk));
      this.finsihAnalysis.emit(this.resultsWalk);
      */

      // this.shoesSelectorValues =
      // this.btnWalkGraphics.nativeElement.click();
    });
    if (this.walkToCompare) {
      this.analysisResultsRepository.get(this.walkToCompare.id).then((res) => {
        this.resultsWalkToCompare = res;
        this.attributeResultToItems(this.resultsWalkToCompare, this.itemsToCompare);

        this.displayComparison = true;
        /*
          this.getAssessments.emit(this.itemsToCompare);      
          this.getStepNumber.emit(this.getStepNumberFromResults(this.resultsWalkToCompare));
          this.finsihAnalysis.emit(this.resultsWalkToCompare);
        */
        // this.shoesSelectorValues =
        // this.btnWalkGraphics.nativeElement.click();
      });
    }
  }

  private attributeResultToItems(results: AnalysisResults, items: AssessmentItem[]) {
    let index = 0;
    items.forEach((item) => {
      switch (item.title) {
        case i18nKey('app.walk.assessment.averageSpeed'): {
          item.value = results.AvgSpeed.toFixed(3);
          break;
        }
        case i18nKey('app.walk.assessment.averageStep'): {
          item.value = results.MeanStepDuration.toFixed(3);
          break;
        }
        case i18nKey('app.walk.assessment.variabilityDuration'): {
          item.value = results.CycleVariability.toFixed(3);
          break;
        }
        case i18nKey('app.walk.assessment.pelvicRoll'): {
          item.value = results.RoliTronc.toFixed(3);
          break;
        }
        case i18nKey('app.walk.assessment.walkingSymmetry'): {
          item.value = results.SwingMeanStrideDuration.toFixed(3);
          break;
        }
        case i18nKey('app.walk.assessment.doublePressTime'): {
          item.value = Number(results.DoubleStance * 100).toFixed(3);
          break;
        }
      }
      items[index] = item;
      index++;
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
  }

  public onChangeTitle(titleGraphic: string) {
    this.titleGraphic = titleGraphic;
  }

  public onSelectPage(newPage: string) {
    this.newPage = newPage;
  }

  public goTo() {
    this.router.navigate([this.newPage]);
  }
}
