import { CommonModule as AngularCommon } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { GaugeChartComponent } from 'src/app/view/common/gauge-chart';
import { HorizontalBarComponent } from 'src/app/view/common/horizontalBar/horizontalBar.component';
import { ModalComponent } from 'src/app/view/common/modal/modal.component';
import { SnackBarComponent } from 'src/app/view/common/snack-bar/snack-bar.component';
import { SpinnerComponent } from 'src/app/view/common/spinner/spinner/spinner.component';
import { TimelineChartComponent } from 'src/app/view/common/timelineChart/timelineChart.component';
import { VerticalBarComponent } from 'src/app/view/common/verticalBar/verticalBar.component';
import { FilterDateRangePipe } from './pipes/filterDateRange.pipe';
import { OrderByPipe } from './pipes/orderby.pipe';
import { SecurePipe } from './pipes/securePipe';
import { UnitPipe } from './pipes/unit.pipe';

@NgModule({
  imports: [AngularCommon, RouterModule, TranslateModule.forChild(), MaterialModule],
  declarations: [
    ModalComponent,
    HorizontalBarComponent,
    TimelineChartComponent,
    VerticalBarComponent,
    SnackBarComponent,
    SecurePipe,
    SpinnerComponent,
    FilterDateRangePipe,
    UnitPipe,
    OrderByPipe,
    GaugeChartComponent,
    // NameFormat,
    // BooleanFormat,
  ],
  providers: [SecurePipe, FilterDateRangePipe],
  exports: [
    ModalComponent,
    HorizontalBarComponent,
    TimelineChartComponent,
    VerticalBarComponent,
    SnackBarComponent,
    SecurePipe,
    SpinnerComponent,
    FilterDateRangePipe,
    UnitPipe,
    OrderByPipe,
    GaugeChartComponent,
  ],
})
export class CommonModule {}
