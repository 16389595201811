import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  @Input() size: number = 50;
  public mode: string = 'indeterminate';
  public value: number = 50;
  constructor() {}

  ngOnInit() {}
}
