<mat-accordion multi>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <i _ngcontent-ufy-c2="" class="fa fa-search"></i> {{ 'Find.practitionner' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="border-container">
      <div *ngIf="selectedPractitionnersList.length > 0" class="selected-practionners-container">
        <div class="selected-name-container" *ngFor="let practitionner of selectedPractitionnersList">
          <span class="selected-name">{{ practitionner.name }}</span>
          <span class="delete-name" (click)="removeChips(practitionner)"><i class="fa fa-times"></i></span>
        </div>
      </div>

      <input
        class="form-control py-2 border-left-0 border bg-grey"
        type="search"
        value=""
        id="input-search"
        placeholder="{{ 'Rechercher un praticien' | translate }}"
        [(ngModel)]="searchText"
        autocomplete="off"
      />

      <div class="checkbox-delegation-container">
        <mat-list>
          <mat-list-item *ngFor="let item of checkboxListDelegation | filter: searchText">
            <mat-checkbox
              class="dropdown-item dropdown-item-delegation"
              [ngClass]="{ 'dropdown-item-delegation-indeterminate': item.indeterminate }"
              name="{{ item.id }}"
              [checked]="item.checked"
              [disabled]="item.disabled"
              [labelPosition]="item.labelPosition"
              (change)="changeState(item)"
              [(indeterminate)]="item.indeterminate"
            >
              {{ item.name | translate }}
            </mat-checkbox>
          </mat-list-item>
        </mat-list>
      </div>

      <div class="select-all-container">
        <mat-checkbox
          class="btn-tertiary "
          name="selectChange"
          [(ngModel)]="allCheckboxChecked"
          [(indeterminate)]="allCheckboxIndeterminate"
          (change)="allCheckboxChange()"
          mat-button
        >
          <ng-container *ngIf="!allCheckboxChecked">{{ 'select.all' | translate }} </ng-container>
          <ng-container *ngIf="allCheckboxChecked">{{ 'deselect.all' | translate }}</ng-container>
        </mat-checkbox>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
