import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { StaticDataService } from 'src/app/modules/abilycare-data-access/api/static-data.service';
import { Symptom } from '@abilycare/dal-client/model/symptom';
import { StaticItemReference, SymptomDetail } from '@abilycare/dal-client';
import { empty, Subscription } from 'rxjs';

@Component({
  selector: 'app-patatt-edition-symptoms',
  templateUrl: './patatt-edition-symptoms.component.html',
  styleUrls: ['./patatt-edition-symptoms.component.scss'],
})
export class PatattEditionSymptomsComponent implements OnInit, OnDestroy {
  @Input() set symptom(symptom: StaticItemReference) {
    if (symptom) {
      this.selectedSymptom = symptom;
      this.sympDetails = null;
      this.reset();
    }
  }

  @Input() set symptomDetails(symptomDetails: SymptomDetail) {
    this.sympDetails = symptomDetails;

    if (
      this.sympDetails &&
      (this.sympDetails.location ||
        this.sympDetails.lateralization ||
        this.sympDetails.rhythm ||
        this.sympDetails.temporality)
    ) {
      this.canEdit = false;
    } else {
      this.canEdit = true;
    }

    if (this.sympDetails) {
      this.selectedSymptom = null;

      this.selectedlocation = {
        id: this.sympDetails.locationId,
        name: this.sympDetails.location,
      };

      this.selectedLateralization = {
        id: this.sympDetails.lateralizationId,
        name: this.sympDetails.lateralization,
      };

      this.selectedTemporality = {
        id: this.sympDetails.temporalityId,
        name: this.sympDetails.temporality,
      };

      this.selectedRhythm = {
        id: this.sympDetails.rhythmId,
        name: this.sympDetails.rhythm,
      };

      this.selectedCurrent = {
        id: this.sympDetails.currentId,
        name: this.sympDetails.current,
      };
    }
  }

  @Input() isTransferSelected;

  @Output() validateOption = new EventEmitter<SymptomDetail>();

  public locationsList: Array<StaticItemReference> = null;
  public lateralizationsList: Array<StaticItemReference> = null;
  public temporalitiesList: Array<StaticItemReference> = null;
  public rhythmsList: Array<StaticItemReference> = null;
  public currentsList: Array<StaticItemReference> = null;

  public selectedlocation: StaticItemReference = null;
  public selectedLateralization: StaticItemReference = null;
  public selectedTemporality: StaticItemReference = null;
  public selectedRhythm: StaticItemReference = null;
  public selectedCurrent: StaticItemReference = null;

  public selectedSymptom: StaticItemReference = null;

  public sympDetails: SymptomDetail = null;

  private subscriptions: Subscription[] = [];

  public canEdit = true;

  constructor(private staticDataService: StaticDataService) {}

  ngOnInit() {
    this.subscriptions.push(
      this.staticDataService.getAllLocations().subscribe((locations) => {
        this.locationsList = locations;
      }),
    );

    this.subscriptions.push(
      this.staticDataService.getAllLateralizations().subscribe((lateralizations) => {
        this.lateralizationsList = lateralizations;
      }),
    );

    this.subscriptions.push(
      this.staticDataService.getAllTemporalities().subscribe((temporalities) => {
        this.temporalitiesList = temporalities;
      }),
    );

    this.subscriptions.push(
      this.staticDataService.getAllRhythms().subscribe((rhytms) => {
        this.rhythmsList = rhytms;
      }),
    );

    this.subscriptions.push(
      this.staticDataService.getAllCurrents().subscribe((currents) => {
        this.currentsList = currents;
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
  }

  public compareItems(item1: StaticItemReference, item2: StaticItemReference): boolean {
    return item1 && item2 ? item1.id === item2.id : item1 === item2;
  }

  public reset(): void {
    this.selectedlocation = null;
    this.selectedLateralization = null;
    this.selectedTemporality = null;
    this.selectedRhythm = null;
    this.selectedCurrent = null;
  }

  public addOptions(): void {
    let newSymptom: SymptomDetail;
    if (!this.isTransferSelected) {
      newSymptom = {
        id: this.selectedSymptom.id,
        name: this.selectedSymptom.name,
        currentId: this.selectedCurrent ? this.selectedCurrent.id : -1,
        lateralizationId: this.selectedLateralization ? this.selectedLateralization.id : -1,
        locationId: this.selectedlocation ? this.selectedlocation.id : -1,
        rhythmId: this.selectedRhythm ? this.selectedRhythm.id : -1,
        temporalityId: this.selectedTemporality ? this.selectedTemporality.id : -1,
      };
    } else {
      newSymptom = {
        id: this.sympDetails.id,
        name: this.sympDetails.name,
        currentId: this.sympDetails.currentId ? this.sympDetails.currentId : -1,
        lateralizationId: this.sympDetails.lateralizationId ? this.sympDetails.lateralizationId : -1,
        locationId: this.sympDetails.locationId ? this.sympDetails.locationId : -1,
        rhythmId: this.sympDetails.rhythmId ? this.sympDetails.rhythmId : -1,
        temporalityId: this.sympDetails.temporalityId ? this.sympDetails.temporalityId : -1,
        current: this.sympDetails.current ? this.sympDetails.current : '',
        lateralization: this.sympDetails.lateralization ? this.sympDetails.lateralization : '',
        location: this.sympDetails.location ? this.sympDetails.location : '',
        rhythm: this.sympDetails.rhythm ? this.sympDetails.rhythm : '',
        temporality: this.sympDetails.temporality ? this.sympDetails.temporality : '',
      };
    }

    this.validateOption.emit(newSymptom);
    this.reset();
  }

  public updateOption(): void {
    const newSymptom: SymptomDetail = {
      id: this.sympDetails.id,
      name: this.sympDetails.name,
      currentId: this.selectedCurrent ? this.selectedCurrent.id : -1,
      lateralizationId: this.selectedLateralization ? this.selectedLateralization.id : -1,
      locationId: this.selectedlocation ? this.selectedlocation.id : -1,
      rhythmId: this.selectedRhythm ? this.selectedRhythm.id : -1,
      temporalityId: this.selectedTemporality ? this.selectedTemporality.id : -1,
      current: this.sympDetails.current ? this.sympDetails.current : '',
      lateralization: this.sympDetails.lateralization ? this.sympDetails.lateralization : '',
      location: this.sympDetails.location ? this.sympDetails.location : '',
      rhythm: this.sympDetails.rhythm ? this.sympDetails.rhythm : '',
      temporality: this.sympDetails.temporality ? this.sympDetails.temporality : '',
    };
    this.validateOption.emit(newSymptom);
    this.reset();
  }

  public onOptionChange(): void {}
}
