import { Component, Input, OnInit } from '@angular/core';
import { LongitudinalGraph } from 'src/app/modules/walk/models/longitudinal.model';
import { AnalysisResults as WalkAnalysisResults } from 'src/app/modules/walk/models/analysis-results.model';
import { WalkOperation, WalkOperationService } from 'src/app/modules/walk/services/walk-operation.service';
import { AnalysisResultsRepository } from 'src/app/modules/walk/services/analysis-result-repository.service';
import { HistoVersusConsultationService } from 'src/app/modules/consultation';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { Subscription } from 'rxjs';
import { WalkingControllerService, WalkingWithRelations } from '@abilycare/dal-client';

@Component({
  selector: 'app-walk-longitudinal',
  templateUrl: './walk-longitudinal.component.html',
  styleUrls: ['./walk-longitudinal.component.scss'],
})
export class WalkLongitudinalComponent implements OnInit {
  @Input() public results: WalkAnalysisResults;
  @Input() currentWalkRecordingSession;

  public graphs: LongitudinalGraph[];
  public zoom: boolean;
  public graphSelected: LongitudinalGraph;
  private subscriptions: Subscription[] = [];
  private walkkObjectBeforeSave: boolean;
  private bilans: WalkingWithRelations[];

  constructor(
    private operationService: WalkOperationService,
    private walkingService: WalkingControllerService,
    private analysisResultsRepository: AnalysisResultsRepository,
    private histoVsConsultService: HistoVersusConsultationService,
    private dataService: DataProviderService,
  ) {}

  public async ngOnInit(): Promise<void> {
    const patient = this.dataService.retrievePatient();
    // this.histoVsConsultService.setPageInConsultation('checkup/walk/longitudinal');
    this.operationService.setCurrentOperation(WalkOperation.Longitudinal);
    // this.locomotionDataService.getOldLocomtionBilan(patient.id).then((bilans) => {
    //   this.transformBilanToGraph(bilans);
    // });

    //see if @Input() currentWalkRecordingSession; is an saved id in database

    this.walkkObjectBeforeSave = this.currentWalkRecordingSession != null;
    this.subscriptions.push(
      this.walkingService.walkingControllerFindAllWalkingByPatient(patient.id).subscribe(
        (res: any) => {
          this.transformBilanToGraph(res);
        },
        (err) => {
          this.createGraphBilan();
          console.log('Error displayBilanWalk()', err);
        },
      ),
    );
    //
  }

  /**
   * Zoom on a graph
   * @param e object pointed
   */
  public chartClicked(e: LongitudinalGraph): void {
    this.zoom = true;
    this.graphSelected = e;
  }

  private transformBilanToGraph(bilans: WalkingWithRelations[]) {
    console.log('transformBilanToGraph');
    this.graphs = new Array<LongitudinalGraph>(6);
    this.graphs[0] = new LongitudinalGraph(new Array<{ x: Date; y: number }>(), 'Checkup.walk.averageSpeed');
    this.graphs[1] = new LongitudinalGraph(new Array<{ x: Date; y: number }>(), 'Checkup.walk.meanStepDuration');
    this.graphs[2] = new LongitudinalGraph(new Array<{ x: Date; y: number }>(), 'Checkup.walk.swingMeanStrideDuration');
    this.graphs[3] = new LongitudinalGraph(new Array<{ x: Date; y: number }>(), 'Checkup.walk.doubleStance');
    this.graphs[4] = new LongitudinalGraph(new Array<{ x: Date; y: number }>(), 'Checkup.walk.cycleVariability');
    this.graphs[5] = new LongitudinalGraph(new Array<{ x: Date; y: number }>(), 'Checkup.walk.roliTronc');

    for (const bilan of bilans) {
      //console.log('bilan in longitudinal graph is ', bilan);
      this.graphs[0].graph.push({ x: bilan.date, y: bilan.averageSpeed });
      this.graphs[1].graph.push({ x: bilan.date, y: bilan.averageStepDuration });
      this.graphs[2].graph.push({ x: bilan.date, y: bilan.walkingSymmetry });
      this.graphs[3].graph.push({ x: bilan.date, y: bilan.doublePressTime * 100 });
      this.graphs[4].graph.push({ x: bilan.date, y: bilan.cycleLengthVariability });
      this.graphs[5].graph.push({ x: bilan.date, y: bilan.pelvisBearing });
    }
    /*
     ** Attention cette partie concerne la vue avant de valider.
     */
    const date = new Date();
    if (this.walkkObjectBeforeSave) {
      this.graphs[0].graph.push({ x: date, y: this.results.AvgSpeed });
      this.graphs[1].graph.push({ x: date, y: this.results.MeanStepDuration });
      this.graphs[2].graph.push({ x: date, y: this.results.SwingMeanStrideDuration });
      this.graphs[3].graph.push({ x: date, y: this.results.DoubleStance * 100 });
      this.graphs[4].graph.push({ x: date, y: this.results.CycleVariability });
      this.graphs[5].graph.push({ x: date, y: this.results.RoliTronc });
    }
  }

  private createGraphBilan() {
    console.log('createGraphBilan');
    this.graphs = new Array<LongitudinalGraph>(6);
    this.graphs[0] = new LongitudinalGraph(new Array<{ x: Date; y: number }>(), 'Checkup.walk.averageSpeed');
    this.graphs[1] = new LongitudinalGraph(new Array<{ x: Date; y: number }>(), 'Checkup.walk.meanStepDuration');
    this.graphs[2] = new LongitudinalGraph(new Array<{ x: Date; y: number }>(), 'Checkup.walk.swingMeanStrideDuration');
    this.graphs[3] = new LongitudinalGraph(new Array<{ x: Date; y: number }>(), 'Checkup.walk.doubleStance');
    this.graphs[4] = new LongitudinalGraph(new Array<{ x: Date; y: number }>(), 'Checkup.walk.cycleVariability');
    this.graphs[5] = new LongitudinalGraph(new Array<{ x: Date; y: number }>(), 'Checkup.walk.roliTronc');
    /*
     ** Attention cette partie concerne la vue avant de valider.
     */
    const date = new Date();
    if (this.walkkObjectBeforeSave) {
      this.graphs[0].graph.push({ x: date, y: this.results.AvgSpeed });
      this.graphs[1].graph.push({ x: date, y: this.results.MeanStepDuration });
      this.graphs[2].graph.push({ x: date, y: this.results.SwingMeanStrideDuration });
      this.graphs[3].graph.push({ x: date, y: this.results.DoubleStance * 100 });
      this.graphs[4].graph.push({ x: date, y: this.results.CycleVariability });
      this.graphs[5].graph.push({ x: date, y: this.results.RoliTronc });
    }
  }

  /**
   * Close the zoom page
   */
  public close() {
    this.zoom = false;
  }
}
