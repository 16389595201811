import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// const consultationRoutes: Routes = [
//     {
//     },
// ];

@NgModule({
  imports: [
    // RouterModule.forChild(consultationRoutes),
  ],
  exports: [
    // RouterModule,
  ],
})
export class ConsultationRoutingModule {}
