import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { WiiBoardService } from 'src/app/modules/force-platform';
import { NotificationService } from 'src/app/modules/core/services/notification/notification.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { Course, PatientControllerService, PatientDetail, WeighingControllerService } from '@abilycare/dal-client';
import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-weight-view',
  templateUrl: './weight-view.component.html',
  styleUrls: ['./weight-view.component.scss'],
  // styleUrls: ['./weight.component.scss', '../shared/scss/common.scss'],
  providers: [WiiBoardService],
})
export class WeightViewComponent implements OnInit {
  @ViewChild('btnClose', { static: true }) btnClose: ElementRef;
  @ViewChild('btnOpenQuit', { static: true }) btnOpenQuit: ElementRef;
  @ViewChild('btnQuitBeforeSave', { static: true }) btnQuitBeforeSave: ElementRef;

  public weight: number;
  public patient: PatientDetail;

  public returnTitle: string = i18nKey('Checkup.navbar.history.back');
  public testName: string = i18nKey('navbar.massBalance');

  public newPage: string = '';

  public testData: any;
  public fromBilan: boolean;

  public fromCourse: boolean;
  public course: Course;

  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private dataService: DataProviderService,
    private router: Router,
    private weightService: WeighingControllerService,
    private location: Location,
    private route: ActivatedRoute,
    private patientService: PatientControllerService,
  ) {}

  ngOnInit() {
    this.dataService.storeHeaderShowed(false);
    this.patient = this.dataService.retrievePatient();
    this.route.queryParams.subscribe((params) => {
      this.fromCourse = params.fromCourse == 1;
    });
    const objState: any = this.location.getState();
    let obj: any;
    if (this.fromCourse) {
      this.course = objState[0];
      obj = objState[1];
      this.returnTitle = i18nKey('Checkup.navbar.patient.back');
      this.weight = obj.value;
    } else {
      obj = objState;
      this.weight = obj.value;
    }
    this.testData = obj;
    this.patientService.patientControllerFindById(obj.patientId).subscribe((p) => {
      this.patient = p;
    });
    this.route.queryParams.subscribe((params) => {
      this.fromBilan = params.fromBilan == 1;
    });
  }

  onCloseModal() {
    this.btnClose.nativeElement.click();
  }

  resetModal() {}

  public onSelectPage(newPage: string) {
    this.newPage = newPage;
  }

  public goTo() {
    this.router.navigate([this.newPage]);
  }
}
