<!-- <div class="connexion_checkup " [class.loading]="state.loading" *ngIf="state"> -->
<div class="connexion_checkup ">
  <!--*ngIf="state.loading"-->

  <div class="d-flex">
    <div class="col-md-6 flex-column no-padding">
      {{ 'PIED GAUCHE' | translate }} :
      <span *ngIf="state.lfSensorConnected; else elseNotConnected" class="connected">{{
        'sensor.connected' | translate
      }}</span>
    </div>
    <div class="col-md-6 flex-column no-padding">
      {{ 'DOS' | translate }} :
      <span *ngIf="state.hipsSensorConnected; else elseNotConnected" class="connected">{{
        'sensor.connected' | translate
      }}</span>
    </div>
  </div>

  <div class="d-flex">
    <div class="col-md-6 flex-column no-padding">
      {{ 'PIED DROIT' | translate }} :
      <span *ngIf="state.rfSensorConnected; else elseNotConnected" class="connected">{{
        'sensor.connected' | translate
      }}</span>
    </div>
    <div class="col-md-6 flex-column no-padding">
      {{ 'Tete' | translate }} :
      <span *ngIf="state.headSensorConnected; else elseNotConnected" class="connected">{{
        'sensor.connected' | translate
      }}</span>
    </div>
  </div>

  <ng-template #elseNotConnected>
    <span class="not-connected">
      {{ 'sensor.notconnected' | translate }}
    </span>
  </ng-template>

  <!-- <p class="connexionTitre ">
    {{ 'Connexion' | translate }}
  </p>
  <p class="connexionTitre-LF label_connexion">
    {{ 'PIED GAUCHE' | translate }}
    <input type="checkbox" [ngModel]="state.lfSensorConnected" [disabled]="disabled" />
    <span class="battery" *ngIf="state.lfSensorBattery !== null">
      {{ 'board.batterielevel' | translate: { level: state.lfSensorBattery | number: '1.0-0' } }}
    </span>
  </p>
  <p class="connexionTitre-RF label_connexion">
    {{ 'PIED DROIT' | translate }} <input type="checkbox" [ngModel]="state.rfSensorConnected" [disabled]="disabled" />
    <span class="battery" *ngIf="state.rfSensorBattery !== null">
      {{ 'board.batterielevel' | translate: { level: state.rfSensorBattery | number: '1.0-0' } }}
    </span>
  </p>
  <p class="connexionTitre-HI label_connexion">
    {{ 'DOS' | translate }} <input type="checkbox" [ngModel]="state.hipsSensorConnected" [disabled]="disabled" />
    <span class="battery" *ngIf="state.hipsSensorBattery !== null">
      {{ 'board.batterielevel' | translate: { level: state.hipsSensorBattery | number: '1.0-0' } }}
    </span>
  </p>
  <p class="connexionTitre-HE label_connexion">
    {{ 'Tete' | translate }} <input type="checkbox" [ngModel]="state.headSensorConnected" [disabled]="disabled" />
    <span class="battery" *ngIf="state.headSensorBattery !== null">
      {{ 'board.batterielevel' | translate: { level: state.headSensorBattery | number: '1.0-0' } }}
    </span>
  </p>
  <p class="connexionTitre-F" *ngIf="!state.platformReady">{{ 'Capteurs pas prêts' | translate }}</p> -->
</div>
