<div class="delegation-container" *ngIf="rolesCheck.hasDelegationChekup | async">
  <div class="delegate-container">
    <div class="title-container">
      <h6 class="title">{{ 'Delegate.tests' | translate }}</h6>
    </div>
    <app-patient-delegate-item
      *ngIf="isLoaded"
      [typeDelegation]="testDelegation"
      [practitionnersList]="practitionnersList"
      [patientIdList]="patientIdList"
      [patientDelegationList]="patientDelegationList"
      (selectPractitionner)="onSelectPractionner($event)"
      (deletePractitionner)="onDeletePractitionner($event)"
    >
    </app-patient-delegate-item>
  </div>

  <div class="delegate-container" *ngIf="rolesCheck.hasDelegationResult | async">
    <div class="title-container">
      <h6 class="title">{{ 'Delegate.results' | translate }}</h6>
    </div>
    <app-patient-delegate-item
      *ngIf="isLoaded"
      [typeDelegation]="resultDelegation"
      [practitionnersList]="practitionnersList"
      [patientIdList]="patientIdList"
      [patientDelegationList]="patientDelegationList"
      (selectPractitionner)="onSelectPractionner($event)"
      (deletePractitionner)="onDeletePractitionner($event)"
    >
    </app-patient-delegate-item>
  </div>
</div>
