<div class="list-container">
  <div class="row justify-content">
    <div class="flex-row div-find-filter header-block">
      <button class="btn btn-tertiary" (click)="selectunSelectAllRows()" *ngIf="patients.length > 0">
        {{ selectionTitle ? selectionTitle : ('select.all' | translate) }}
      </button>
    </div>
  </div>
  <div class="list-group">
    <ng-container *ngFor="let patient of patients | filter: searchPatient; let i = index">
      <div
        class="list-group-item list-group-item-action patients-option"
        [ngClass]="{ 'patient-info-active': id === patient.id }"
        id="{{ patient.id }}"
        #patientLinks
      >
        <div class="flex-row justify-content-between align-items-center">
          <div class="flex-row align-items-center">
            <mat-checkbox
              class="checkbox"
              (change)="addPatientToDelegateList(patient.id, $event.checked)"
              [checked]="isChecked(patient.id)"
            >
            </mat-checkbox>
            <div class="div-space">
              <img *ngIf="patient.hasNamesake" src="assets/images/icons/namesake.svg" width="16" height="16" />
            </div>
            <div class="flex-column patient-info-container" (click)="selectPatient(patient.id)">
              <p class="patient-info name">{{ patient.firstName }} {{ patient.lastName }}</p>
              <p class="patient-info" *ngIf="!patient.delegateTo[0] && !patient.delegateFrom">
                {{ 'SansDelegation' | translate }}
              </p>
              <p *ngIf="patient.delegateTo[0] && !patient.delegateFrom" class="patient-info delegate-to">
                {{ 'Délégué' | translate }}
              </p>
              <!-- commented, to be integrated in V5.1
              <p *ngIf="patient.delegateFrom && patient.delegateTo[0]" class="patient-info delegate-from">
                {{ 'Délégué_par' | translate }} {{ patient.delegateFrom }}
              </p>-->
              <p class="patient-info last-test">
                {{ 'Dernier_passage' | translate }}
                <ng-container *ngIf="patient.lastTest">{{ patient.lastTest | localdate }}</ng-container>
                <ng-container *ngIf="!patient.lastTest">-</ng-container>
              </p>
            </div>
          </div>
          <div *ngIf="canDelete && patientListDelegate.length < 2">
            <div class="trash-container" (click)="deletePatient(patient)">
              <i class="trash fa fa-trash"></i>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
