import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HistoVersusConsultationService } from 'src/app/modules/consultation';
import { LocalDatePipe } from 'src/app/modules/time/pipes/localdate.pipe';
import { AnalysisResults as WalkAnalysisResults } from 'src/app/modules/walk/models/analysis-results.model';
import { AnalysisResultsRepository } from 'src/app/modules/walk/services/analysis-result-repository.service';
import { WalkOperation, WalkOperationService } from 'src/app/modules/walk/services/walk-operation.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { Compute } from 'src/app/tools/compute';

@Component({
  selector: 'app-walk-semiogram',
  templateUrl: './walk-semiogram.component.html',
  styleUrls: ['./walk-semiogram.component.scss'],
})
export class WalkSemiogramComponent implements OnInit {
  @Input() public results: WalkAnalysisResults;
  @Input() public resultsWalkToCompare: WalkAnalysisResults;
  @Input() testData: any;
  @Input() walkToCompare: any;

  public currentPatientId: number;
  public bilanType = 3;

  // public results: Promise<AnalysisResults>;
  /**
   * labels of buttons on the top of the frame (choosing age)
   */
  public typesOfComparisons = [
    { text: 'All', agemin: 0, agemax: 200 },
    { text: '18/39', agemin: 18, agemax: 40 },
    { text: '40/59', agemin: 40, agemax: 60 },
    { text: '60/79', agemin: 60, agemax: 80 },
    { text: '80/99', agemin: 80, agemax: 99 },
    { text: '+100', agemin: 100, agemax: 1000 },
  ];

  public choice: number;
  public comparedChoice$ = new BehaviorSubject<number>(0);

  public imageDisplayed: string;
  public imageToCompareDisplayed: string;

  public currentDate: string;
  public histo: boolean;

  public comparedData$: Observable<string>;

  /**
   * Constructor
   */

  constructor(
    private localDatePipe: LocalDatePipe,
    private operationService: WalkOperationService,
    private analysisResultsRepository: AnalysisResultsRepository,
    public histoVersusConsultationService: HistoVersusConsultationService,
    private dataService: DataProviderService,
  ) {}

  /**
   * Initialization
   */
  public ngOnInit() {
    this.currentPatientId = this.dataService.retrievePatient().id; // LocalStorageEx.currentPatientSelected.patientId;
    // this.histo = LocalStorageEx.histoBoolean;
    // this.histoVersusConsultationService.setPageInConsultation('checkup/walk/semiogram');
    this.operationService.setCurrentOperation(WalkOperation.Semiogram);

    const dateTest = new Date();
    // if (this.histo) {
    //   this.currentDate = this.localDatePipe.parse(
    //     new Date(LocalStorageEx.currentConsultation.date),
    //     'dd/MM/yyyy HH:mm:ss',
    //   );
    //   dateTest = new Date(LocalStorageEx.currentConsultation.date);
    //   this.comparedData$ = combineLatest(
    //     this.histoVersusConsultationService.bilanResultSelected$,
    //     this.comparedChoice$,
    //   ).pipe(flatMap(([bilan, choice]) => this.comparedImage(bilan, choice)));
    //   this.comparedChoice$.next(0);
    // }

    const ageAtTest = Compute.age(new Date(this.dataService.retrievePatient().birthDate), dateTest);
    let selectedValue: number = 0;
    for (let i = 1; i < this.typesOfComparisons.length; i++) {
      if (ageAtTest >= this.typesOfComparisons[i].agemin && ageAtTest <= this.typesOfComparisons[i].agemax) {
        selectedValue = i;
        break;
      }
    }

    //const selectedValue = this.typesOfComparisons.findIndex((val) => ageAtTest >= val.agemin && ageAtTest < val.agemax);
    this.onSelect(selectedValue >= 0 ? selectedValue : 0);
  }

  /**
   * Called when user selects an age for comparison
   * @param selection age selected for comparison
   */
  public onSelect(index: number) {
    this.choice = index;
    this.setImage();
    if (this.histoVersusConsultationService.comparaisonEnabled) {
      this.onSelectComparedData(index);
    }
  }

  public onSelectComparedData(index: number) {
    this.choice = index;
    this.comparedChoice$.next(index);
  }

  private async setImage() {
    const resultDisplayed = await this.results;
    const resultDisplayedToCompare = await this.resultsWalkToCompare;

    switch (this.choice) {
      case 0:
        this.imageDisplayed = resultDisplayed.graphes.semioAll;
        this.imageToCompareDisplayed = this.resultsWalkToCompare ? resultDisplayedToCompare.graphes.semioAll : '';
        break;
      case 1:
        this.imageDisplayed = resultDisplayed.graphes.semio18_39;
        this.imageToCompareDisplayed = this.resultsWalkToCompare ? resultDisplayedToCompare.graphes.semio18_39 : '';
        break;
      case 2:
        this.imageDisplayed = resultDisplayed.graphes.semio40_59;
        this.imageToCompareDisplayed = this.resultsWalkToCompare ? resultDisplayedToCompare.graphes.semio40_59 : '';
        break;
      case 3:
        this.imageDisplayed = resultDisplayed.graphes.semio60_79;
        this.imageToCompareDisplayed = this.resultsWalkToCompare ? resultDisplayedToCompare.graphes.semio60_79 : '';
        break;
      case 4:
        this.imageDisplayed = resultDisplayed.graphes.semio80_99;
        this.imageToCompareDisplayed = this.resultsWalkToCompare ? resultDisplayedToCompare.graphes.semio80_99 : '';
        break;
      case 5:
        this.imageDisplayed = resultDisplayed.graphes.semio100_200;
        this.imageToCompareDisplayed = this.resultsWalkToCompare ? resultDisplayedToCompare.graphes.semio100_200 : '';
        break;
    }
  }

  // private comparedImage(bilan: BilanResults, choice: number): Observable<string> {
  //   console.log('Choice compare : ' + choice);
  //   if (!bilan || !bilan.locomotionBilans || !bilan.locomotionBilans.length) {
  //     return of(null);
  //   }

  //   return this.analysisResultsRepository.getOther('' + bilan.Id).pipe(
  //     map((b) => {
  //       if (!b) {
  //         return null;
  //       }

  //       switch (choice) {
  //         case 0:
  //           return b.graphes.semio0_17;
  //         case 1:
  //           return b.graphes.semio18_39;
  //         case 2:
  //           return b.graphes.semio40_59;
  //         case 3:
  //           return b.graphes.semio60_79;
  //         case 4:
  //           return b.graphes.semio80_200;
  //       }
  //       return null;
  //     }),
  //   );
  // }
}
