import { Component, Input, OnInit } from '@angular/core';
import { ComparisonGraph } from 'src/app/modules/common/models/comparison.model';
import { AnalysisResults as WalkAnalysisResults } from 'src/app/modules/walk/models/analysis-results.model';
import { SUBINDICATORS } from 'src/app/modules/walk/models/subindicators.model';
import { AnalysisResultsRepository } from 'src/app/modules/walk/services/analysis-result-repository.service';
import { WalkComparisonService } from 'src/app/modules/walk/services/walk-comparaison.service';
import { WalkOperation, WalkOperationService } from 'src/app/modules/walk/services/walk-operation.service';

@Component({
  selector: 'app-walk-comparaison',
  templateUrl: './walk-comparaison.component.html',
  styleUrls: ['./walk-comparaison.component.scss'],
})
export class WalkComparaisonComponent implements OnInit {
  @Input() public results: WalkAnalysisResults;
  @Input() public resultsToCompare: WalkAnalysisResults;

  @Input() testData: any;
  @Input() walkToCompare: any;

  public typesOfComparisons: string[];
  public recup: boolean = false;
  public graphSelected: ComparisonGraph;
  public choiceOfComparison: string; // population compared to the patient
  public datas: ComparisonGraph[];
  public dataToCompare: ComparisonGraph[];
  public zoom: boolean = false;

  constructor(
    private operationService: WalkOperationService,
    private analysisResultsRepository: AnalysisResultsRepository,
    private walkComparaisonService: WalkComparisonService,
  ) {}

  /**
   * Initialisation of populations for comparisons and the differents types of graphs.
   */
  ngOnInit() {
    this.operationService.setCurrentOperation(WalkOperation.Comparison);

    this.typesOfComparisons = SUBINDICATORS;
    this.choiceOfComparison = this.typesOfComparisons[0];
    // this.results = await this.analysisResultsRepository.get(LocalStorageEx.currentWalkRecordingSession);
    this.getGraph(this.typesOfComparisons[0]);
  }

  /**
   * Zoom on a graph
   * @param e object pointed
   */
  public chartClicked(e: ComparisonGraph): void {
    this.zoom = true;
    this.graphSelected = e;
  }

  /**
   * Close the zoom page
   */
  public close() {
    this.zoom = false;
  }

  /**
   * selection of a population for comparison
   * @param selection the population selected
   */
  public onSelect(selection: string) {
    this.choiceOfComparison = selection;
    this.getGraph(selection);
  }

  /**
   * Calls the service bilan-marche to get data
   */
  private async getGraph(selection: string) {
    this.recup = false;
    this.datas = await this.walkComparaisonService.getAllGraph(selection, this.results);

    if (this.resultsToCompare) {
      this.dataToCompare = await this.walkComparaisonService.getAllGraph(selection, this.resultsToCompare);
      for (let i = 0; i < this.dataToCompare.length; i++) {
        let item = this.dataToCompare[i];
        let datasArray = this.datas[i];
        item.graph.forEach((graphItem) => {
          if (graphItem.current) {
            graphItem.current = false;
            graphItem.toCompare = true;
            datasArray.graph.push(graphItem);
          }
        });
        this.datas[i] = datasArray;
      }
    }
    this.recup = true;
  }
}
