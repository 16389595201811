import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { PractitionerControllerService, Practitioner, PractitionerWithRelations } from '@abilycare/dal-client';

@Injectable({ providedIn: 'root' })
export class PractitionerDataService {
  constructor(private pratitionerControllerService: PractitionerControllerService) {}

  public getPractitionerMe(): Observable<PractitionerWithRelations> {
    return this.pratitionerControllerService
      .practitionerControllerMe()
      .pipe(map((res) => res as PractitionerWithRelations));
  }

  public getAllPractitioners(): Observable<Practitioner[]> {
    return this.pratitionerControllerService.practitionerControllerList().pipe(map((res) => res as Practitioner[]));
  }

  public setMySpeciality(idSpeciality: number): Observable<any> {
    return this.pratitionerControllerService
      .practitionerControllerSetMySpeciality(idSpeciality)
      .pipe(map((res) => res as any));
  }
}
