import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { PratitionerSpecialityControllerService, PractitionerSpeciality } from '@abilycare/dal-client';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class PractitionerSpecialityDataService {
  lang = '';

  constructor(
    private pratitionerSpecialityControllerService: PratitionerSpecialityControllerService,
    private translateService: TranslateService,
  ) {
    this.lang = this.translateService.currentLang;
  }

  public getPractitionerSpeciality(id: number): Observable<PractitionerSpeciality> {
    return this.pratitionerSpecialityControllerService
      .pratitionerSpecialityControllerFindById(id, this.lang)
      .pipe(map((res) => res as PractitionerSpeciality));
  }

  public getAllPractitionerSpecialities(): Observable<PractitionerSpeciality[]> {
    return this.pratitionerSpecialityControllerService
      .pratitionerSpecialityControllerList(this.lang)
      .pipe(map((res) => res as PractitionerSpeciality[]));
  }
}
