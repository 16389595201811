export class Notification {
  public constructor(private messageString: string, private notificationLevel: NotificationLevel) {}

  public static newError(message: string): Notification {
    return new Notification(message, NotificationLevel.Error);
  }

  public static newWarning(message: string): Notification {
    return new Notification(message, NotificationLevel.Warn);
  }

  public static newInfo(message: string): Notification {
    return new Notification(message, NotificationLevel.Info);
  }

  public get message(): string {
    return this.messageString;
  }

  public get level(): NotificationLevel {
    return this.notificationLevel;
  }
}

export enum NotificationLevel {
  Error,
  Warn,
  Info,
}
