<div class="shoes-selector">
  <div class="row">
    <div class="col-lg-12">
      <div class="page-sub-title">{{ 'app.romberg.shoes.type' | translate }}</div>
      <select [ngModel]="value.shoesType" (ngModelChange)="setShoes($event)">
        <option *ngFor="let shoes of footWears" [ngValue]="shoes.alias">{{ shoes.translate }}</option>
      </select>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="page-sub-title">{{ 'app.romberg.help.stability' | translate }}</div>
      <select [ngModel]="value.helpStability" (ngModelChange)="setHelp($event)">
        <option *ngFor="let help of helpStability" [ngValue]="help.translate">{{ help.translate }}</option>
      </select>
    </div>
  </div>
</div>
