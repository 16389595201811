import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './view/login/login.component';
import { PatientComponent } from './view/patient/patient.component';
import { AuthGuard } from './modules/core/services/authentification/guard.service';
import { ProfilComponent } from './view/profil/profil.component';
import { SettingsComponent } from './view/settings/settings.component';
import { WeightComponent } from './view/courses/fried/weight/weight.component';
import { PatattListComponent } from './view/patatt/patatt-list/patatt-list.component';
import { PatattEditionComponent } from './view/patatt/patatt-edition/patatt-edition.component';
import { RombergComponent } from './view/courses/fried/romberg/romberg.component';
import { WalkComponent } from './view/courses/fried/walk/walk.component';
import { QuestionnaryComponent } from './view/courses/fried/questionnary/questionnary.component';
import { RapportNewComponent } from './modules/rapports/rapport-new/rapport-new.component';
import { RapportSelectionComponent } from './modules/rapports/rapport-selection/rapport-selection.component';
import { Roles } from './modules/core/roles.enum';
import { RapportPraticienComponent } from './modules/rapports/rapportPraticien/rapportPraticien.component';
import { FriedComponent } from './view/courses/fried/fried.component';
import { PatientHistoryCoursesComponent } from './view/patient/patient-history-courses/patient-history-courses.component';
import { GripComponent } from './view/courses/fried/grip/grip.component';
import { WalkViewComponent } from './view/courses/fried/walk/walk-view/walk-view.component';
import { RombergViewComponent } from './view/courses/fried/romberg/romberg-view/romberg-view.component';
import { WeightViewComponent } from './view/courses/fried/weight/weight-view/weight-view.component';
import { GripViewComponent } from './view/courses/fried/grip/grip-view/grip-view.component';
import { QuestionnaryViewComponent } from './view/courses/fried/questionnary/questionnary-view/questionnary-view.component';
import { GripMonitorComponent } from './view/courses/fried/grip/grip-monitor/grip-monitor.component';
import { BilansComponent } from './view/Bilans/bilans.component';
import { NotificationsComponent } from './view/notifications/notifications.component';
import { WalkComparisonComponent } from './view/courses/fried/walk/walk-comparison/walk-comparison.component';
import { RombergComparisonComponent } from './view/courses/fried/romberg/romberg-comparison/romberg-comparison.component';
import { CourseListComponent } from './view/courses/fried/course/course-list/course-list.component';
import { QuestionnaryInfosComponent } from './view/courses/fried/questionnary/questionnary-infos/questionnary-infos.component';
import { QuestionnaryCourseComponent } from './view/courses/fried/questionnary-course/questionnary-course.component';
import { CourseSynthesisComponent } from './view/courses/fried/course/synthesis/course-synthesis.component';
import { ConfigComponent } from './view/common/config/config.component';
import { LoginErrorComponent } from './view/login-error/login-error.component';
import { InformationConnexionComponent } from './view/information-connexion/information-connexion.component';

const appRoutes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'Patient', component: PatientComponent, canActivate: [AuthGuard], canDeactivate: [AuthGuard] },
  { path: 'Patient/:patientId', component: PatientComponent, canActivate: [AuthGuard], canDeactivate: [AuthGuard] },
  { path: 'Profil', component: ProfilComponent, canActivate: [AuthGuard], canDeactivate: [AuthGuard] },
  { path: 'Notifications', component: NotificationsComponent, canActivate: [AuthGuard], canDeactivate: [AuthGuard] },
  { path: 'Settings', component: SettingsComponent, canActivate: [AuthGuard], canDeactivate: [AuthGuard] },
  { path: 'courses/fried', component: FriedComponent, canActivate: [AuthGuard], canDeactivate: [AuthGuard] },
  { path: 'courses/fried/weight', component: WeightComponent, canActivate: [AuthGuard], canDeactivate: [AuthGuard] },
  { path: 'courses/fried/romberg', component: RombergComponent, canActivate: [AuthGuard], canDeactivate: [AuthGuard] },
  { path: 'courses/fried/walk', component: WalkComponent, canActivate: [AuthGuard], canDeactivate: [AuthGuard] },
  { path: 'courses/fried/grip', component: GripComponent, canActivate: [AuthGuard], canDeactivate: [AuthGuard] },
  { path: 'Bilans', component: BilansComponent, canActivate: [AuthGuard], canDeactivate: [AuthGuard] },
  {
    path: 'courses/fried/walkComparison',
    component: WalkComparisonComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: 'courses/fried/rombergComparison',
    component: RombergComparisonComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },

  {
    path: 'courses/fried/walkView',
    component: WalkViewComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: 'gripMonitor',
    component: GripMonitorComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: 'config',
    component: ConfigComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: 'loginError',
    component: LoginErrorComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: 'courses/fried/rombergView',
    component: RombergViewComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: 'courses/fried/weightView',
    component: WeightViewComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: 'courses/fried/gripView',
    component: GripViewComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: 'courses/fried/questionnaryView',
    component: QuestionnaryViewComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: 'courses/fried/questionnaryView11',
    component: QuestionnaryViewComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },

  {
    path: 'courses/fried/questionnaryCourse/:questionnaryId',
    component: QuestionnaryCourseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },

  {
    path: 'rapport',
    component: RapportSelectionComponent,
    canActivate: [AuthGuard],
    data: { role: Roles.accessResults },
  },
  {
    path: 'rapport/patient',
    component: RapportNewComponent,
    canActivate: [AuthGuard],
    data: { role: Roles.patientReport },
  },
  {
    path: 'config',
    component: InformationConnexionComponent,
    canActivate: [AuthGuard],
    data: { role: Roles.configurator },
  },
  {
    path: 'rapport/praticien',
    component: RapportPraticienComponent,
    canActivate: [AuthGuard],
    data: { role: Roles.doctorReport },
  },
  {
    path: 'courses/fried/questionnary',
    component: QuestionnaryComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: 'courses/fried/questionnary/:questionnaryId',
    component: QuestionnaryComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: 'courses/synthesis',
    component: CourseSynthesisComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: 'PatAtt/:patientId',
    children: [
      { path: '', component: PatattListComponent, canActivate: [AuthGuard], canDeactivate: [AuthGuard] },
      {
        path: ':isCreation/edition',
        component: PatattEditionComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'CourseList/:patientId',
    component: CourseListComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: 'PatientHistoryCourses/:patientId',
    component: PatientHistoryCoursesComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
