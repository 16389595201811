<app-custom-navbar *ngIf="returnTitle !== ''" [patient]="patient" [returnTitle]="returnTitle"></app-custom-navbar>
<div class="main-container container">
  <div class="row row-content">
    <div class="main-content flex-column">
      <div class="header">
        <h3>{{ 'Patient.Creation.clinical_profile' | translate }}</h3>
        <div class="sub-header">
          <h6>{{ 'Patient.Complet.clinical_profile' | translate }}</h6>
        </div>
      </div>

      <div class="flex-column master-content">
        <div class="content flex-column">
          <div class="etiology-container flex-column container-margin">
            <ng-container *ngIf="isCreation; else elseEtiology">
              <h5>{{ 'Patient.Choose.Etiology' | translate }}</h5>
              <select
                class="form-control selectpicker"
                name="select-etiology"
                id="select-etiology"
                (change)="onEtiologieChange()"
                [(ngModel)]="selectedEtiology"
                data-live-search="true"
              >
                <option data-tokens="ketchup mustard" [ngValue]="null"></option>
                <option *ngFor="let etiology of etiologiesList" data-tokens="etiology.name" [ngValue]="etiology">{{
                  etiology.name
                }}</option>
              </select>
            </ng-container>

            <ng-template #elseEtiology>
              <h5>{{ 'Patient.Etiologie' | translate }}</h5>
              <h6 class="selected-item" *ngIf="selectedPatatt && selectedPatatt.etiology; else elseNothing">
                {{ selectedPatatt.etiology }}
              </h6>
            </ng-template>
          </div>

          <div class="pathology-container flex-column container-margin">
            <ng-container *ngIf="isCreation; else elsePathology">
              <h5>{{ 'Patient.Choose.Pathology' | translate }}</h5>
              <select
                class="form-control"
                name="select-pathology"
                id="select-pathology"
                [(ngModel)]="selectedPathology"
                [disabled]="!pathologiesList || pathologiesList.length === 0"
              >
                <option [ngValue]="null"></option>
                <option *ngFor="let pathology of pathologiesList" [ngValue]="pathology">{{ pathology.name }} </option>
              </select>
            </ng-container>

            <ng-template #elsePathology>
              <h5>{{ 'Patient.Pathology' | translate }}</h5>
              <h6 class="selected-item" *ngIf="selectedPatatt && selectedPatatt.pathology; else elseNothing">
                {{ selectedPatatt.pathology }}
              </h6>
            </ng-template>
          </div>

          <div class="symptoms-container flex-column container-margin">
            <h5>{{ 'Patient.Choose.Symptom' | translate }}</h5>

            <div class="accordion" id="accodionSelection">
              <!-- ADD SYMPTOM -->
              <div class="card card-container">
                <div class="card-header card-header-container" id="headingOne">
                  <h2 class="mb-0">
                    <button
                      #addSymptomButton
                      class="btn btn-tertiary"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseAdd"
                      aria-expanded="false"
                      aria-controls="collapseAdd"
                    >
                      <i class="fa fa-plus-circle"></i>{{ 'Patient.Add_new_symptom' | translate }}
                    </button>
                  </h2>
                </div>

                <div class="collapse" id="collapseAdd" aria-labelledby="headingOne" data-parent="#accodionSelection">
                  <select
                    class="form-control"
                    name="select-symptoms"
                    id="select-symptoms"
                    [(ngModel)]="selectedSymptom"
                    (change)="onSymptomsChange()"
                    [disabled]="isSymptomDisabled"
                  >
                    <option [ngValue]="null"></option>
                    <option *ngFor="let symptom of symptomsList" [ngValue]="symptom">{{ symptom.name }}</option>
                  </select>
                </div>
              </div>

              <!-- transfer SYMPTOM -->
              <div class="card card-container" *ngIf="transferPatattList.length > 0">
                <div class="card-header card-header-container" id="headingTwo">
                  <h2 class="mb-0">
                    <button
                      #transferSymptomButton
                      class="btn btn-tertiary"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapsetransfer"
                      aria-expanded="false"
                      aria-controls="collapsetransfer"
                    >
                      <i class="fa fa-paper-plane"></i>{{ 'Patient.Transfer_symptom' | translate }}
                    </button>
                  </h2>
                </div>
                <div
                  class="collapse"
                  id="collapsetransfer"
                  aria-labelledby="headingTwo"
                  data-parent="#accodionSelection"
                >
                  <select
                    class="form-control"
                    name="select-transfer-symptoms"
                    id="select-transfer-symptoms"
                    (change)="onSymptomsTranferChange()"
                    [disabled]="isSymptomDisabled"
                    [(ngModel)]="selectedSymptomtransfer"
                  >
                    <option [ngValue]="null"></option>
                    <option *ngFor="let symptom of transferPatattList" [ngValue]="symptom">{{ symptom.name }}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="list-container symptoms-list">
              <div class="list-group">
                <ng-container *ngFor="let symptom of symptomSelectedOptions; let index = index">
                  <a
                    class="list-group-item list-group-item-action flex-row symptom"
                    [ngClass]="{ 'symptom-active': index === indexSelected }"
                    id="{{ index }}"
                    (click)="selectSymptom(symptom, index)"
                  >
                    <h6 class="">{{ symptom.name }}</h6>
                    <span class="btn-important" aria-hidden="true" (click)="deleteSymptom(index)">&times;</span>
                  </a>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <div class="button-container flex-row">
          <button class="btn btn-secondary cancel" (click)="cancelPatAtt()">{{ 'Annuler' | translate }}</button>
          <button *ngIf="isCreation" class="btn btn-primary validate" (click)="createPatAtt()" [disabled]="isDisabled">
            {{ 'button.validation' | translate }}
          </button>
          <button *ngIf="!isCreation" class="btn btn-primary validate" (click)="updatePatAtt()">
            {{ 'Modifier' | translate }}
          </button>
        </div>
      </div>
    </div>

    <app-patatt-edition-symptoms
      [symptom]="selectedSymptom"
      *ngIf="selectedSymptom || symptomDetailsModify"
      [symptomDetails]="symptomDetailsModify"
      [isTransferSelected]="isTransferSelected"
      (validateOption)="onValidateOption($event)"
    ></app-patatt-edition-symptoms>
  </div>
  <ng-template #elseNothing>
    <h6 class="selected-item">{{ 'Patient.Not_specified' | translate }}</h6>
  </ng-template>
</div>

<button
  #btnDuplicatePatAttModal
  [hidden]="true"
  type="button"
  class="btn btn-primary"
  data-toggle="modal"
  data-target="#duplicatePatattModal"
  data-backdrop="static"
  data-keyboard="false"
  data-focus="true"
></button>

<!-- Modal -->
<div
  class="modal fade"
  id="duplicatePatattModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="duplicatePatattModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="duplicatePatattModalLabel">{{ 'PatAtt.existing.title' | translate }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{ 'PatAtt.existing.content' | translate }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">{{ 'OK' | translate }}</button>
      </div>
    </div>
  </div>
</div>
