<div class="container">
  <div class="mx-auto col-sm-8 mt-5">
    <div class="form-group">
      <label>{{ 'Langue' | translate }}</label>

      <div class="formy flex-row selectlanguages">
        <select class="form-control" (change)="setLanguage($event)" [(ngModel)]="selectLanguage">
          <option *ngFor="let locale of locales" value="{{ locale.code }}">{{ locale.label }}</option>
        </select>
      </div>
    </div>
  </div>

  <!--<div class="row button-info-tuto">
    <div class="col-lg-4">
      <button class="btn btn-default" routerLink="/tutorial-sync-ForceTab" routerLinkActive="active">
        {{ 'Settings.tutorial.platform' | translate }}
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <board-sync (wiiConnectedEvent)="wiiConnected = $event" (errorMessageEvent)="errorEvent($event)"> </board-sync>
    </div>
  </div>

  <div class="alert alert-danger" *ngIf="errorMessage">
    <div>{{ errorMessage | translate }}</div>
  </div>-->
</div>
