import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TimeService } from './services/time.service';
import { LocalDatePipe } from './pipes/localdate.pipe';
import { LocalHourPipe } from './pipes/localhour.pipe';

@NgModule({
  imports: [TranslateModule.forChild()],
  declarations: [LocalDatePipe, LocalHourPipe],
  providers: [TimeService, LocalDatePipe, LocalHourPipe],
  exports: [LocalDatePipe, LocalHourPipe],
})
export class TimeModule {}
