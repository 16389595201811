import { PatientDetail } from '@abilycare/dal-client';
import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { ComparisonGraph } from 'src/app/modules/common/models/comparison.model';
import { ShoesSelectorValues } from 'src/app/modules/force-platform';
import { AnalysisResults } from 'src/app/modules/romberg/models/analysis-results.model';
import { LongitudinalGraph } from 'src/app/modules/romberg/models/longitudinal.model';
import { RombergSummary } from 'src/app/modules/romberg/models/romberg-summary.model';
import { SUBINDICATORS } from 'src/app/modules/romberg/models/subindicators.model';
import { AnalysisResultsRepository } from 'src/app/modules/romberg/services/analysis-result-repository.service';
import { RombergComparisonService } from 'src/app/modules/romberg/services/romberg-comparaison.service';
import { Timer } from 'src/app/modules/time';
import { DataProviderService } from 'src/app/services/data-provider.service';
import * as medicalConst from 'src/app/tools/medical.constants';
import { ModalComponent } from 'src/app/view/common/modal/modal.component';
import { Location } from '@angular/common';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-romberg-comparison',
  templateUrl: './romberg-comparison.component.html',
  styleUrls: ['./romberg-comparison.component.scss'],
  providers: [AnalysisResultsRepository, RombergComparisonService],
})
export class RombergComparisonComponent implements OnInit, OnDestroy {
  @ViewChild('btnClose', { static: true }) btnClose: ElementRef;
  @ViewChild('btnCloseGraphModal', { static: true }) btnCloseGraphModal: ElementRef;

  @ViewChildren(ModalComponent)
  public modals: QueryList<ModalComponent>;

  public shoesSelectorValues: ShoesSelectorValues;

  public timeFinishYO: string;
  public timeFinishYF: string;

  public patient: PatientDetail;

  private unsubscribe = new Subject<void>();
  private subscriptions: Subscription[] = [];

  public data: RombergSummary = new RombergSummary();
  public analyisResult: AnalysisResults;
  public dataToCompare: RombergSummary = new RombergSummary();
  public analyisResultToCompare: AnalysisResults;

  // Valeurs booléennes permettant de déterminer si on est en dehors des bornes pour chaque valeur
  public isOffLimitOpenedEyesScore: boolean = false;
  public isOffLimitClosedEyesScore: boolean = false;
  public isOffLimitOpenedEyesArea: boolean = false;
  public isOffLimitClosedEyesArea: boolean = false;
  public isOffLimitOpenedEyesSwayDensity: boolean = false;
  public isOffLimitClosedEyesSwayDensity: boolean = false;
  public isOffLimitOpenedEyesLateralVariance: boolean = false;
  public isOffLimitClosedEyesLateralVariance: boolean = false;
  public isOffLimitOpenedEyesAverageSpeed: boolean = false;
  public isOffLimitClosedEyesAverageSpeed: boolean = false;

  // Valeurs permettant de définir les limites de chaque valeur calculée
  public minEyesScore: number = 0;
  public maxEyesScore: number = 100;
  public minEyesArea: number = 0;
  public maxEyesArea: number = 15;
  public minEyesSwayDensity: number = 0;
  public maxEyesSwayDensity: number = 30;
  public minEyesLateralVariance: number = 0;
  public maxEyesLateralVariance: number = 5;
  public minEyesAverageSpeed: number = 0;
  public maxEyesAverageSpeed: number = 8;
  public minEyesPrcSupport: number = 0;
  public maxEyesPrcSupport: number = 100;

  //value to get value and highValue for ngx-slider
  public valueEyesScoreOpen: number = 0;
  public highValueEyesScoreOpen: number = 100;
  public valueEyesAreaOpen: number = 0;
  public highValueEyesAreaOpen: number = 50;
  public valueEyesSwayDensityOpen: number = 15;
  public highValueEyesSwayDensityOpen: number = 30;
  public valueEyesLateralVarianceOpen: number = 0.0;
  public highValueEyesLateralVarianceOpen: number = 0.1;
  public valueEyesAverageSpeedOpen: number = 0;
  public highValueEyesAverageSpeedOpen: number = 50;
  public valueEyesScoreClose: number = 0;
  public highValueEyesScoreClose: number = 100;
  public valueEyesAreaClose: number = 0;
  public highValueEyesAreaClose: number = 50;
  public valueEyesSwayDensityClose: number = 15;
  public highValueEyesSwayDensityClose: number = 30;
  public valueEyesLateralVarianceClose: number = 0.0;
  public highValueEyesLateralVarianceClose: number = 0.1;
  public valueEyesAverageSpeedClose: number = 0;
  public highValueEyesAverageSpeedClose: number = 50;

  public valueEyesPrcLeftSupportOpen: number = 0;
  public highValueEyesPrcLeftSupportOpen: number = 100;
  public valueEyesPrcLeftSupportClose: number = 0;
  public highValueEyesPrcLeftSupportClose: number = 100;

  public optionsEyesScore: Options;
  public optionsEyesArea: Options;
  public optionsEyesLateralVariance: Options;
  public optionsAverageSpeed: Options;
  public optionsEyesSwayDensity: Options;
  public optionsEyesScoreClose: Options;
  public optionsEyesAreaClose: Options;
  public optionsEyesLateralVarianceClose: Options;
  public optionsAverageSpeedClose: Options;
  public optionsEyesSwayDensityClose: Options;
  public optionsPrcLeftSupportOpen: Options;
  public optionsPrcLeftSupportClose: Options;

  public graphTitle = this.translateService.instant('Checkup.Romberg.Breadcrumb.StatoKinesiGram');
  public isStatokinesigram = true;
  public isStatokinesigramStandard = true;
  public isStatokinesigramAsym = false;
  public isStabilogram = false;
  public isComparison = false;

  public standardGraph: boolean = true;
  public asymetrieGraph: boolean = false;

  public recupComparaison: boolean = false;
  public comparisonDatas: ComparisonGraph[];
  public comparisonDatasToCompare: ComparisonGraph[];
  public comparisonGraphSelected: ComparisonGraph;
  public typesOfComparisons: string[];
  public choiceOfComparison: string;

  public graphs: LongitudinalGraph[];
  public zoom: boolean;
  public graphSelected: LongitudinalGraph;

  public error: boolean = false;

  public calculResult: boolean = false;

  public returnTitle: string = i18nKey('Checkup.navbar.history.back');
  public testName: string = i18nKey('navbar.rombergTest');

  public newPage: string = '';

  public testData: any;
  public rombergToCompare: any;

  public readonly waitAnalyseName = 'WaitAnalyse';

  public showdata: boolean = false;

  constructor(
    private translateService: TranslateService,
    private dataService: DataProviderService,
    private router: Router,
    private analysisResultsRepository: AnalysisResultsRepository,
    private rombergComparaisonService: RombergComparisonService,
    private location: Location,
  ) {}

  ngOnInit() {
    this.dataService.storeHeaderShowed(false);
    this.patient = this.dataService.retrievePatient();
    this.shoesSelectorValues = new ShoesSelectorValues();

    const obj: any = this.location.getState();
    this.testData = obj[0];
    this.rombergToCompare = obj[1];
    const results = this.analysisResultsRepository.get(this.testData.id);
    results
      .then((value) => {
        this.analyisResult = value;
        this.data = this.buildSummary(this.analyisResult, this.shoesSelectorValues);
      })
      .catch((error) => {
        console.error('Error during get analysis result', error);
      });

    const resultsToCompare = this.analysisResultsRepository.get(this.rombergToCompare.id);
    resultsToCompare
      .then((value) => {
        this.analyisResultToCompare = value;
        this.dataToCompare = this.buildSummary(this.analyisResultToCompare, this.shoesSelectorValues);
        this.getGraphComparaison();
        this.transformBilanToGraph();
        this.GetMinAndMaxForNgxSlider();
        this.showdata = true;
      })
      .catch((error) => {
        console.error('Error during get analysis result', error);
      });

    this.typesOfComparisons = SUBINDICATORS;
    this.choiceOfComparison = this.typesOfComparisons[0];

    this.graphs = new Array<LongitudinalGraph>(5);
    this.graphs[0] = new LongitudinalGraph(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      this.translateService.instant('Checkup.Romberg.Graph.Longitu.Score'),
    );
    this.graphs[1] = new LongitudinalGraph(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      this.translateService.instant('Checkup.Romberg.Graph.Longitu.SpeedMoy'),
    );
    this.graphs[2] = new LongitudinalGraph(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      this.translateService.instant('Checkup.Romberg.Graph.Longitu.SwayDensiy'),
    );
    this.graphs[3] = new LongitudinalGraph(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      this.translateService.instant('Checkup.Romberg.Graph.Longitu.Suface'),
    );
    this.graphs[4] = new LongitudinalGraph(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      this.translateService.instant('Checkup.Romberg.Graph.Longitu.VarianceLateral'),
    );
  }

  private GetMinAndMaxForNgxSlider(): void {
    // Analysis Score
    this.valueEyesScoreOpen =
      this.analyisResult.ScoreYO > this.analyisResultToCompare.ScoreYO
        ? this.analyisResultToCompare.ScoreYO
        : this.analyisResult.ScoreYO;
    this.highValueEyesScoreOpen =
      this.analyisResult.ScoreYO < this.analyisResultToCompare.ScoreYO
        ? this.analyisResultToCompare.ScoreYO
        : this.analyisResult.ScoreYO;
    this.optionsEyesScore = {
      floor: this.minEyesScore,
      ceil: this.maxEyesScore,
      //showTicksValues: true,
      step: 1,
      readOnly: true,
      translate: (value: number): string => {
        if (value == this.minEyesScore) {
          return value + '';
        } else if (value == this.maxEyesScore) {
          return value + '';
        } else {
          return '';
        }
      },
      getPointerColor: (value: number): string => {
        if (value == this.analyisResultToCompare.ScoreYO) {
          return 'red';
        }
        if (value == this.analyisResult.ScoreYO) {
          return 'green';
        }
        return '#2AE02A';
      },
    };

    this.valueEyesScoreClose =
      this.analyisResult.ScoreYF > this.analyisResultToCompare.ScoreYF
        ? this.analyisResultToCompare.ScoreYF
        : this.analyisResult.ScoreYF;
    this.highValueEyesScoreClose =
      this.analyisResult.ScoreYF < this.analyisResultToCompare.ScoreYF
        ? this.analyisResultToCompare.ScoreYF
        : this.analyisResult.ScoreYF;
    this.optionsEyesScoreClose = {
      floor: this.minEyesScore,
      ceil: this.maxEyesScore,
      //showTicksValues: true,
      step: 1,
      readOnly: true,
      translate: (value: number): string => {
        switch (value) {
          case this.minEyesScore:
            return '' + value;
          case this.maxEyesScore:
            return '' + value;
          default:
            return '';
        }
      },
      getPointerColor: (value: number): string => {
        if (value == this.analyisResultToCompare.ScoreYF) {
          return 'red';
        }
        if (value == this.analyisResult.ScoreYF) {
          return 'green';
        }
        return '#2AE02A';
      },
    };
    // Analysis Area
    this.valueEyesAreaOpen =
      this.analyisResult.SurfaceYO > this.analyisResultToCompare.SurfaceYO
        ? this.analyisResultToCompare.SurfaceYO
        : this.analyisResult.SurfaceYO;
    this.highValueEyesAreaOpen =
      this.analyisResult.SurfaceYO < this.analyisResultToCompare.SurfaceYO
        ? this.analyisResultToCompare.SurfaceYO
        : this.analyisResult.SurfaceYO;
    this.valueEyesAreaClose =
      this.analyisResult.SurfaceYF > this.analyisResultToCompare.SurfaceYF
        ? this.analyisResultToCompare.SurfaceYF
        : this.analyisResult.SurfaceYF;
    this.highValueEyesAreaClose =
      this.analyisResult.SurfaceYF < this.analyisResultToCompare.SurfaceYF
        ? this.analyisResultToCompare.SurfaceYF
        : this.analyisResult.SurfaceYF;
    this.optionsEyesArea = {
      floor: this.minEyesArea,
      ceil: this.maxEyesArea,
      //showTicksValues: true,
      step: 0.1,
      readOnly: true,
      translate: (value: number): string => {
        if (value == this.minEyesArea) {
          return value + '';
        } else if (value == this.maxEyesArea) {
          return value + '';
        } else {
          return '';
        }
      },
      getPointerColor: (value: number): string => {
        if (value == parseFloat(this.analyisResultToCompare.SurfaceYO.toFixed(1))) {
          return 'red';
        }
        if (value == parseFloat(this.analyisResult.SurfaceYO.toFixed(1))) {
          return 'green';
        }
        return '#2AE02A';
      },
    };
    this.optionsEyesAreaClose = {
      floor: this.minEyesArea,
      ceil: this.maxEyesArea,
      //showTicksValues: true,
      step: 0.1,
      readOnly: true,
      translate: (value: number): string => {
        if (value == this.minEyesArea) {
          return value + '';
        } else if (value == this.maxEyesArea) {
          return value + '';
        } else {
          return '';
        }
      },
      getPointerColor: (value: number): string => {
        if (value == parseFloat(this.analyisResultToCompare.SurfaceYF.toFixed(1))) {
          return 'red';
        }
        if (value == parseFloat(this.analyisResult.SurfaceYF.toFixed(1))) {
          return 'green';
        }
        return '#2AE02A';
      },
    };

    // Analysis Sway Density
    this.valueEyesSwayDensityOpen =
      this.analyisResult.SwayDensityYO > this.analyisResultToCompare.SwayDensityYO
        ? this.analyisResultToCompare.SwayDensityYO
        : this.analyisResult.SwayDensityYO;
    this.highValueEyesSwayDensityOpen =
      this.analyisResult.SwayDensityYO < this.analyisResultToCompare.SwayDensityYO
        ? this.analyisResultToCompare.SwayDensityYO
        : this.analyisResult.SwayDensityYO;
    this.valueEyesSwayDensityClose =
      this.analyisResult.SwayDensityYF > this.analyisResultToCompare.SwayDensityYF
        ? this.analyisResultToCompare.SwayDensityYF
        : this.analyisResult.SwayDensityYF;
    this.highValueEyesSwayDensityClose =
      this.analyisResult.SwayDensityYF < this.analyisResultToCompare.SwayDensityYF
        ? this.analyisResultToCompare.SwayDensityYF
        : this.analyisResult.SwayDensityYF;
    this.optionsEyesSwayDensity = {
      floor: this.minEyesSwayDensity,
      ceil: this.maxEyesSwayDensity,
      //showTicksValues: true,
      step: 0.1,
      readOnly: true,
      translate: (value: number): string => {
        if (value == this.minEyesSwayDensity) {
          return value + '';
        } else if (value == this.maxEyesSwayDensity) {
          return value + '';
        } else {
          return '';
        }
      },
      getPointerColor: (value: number): string => {
        if (value == parseFloat(this.analyisResultToCompare.SwayDensityYO.toFixed(1))) {
          return 'red';
        }
        if (value == parseFloat(this.analyisResult.SwayDensityYO.toFixed(1))) {
          return 'green';
        }
        return '#2AE02A';
      },
    };
    this.optionsEyesSwayDensityClose = {
      floor: this.minEyesSwayDensity,
      ceil: this.maxEyesSwayDensity,
      //showTicksValues: true,
      step: 0.1,
      readOnly: true,
      translate: (value: number): string => {
        if (value == this.minEyesSwayDensity) {
          return value + '';
        } else if (value == this.maxEyesSwayDensity) {
          return value + '';
        } else {
          return '';
        }
      },
      getPointerColor: (value: number): string => {
        if (value == parseFloat(this.analyisResultToCompare.SwayDensityYF.toFixed(1))) {
          return 'red';
        }
        if (value == parseFloat(this.analyisResult.SwayDensityYF.toFixed(1))) {
          return 'green';
        }
        return '#2AE02A';
      },
    };

    // Analysis Lateral Variance
    this.valueEyesLateralVarianceOpen =
      this.analyisResult.VarianceLateraleYO > this.analyisResultToCompare.VarianceLateraleYO
        ? this.analyisResultToCompare.VarianceLateraleYO
        : this.analyisResult.VarianceLateraleYO;
    this.highValueEyesLateralVarianceOpen =
      this.analyisResult.VarianceLateraleYO < this.analyisResultToCompare.VarianceLateraleYO
        ? this.analyisResultToCompare.VarianceLateraleYO
        : this.analyisResult.VarianceLateraleYO;
    this.valueEyesLateralVarianceClose =
      this.analyisResult.VarianceLateraleYF > this.analyisResultToCompare.VarianceLateraleYF
        ? this.analyisResultToCompare.VarianceLateraleYF
        : this.analyisResult.VarianceLateraleYF;
    this.highValueEyesLateralVarianceClose =
      this.analyisResult.VarianceLateraleYF < this.analyisResultToCompare.VarianceLateraleYF
        ? this.analyisResultToCompare.VarianceLateraleYF
        : this.analyisResult.VarianceLateraleYF;
    this.optionsEyesLateralVariance = {
      floor: this.minEyesLateralVariance,
      ceil: this.maxEyesLateralVariance,
      //showTicksValues: true,
      step: 0.001,
      readOnly: true,
      translate: (value: number): string => {
        if (value == this.minEyesLateralVariance) {
          return value + '';
        } else if (value == this.maxEyesLateralVariance) {
          return value + '';
        } else {
          return '';
        }
      },
      getPointerColor: (value: number): string => {
        if (value == parseFloat(this.analyisResultToCompare.VarianceLateraleYO.toFixed(3))) {
          return 'red';
        }
        if (value == parseFloat(this.analyisResult.VarianceLateraleYO.toFixed(3))) {
          return 'green';
        }
        return '#2AE02A';
      },
    };
    this.optionsEyesLateralVarianceClose = {
      floor: this.minEyesLateralVariance,
      ceil: this.maxEyesLateralVariance,
      //showTicksValues: true,
      step: 0.001,
      readOnly: true,
      translate: (value: number): string => {
        if (value == this.minEyesLateralVariance) {
          return value + '';
        } else if (value == this.maxEyesLateralVariance) {
          return value + '';
        } else {
          return '';
        }
      },
      getPointerColor: (value: number): string => {
        if (value == parseFloat(this.analyisResultToCompare.VarianceLateraleYF.toFixed(3))) {
          return 'red';
        }
        if (value == parseFloat(this.analyisResult.VarianceLateraleYF.toFixed(3))) {
          return 'green';
        }
        return '#2AE02A';
      },
    };

    // Analysis Average Speed
    this.valueEyesAverageSpeedOpen =
      this.analyisResult.SpeedMoyYO > this.analyisResultToCompare.SpeedMoyYO
        ? this.analyisResultToCompare.SpeedMoyYO
        : this.analyisResult.SpeedMoyYO;
    this.highValueEyesAverageSpeedOpen =
      this.analyisResult.SpeedMoyYO < this.analyisResultToCompare.SpeedMoyYO
        ? this.analyisResultToCompare.SpeedMoyYO
        : this.analyisResult.SpeedMoyYO;
    this.valueEyesAverageSpeedClose =
      this.analyisResult.SpeedMoyYF > this.analyisResultToCompare.SpeedMoyYF
        ? this.analyisResultToCompare.SpeedMoyYF
        : this.analyisResult.SpeedMoyYF;
    this.highValueEyesAverageSpeedClose =
      this.analyisResult.SpeedMoyYF < this.analyisResultToCompare.SpeedMoyYF
        ? this.analyisResultToCompare.SpeedMoyYF
        : this.analyisResult.SpeedMoyYF;
    this.optionsAverageSpeed = {
      floor: this.minEyesAverageSpeed,
      ceil: this.maxEyesAverageSpeed,
      //showTicksValues: true,
      step: 0.1,
      readOnly: true,
      translate: (value: number): string => {
        if (value == this.minEyesAverageSpeed) {
          return value + '';
        } else if (value == this.maxEyesAverageSpeed) {
          return value + '';
        } else {
          return '';
        }
      },
      getPointerColor: (value: number): string => {
        if (value == parseFloat(this.analyisResultToCompare.SpeedMoyYO.toFixed(1))) {
          return 'red';
        }
        if (value == parseFloat(this.analyisResult.SpeedMoyYO.toFixed(1))) {
          return 'green';
        }
        return '#2AE02A';
      },
    };
    this.optionsAverageSpeedClose = {
      floor: this.minEyesAverageSpeed,
      ceil: this.maxEyesAverageSpeed,
      //showTicksValues: true,
      step: 0.1,
      readOnly: true,
      translate: (value: number): string => {
        if (value == this.minEyesAverageSpeed) {
          return value + '';
        } else if (value == this.maxEyesAverageSpeed) {
          return value + '';
        } else {
          return '';
        }
      },
      getPointerColor: (value: number): string => {
        if (value == parseFloat(this.analyisResultToCompare.SpeedMoyYF.toFixed(1))) {
          return 'red';
        }
        if (value == parseFloat(this.analyisResult.SpeedMoyYF.toFixed(1))) {
          return 'green';
        }
        return '#2AE02A';
      },
    };
    //prcentage support left
    this.valueEyesPrcLeftSupportOpen =
      this.testData.openedEyesLeftSupport > this.rombergToCompare.openedEyesLeftSupport
        ? this.rombergToCompare.openedEyesLeftSupport
        : this.testData.openedEyesLeftSupport;
    this.highValueEyesPrcLeftSupportOpen =
      this.testData.openedEyesLeftSupport < this.rombergToCompare.openedEyesLeftSupport
        ? this.rombergToCompare.openedEyesLeftSupport
        : this.testData.openedEyesLeftSupport;
    this.valueEyesPrcLeftSupportClose =
      this.testData.closedEyesLeftSupport > this.rombergToCompare.closedEyesLeftSupport
        ? this.rombergToCompare.closedEyesLeftSupport
        : this.testData.closedEyesLeftSupport;
    this.highValueEyesPrcLeftSupportClose =
      this.testData.closedEyesLeftSupport < this.rombergToCompare.closedEyesLeftSupport
        ? this.rombergToCompare.closedEyesLeftSupport
        : this.testData.closedEyesLeftSupport;
    this.optionsPrcLeftSupportOpen = {
      floor: this.minEyesPrcSupport,
      ceil: this.maxEyesPrcSupport,
      //showTicksValues: true,
      step: 0.1,
      readOnly: true,
      translate: (value: number): string => {
        if (value == this.minEyesPrcSupport) {
          return this.translateService.instant('Gauche');
        } else if (value == this.maxEyesPrcSupport) {
          return this.translateService.instant('Droite');
        } else {
          return '';
        }
      },
      getPointerColor: (value: number): string => {
        if (value == parseFloat(this.rombergToCompare.openedEyesLeftSupport.toFixed(0))) {
          return 'red';
        }
        if (value == parseFloat(this.testData.openedEyesLeftSupport.toFixed(0))) {
          return 'green';
        }
        return '#2AE02A';
      },
    };

    this.optionsPrcLeftSupportClose = {
      floor: this.minEyesPrcSupport,
      ceil: this.maxEyesPrcSupport,
      //showTicksValues: true,
      step: 0.1,
      readOnly: true,
      translate: (value: number): string => {
        if (value == this.minEyesPrcSupport) {
          return this.translateService.instant('Gauche');
        } else if (value == this.maxEyesPrcSupport) {
          return this.translateService.instant('Droite');
        } else {
          return '';
        }
      },
      getPointerColor: (value: number): string => {
        if (value == parseFloat(this.rombergToCompare.closedEyesLeftSupport.toFixed(0))) {
          return 'red';
        }
        if (value == parseFloat(this.testData.closedEyesLeftSupport.toFixed(0))) {
          return 'green';
        }
        return '#2AE02A';
      },
    };
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
  }

  onCloseModal() {
    this.btnClose.nativeElement.click();
  }

  private showModal(modalName: string) {
    this.modals.filter((e) => e.name === modalName).forEach((e) => e.show());
  }
  private hideModal(modalName: string) {
    this.modals.filter((e) => e.name === modalName).forEach((e) => e.hide());
  }

  resetModal() {}

  private buildSummary(results: AnalysisResults, preamble: ShoesSelectorValues): RombergSummary {
    console.log('buildSummary');
    const out = new RombergSummary();

    out.shoesType = preamble.shoesType;
    out.helpStability = preamble.helpStability;
    out.ScoreYF = results.ScoreYF;
    out.ScoreYO = results.ScoreYO;
    out.SpeedMoyYF = results.SpeedMoyYF;
    out.SpeedMoyYO = results.SpeedMoyYO;
    out.SurfaceYF = results.SurfaceYF;
    out.SurfaceYO = results.SurfaceYO;
    out.SwayDensityYF = results.SwayDensityYF;
    out.SwayDensityYO = results.SwayDensityYO;
    out.VarianceLateraleYF = results.VarianceLateraleYF;
    out.VarianceLateraleYO = results.VarianceLateraleYO;
    out.Duration = medicalConst.TIME_TO_ROMBERG;
    out.statsym = this.transformUrl(results.graphes.stasym);
    out.statok = this.transformUrl(results.graphes.statok);
    out.stabilo = this.transformUrl(results.graphes.stabilo);

    return out;
  }

  private transformUrl(url: string) {
    //return url.replace('https://', 'http://');
    return url;
  }

  public closeGraphModal() {
    this.btnCloseGraphModal.nativeElement.click();
  }

  public openGraphModal() {
    this.graphTitle = this.translateService.instant('Checkup.Romberg.Breadcrumb.StatoKinesiGram');
    this.isStatokinesigram = true;
    this.isStatokinesigramStandard = true;
    this.isStatokinesigramAsym = false;
    this.isStabilogram = false;
    this.isComparison = false;
    this.standardGraph = true;
    this.asymetrieGraph = false;
  }

  public goToGraph(graph: string) {
    if (graph === 'statokinesigram') {
      this.graphTitle = this.translateService.instant('Checkup.Romberg.Breadcrumb.StatoKinesiGram');
      this.isStatokinesigram = true;
      this.isStatokinesigramStandard = true;
      this.isStatokinesigramAsym = false;
      this.isStabilogram = false;
      this.isComparison = false;
    } else if (graph === 'stabilogram') {
      this.graphTitle = this.translateService.instant('Checkup.Romberg.Breadcrumb.StabiloGram');
      this.isStatokinesigram = false;
      this.isStatokinesigramStandard = false;
      this.isStatokinesigramAsym = false;
      this.isStabilogram = true;
      this.isComparison = false;
    } else if (graph === 'comparison') {
      this.graphTitle = this.translateService.instant('Checkup.Romberg.Breadcrumb.Comparison');
      this.isStatokinesigram = false;
      this.isStatokinesigramStandard = false;
      this.isStatokinesigramAsym = false;
      this.isStabilogram = false;
      this.isComparison = true;
    }
    /*else if (graph === 'longitudinal') {
      this.graphTitle = this.translateService.instant('Checkup.Romberg.Breadcrumb.Longitudinal');
      this.isStatokinesigram = false;
      this.isStatokinesigramStandard = false;
      this.isStatokinesigramAsym = false;
      this.isStabilogram = false;
      this.isComparison = false;
      this.isLongitudinal = true;
    }*/
  }

  public zoomGraph() {
    this.zoom = true;
  }

  /**
   * Close the zoom page
   */
  public closeChart() {
    this.zoom = false;
  }

  public changeToStandard() {
    this.standardGraph = true;
    this.asymetrieGraph = false;
  }

  public changeToAsymetrie() {
    this.standardGraph = false;
    this.asymetrieGraph = true;
  }

  /**
   * Calls the service bilan-marche to get data
   */
  private async getGraphComparaison() {
    this.recupComparaison = false;
    this.comparisonDatas = await this.rombergComparaisonService.getAllGraph(
      this.choiceOfComparison,
      this.analyisResult,
    );
    if (this.analyisResultToCompare) {
      this.comparisonDatasToCompare = await this.rombergComparaisonService.getAllGraph(
        this.choiceOfComparison,
        this.analyisResultToCompare,
      );

      for (let i = 0; i < this.comparisonDatasToCompare.length; i++) {
        let item = this.comparisonDatasToCompare[i];
        let datasArray = this.comparisonDatas[i];
        item.graph.forEach((graphItem) => {
          if (graphItem.current) {
            graphItem.current = false;
            graphItem.toCompare = true;
            datasArray.graph.push(graphItem);
          }
        });
        this.comparisonDatas[i] = datasArray;
      }
    }

    this.recupComparaison = true;
  }

  /**
   * Zoom on a graph
   * @param e object pointed
   */
  public comparisonChartClicked(e: ComparisonGraph): void {
    this.zoom = true;
    this.comparisonGraphSelected = e;
  }

  // push the Romberg bilan values into eaach graph for each property
  private transformBilanToGraph() {
    const date = this.testData.date;
    this.graphs[4].graphYF.push({ x: date, y: Number(this.data.VarianceLateraleYF.toFixed(4)) });
    this.graphs[4].graphYO.push({ x: date, y: Number(this.data.VarianceLateraleYO.toFixed(4)) });
    this.graphs[3].graphYF.push({ x: date, y: Number(this.data.SurfaceYF.toFixed(3)) });
    this.graphs[3].graphYO.push({ x: date, y: Number(this.data.SurfaceYO.toFixed(3)) });
    this.graphs[2].graphYF.push({ x: date, y: Number(this.data.SwayDensityYF.toFixed(3)) });
    this.graphs[2].graphYO.push({ x: date, y: Number(this.data.SwayDensityYO.toFixed(3)) });
    this.graphs[1].graphYF.push({ x: date, y: Number(this.data.SpeedMoyYF.toFixed(3)) });
    this.graphs[1].graphYO.push({ x: date, y: Number(this.data.SpeedMoyYO.toFixed(3)) });
    this.graphs[0].graphYF.push({ x: date, y: this.data.ScoreYF });
    this.graphs[0].graphYO.push({ x: date, y: this.data.ScoreYO });
  }

  /**
   * Zoom on a graph
   * @param e object pointed
   */
  public chartClicked(e: LongitudinalGraph): void {
    this.zoom = true;
    this.graphSelected = e;
  }

  public onSelectPage(newPage: string) {
    this.newPage = newPage;
  }

  public goTo() {
    this.router.navigate([this.newPage]);
  }
}
