import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataProviderService } from 'src/app/services/data-provider.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  public locales: any[];
  public selectLanguage: string;

  constructor(private translate: TranslateService, private dataService: DataProviderService) {
    this.locales = this.translate.getLangs().map((code) => {
      let label = '';
      switch (code) {
        case 'fr':
          label = 'Français';
          break;
        case 'en':
          label = 'English';
          break;
      }
      return {
        code,
        label,
      };
    });
    this.selectLanguage = this.translate.currentLang;
  }

  ngOnInit() {}

  public setLanguage(local: any) {
    console.log(local.target.selectedOptions[0].value);
    this.translate.use(local.target.selectedOptions[0].value);
    this.selectLanguage = this.translate.currentLang;
    console.log(this.translate.currentLang);
    console.log(this.selectLanguage);
    this.dataService.setLanguage(local.target.selectedOptions[0].value);
  }
}
