<div class="main-container">
  <div class="msg">
    <p>(<span class="red-text">*</span>){{ 'Patient.Profil.mandatory_field' | translate }}</p>
  </div>

  <div class="stepper-title">
    <p>{{ 'Patient.Doctor' | translate }}</p>
  </div>

  <form [formGroup]="pratitionerFormGroup" class="form-group patient-form" *ngIf="isOk">
    <div class="form-group formy col-sm-6">
      <label for="practitioner">{{ 'Patient.Profil.complete_name' | translate }}<span class="red-text">*</span>:</label>
      <select
        class="form-control"
        id="practitioner"
        formControlName="practitioner"
        [compareWith]="compareFn"
        (change)="onChangePractitioner()"
        required
      >
        <option *ngFor="let practitioner of practitioners" [ngValue]="practitioner">{{ practitioner.name }} </option>
      </select>
    </div>
    <div class="form-group formy col-sm-6">
      <label for="practitionerSpeciality">{{ 'Patient.Profil.speciality' | translate }}</label>
      <input
        class="form-control"
        id="practitionerSpeciality"
        placeholder=""
        value="{{ speciality }}"
        formControlName="practitionerSpeciality"
        readonly
      />
    </div>
  </form>

  <div class="flex-row div-btn">
    <button class="btn btn-primary" (click)="validate()">{{ 'button.validation' | translate }}</button>
  </div>
</div>
