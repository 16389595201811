export class State {
  private playState: boolean = true;
  private stopState: boolean = false;
  private backwardState: boolean = false;

  get stop(): boolean {
    return this.stopState;
  }
  get backward(): boolean {
    return this.backwardState;
  }
  get play(): boolean {
    return this.playState;
  }

  public setPlay() {
    this.stopState = true;
    this.playState = this.backwardState = false;
  }
  public setStop() {
    this.stopState = false;
    this.playState = this.backwardState = true;
  }
  public setBackward() {
    this.playState = true;
    this.stopState = this.backwardState = false;
  }
}
