import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PatientDataService } from './api/patient-data.service';
import { FootWearDataService } from './api/footwears-data.service';
import { HttpClientModule } from '@angular/common/http';
import { ServiceDal } from './service-dal/service-dal';
@NgModule({
  imports: [HttpClientModule, TranslateModule.forChild()],
  declarations: [],
  providers: [ServiceDal, PatientDataService, FootWearDataService],
  exports: [],
})
export class AbilycareDataAccessModule {}
