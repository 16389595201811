import { Injectable } from '@angular/core';

@Injectable()
export class VersionService {

    private currentVersion: string = null;

    public getAppVersion(): string {
        if (this.currentVersion === null) {

            const { version: appVersion } = require('../../../../../../package.json');
            this.currentVersion = appVersion;
        }
        return this.currentVersion;
    }
}
