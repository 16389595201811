<div class="longitudinal-container">
  <div *ngIf="!zoom" class="setOfGraph">
    <div *ngFor="let graph of graphs; let i = index" class="graphic2" (click)="chartClicked(graph)">
      <app-graph-timeline [title]="graph.title" [data]="graph"></app-graph-timeline>
    </div>
  </div>

  <div class="zoom" *ngIf="zoom" (click)="close()">
    <app-graph-timeline [title]="graphSelected.title" [data]="graphSelected"></app-graph-timeline>
  </div>
</div>
