export class AnalysisParameters {
  public taille: number;
  public date: Date;
  public sexe: string;
  public poids: number;
  public lateralisation: string;
  public chaussure: string;
  public typebilan: number;
  public duree: number;
}
