import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export interface ILateralityModel {
  value: string;
  text: string;
}

export const lateralityItems: ILateralityModel[] = [
  {
    value: 'D',
    text: _('Handedness.Right'),
  },
  {
    value: 'G',
    text: _('Handedness.Left'),
  },
  {
    value: 'B',
    text: _('Handedness.Ambidextrous'),
  },
];
