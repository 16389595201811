<!-- <div *ngIf="histo" class="row" style="padding-top: 30px !important;padding-bottom: 30px !important">
    <div style="  text-align: center  !important;">
        <div class="col-md-6">
            <label style="font-weight: bold !important; ">{{ 'Test.Date' | translate }} : {{ currentDate }} </label>
        </div>

        <div class="col-md-6">
            <app-compare-date-select [currentPatientId]="currentPatientId" [bilanType]="bilanType">
            </app-compare-date-select>
        </div>
    </div>
</div> -->

<!-- <div *ngIf="!(histoVersusConsultationService.comparaisonEnabled && (comparedData$ | async)); else comparaisonEnabled" -->
<div class="semio-container">
  <div class="optionsBar row">
    <button
      *ngFor="let title of typesOfComparisons; let i = index"
      (click)="onSelect(i)"
      type="button"
      class="btn btn-primary choice-button"
      [class.selected]="i === choice"
    >
      {{ title.text }} {{ 'ans' | translate }}
    </button>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <div *ngIf="imageDisplayed" class="graphique">
        <img [attr.src]="imageDisplayed | secure | async" />
        <span>
          <img
            src="assets/images/icons/rounded-rectangle-green.svg"
            style="width:25px; height:30px;"
            *ngIf="resultsWalkToCompare"
          />
          {{ testData.date | localdate: 'datetime' }}</span
        >
      </div>
    </div>
    <div class="col-lg-6">
      <div *ngIf="imageToCompareDisplayed" class="graphique">
        <img [attr.src]="imageToCompareDisplayed | secure | async" />
        <span>
          <img src="assets/images/icons/rounded-rectangle.svg" style="width:25px; height:30px;" />
          {{ walkToCompare.date | localdate: 'datetime' }}</span
        >
      </div>
    </div>
    <div class="col-lg-6" *ngIf="!imageToCompareDisplayed">
      <div class="texte">
        {{ 'Semiogram.Description.Part1' | translate }}:
        <ul>
          <li>{{ 'Semiogram.Description.li1' | translate }}</li>
          <li>{{ 'Semiogram.Description.li2' | translate }}</li>
          <li>{{ 'Semiogram.Description.li3' | translate }}</li>
          <li>{{ 'Semiogram.Description.li4' | translate }}</li>
          <li>{{ 'Semiogram.Description.li5' | translate }}</li>
          <li>{{ 'Semiogram.Description.li6' | translate }}</li>
          <li>{{ 'Semiogram.Description.li7' | translate }}</li>
        </ul>
        {{ 'Semiogram.Description.Part2' | translate }}
        <div class="container-img">
          <img class="legend" src="assets/images/images/legende.svg" />
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <ng-template #comparaisonEnabled>
    <div style="width: 50% !important;" class=" col-lg-6 semio-container">
        <div class="optionsBar row">
            <button *ngFor="let title of typesOfComparisons; let i = index" (click)="onSelect(i)" type="button"
                class="btn btn-primary choice-button" [class.selected]="i === choice">
                {{ title.text }} {{ 'ans' | translate }}
            </button>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <div *ngIf="imageDisplayed" class="graphique">
                    <img [attr.src]="imageDisplayed | secure | async" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="texte">
                    {{ 'Semiogram.Description.Part1' | translate }}:
                    <ul>
                        <li>{{ 'Semiogram.Description.li1' | translate }}</li>
                        <li>{{ 'Semiogram.Description.li2' | translate }}</li>
                        <li>{{ 'Semiogram.Description.li3' | translate }}</li>
                        <li>{{ 'Semiogram.Description.li4' | translate }}</li>
                        <li>{{ 'Semiogram.Description.li5' | translate }}</li>
                        <li>{{ 'Semiogram.Description.li6' | translate }}</li>
                        <li>{{ 'Semiogram.Description.li7' | translate }}</li>
                    </ul>
                    {{ 'Semiogram.Description.Part2' | translate }}
                    <div class="container-img">
                        <img class="legend" src="assets/images/legende.svg" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div style="width: 50% !important;" class=" col-lg-6 semio-container">
        <div class="optionsBar row">
            <button *ngFor="let title of typesOfComparisons; let i = index" (click)="onSelect(i)" type="button"
                class="btn btn-primary choice-button" [class.selected]="i === choice">
                {{ title.text }}
                {{ 'ans' | translate }}
            </button>
        </div>

        <div class="row" *ngIf="comparedData$ | async as comparedData">
            <div class="col-lg-6">
                <div *ngIf="imageDisplayed" class="graphique">
                    <img [attr.src]="comparedData | secure | async" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="texte">
                    {{ 'Semiogram.Description.Part1' | translate }}:
                    <ul>
                        <li>{{ 'Semiogram.Description.li1' | translate }}</li>
                        <li>{{ 'Semiogram.Description.li2' | translate }}</li>
                        <li>{{ 'Semiogram.Description.li3' | translate }}</li>
                        <li>{{ 'Semiogram.Description.li4' | translate }}</li>
                        <li>{{ 'Semiogram.Description.li5' | translate }}</li>
                        <li>{{ 'Semiogram.Description.li6' | translate }}</li>
                        <li>{{ 'Semiogram.Description.li7' | translate }}</li>
                    </ul>
                    {{ 'Semiogram.Description.Part2' | translate }}
                    <div class="container-img">
                        <img class="legend" src="assets/images/legende.svg" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template> -->
