<div class="weight-tuto-container">
  <div class="weight-installation" style=" width: 100%;" *ngIf="firstStep">
    <div class="row align-items-center justify-content-between">
      <div class="col-lg-1"></div>
      <div class="col-lg-9" style="text-align : center;">
        <img src="assets/images/images/weight_installation.svg" width="100%;" alt="" />
      </div>
      <div class="col-lg-2 d-flex justify-content-end">
        <img
          (click)="goToNextStep()"
          src="assets/images/icons/right-chevron.svg"
          alt=""
          style="cursor: pointer; width: 4rem;"
        />
      </div>
    </div>
  </div>
  <div class="weight-protocol" style=" width: 100%;" *ngIf="!firstStep">
    <div class="row align-items-center justify-content-between">
      <div class="col-lg-1">
        <img
          (click)="goToPreviousStep()"
          src="assets/images/icons/left-chevron.svg"
          alt=""
          style="cursor: pointer; width: 4rem;"
        />
      </div>
      <div class="col-lg-4 offset-lg-1">
        <p class="explanations">{{ 'app.weight.protocol.text' | translate }}</p>
      </div>
      <div class="col-lg-4 offset-lg-1">
        <img src="assets/images/images/weight_protocole.svg" height="260px;" alt="" />
      </div>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-lg-12">
      <div class="page-footer buttons-component">
        <button (click)="goToMesure()" class="btn btn-danger">
          Fermer
        </button>
      </div>
    </div>
  </div> -->
</div>
