import {
  CourseControllerService,
  DoubleTaskControllerService,
  WalkingControllerService,
  WalkingWithRelations,
} from '@abilycare/dal-client';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-courses-breadcrumb-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
/**
 * This component representes the informative div of the connexion with wiiBoard
 */
export class CoursesBreadcrumbNavbarComponent implements OnInit {
  @Input() testName: string;
  @Input() course: any;
  @Input() fromSynthesis: string;
  @Input() isWalking: boolean;

  constructor(
    private courseDataService: CourseControllerService,
    private walkingService: WalkingControllerService,
    private doubleTaskService: DoubleTaskControllerService,
    private translateService: TranslateService,
  ) {}

  public walkingTest: WalkingWithRelations;
  public doubleTask: string;

  ngOnInit() {
    this.isWalking = this.testName == this.translateService.instant('navbar.walkTest');
    console.log('get walking test for this course ', this.testName);
    if (this.course && this.isWalking) {
      this.walkingService.walkingControllerFindWalkingByCourseId(this.course.id).subscribe((walking) => {
        this.walkingTest = walking;
        if (this.walkingTest.doubleTask) {
          this.doubleTaskService
            .doubleTaskControllerFindById(this.walkingTest.doubleTask, this.translateService.currentLang)
            .subscribe((res) => {
              this.doubleTask = res.label;
            });
        }
      });
    }
    //call update score if score note existed and status is finished == 2
    if (this.course && this.course.status === 2 && !this.course.score) {
      this.courseDataService
        .courseControllerUpdateScoreAndStatus(this.course.patientId, this.course.id)
        .subscribe((res) => {
          console.log('update score and status');
        });
    }
  }
}
