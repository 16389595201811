<div class="connexion-global" *ngIf="deviceExist || config">
  <ng-container *ngIf="gripState && gripState.gripConnected">
    <div class="connected">
      <div style="text-align: center; margin-top: 25px; margin-bottom: 25px;">
        <img class="d-inline-block align-top" width="100" src="assets/images/images/grip_kinvent.png" alt="" />
      </div>
      <div style="text-align: center;">
        <span>{{ device.name }}</span>
      </div>
      <div style="text-align: center;">
        <span>{{ 'app.weight.boardconnected' | translate }}</span>
        <div *ngIf="gripState" style="margin-left: 10px; display:inline;">
          <img class="d-inline-block align-top" width="20" height="20" src="assets/images/icons/baterie.svg" alt="" />
          {{ gripState.batteryLevel | number: '1.0-0' }}%
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Test with false because it start with null -->
  <div class="unconnected" *ngIf="!gripState || !gripState.gripConnected">
    <div style="text-align: center; margin-top: 25px; margin-bottom: 25px;">
      <img class="d-inline-block align-top" width="100" src="assets/images/images/grip_kinvent.png" alt="" />
    </div>
    <div style="text-align: center; margin-top: 25px; margin-bottom: 25px;">
      {{ 'app.weight.boardnotconnected' | translate }}
    </div>
    <button class="btn btn-primary text-align: center" type="button" disabled *ngIf="deviceExist && !config">
      <span class="spinner-border spinner-border-sm text-align: center" role="status" aria-hidden="true"></span>
      Connecting...
    </button>
  </div>
  <div *ngIf="config">
    <div>
      <button class="btn btn-primary" (click)="connectGripDevice()">
        {{ 'app.grip.connect' | translate }}
      </button>
    </div>
    <div *ngIf="config">
      <button class="btn btn-primary" (click)="onForgetBluetoothDeviceButtonClick()">
        {{ 'forget Grip' }}
      </button>
    </div>
  </div>
</div>
<div class="connexion-global" *ngIf="!deviceExist && !config">
  <ng-container>
    <div class="connected">
      <div style="text-align: center; margin-top: 25px; margin-bottom: 25px;">
        <img class="d-inline-block align-top" width="100" src="assets/images/images/dynamometer.jpg" alt="" />
      </div>
    </div>
  </ng-container>
</div>
