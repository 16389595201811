import {
  Course,
  CourseControllerService,
  PatientDetail,
  QuestionnaireControllerService,
  QuestionnaireDetailWithRelations,
  QuestionnaireReplyControllerService,
  QuestionnaireSummary,
} from '@abilycare/dal-client';
import { PatientBrief } from '@abilycare/dal-client';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin, Subscription } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { Roles } from 'src/app/modules/core/roles.enum';
import { AuthService } from 'src/app/modules/core/services/authentification/auth.service';
import { RolesCheckService } from 'src/app/modules/core/services/authentification/roles-check.service';
import { PatientDataService } from 'src/app/modules/abilycare-data-access/api/patient-data.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-patient-detail-header',
  templateUrl: './patient-detail-header.component.html',
  styleUrls: ['./patient-detail-header.component.scss'],
})
export class PatientDetailHeaderComponent implements OnInit, OnDestroy {
  @Output() openDelegation: EventEmitter<void> = new EventEmitter<void>();
  @Output() callDeletePatient: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('QuestionnaireMandataryModal', { static: true }) QuestionnaireMandataryModal: ElementRef;
  @ViewChild('btnCloseQuestionnaireMandatary', { static: true }) btnCloseQuestionnaireMandatary: ElementRef;

  @Input() hasCoursInProgress;
  @Input() hasQuestionnaryMandatary;
  @Input() idQuestionnaryMandatary;
  @Input() set patient(patient: PatientDetail) {
    this.currentPatient = patient;
    this.getAge(patient.birthDate);
  }

  public canDelete: boolean = false;
  private unsubscribe = new Subject<void>();
  public age: number = 0;
  public currentPatient: PatientDetail;
  private subscriptions: Subscription[] = [];
  public canViewHistory: boolean = false;
  public allcourses: any;
  public questionaireMandatryIds: QuestionnaireSummary[];
  public finishLoadQuestionnary: boolean = false;

  constructor(
    private router: Router,
    private questionnaireService: QuestionnaireControllerService,
    public rolesCheck: RolesCheckService,
    private authService: AuthService,
    private questionnaireRepliesService: QuestionnaireReplyControllerService,
    private translateService: TranslateService,
  ) {}

  comparerDateAvecMois(dateComparaison: Date, moisAjoutes: number): boolean {
    // Obtenez la date du jour
    const dateDuJour = new Date();

    // Ajoutez le nombre de mois spécifié à la date de comparaison
    const dateAvecMois = new Date(dateComparaison);
    dateAvecMois.setMonth(dateAvecMois.getMonth() + moisAjoutes);

    // Comparez les deux dates
    if (dateDuJour < dateAvecMois) {
      return false;
    } else {
      return true;
    }
  }

  ngOnInit() {
    this.rolesCheck.hasManagePatient.pipe(takeUntil(this.unsubscribe)).subscribe((canDelete) => {
      this.canDelete = canDelete;
    });

    //load questionnary mondatary
    const lang = this.translateService.currentLang;
    //find questionaire mandatary
    this.questionnaireService.questionnaireControllerFind(lang).subscribe((questionnaireList) => {
      //console.log("questionnaireList is ", questionnaireList);
      questionnaireList.forEach((quest) => {
        if (quest.mandatary == true) {
          //console.log("this.currentPatient.id ", this.currentPatient.id);
          this.questionnaireRepliesService
            .questionnaireReplyControllerFindByPatientId(this.currentPatient.id)
            .subscribe((patientReplyListQuestionnary: any) => {
              const result = patientReplyListQuestionnary.filter((reply) => reply.questionnaireId === quest.id);
              //console.log('result is ', result);
              if (result && result.length > 0) {
                const idLastQuestionnary = result[result.length - 1].questionnaireId;
                const dateQuest = result[result.length - 1].date;
                const validityMonth = quest.validityMonth;
                if (this.comparerDateAvecMois(dateQuest, validityMonth)) this.questionaireMandatryIds.push(quest);
              } else {
                this.questionaireMandatryIds.push(quest);
              }
            });
        }
        this.finishLoadQuestionnary = true;
      });
    });
    //console.log('this.questionaireMandatryIds ', this.questionaireMandatryIds);
  }

  public checkCanRunTest() {
    let canRunTest: boolean = false;
    if (this.currentPatient.currentPractionerRoles && this.currentPatient.currentPractionerRoles.length > 0) {
      this.currentPatient.currentPractionerRoles.forEach((role) => {
        if (role === 'Owner' || role === 'TestDelegation') {
          canRunTest = true;
        }
      });
    }
    return canRunTest && this.currentPatient.consent;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
  }

  public checkCanDelegate(): boolean {
    let canDelegate: boolean = false;
    if (
      this.authService.hasRoleInstant(Roles.delegationChekup) ||
      this.authService.hasRoleInstant(Roles.delegationResult)
    ) {
      if (
        this.authService.hasRoleInstant(Roles.managePatient) ||
        this.authService.hasRoleInstant(Roles.listAllPatient)
      ) {
        canDelegate = true;
      }
    }
    return canDelegate;
  }

  public clickOnDeletePatient(): void {
    this.callDeletePatient.emit();
  }

  public getAge(birthdate): void {
    const years: number = moment().diff(birthdate, 'years');

    if (years >= 0) this.age = years;
  }

  public clickOnDelegation(): void {
    this.openDelegation.emit();
  }

  public goToQuestionnayMandatary(): void {
    this.goTo('/courses/fried/questionnary/' + this.idQuestionnaryMandatary);
  }

  public CloseQuestionnaryMandatary() {
    this.btnCloseQuestionnaireMandatary.nativeElement.click();
    this.hasQuestionnaryMandatary = false;
  }

  public OpenQuestionnaryMandatary() {
    this.QuestionnaireMandataryModal.nativeElement.openModal();
  }

  public launchCourse(): void {
    if (!this.hasQuestionnaryMandatary) {
      this.router.navigate(['CourseList/', this.currentPatient.id]);
    }
  }

  public launchTest(): void {
    if (!this.hasQuestionnaryMandatary) {
      this.goTo('/courses/fried');
    }
  }

  public goTo(page) {
    this.router.navigate([page]);
  }
}
