import { PatientDetail, UpdatePatient } from '@abilycare/dal-client';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { forkJoin, Subscription } from 'rxjs';
import { PatientDataService } from 'src/app/modules/abilycare-data-access/api/patient-data.service';
import { PractitionerDataService } from 'src/app/modules/abilycare-data-access/api/practitioner-data.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { genderItems } from 'src/app/shared/models/gender-item.model';
import { lateralityItems } from 'src/app/shared/models/laterality-item.model';

@Component({
  selector: 'app-patient-creation-profil-form',
  templateUrl: './patient-creation-profil-form.component.html',
  styleUrls: ['./patient-creation-profil-form.component.scss'],
})
export class PatientCreationProfilFormComponent implements OnInit, OnDestroy {
  @Input() patient: PatientDetail;
  @Output() closeModal = new EventEmitter();

  public profilFormGroup: FormGroup;
  public age: string = '-';
  public minimumAge: number = 0;
  public isAgeValid: boolean = true;
  public firstFormValid: boolean = true;
  public maxDate: Date = new Date();
  public genders = genderItems;
  public lateralities = lateralityItems;
  private subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private practitionerDataService: PractitionerDataService,
    private patientDataService: PatientDataService,
    private dataService: DataProviderService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.profilFormGroup = this.formBuilder.group({
      lastName: [this.patient.lastName, Validators.required],
      firstName: [this.patient.firstName, Validators.required],
      folderNumber: [this.patient.folderNumber],
      gender: [this.patient.gender, Validators.required],
      birthDate: [this.patient.birthDate, Validators.required],
      laterality: [this.patient.lateralization, Validators.required],
      consent: [this.patient.consent ? 'yes' : 'no', Validators.required],
    });
    this.onBirthdateChange(this.patient.birthDate);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
  }

  public getPractitionerMe(): void {
    this.subscriptions.push(
      this.practitionerDataService.getPractitionerMe().subscribe(
        (practitionerMe) => {
          this.minimumAge = practitionerMe.establishment.config.minimumAge;
        },
        (error) => {
          console.log('Error getPractitionerMe()', error);
        },
      ),
    );
  }

  public onBirthdateChange(birthdate): void {
    if (birthdate) {
      const years = moment().diff(birthdate, 'years');

      this.age = years.toString();
      if (years < 0 || years < this.minimumAge) {
        this.isAgeValid = false;
      } else {
        this.isAgeValid = true;
      }
    }
  }

  public validate(): void {
    if (this.profilFormGroup.valid) {
      this.firstFormValid = true;
      const form = this.profilFormGroup.value;
      const updatePatient: UpdatePatient = {
        id: this.patient.id,
        lastName: form.lastName,
        firstName: form.firstName,
        birthDate: new Date(form.birthDate),
        consent: form.consent === 'yes' ? true : false,
        gender: form.gender,
        lateralization: form.laterality,
        folderNumber: form.folderNumber ? form.folderNumber : '',
      };

      this.subscriptions.push(
        forkJoin({
          data: this.patientDataService.updatePatient(updatePatient),
          patient: this.patientDataService.getPatientById(this.patient.id),
        }).subscribe(
          ({ data, patient }) => {
            this.snackBarService.show(this.translateService.instant('app.snack.update.patient.success'), 'success');
            this.dataService.setPatient(patient);
            this.closeModal.emit();
          },
          (err) => {
            console.error('PatientCreationProfilFormComponent : Err-Patient-04 ', err);
            const errMsg = this.translateService.instant('Err-Patient-04');
            this.snackBarService.show(errMsg, 'danger');
          },
        ),
      );

      // this.patientDataService.updatePatient(updatePatient).subscribe((data) => {
      //   this.patientDataService.getPatientById(this.patient.id).subscribe((patient) => {
      //     this.dataService.setPatient(patient);
      //     this.closeModal.emit();
      //   });
      // });
    } else {
      this.firstFormValid = false;
    }
  }

  public cancel(): void {
    this.closeModal.emit();
  }
}
