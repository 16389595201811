import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatSliderChange } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { AssessmentRomberg, RombergAssessmentType } from 'src/app/modules/romberg/models/assessment.model';

@Component({
  selector: 'app-romberg-appreciation',
  templateUrl: './romberg-appreciation.component.html',
  styleUrls: ['./romberg-appreciation.component.scss'],
})
export class RombergAppreciationComponent implements OnInit {
  @Output() public validate: EventEmitter<any> = new EventEmitter<any>();
  @Output() replayTest: EventEmitter<void> = new EventEmitter<void>();
  @Output() modifyAppreciation: EventEmitter<void> = new EventEmitter<void>();

  public minOEBalanceQuality: number = 1;
  public maxOEBalanceQuality: number = 5;
  public valueOEBalanceQuality: number = 3;
  public isOEBalanceQualityNotEvaluated = true;

  public minCEBalanceQuality: number = 1;
  public maxCEBalanceQuality: number = 5;
  public valueCEBalanceQuality: number = 3;
  public isCEBalanceQualityNotEvaluated = true;

  public minQualityWalk: number = 1;
  public maxQualityWalk: number = 5;
  public valueQualityWalk: number = 3;
  public isQualityWalkNotEvaluated = true;

  public isFallen: number = 0;
  public isFallenEvaluated = false;

  thumbLabel = true;

  public step: number = 1;
  constructor() {}

  ngOnInit() {}

  formatLabel(value: number) {
    return value;
  }

  /**
   * Method to save value if the opened eyes balance quality slider is moved.
   */
  public onValueOEBQChange(sliderChange: MatSliderChange): void {
    this.isOEBalanceQualityNotEvaluated = false;
    this.valueOEBalanceQuality = sliderChange.value;
  }

  /**
   * Method to save value if the closed eyes balance quality slider is moved.
   */
  public onValueCEBQChange(sliderChange: MatSliderChange): void {
    this.isCEBalanceQualityNotEvaluated = false;
    this.valueCEBalanceQuality = sliderChange.value;
  }

  /**
   * Method to save value if the quality walking slider is moved.
   */
  public onValueQWChange(sliderChange: MatSliderChange): void {
    this.isQualityWalkNotEvaluated = false;
    this.valueQualityWalk = sliderChange.value;
  }

  /**
   * Method to update isFallen value according to the user choice.
   */
  public updateIsFallenValue(): void {
    if (this.isFallenEvaluated === true) {
      this.isFallen = 1;
    } else {
      this.isFallen = 0;
    }
  }

  /**
   * Method to save all visual appreciations values when the user click on the validate
   * button of the visual appreciations pop-up.
   */
  public viewResults(): void {
    this.updateIsFallenValue();
    const appreciations: Array<AssessmentRomberg> = [
      {
        type: RombergAssessmentType.QualityEquiYO,
        title: 'Balance quality opened eyes',
        value: this.valueOEBalanceQuality,
        nonEvaluated: this.isOEBalanceQualityNotEvaluated,
      },
      {
        type: RombergAssessmentType.QualityEquiYF,
        title: 'Balance quality closed eyes',
        value: this.valueCEBalanceQuality,
        nonEvaluated: this.isCEBalanceQualityNotEvaluated,
      },
      {
        type: RombergAssessmentType.QualityWalk,
        title: 'Walk quality',
        value: this.valueQualityWalk,
        nonEvaluated: this.isQualityWalkNotEvaluated,
      },
      {
        type: RombergAssessmentType.Fall,
        title: 'Is fallen',
        value: this.isFallen,
        nonEvaluated: this.isFallenEvaluated,
      },
    ];

    this.validate.emit(appreciations);
  }

  public replay(): void {
    this.replayTest.emit();
  }

  public modify(): void {
    this.modifyAppreciation.emit();
  }
}
