<!-- <div class="flex-row"> -->
<mat-horizontal-stepper class="patient-creation" [linear]="linear" #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <div class="msg">
      <p>(<span class="red-text">*</span>){{ 'Patient.Profil.mandatory_field' | translate }}</p>
    </div>

    <div class="stepper-title">
      <p>{{ 'Patient.Profil.profil_and_health' | translate }}</p>
    </div>

    <form
      [formGroup]="firstFormGroup"
      class="needs-validation patient-form"
      [ngClass]="{ 'needs-validation': true, 'patient-form': true, 'was-validated': !firstFormValid }"
    >
      <div class="form-group formy col-sm-8">
        <label for="lastName">{{ 'Patient.Lastname' | translate }}<span class="red-text">*</span>:</label>
        <input class="form-control" id="lastName" formControlName="lastName" aria-describedby="lastName" required />
      </div>
      <div class="form-group formy col-sm-8">
        <label for="firstName">{{ 'Patient.Firstname' | translate }}<span class="red-text">*</span>:</label>
        <input class="form-control" id="firstName" placeholder="" formControlName="firstName" required />
      </div>
      <div class="form-group formy col-sm-8">
        <label for="folderNumber">{{ 'Patient.FolderNumber' | translate }}:</label>
        <input class="form-control" id="folderNumber" placeholder="" formControlName="folderNumber" />
      </div>
      <div class="form-group formy col-sm-6">
        <label for="gender">{{ 'Sexe' | translate }}<span class="red-text">*</span>:</label>
        <select class="form-control " id="gender" formControlName="gender" required>
          <option *ngFor="let gender of genders" [value]="gender.value">{{ gender.text | translate }}</option>
        </select>
      </div>
      <div class="flex-column form-group formy col-sm-8">
        <label for="birthDate">{{ 'Patient.BirthDate' | translate }}<span class="red-text">*</span>:</label>
        <div class="input-group">
          <input
            class="form-control"
            #birthDate
            (ngModelChange)="onBirthdateChange($event)"
            id="birthDate"
            formControlName="birthDate"
            [matDatepicker]="picker"
            placeholder="{{ 'formatDate' | translate }}"
            [max]="maxDate"
            required
          />
          <span class="input-group-prepend">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker></mat-datepicker>
          </span>
          <p class="detail">
            {{ 'Patient.YearsOld' | translate }} : {{ age }} <ng-container>{{ 'ans' | translate }}</ng-container>
          </p>

          <div class="error-msg red-text">
            <p *ngIf="!isAgeValid">
              {{ 'Patient.Profil.minimum.years' | translate }} {{ minimumAge }} {{ 'ans' | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="form-group formy col-sm-6">
        <label for="laterality">{{ 'Patient.Profil.laterality' | translate }}<span class="red-text">*</span>:</label>
        <select class="form-control" id="laterality" formControlName="laterality" required>
          <option *ngFor="let laterality of lateralities" [value]="laterality.value">{{
            laterality.text | translate
          }}</option>
        </select>
      </div>
      <div class="form-group formy col-sm-6">
        <label for="height">{{ 'Patient.Profil.height_cm' | translate }}<span class="red-text">*</span>:</label>
        <div class="input-group">
          <input
            type="number"
            class="form-control"
            (change)="onCalculImc()"
            id="height"
            placeholder=""
            formControlName="height"
            min="50"
            max="270"
            required
          />
          <p class="detail">IMC : {{ imc }}</p>
        </div>
        <div class="error-msg red-text">
          <p *ngIf="!isHeightValid">
            {{ 'Patient.Profil.borne.height' | translate }}
          </p>
        </div>
      </div>
      <div class="form-group formy col-sm-6">
        <label for="weight">{{ 'Patient.Profil.weight' | translate }} <span class="red-text">*</span>:</label>
        <div class="input-group">
          <input
            type="number"
            class="form-control"
            (change)="onCalculImc()"
            id="weight"
            placeholder=""
            formControlName="weight"
            min="20"
            max="200"
            required
          />
        </div>
        <div class="error-msg red-text">
          <p *ngIf="!isWeightValid">
            {{ 'Patient.Profil.borne.weight' | translate }}
          </p>
        </div>
      </div>

      <div class="form-group formy flex-column col-sm-6">
        <label>{{ 'Patient.Profil.consent' | translate }}<span class="red-text">*</span>:</label>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            formControlName="consent"
            name="consent"
            id="radioYes"
            value="yes"
            required
          />
          <label class="form-check-label" for="radioYes">
            {{ 'Oui' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            formControlName="consent"
            name="consent"
            id="radioNo"
            value="no"
            required
          />
          <label class="form-check-label" for="radioNo">
            {{ 'Non' | translate }}
          </label>
        </div>
      </div>
    </form>
    <div class="flex-row div-error-button">
      <div class="error-msg red-text">
        <p *ngIf="!firstFormValid">{{ 'Patient.Profil.fill_all_Field' | translate }}</p>
      </div>
      <div class="flex-row div-btn">
        <button class="btn btn-primary" (click)="onNextClick()" matStepperNext>
          {{ 'Patient.Profil.next_step' | translate }}
        </button>
      </div>
    </div>
  </mat-step>

  <mat-step [stepControl]="secondFormGroup">
    <div class="stepper-title">
      <p>{{ 'Patient.Profil.contact.information' | translate }}</p>
    </div>

    <form [formGroup]="secondFormGroup" class="patient-form">
      <div class="form-group formy col-sm-5">
        <label for="phoneNumber">{{ 'Patient.Profil.fix_number' | translate }}:</label>
        <input class="form-control" id="phoneNumber" placeholder="" formControlName="phoneNumber" />
      </div>
      <div class="form-group formy col-sm-5">
        <label for="mobilePhoneNumber">{{ 'Patient.Profil.mobile_number' | translate }}:</label>
        <input class="form-control" id="mobilePhoneNumber" placeholder="" formControlName="mobilePhoneNumber" />
      </div>
      <div class="form-group formy col-sm-8">
        <label for="email">{{ 'Patient.Email' | translate }}:</label>
        <input class="form-control" id="email" placeholder="" formControlName="email" />
      </div>
      <div class="form-group formy col-sm-8">
        <label for="address">{{ 'Patient.Profil.postal_address' | translate }}:</label>
        <input class="form-control" id="address" placeholder="" formControlName="address" />
      </div>
      <div class="form-group formy col-sm-7">
        <label for="city">{{ 'Patient.Profil.city' | translate }}:</label>
        <input class="form-control" id="city" placeholder="" formControlName="city" />
      </div>
      <div class="form-group formy col-sm-5">
        <label for="postalCode">{{ 'Patient.Profil.postal_code' | translate }}:</label>
        <input class="form-control" id="postalCode" placeholder="" formControlName="postalCode" />
      </div>
    </form>
    <div class="flex-row div-btn">
      <button class="btn btn-tertiary" mat-button matStepperPrevious>
        {{ 'Patient.Profil.previous_step' | translate }}
      </button>
      <button class="btn btn-primary" mat-button matStepperNext>
        {{ 'Patient.Profil.next_step' | translate }}
      </button>
    </div>
  </mat-step>

  <mat-step [stepControl]="thirdFormGroup">
    <div class="msg">
      <p>(<span class="red-text">*</span>){{ 'Patient.Profil.mandatory_field' | translate }}</p>
    </div>

    <div class="stepper-title">
      <p>{{ 'Patient.Doctor' | translate }}</p>
    </div>

    <form [formGroup]="thirdFormGroup" class="form-group patient-form" *ngIf="isOk">
      <div class="form-group formy col-sm-6">
        <label for="practitioner"
          >{{ 'Patient.Profil.complete_name' | translate }}<span class="red-text">*</span>:</label
        >
        <select
          class="form-control"
          id="practitioner"
          formControlName="practitioner"
          [compareWith]="compareFn"
          (change)="onChangePractitioner()"
          required
        >
          <option *ngFor="let practitioner of practitioners" [ngValue]="practitioner">{{ practitioner.name }}</option>
        </select>
      </div>
      <div class="form-group formy col-sm-6">
        <label for="practitionerSpeciality">{{ 'Patient.Profil.speciality' | translate }}</label>
        <input
          class="form-control"
          id="practitionerSpeciality"
          placeholder=""
          value="{{ speciality }}"
          formControlName="practitionerSpeciality"
          readonly
        />
      </div>
    </form>
    <div class="flex-row div-btn">
      <button class="btn btn-tertiary" mat-button matStepperPrevious>
        {{ 'Patient.Profil.previous_step' | translate }}
      </button>
      <button
        class="btn btn-primary"
        mat-button
        (click)="createNewPatient()"
        *ngIf="hasQuestionnaryMandatary"
        matStepperNext
      >
        {{ 'Créer' | translate }}
      </button>
      <button class="btn btn-primary" mat-button (click)="createNewPatient()" *ngIf="!hasQuestionnaryMandatary">
        {{ 'Créer' | translate }}
      </button>
    </div>
  </mat-step>

  <mat-step [stepControl]="fourthFormGroup" *ngIf="hasQuestionnaryMandatary">
    <form [formGroup]="fourthFormGroup" class="form-group patient-form">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="QuestionnaireMandataryTitle">
              {{ 'app.questionnary.mandatary' | translate }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="confirm-text">
              {{ 'app.questionnary.mandatary.message' | translate }}
              <br />
              <ng-container *ngFor="let quest of questionaireMandatryIds">
                <br />
                <span class="title"> -{{ quest.name }}</span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="flex-row div-btn">
      <button class="btn btn-tertiary" mat-button (click)="closeFrame()">
        {{ 'app.questionnary.cancel' | translate }}
      </button>
      <button class="btn btn-primary" mat-button (click)="goToQuestionnayMandatary()">
        {{ 'app.questionnary.create' | translate }}
      </button>
    </div>
  </mat-step>
</mat-horizontal-stepper>

<!-- </div> -->
