<div class="col-md-12">
  <div class="row">
    <div class="col-md-11  full-size-content-component" style="font-weight: normal;">
      <img class="d-inline-block align-top" width="20" height="20" src="assets/images/icons/info-circle.svg" alt="" />
      {{ 'app.walk.protocol.followStep' | translate }}
    </div>
  </div>

  <div class="row">
    <div class="col-md-7 simple-content-component" style="height: 50%;">
      <div>{{ 'Résultats' | translate }}</div>
      <div class="results-container flex-column">
        <div class="assessment-item-container">
          <div class="col-md-6" *ngFor="let item of items">
            <app-walk-assessment-item [item]="item"> </app-walk-assessment-item>
          </div>
        </div>

        <div class="buttons-container d-flex justify-content-between">
          <button class="btn btn-tertiary" data-target="#restartWalkModal" data-toggle="modal">
            {{ 'app.test.restart' | translate }}
          </button>
          <button class="btn btn-tertiary" (click)="modify()">{{ 'app.walk.changeAppreciation' | translate }}</button>
        </div>
      </div>
    </div>

    <div class="flex-column col-md-4">
      <div class="simple-content-component flex-column justify-content-between">
        <div>{{ 'app.walk.sensors.states' | translate }}</div>
        <div class="img-container">
          <img src="assets/images/images/sensors.png" alt="" />
        </div>
        <div class="sensor-container">
          <app-forceplatform-sensor-connexion> </app-forceplatform-sensor-connexion>
        </div>
      </div>

      <div class="graphics simple-content-component flex-column">
        <img
          class="pointer"
          src="assets/images/images/walk_graphic.svg"
          data-target="#walkGraphicsModal"
          data-toggle="modal"
          alt=""
        />
        <button class="btn btn-tertiary" data-target="#walkGraphicsModal" data-toggle="modal">
          {{ 'app.walk.access.graphic' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="restartWalkModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="restartWalkModalModalTitle"
  aria-hidden="true"
  #basicModal
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="restartWalkModalModalTitle">
          {{ 'app.assessment.restart.warning' | translate }}
        </h5>
        <button #btnClose type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <label>
            {{ 'app.assessment.restart.message' | translate }}
          </label>
        </div>
        <div class="col-lg-12">
          <button
            #btnClose
            (click)="replay()"
            type="button"
            class="btn btn-tertiary restart-buttons"
            data-dismiss="modal"
          >
            {{ 'app.assessment.restart.validation' | translate }}
          </button>
          <button #btnClose type="button" class="btn btn-primary restart-buttons" data-dismiss="modal">
            {{ 'app.assessment.restart.cancel' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <button #btnWalkGraphics data-target="#walkGraphicsModal" data-toggle="modal" [hidden]="true"></button> -->

<div
  class="modal fade"
  id="walkGraphicsModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="walkGraphicsModalTitle"
  aria-hidden="true"
  #walkGraphics
>
  <div class="modal-dialog modal-dialog-centered modal-extra-large" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="walkgraphicsModalModalTitle">
          {{ titleGraphic }}
        </h5>
        <button #btnCloseGraphics type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-walk-graphics
          *ngIf="resultsWalk"
          [resultsWalk]="resultsWalk"
          (changeTitle)="onChangeTitle($event)"
          [currentWalkRecordingSession]="currentWalkRecordingSession"
        ></app-walk-graphics>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" data-dismiss="modal" aria-label="Close">{{ 'Close' | translate }}</button>
      </div>
    </div>
  </div>
</div>
