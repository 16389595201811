<div class="fried-navbar">
  {{ 'navbar.select' | translate }}
  <ul style="list-style-type: none; margin: 0px; padding: 0px;">
    <li style="padding: 20px 0px;">
      <a
        [ngClass]="{ selected: currentPage === 'weight' }"
        class="list-item"
        (click)="goTo('/courses/fried/weight', 'weight')"
      >
        <img
          class="d-inline-block align-top"
          width="20"
          height="20"
          src="assets/images/icons/check-circle-uncheck.svg"
          alt=""
          *ngIf="!hasWeightTest"
        />
        <img
          class="d-inline-block align-top"
          width="20"
          height="20"
          src="assets/images/icons/check-circle-check.svg"
          alt=""
          *ngIf="hasWeightTest"
        />
        <img width="40" height="40" src="assets/images/images/weight.png" alt="" />
        {{ 'navbar.massBalance' | translate }}
        <span style="float: right;" *ngIf="!hasWeightTest">5 {{ 'min' | translate }}</span>
      </a>
    </li>
    <li style="padding: 20px 0px;" *ngIf="course && course.id">
      <a
        [ngClass]="{ selected: currentPage === 'questionnary12' }"
        class="list-item"
        (click)="goTo('/courses/fried/questionnaryCourse/12', 'questionnary12')"
      >
        <img
          class="d-inline-block align-top"
          width="20"
          height="20"
          src="assets/images/icons/check-circle-uncheck.svg"
          alt=""
          *ngIf="!hasQAPPATest"
        />
        <img
          class="d-inline-block align-top"
          width="20"
          height="20"
          src="assets/images/icons/check-circle-check.svg"
          alt=""
          *ngIf="hasQAPPATest"
        />
        <img width="40" height="40" src="assets/images/images/questionnaire.png" alt="" />
        {{ 'app.questionnary' | translate }}&nbsp;QAPPA
        <span style="float: right;" *ngIf="!hasQAPPATest">5 {{ 'min' | translate }}</span>
      </a>
    </li>
    <li style="padding: 20px 0px;" *ngIf="course && course.id">
      <a
        [ngClass]="{ selected: currentPage === 'questionnary11' }"
        class="list-item"
        (click)="goTo('/courses/fried/questionnaryCourse/11', 'questionnary11')"
      >
        <img
          class="d-inline-block align-top"
          width="20"
          height="20"
          src="assets/images/icons/check-circle-uncheck.svg"
          alt=""
          *ngIf="!hasCESTest"
        />
        <img
          class="d-inline-block align-top"
          width="20"
          height="20"
          src="assets/images/icons/check-circle-check.svg"
          alt=""
          *ngIf="hasCESTest"
        />
        <img width="40" height="40" src="assets/images/images/questionnaire.png" alt="" />
        {{ 'app.questionnary' | translate }}&nbsp;CES-D
        <span style="float: right;" *ngIf="!hasCESTest">5 {{ 'min' | translate }}</span>
      </a>
    </li>
    <li style="padding: 20px 0px;" *ngIf="!course || !course.id">
      <a
        [ngClass]="{ selected: currentPage === 'romberg' }"
        class="list-item"
        (click)="goTo('/courses/fried/romberg', 'romberg')"
      >
        <img
          class="d-inline-block align-top"
          width="20"
          height="20"
          src="assets/images/icons/check-circle-uncheck.svg"
          alt=""
        />
        <img width="40" height="40" src="assets/images/images/romberg.png" alt="" />
        {{ 'navbar.rombergTest' | translate }}
        <span style="float: right;">5 {{ 'min' | translate }}</span>
      </a>
    </li>
    <li style="padding: 20px 0px;">
      <a
        [ngClass]="{ selected: currentPage === 'grip' }"
        class="list-item"
        (click)="goTo('/courses/fried/grip', 'grip')"
      >
        <img
          class="d-inline-block align-top"
          width="20"
          height="20"
          src="assets/images/icons/check-circle-uncheck.svg"
          alt=""
          *ngIf="!hasGripTest"
        />
        <img
          class="d-inline-block align-top"
          width="20"
          height="20"
          src="assets/images/icons/check-circle-check.svg"
          alt=""
          *ngIf="hasGripTest"
        />
        <img width="40" height="40" src="assets/images/images/strenght.png" alt="" />
        {{ 'navbar.strengthTest' | translate }}
        <span style="float: right;" *ngIf="!hasGripTest">5 {{ 'min' | translate }}</span>
      </a>
    </li>
    <li style="padding: 20px 0px;">
      <a
        [ngClass]="{ selected: currentPage === 'walk' }"
        class="list-item"
        (click)="goTo('/courses/fried/walk', 'walk')"
      >
        <img
          class="d-inline-block align-top"
          width="20"
          height="20"
          src="assets/images/icons/check-circle-uncheck.svg"
          alt=""
          *ngIf="!hasWalkingTest"
        />
        <img
          class="d-inline-block align-top"
          width="20"
          height="20"
          src="assets/images/icons/check-circle-check.svg"
          alt=""
          *ngIf="hasWalkingTest"
        />
        <img width="40" height="40" src="assets/images/images/walk.png" alt="" />
        {{ 'navbar.walkTest' | translate }}
        <span style="float: right;" *ngIf="!hasWalkingTest">5 {{ 'min' | translate }} </span>
      </a>
    </li>
  </ul>
  <button class="btn btn-primary" (click)="seeSynthesis()" *ngIf="course && course.status == 2">
    {{ 'Course.see.synthesis' | translate }}
  </button>
</div>
