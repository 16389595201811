<nav class="navbar navbar-expand-lg sticky-top">
  <div class="row" style="width: 100%;">
    <div class="col-md-4 container-title flex-column">
      <a class="return-button text-uppercase" (click)="goToHomePage()">
        <h6>
          <img width="20" src="assets/images/icons/arrow-left.svg" alt="" />
          {{ returnTitle | translate }}
        </h6>
      </a>
    </div>
    <div class="col-md-8" *ngIf="patient">
      <div class="container-name">{{ patient.firstName }} {{ patient.lastName }} - {{ getAge() }}</div>
      <div class="container-foldernumber">{{ 'Patient.FolderNumber' | translate }} : {{ patient.folderNumber }}</div>
    </div>
  </div>
</nav>
