<div class="main-container col-md-12">
  <div *ngIf="!isRapportSelected" class="buttons-container">
    <button class="btn btn-primary" (click)="clickOnPatientRapport()">
      {{ 'ChoixImpression.rapportPatient' | translate }}
    </button>
    <button class="btn btn-primary" (click)="clickOnPractitionnerRapport()">
      {{ 'ChoixImpression.rapportPraticien' | translate }}
    </button>
  </div>
</div>
<div class="col-md-12 footer-content-component">
  <button class="btn btn-primary" (click)="goToTest()" *ngIf="!fromHistory">
    {{ 'Checkup.navbar.test.back' | translate }}
  </button>
  <!--
  <button class="btn btn-primary" (click)="goToPatient()" *ngIf="!fromHistory">
    {{ 'Checkup.navbar.patient.back' | translate }}
  </button>
  -->
  <button class="btn btn-primary" (click)="returnToHistory()" *ngIf="fromHistory">
    {{ 'Checkup.navbar.history.back' | translate }}
  </button>
</div>
