import { DoubleTaskControllerService, PatientControllerService, PatientDetail } from '@abilycare/dal-client';
import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { ShoesSelectorValues } from '../../force-platform';
import { AnalysisResults as AnalysisResultsRomberg } from '../../romberg/models/analysis-results.model';
import { AssessmentRomberg } from '../../romberg/models/assessment.model';
import { AnalysisResults as AnalysisResultsWalk } from '../../walk/models/analysis-results.model';
import { Assessment, WalkAssessmentType } from '../../walk/models/assessment.model';
import { TranslateService } from '@ngx-translate/core';
import { FootWearDataService } from '../../abilycare-data-access';

@Component({
  selector: 'app-rapport-selection',
  templateUrl: './rapport-selection.component.html',
  styleUrls: ['./rapport-selection.component.scss'],
})
export class RapportSelectionComponent implements OnInit {
  public walkAnalysisResult: AnalysisResultsWalk = null;
  public rombergAnalysisResult: AnalysisResultsRomberg = null;
  public shoesSelectorValues: ShoesSelectorValues = null;
  public walkAssessments: Assessment = null;
  public rombergAssessments: AssessmentRomberg = null;
  public typeRapport: string;
  public patient: PatientDetail;
  public dateConsultation: Date;
  public typePlate: string;
  public doubleTask: string;
  public openedEyesLeftSupport: number = 0;
  public closedEyesLeftSupport: number = 0;

  public isRapportSelected = false;
  public fromHistory = false;

  constructor(
    private router: Router,
    private location: Location,
    private dataService: DataProviderService,
    private translateService: TranslateService,
    private patientService: PatientControllerService,
  ) {}

  ngOnInit() {
    const obj: any = this.location.getState();
    this.walkAnalysisResult = obj.walkAnalysisResult;
    this.rombergAnalysisResult = obj.rombergAnalysisResult;
    this.shoesSelectorValues = obj.shoesSelectorValues;
    this.typeRapport = obj.typeRapport;
    this.walkAssessments = obj.walkAssessments;
    this.rombergAssessments = obj.rombergAssessments;
    this.dateConsultation = obj.dateConsultation;
    this.fromHistory = obj.fromHistory;
    this.typePlate = obj.typePlate;
    this.doubleTask = obj.doubleTask;
    this.openedEyesLeftSupport = obj.openedEyesLeftSupport;
    this.closedEyesLeftSupport = obj.closedEyesLeftSupport;

    this.patient = this.dataService.retrievePatient();

    if (!this.walkAnalysisResult && !this.rombergAnalysisResult) {
      this.router.navigateByUrl('/Patient/' + this.patient.id);
    }

    this.patientService.patientControllerFindById(obj.patientId).subscribe((p) => {
      this.patient = p;
    });
  }

  public clickOnPatientRapport(): void {
    // this.isRapportSelected = true;
    const obj = {
      rombergAnalysisResult: this.rombergAnalysisResult,
      walkAnalysisResult: this.walkAnalysisResult,
      shoesSelectorValues: this.shoesSelectorValues,
      walkAssessments: this.walkAssessments,
      rombergAssessments: this.rombergAssessments,
      typeRapport: this.typeRapport,
      dateConsultation: this.dateConsultation,
      fromHistory: this.fromHistory,
      doubleTask: this.doubleTask,
      typePlate: this.typePlate,
      openedEyesLeftSupport: this.openedEyesLeftSupport,
      closedEyesLeftSupport: this.closedEyesLeftSupport,
    };
    this.router.navigateByUrl('/rapport/patient', { state: obj });
  }

  public clickOnPractitionnerRapport(): void {
    const obj = {
      rombergAnalysisResult: this.rombergAnalysisResult,
      walkAnalysisResult: this.walkAnalysisResult,
      shoesSelectorValues: this.shoesSelectorValues,
      walkAssessments: this.walkAssessments,
      rombergAssessments: this.rombergAssessments,
      typeRapport: this.typeRapport,
      dateConsultation: this.dateConsultation,
      fromHistory: this.fromHistory,
      typePlate: this.typePlate,
      doubleTask: this.doubleTask,
      openedEyesLeftSupport: this.openedEyesLeftSupport,
      closedEyesLeftSupport: this.closedEyesLeftSupport,
    };
    this.router.navigateByUrl('/rapport/praticien', { state: obj });
  }

  public goToPatient(): void {
    this.router.navigateByUrl('/Patient/' + this.patient.id);
  }

  public goToTest(): void {
    this.router.navigateByUrl('/courses/fried');
  }

  public returnToHistory(): void {
    this.router.navigateByUrl('/PatientHistoryCourses/' + this.patient.id);
  }
}
