import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';

export enum Handedness {
  Unknown = 0,
  Left = 1,
  Right = 2,
  Ambidextrous = 3,
}

export const HandednessArray = [
  { name: 'Unknown', completeName: i18nKey('Handedness.Unknown'), value: Handedness.Unknown },
  { name: 'Left', completeName: i18nKey('Handedness.Left'), value: Handedness.Left },
  { name: 'Right', completeName: i18nKey('Handedness.Right'), value: Handedness.Right },
  { name: 'Ambidextrous', completeName: i18nKey('Handedness.Ambidextrous'), value: Handedness.Ambidextrous },
];
