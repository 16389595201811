import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-weight-tutorial',
  templateUrl: './weight-tutorial.component.html',
  styleUrls: ['./weight-tutorial.component.scss'],
})
/**
 * This component representes the informative div of the connexion with wiiBoard
 */
export class WeightTutorialComponent {
  public firstStep: boolean;
  @Output() closeModal = new EventEmitter();

  constructor() {
    this.firstStep = true;
  }

  public reset() {
    this.firstStep = true;
  }

  public goToMesure(): void {
    this.firstStep = true;
    this.closeModal.emit();
  }

  public goToNextStep(): void {
    this.firstStep = false;
  }

  public goToPreviousStep(): void {
    this.firstStep = true;
  }
}
