import {
  NewRomberg,
  PatientControllerService,
  PatientDetail,
  RombergControllerService,
  RombergWithRelations,
} from '@abilycare/dal-client';
import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, Subscription, timer } from 'rxjs';
import { EtatYeux } from 'src/app/modules/abilycare-data-access/model/romberg-test.model';
import { ComparisonGraph } from 'src/app/modules/common/models/comparison.model';
import { ShoesSelectorValues, WiiBoardService, WiiBoardState } from 'src/app/modules/force-platform';
import { AnalysisResults } from 'src/app/modules/romberg/models/analysis-results.model';
import { LongitudinalGraph } from 'src/app/modules/romberg/models/longitudinal.model';
import { RombergSummary } from 'src/app/modules/romberg/models/romberg-summary.model';
import { SUBINDICATORS } from 'src/app/modules/romberg/models/subindicators.model';
import { AnalysisResultsRepository } from 'src/app/modules/romberg/services/analysis-result-repository.service';
import { RombergComparisonService } from 'src/app/modules/romberg/services/romberg-comparaison.service';
import { Timer } from 'src/app/modules/time';
import { DataProviderService } from 'src/app/services/data-provider.service';
import * as medicalConst from 'src/app/tools/medical.constants';
import { ModalComponent } from 'src/app/view/common/modal/modal.component';
import { Location } from '@angular/common';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-romberg-view',
  templateUrl: './romberg-view.component.html',
  styleUrls: ['./romberg-view.component.scss'],
  providers: [AnalysisResultsRepository, RombergComparisonService],
})
export class RombergViewComponent implements OnInit, OnDestroy {
  @ViewChild('btnClose', { static: true }) btnClose: ElementRef;
  @ViewChild('btnCloseGraphModal', { static: true }) btnCloseGraphModal: ElementRef;

  @ViewChildren(ModalComponent)
  public modals: QueryList<ModalComponent>;

  public shoesSelectorValues: ShoesSelectorValues;

  /** timer */
  public timerYO: Timer = new Timer(medicalConst.TIME_TO_ROMBERG);
  public timerYF: Timer = new Timer(medicalConst.TIME_TO_ROMBERG);

  public timeFinishYO: string;
  public timeFinishYF: string;

  public readonly boardReady: Observable<boolean>;
  public wiiState: Observable<WiiBoardState>;

  public patient: PatientDetail;

  private currentRombergRecordingSession;
  private unsubscribe = new Subject<void>();
  private subscriptions: Subscription[] = [];

  public data: RombergSummary = new RombergSummary();
  public analyisResult: AnalysisResults;
  public rombergToCompare: any;

  // Valeurs booléennes permettant de déterminer si on est en dehors des bornes pour chaque valeur
  public isOffLimitOpenedEyesScore: boolean = false;
  public isOffLimitClosedEyesScore: boolean = false;
  public isOffLimitOpenedEyesArea: boolean = false;
  public isOffLimitClosedEyesArea: boolean = false;
  public isOffLimitOpenedEyesSwayDensity: boolean = false;
  public isOffLimitClosedEyesSwayDensity: boolean = false;
  public isOffLimitOpenedEyesLateralVariance: boolean = false;
  public isOffLimitClosedEyesLateralVariance: boolean = false;
  public isOffLimitOpenedEyesAverageSpeed: boolean = false;
  public isOffLimitClosedEyesAverageSpeed: boolean = false;

  // Valeurs permettant de définir les limites de chaque valeur calculée
  public minEyesScore: number = 0;
  public maxEyesScore: number = 100;
  public minEyesArea: number = 0;
  public maxEyesArea: number = 15;
  public minEyesSwayDensity: number = 0;
  public maxEyesSwayDensity: number = 30;
  public minEyesLateralVariance: number = 0;
  public maxEyesLateralVariance: number = 5;
  public minEyesAverageSpeed: number = 0;
  public maxEyesAverageSpeed: number = 8;

  public graphTitle = this.translateService.instant('Checkup.Romberg.Breadcrumb.StatoKinesiGram');
  public isStatokinesigram = true;
  public isStatokinesigramStandard = true;
  public isStatokinesigramAsym = false;
  public isStabilogram = false;
  public isComparison = false;
  public isLongitudinal = false;

  public standardGraph: boolean = true;
  public asymetrieGraph: boolean = false;

  public recupComparaison: boolean = false;
  public comparisonDatas: ComparisonGraph[];
  public comparisonGraphSelected: ComparisonGraph;
  public typesOfComparisons: string[];
  public choiceOfComparison: string;

  public graphs: LongitudinalGraph[];
  public zoom: boolean;
  public graphSelected: LongitudinalGraph;

  public error: boolean = false;

  public calculResult: boolean = false;

  public returnTitle: string = i18nKey('Checkup.navbar.history.back');
  public testName: string = i18nKey('navbar.rombergTest');

  public newPage: string = '';

  public testData: any;

  public readonly waitAnalyseName = 'WaitAnalyse';

  public showdata: boolean = false;

  public listTestRombergs: any;

  public optionsopenedEyesLeftSupport: Options;
  public minOpenedEyesLeftSupport: number = 0;
  public maxOpenedEyesLeftSupport: number = 100;

  public optionsclosedEyesLeftSupport: Options;
  public minClosedEyesLeftSupport: number = 0;
  public maxClosedEyesLeftSupport: number = 100;

  constructor(
    private translateService: TranslateService,
    private dataService: DataProviderService,
    private router: Router,
    private analysisResultsRepository: AnalysisResultsRepository,
    private rombergComparaisonService: RombergComparisonService,
    private location: Location,
    private rombergService: RombergControllerService,
    private patientService: PatientControllerService,
  ) {}

  async ngOnInit() {
    await this.dataService.storeHeaderShowed(false);
    this.typesOfComparisons = SUBINDICATORS;
    this.choiceOfComparison = this.typesOfComparisons[0];
    this.patient = await this.dataService.retrievePatient();
    this.shoesSelectorValues = await new ShoesSelectorValues();
    const that = await this;
    const obj: any = await this.location.getState();
    this.currentRombergRecordingSession = await obj.id;
    this.testData = await obj;
    await this.patientService.patientControllerFindById(this.testData.patientId).subscribe((p) => {
      this.patient = p;
      this.dataService.setPatient(p);
      this.rombergService.rombergControllerFindAllRombergByPatient(this.patient.id).subscribe((res) => {
        this.listTestRombergs = res;
        this.transformBilanToGraph(this.listTestRombergs);
        this.listTestRombergs = this.listTestRombergs.filter(function(e) {
          return e.id != this.testData.id;
        });
      });
    });
    await this.analysisResultsRepository
      .get(this.currentRombergRecordingSession)
      .then((value) => {
        this.analyisResult = value;
        this.checkLimitResults();
        this.data = this.buildSummary(value, this.shoesSelectorValues);
        this.getGraphComparaison();
        this.showdata = true;
      })
      .catch((error) => {
        this.showdata = true;
        console.error('Error during get analysis result', error);
      });

    // OpenedEyesLeftSupport
    this.minOpenedEyesLeftSupport =
      this.testData.openedEyesLeftSupport > 50 ? 100 - this.testData.openedEyesLeftSupport : 50;
    this.maxOpenedEyesLeftSupport =
      this.testData.openedEyesLeftSupport < 50 ? 100 - this.testData.openedEyesLeftSupport : 50;

    this.optionsopenedEyesLeftSupport = {
      floor: 0,
      ceil: 100,
      //showTicksValues: true,
      step: 10,
      readOnly: true,
      translate: (value: number): string => {
        if (value == 0) {
          return this.translateService.instant('Gauche');
        } else if (value == 100) {
          return this.translateService.instant('Droite');
        } else {
          return '';
        }
      },
      getPointerColor: (value: number): string => {
        if (value != 50) {
          return '#ec9a1f';
        }
        if (value == 50) {
          return '#70757a';
        }
      },
    };

    this.minClosedEyesLeftSupport =
      this.testData.closedEyesLeftSupport > 50 ? 100 - this.testData.closedEyesLeftSupport : 50;
    this.maxClosedEyesLeftSupport =
      this.testData.closedEyesLeftSupport < 50 ? 100 - this.testData.closedEyesLeftSupport : 50;

    this.optionsclosedEyesLeftSupport = {
      floor: 0,
      ceil: 100,
      //showTicksValues: true,
      step: 10,
      readOnly: true,
      translate: (value: number): string => {
        if (value == 0) {
          return this.translateService.instant('Gauche');
        } else if (value == 100) {
          return this.translateService.instant('Droite');
        } else {
          return '';
        }
      },
      getPointerColor: (value: number): string => {
        if (value != 50) {
          return '#ec9a1f';
        }
        if (value == 50) {
          return '#70757a';
        }
      },
    };
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
  }

  onCloseModal() {
    this.btnClose.nativeElement.click();
  }

  private showModal(modalName: string) {
    this.modals.filter((e) => e.name === modalName).forEach((e) => e.show());
  }
  private hideModal(modalName: string) {
    this.modals.filter((e) => e.name === modalName).forEach((e) => e.hide());
  }

  resetModal() {}

  /**
   * Method which will check all romberg results to determine if they are off limit or not.
   */
  private checkLimitResults(): void {
    // Analysis Score
    if (this.analyisResult.ScoreYO < this.minEyesScore || this.analyisResult.ScoreYO > this.maxEyesScore) {
      this.isOffLimitOpenedEyesScore = true;
    }
    if (this.analyisResult.ScoreYF < this.minEyesScore || this.analyisResult.ScoreYF > this.maxEyesScore) {
      this.isOffLimitClosedEyesScore = true;
    }
    // Analysis Area
    if (this.analyisResult.SurfaceYO < this.minEyesArea || this.analyisResult.SurfaceYO > this.maxEyesArea) {
      this.isOffLimitOpenedEyesArea = true;
    }
    if (this.analyisResult.SurfaceYF < this.minEyesArea || this.analyisResult.SurfaceYF > this.maxEyesArea) {
      this.isOffLimitClosedEyesArea = true;
    }
    // Analysis Sway Density
    if (
      this.analyisResult.SwayDensityYO < this.minEyesSwayDensity ||
      this.analyisResult.SwayDensityYO > this.maxEyesSwayDensity
    ) {
      this.isOffLimitOpenedEyesSwayDensity = true;
    }
    if (
      this.analyisResult.SwayDensityYF < this.minEyesSwayDensity ||
      this.analyisResult.SwayDensityYF > this.maxEyesSwayDensity
    ) {
      this.isOffLimitClosedEyesSwayDensity = true;
    }
    // Analysis Lateral Variance
    if (
      this.analyisResult.VarianceLateraleYO < this.minEyesLateralVariance ||
      this.analyisResult.VarianceLateraleYO > this.maxEyesLateralVariance
    ) {
      this.isOffLimitOpenedEyesLateralVariance = true;
    }
    if (
      this.analyisResult.VarianceLateraleYF < this.minEyesLateralVariance ||
      this.analyisResult.VarianceLateraleYF > this.maxEyesLateralVariance
    ) {
      this.isOffLimitClosedEyesLateralVariance = true;
    }
    // Analysis Average Speed
    if (
      this.analyisResult.SpeedMoyYO < this.minEyesAverageSpeed ||
      this.analyisResult.SpeedMoyYO > this.maxEyesAverageSpeed
    ) {
      this.isOffLimitOpenedEyesAverageSpeed = true;
    }
    if (
      this.analyisResult.SpeedMoyYF < this.minEyesAverageSpeed ||
      this.analyisResult.SpeedMoyYF > this.maxEyesAverageSpeed
    ) {
      this.isOffLimitClosedEyesAverageSpeed = true;
    }
  }

  private buildSummary(results: AnalysisResults, preamble: ShoesSelectorValues): RombergSummary {
    console.log('buildSummary');
    const out = new RombergSummary();

    out.shoesType = preamble.shoesType;
    out.helpStability = preamble.helpStability;
    out.ScoreYF = results.ScoreYF;
    out.ScoreYO = results.ScoreYO;
    out.SpeedMoyYF = results.SpeedMoyYF;
    out.SpeedMoyYO = results.SpeedMoyYO;
    out.SurfaceYF = results.SurfaceYF;
    out.SurfaceYO = results.SurfaceYO;
    out.SwayDensityYF = results.SwayDensityYF;
    out.SwayDensityYO = results.SwayDensityYO;
    out.VarianceLateraleYF = results.VarianceLateraleYF;
    out.VarianceLateraleYO = results.VarianceLateraleYO;
    out.Duration = medicalConst.TIME_TO_ROMBERG;
    out.statsym = this.transformUrl(results.graphes.stasym);
    out.statok = this.transformUrl(results.graphes.statok);
    out.stabilo = this.transformUrl(results.graphes.stabilo);

    return out;
  }

  private transformUrl(url: string) {
    //return url.replace('https://', 'http://');
    return url;
  }

  public closeGraphModal() {
    this.btnCloseGraphModal.nativeElement.click();
  }

  public openGraphModal() {
    this.graphTitle = this.translateService.instant('Checkup.Romberg.Breadcrumb.StatoKinesiGram');
    this.isStatokinesigram = true;
    this.isStatokinesigramStandard = true;
    this.isStatokinesigramAsym = false;
    this.isStabilogram = false;
    this.isComparison = false;
    this.isLongitudinal = false;
    this.standardGraph = true;
    this.asymetrieGraph = false;
  }

  public goToGraph(graph: string) {
    if (graph === 'statokinesigram') {
      this.graphTitle = this.translateService.instant('Checkup.Romberg.Breadcrumb.StatoKinesiGram');
      this.isStatokinesigram = true;
      this.isStatokinesigramStandard = true;
      this.isStatokinesigramAsym = false;
      this.isStabilogram = false;
      this.isComparison = false;
      this.isLongitudinal = false;
    } else if (graph === 'stabilogram') {
      this.graphTitle = this.translateService.instant('Checkup.Romberg.Breadcrumb.StabiloGram');
      this.isStatokinesigram = false;
      this.isStatokinesigramStandard = false;
      this.isStatokinesigramAsym = false;
      this.isStabilogram = true;
      this.isComparison = false;
      this.isLongitudinal = false;
    } else if (graph === 'comparison') {
      this.graphTitle = this.translateService.instant('Checkup.Romberg.Breadcrumb.Comparison');
      this.isStatokinesigram = false;
      this.isStatokinesigramStandard = false;
      this.isStatokinesigramAsym = false;
      this.isStabilogram = false;
      this.isComparison = true;
      this.isLongitudinal = false;
    } else if (graph === 'longitudinal') {
      this.graphTitle = this.translateService.instant('Checkup.Romberg.Breadcrumb.Longitudinal');
      this.isStatokinesigram = false;
      this.isStatokinesigramStandard = false;
      this.isStatokinesigramAsym = false;
      this.isStabilogram = false;
      this.isComparison = false;
      this.isLongitudinal = true;
    }
  }

  public zoomGraph() {
    this.zoom = true;
  }

  /**
   * Close the zoom page
   */
  public closeChart() {
    this.zoom = false;
  }

  public changeToStandard() {
    this.standardGraph = true;
    this.asymetrieGraph = false;
  }

  public changeToAsymetrie() {
    this.standardGraph = false;
    this.asymetrieGraph = true;
  }

  /**
   * Calls the service bilan-marche to get data
   */
  private async getGraphComparaison() {
    this.recupComparaison = false;
    this.comparisonDatas = await this.rombergComparaisonService.getAllGraph(
      this.choiceOfComparison,
      this.analyisResult,
    );

    this.recupComparaison = true;
  }

  public onSelectGraphComparaison(selection: string) {
    this.choiceOfComparison = selection;
    this.getGraphComparaison();
  }

  /**
   * Zoom on a graph
   * @param e object pointed
   */
  public comparisonChartClicked(e: ComparisonGraph): void {
    this.zoom = true;
    this.comparisonGraphSelected = e;
  }

  // push the Romberg bilan values into eaach graph for each property

  // push the other Romberg bilans values into each graph for each property
  private transformBilanToGraph(bilans: RombergWithRelations[]) {
    //prepare graph to put results :
    this.graphs = new Array<LongitudinalGraph>(5);
    this.graphs[0] = new LongitudinalGraph(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      this.translateService.instant('Checkup.Romberg.Graph.Longitu.Score'),
    );
    this.graphs[1] = new LongitudinalGraph(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      this.translateService.instant('Checkup.Romberg.Graph.Longitu.SpeedMoy'),
    );
    this.graphs[2] = new LongitudinalGraph(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      this.translateService.instant('Checkup.Romberg.Graph.Longitu.SwayDensiy'),
    );
    this.graphs[3] = new LongitudinalGraph(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      this.translateService.instant('Checkup.Romberg.Graph.Longitu.Suface'),
    );
    this.graphs[4] = new LongitudinalGraph(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      this.translateService.instant('Checkup.Romberg.Graph.Longitu.VarianceLateral'),
    );
    for (const bilan of bilans) {
      const date = bilan.date;
      this.graphs[4].graphYF.push({ x: date, y: Number(bilan.closedEyesLateralVariance) });
      this.graphs[4].graphYO.push({ x: date, y: Number(bilan.openedEyesLateralVariance) });
      this.graphs[3].graphYF.push({ x: date, y: Number(bilan.closedEyesArea) });
      this.graphs[3].graphYO.push({ x: date, y: Number(bilan.openedEyesArea) });
      this.graphs[2].graphYF.push({ x: date, y: Number(bilan.closedEyesSwayDensity) });
      this.graphs[2].graphYO.push({ x: date, y: Number(bilan.openedEyesSwayDensity) });
      this.graphs[1].graphYF.push({ x: date, y: Number(bilan.closedEyesAverageSpeed) });
      this.graphs[1].graphYO.push({ x: date, y: Number(bilan.openedEyesAverageSpeed) });
      this.graphs[0].graphYF.push({ x: date, y: bilan.closedEyesScore });
      this.graphs[0].graphYO.push({ x: date, y: bilan.openedEyesScore });
    }
  }

  /**
   * Zoom on a graph
   * @param e object pointed
   */
  public chartClicked(e: LongitudinalGraph): void {
    this.zoom = true;
    this.graphSelected = e;
  }
  private stop(): void {
    this.timerYO.stop();
  }

  public onSelectPage(newPage: string) {
    this.newPage = newPage;
  }

  public goTo() {
    this.router.navigate([this.newPage]);
  }

  public setRombergToCompare(value: string): void {
    //console.log("value changed ", value);
    this.rombergToCompare = value;
    this.error = false;
    this.doCompare();
  }

  public doCompare() {
    const res = [this.testData, this.rombergToCompare];
    if (!this.rombergToCompare) this.error = true;
    else this.router.navigateByUrl('/courses/fried/rombergComparison', { state: res });
  }
}
