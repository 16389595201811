<div class="graphic-zoom-container">
  <div class="row">
    <div class="col-md-5 d-flex title-container" style="text-align: center; margin-bottom: 20px; font-weight: normal;">
      <h5>{{ 'app.grip.graphLeftHand.Title' | translate }}</h5>
    </div>
    <div class="col-md-5 d-flex title-container" style="text-align: center; margin-bottom: 20px; font-weight: normal;">
      <h5>{{ 'app.grip.graphRightHand.Title' | translate }}</h5>
    </div>
  </div>
  <div class="row">
    <div
      class="col-md-5 d-flex description-container"
      style="text-align: center; margin-bottom: 20px; font-weight: normal;"
    >
      <img class="d-inline-block align-top" width="20" height="20" src="assets/images/icons/lefthand.svg" alt="" />
      {{ 'app.grip.lefthand' | translate }}
    </div>
    <div
      class="col-md-5 d-flex .description-container"
      style="text-align: center; margin-bottom: 20px; font-weight: normal;"
    >
      <img class="d-inline-block align-top" width="20" height="20" src="assets/images/icons/righthand.svg" alt="" />
      {{ 'app.grip.righthand' | translate }}
    </div>
  </div>
  <div class="row">
    <div class="col-md-5 d-flex align-text-center">
      <canvas #graphLeftHand></canvas>
    </div>
    <div class="col-md-5 d-flex align-items-center">
      <canvas #graphRightHand></canvas>
    </div>
  </div>
</div>
