<app-fried-history-navbar
  [patient]="patient"
  [returnTitle]="returnTitle"
  (selectPage)="onSelectPage($event)"
  *ngIf="!fromCourse"
></app-fried-history-navbar>
<app-custom-navbar
  [patient]="patient"
  [returnTitle]="returnTitle"
  (selectPage)="onSelectPage($event)"
  *ngIf="fromCourse"
></app-custom-navbar>
<app-fried-custom-navbar [testName]="testName" [testData]="testData" *ngIf="!fromCourse" [displayDoubleTask]="false">
</app-fried-custom-navbar>
<app-courses-breadcrumb-navbar
  [testName]="testName | translate"
  [course]="course"
  *ngIf="fromCourse"
></app-courses-breadcrumb-navbar>
<div class="col-md-12">
  <div class="row">
    <div class="col-md-3 p-0" *ngIf="fromCourse">
      <app-fried-navbar
        [currentPage]="'weight'"
        (selectPage)="onSelectPage($event)"
        [course]="course"
      ></app-fried-navbar>
    </div>
    <div class="col-md-9 fried-info">
      <div class="row">
        <div class="col-md-7 simple-content-component flex-column justify-content-between">
          <div style="margin-bottom: 30px;">
            {{ 'app.weight.results' | translate }}
          </div>
          <div style="text-align: center; margin-bottom: 30px; font-weight: normal;">
            {{ 'app.weight.poidsmesure' | translate }}
          </div>
          <div style="text-align: center; margin-bottom: 30px; font-weight: normal;">
            <h3>{{ weight | float: 3 }} Kg</h3>
          </div>
        </div>
        <div class="col-md-4 simple-content-component">
          <div style="text-align: center;">
            <div>
              <img
                class="d-inline-block align-top pointer"
                data-target="#weightGraphicsModal"
                data-toggle="modal"
                src="assets/images/images/graphique.svg"
                alt=""
              />
            </div>
            <div class="d-flex justify-content-center">
              <button class="btn btn-tertiary" data-target="#weightGraphicsModal" data-toggle="modal">
                {{ 'app.walk.access.graphic' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="weightGraphicsModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="weightGraphicsModalTitle"
  aria-hidden="true"
  #weightGraphics
>
  <div class="modal-dialog modal-dialog-centered modal-extra-large" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button #btnCloseGraphics type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-weight-graphics [testData]="testData" [patient]="patient"></app-weight-graphics>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" data-dismiss="modal" aria-label="Close">{{ 'Close' | translate }}</button>
      </div>
    </div>
  </div>
</div>
