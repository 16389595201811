import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import {
  Course,
  CourseControllerService,
  Grip,
  GripControllerService,
  PatientDetail,
  QuestionnaireReply,
  QuestionnaireReplyControllerService,
  Walking,
  WalkingControllerService,
  Weighing,
  WeighingControllerService,
} from '@abilycare/dal-client';
import { Router } from '@angular/router';
import { RolesCheckService } from 'src/app/modules/core/services/authentification/roles-check.service';

@Component({
  selector: 'app-course-information',
  templateUrl: './course-information.component.html',
  styleUrls: ['./course-information.component.scss'],
})
export class CourseInformationComponent implements OnInit {
  @Input() course: Course;
  @Input() patient: PatientDetail;
  @Input() canEdit: boolean;
  @Output() deleteCourse = new EventEmitter<number>();

  courseDetail: Course = null;
  isLoaded = false;
  isOpen = false;
  isEditable = true;

  public hasGripTest: boolean;
  public hasQAPPATest: boolean;
  public hasCESTest: boolean;
  public hasWeightTest: boolean;
  public hasWalkingTest: boolean;
  public gripTest: Grip;
  public QappaTest: QuestionnaireReply;
  public CESTest: QuestionnaireReply;
  public weightTest: Weighing;
  public walkingTest: Walking;

  public dateLastTest: Date;
  public hasAtLeastOneTest: boolean = false;
  public imgStatusUrl: string;

  constructor(
    private courseDataService: CourseControllerService,
    private router: Router,
    public rolesCheck: RolesCheckService,
    private gripService: GripControllerService,
    private questReplyService: QuestionnaireReplyControllerService,
    private walkingService: WalkingControllerService,
    private weighingService: WeighingControllerService,
  ) {}

  async ngOnInit() {
    this.dateLastTest = new Date('01/01/1999');
    if (this.course && this.course.id) {
      //get image for course status
      if (this.course.status === 1 || this.course.status === 0) this.imgStatusUrl = '/assets/images/icons/delegate.svg';
      else if (this.course.status === -1) this.imgStatusUrl = '/assets/images/icons/archive.svg';
      else if (this.course.status === 2) this.imgStatusUrl = '/assets/images/icons/finish.svg';
      await this.gripService.gripControllerFindGripByCourseId(this.course.id).subscribe((data) => {
        this.hasGripTest = data.id ? true : false;
        this.gripTest = data;
        if (new Date(data.date) >= this.dateLastTest) this.dateLastTest = new Date(data.date);
        this.hasAtLeastOneTest = true;
      });
      await this.walkingService.walkingControllerFindWalkingByCourseId(this.course.id).subscribe((data) => {
        this.hasWalkingTest = data.id ? true : false;
        this.walkingTest = data;
        if (new Date(data.date) >= this.dateLastTest) this.dateLastTest = new Date(data.date);
        this.hasAtLeastOneTest = true;
      });
      await this.weighingService.weighingControllerFindWeightByCourseId(this.course.id).subscribe((data) => {
        this.hasWeightTest = data.id ? true : false;
        this.weightTest = data;
        if (new Date(data.date) >= this.dateLastTest) this.dateLastTest = new Date(data.date);
        this.hasAtLeastOneTest = true;
      });
      await this.questReplyService
        .questionnaireReplyControllerFindQuestionnaryByCourseId(this.course.id, 12)
        .subscribe((data) => {
          this.hasQAPPATest = data.id ? true : false;
          this.QappaTest = data;
          if (new Date(data.date) >= this.dateLastTest) this.dateLastTest = new Date(data.date);
          this.hasAtLeastOneTest = true;
        });
      await this.questReplyService
        .questionnaireReplyControllerFindQuestionnaryByCourseId(this.course.id, 11)
        .subscribe((data) => {
          this.hasCESTest = data.id ? true : false;
          this.CESTest = data;
          if (new Date(data.date) >= this.dateLastTest) this.dateLastTest = new Date(data.date);
          this.hasAtLeastOneTest = true;
        });
      //this.dateLastTest = await lastDateTest;
    }
  }

  public openClose(): void {
    this.isOpen = !this.isOpen;
  }

  public removeCourse(): void {
    this.deleteCourse.emit(this.course.id);
  }

  public launchCourse(): void {
    this.router.navigateByUrl('/courses/fried', { state: this.course });
  }
}
