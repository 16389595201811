import { PatientDetail, Practitioner, RefPractionerDetail, UpdatePatient } from '@abilycare/dal-client';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PatientDataService } from 'src/app/modules/abilycare-data-access/api/patient-data.service';
import { PractitionerDataService } from 'src/app/modules/abilycare-data-access/api/practitioner-data.service';
import { PractitionerSpecialityDataService } from 'src/app/modules/abilycare-data-access/api/practitioner-speciality-data.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-patient-creation-practitioner-form',
  templateUrl: './patient-creation-practitioner-form.component.html',
  styleUrls: ['./patient-creation-practitioner-form.component.scss'],
})
export class PatientCreationPractitionerFormComponent implements OnInit {
  @Input() patient: PatientDetail;
  @Output() closeModal = new EventEmitter();

  public practitioners: Array<Practitioner> = new Array<Practitioner>();
  pratitionerFormGroup: FormGroup;
  speciality: string = '';
  isOk = false;

  constructor(
    private formBuilder: FormBuilder,
    private patientDataService: PatientDataService,
    private practitionerDataService: PractitionerDataService,
    private practitionerSpecialityService: PractitionerSpecialityDataService,
    private dataService: DataProviderService,
    private location: Location,
  ) {}

  ngOnInit() {
    this.practitionerDataService.getAllPractitioners().subscribe(
      (practitioners) => {
        this.practitioners = practitioners;
        if (this.patient.practioner) this.speciality = this.patient.practioner.speciality;
        this.pratitionerFormGroup = this.formBuilder.group({
          practitioner: [this.patient.practioner, Validators.required],
          practitionerSpeciality: this.patient.practioner ? [this.patient.practioner.speciality] : [],
        });
        this.isOk = true;
      },
      (error) => {
        console.error('Error getAllPractitioners() : ', error);
      },
    );
  }

  compareFn(p1: Practitioner, p2: RefPractionerDetail): boolean {
    return p1 && p2 ? p1.id === p2.id : null;
  }

  onChangePractitioner() {
    const practitioner: Practitioner = this.pratitionerFormGroup.value.practitioner;
    this.getSpeciality(practitioner);
  }

  getSpeciality(practitioner) {
    this.practitionerSpecialityService.getPractitionerSpeciality(practitioner.specialityId).subscribe(
      (speciality) => {
        if (speciality) this.speciality = speciality.name;
      },
      (error) => {
        console.error('Error getPractitionerSpeciality()', error);
      },
    );
  }

  validate() {
    if (this.pratitionerFormGroup.valid) {
      const form = this.pratitionerFormGroup.value;
      const updatePatient: UpdatePatient = {
        id: this.patient.id,
        practitionerId: form.practitioner.id,
      };
      this.patientDataService.updatePatient(updatePatient).subscribe((data) => {
        this.patientDataService.getPatientById(this.patient.id).subscribe((patient) => {
          this.dataService.setPatient(patient);
          this.closeModal.emit();
        });
      });
    }
  }

  cancel() {
    this.closeModal.emit();
  }
}
