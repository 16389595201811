import { Course, QuestionnaireSummary } from '@abilycare/dal-client';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-questionnary-infos',
  templateUrl: './questionnary-infos.component.html',
  styleUrls: ['./questionnary-infos.component.scss'],
  // styleUrls: ['./questionnary-infos.component.scss', '../../shared/scss/common.scss'],
})
export class QuestionnaryInfosComponent implements OnInit {
  @Input() public questionnary: QuestionnaireSummary;
  @Input() public course: Course;
  @Input() public displayStart: boolean;
  @Output() public startQuestionnary: EventEmitter<number> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  public start() {
    this.startQuestionnary.emit(this.questionnary.id);
  }
}
