import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { PatAttDataService } from 'src/app/modules/abilycare-data-access/api/patatt-data.service';
import { PatattDetail, PatientDetail } from '@abilycare/dal-client';
import { Router } from '@angular/router';
import { RolesCheckService } from 'src/app/modules/core/services/authentification/roles-check.service';

@Component({
  selector: 'app-patatt-information',
  templateUrl: './patatt-information.component.html',
  styleUrls: ['./patatt-information.component.scss'],
})
export class PatattInformationComponent implements OnInit {
  @Input() set patAtt(patAtt: PatattDetail) {
    this.patAttt = patAtt;
    if (this.patAttt.symptoms) this.symptomIsOpened = new Array(this.patAttt.symptoms.length);
    else this.symptomIsOpened = new Array();
    this.symptomIsOpened.forEach((bool) => (bool = false));
    this.isLoaded = true;
  }

  @Input() set patient(patient: PatientDetail) {
    this.selectedPatient = patient;
    if (patient.patattBrief.isHealthy || patient.patattBrief.isUnknown) {
      this.isEditable = false;
    }
  }
  @Input() canEdit: boolean;
  @Output() deletePatAtt = new EventEmitter<number>();

  patAttt: PatattDetail = null;
  symptomIsOpened: Array<boolean> = new Array();
  isLoaded = false;
  isOpen = false;
  isEditable = true;
  selectedPatient: PatientDetail;

  constructor(
    private patattDataService: PatAttDataService,
    private router: Router,
    public rolesCheck: RolesCheckService,
  ) {}

  ngOnInit() {}

  public openClose(): void {
    this.isOpen = !this.isOpen;
  }

  public removePatAtt(): void {
    this.deletePatAtt.emit(this.patAttt.id);
  }

  // onRemovePatAtt() {
  //   this.patattDataService.deletePatAtt(this.selectedPatient.id, this.patAttt.id).subscribe((data) => {

  //   });
  // }

  public goToModify(): void {
    this.router.navigate(['PatAtt/', this.selectedPatient.id, false, 'edition'], {
      queryParams: { patattId: this.patAttt.id },
      skipLocationChange: true,
    });
  }

  public onOpened(indexDeficit: number): void {
    this.symptomIsOpened[indexDeficit] = true;
  }

  public onClosed(indexDeficit: number): void {
    this.symptomIsOpened[indexDeficit] = false;
  }
}
