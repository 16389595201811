<div
  class="modal fade"
  tabindex="-1"
  [ngClass]="{ in: visibleAnimate }"
  [ngStyle]="{ display: visible ? 'block' : 'none', opacity: visibleAnimate ? 1 : 0 }"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <ng-content select=".modal-header"></ng-content>
      </div>
      <div class="modal-body">
        <ng-content select=".modal-body"></ng-content>
      </div>
      <div class="modal-footer">
        <ng-content select=".modal-footer"></ng-content>
      </div>
    </div>
  </div>
</div>
