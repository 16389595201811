import { PatientDelegation, PatientDetail } from '@abilycare/dal-client';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { forkJoin, Subscription } from 'rxjs';
import { PatientDataService } from 'src/app/modules/abilycare-data-access/api/patient-data.service';
import { Roles } from 'src/app/modules/core/roles.enum';
import { AuthService } from 'src/app/modules/core/services/authentification/auth.service';
import { RolesCheckService } from 'src/app/modules/core/services/authentification/roles-check.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { genderItems } from 'src/app/shared/models/gender-item.model';
import { lateralityItems } from 'src/app/shared/models/laterality-item.model';

export interface PatientDetailItem {
  title: string;
  content: Array<{ title: string; value: any }>;
  component: any;
}

@Component({
  selector: 'app-patient-detail-accordeon',
  templateUrl: './patient-detail-accordeon.component.html',
  styleUrls: ['./patient-detail-accordeon.component.scss'],
})
export class PatientDetailAccordeonComponent implements OnInit, OnDestroy {
  @Output() openDelegation: EventEmitter<void> = new EventEmitter<void>();
  @Output() refreshPatient: EventEmitter<void> = new EventEmitter<void>();
  @Input() set patient(patient: PatientDetail) {
    this.patientSelected = patient;
    if (this.translateService) {
      this.refreshInformation();
    }
  }

  @ViewChild('btnClose', { static: true }) btnClose: ElementRef;

  patientSelected: PatientDetail;

  public isProfil = false;
  public isPersonalDetail = false;
  public isPractitioner = false;
  public patientItems: PatientDetailItem[];
  public genders = genderItems;
  public lateralities = lateralityItems;
  private subscriptions: Subscription[] = [];
  public patientDelegation: PatientDelegation[] = [];
  public delegateForTest: string[] = [];
  public delegateForResult: string[] = [];
  public isDelegateForTest: boolean = false;
  public isDelegateForResult: boolean = false;

  public delegateBy: string;

  constructor(
    public rolesCheck: RolesCheckService,
    public translateService: TranslateService,
    private patientDataService: PatientDataService,
    private snackBarService: SnackBarService,
    private authService: AuthService,
  ) {
    // this.subscriptions.push(
    //   forkJoin({
    //   hasListAllPatient: this.rolesCheck.hasListAllPatient,
    //   hasManagePatient: this.rolesCheck.hasManagePatient,
    //   hasDelegationResult: this.rolesCheck.hasDelegationResult,
    //   hasDelegationChekup: this.rolesCheck.hasDelegationChekup,
    // }).subscribe(
    //   ({ hasListAllPatient, hasManagePatient, hasDelegationResult, hasDelegationChekup }) => {
    //     if (hasDelegationResult || hasDelegationChekup) {
    //       if (hasManagePatient || hasListAllPatient) {
    //         this.canDelegate = true;
    //       }
    //     }
    //   }));
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public clickOnDelegation(): void {
    this.openDelegation.emit();
  }

  public checkCanDelegate(): boolean {
    let canDelegate: boolean = false;
    if (
      this.authService.hasRoleInstant(Roles.delegationChekup) ||
      this.authService.hasRoleInstant(Roles.delegationResult)
    ) {
      if (
        this.authService.hasRoleInstant(Roles.managePatient) ||
        this.authService.hasRoleInstant(Roles.listAllPatient)
      ) {
        canDelegate = true;
      }
    }
    return canDelegate;
  }

  private getDelegations(): void {
    this.subscriptions.push(
      this.patientDataService.getAllDelegationsForPatient(this.patientSelected.id).subscribe(
        (patientDelegation) => {
          // Assign practitionner delegation
          this.patientDelegation = patientDelegation;
          this.delegateForResult = [];
          this.delegateForTest = [];

          this.patientDelegation.forEach((practitionner) => {
            if (practitionner.type === 'TestDelegation') {
              this.delegateForTest.push(practitionner.practitioner.name);
            } else if (practitionner.type === 'ResultDelegation') {
              this.delegateForResult.push(practitionner.practitioner.name);
            }
          });
        },
        (err) => {
          console.error('PatientDetailAccordeonComponent : Err-Delegation-03 ', err);
          const errMsg = this.translateService.instant('Err-Delegation-03');
          this.snackBarService.show(errMsg, 'danger');
        },
      ),
    );
  }

  private refreshInformation(): void {
    if (this.patientSelected) {
      this.delegateBy = '';
      this.isDelegateForResult = false;
      this.isDelegateForTest = false;

      this.getDelegations();

      if (this.patientSelected.currentPractionerRoles) {
        this.patientSelected.currentPractionerRoles.forEach((role) => {
          if (role === 'ResultDelegation') {
            this.isDelegateForResult = true;
            this.delegateBy = this.patientSelected.practioner.name;
          } else if (role === 'TestDelegation') {
            this.isDelegateForTest = true;
            this.delegateBy = this.patientSelected.practioner.name;
          }
        });
      }

      this.patientItems = [
        {
          title: this.translateService.instant('Patient.Profil.profil'),
          content: [
            { title: this.translateService.instant('Patient.Lastname'), value: this.patientSelected.lastName },
            { title: this.translateService.instant('Patient.Firstname'), value: this.patientSelected.firstName },
            { title: this.translateService.instant('Patient.FolderNumber'), value: this.patientSelected.folderNumber },
            { title: this.translateService.instant('Sexe'), value: this.getGender(this.patientSelected.gender) },
            {
              title: this.translateService.instant('Patient.BirthDate'),
              value: moment(this.patientSelected.birthDate).format('DD/MM/YYYY'),
            },
            {
              title: this.translateService.instant('Patient.YearsOld'),
              value: this.getAge(this.patientSelected.birthDate),
            },
            {
              title: this.translateService.instant('Patient.Profil.laterality'),
              value: this.getLaterality(this.patientSelected.lateralization),
            },
          ],
          component: 'Profil',
        },
        {
          title: this.translateService.instant('Patient.Profil.contact.information'),
          content: [
            {
              title: this.translateService.instant('Patient.Profil.fix_number'),
              value: this.patientSelected.phoneNumber,
            },
            {
              title: this.translateService.instant('Patient.Profil.mobile_number'),
              value: this.patientSelected.mobilePhoneNumber,
            },
            { title: this.translateService.instant('Patient.Email'), value: this.patientSelected.email },
            {
              title: this.translateService.instant('Patient.Profil.postal_address'),
              value: this.patientSelected.address,
            },
          ],
          component: 'Personal',
        },
        {
          title: this.translateService.instant('Patient.Doctor'),
          content: [
            {
              title: this.translateService.instant('Patient.Profil.complete_name'),
              value: this.patientSelected.practioner ? this.patientSelected.practioner.name : '',
            },
            {
              title: this.translateService.instant('Patient.Profil.speciality'),
              value: this.patientSelected.practioner ? this.patientSelected.practioner.speciality : '',
            },
          ],
          component: 'Practitioner',
        },
      ];
    }
  }

  public getGender(genderSelected: string): string {
    let genderLabel: string = '';
    this.genders.forEach((gender) => {
      if (gender.value === genderSelected) {
        genderLabel = gender.text;
      }
    });
    return genderLabel;
  }

  public getLaterality(lateralitySelected: string): string {
    let lateralityLabel: string = '';
    this.lateralities.forEach((laterality) => {
      if (laterality.value === lateralitySelected) {
        lateralityLabel = laterality.text;
      }
    });
    return lateralityLabel;
  }

  public modify(component): void {
    this.isPersonalDetail = false;
    this.isProfil = false;
    this.isPractitioner = false;

    if (component === 'Personal') {
      this.isPersonalDetail = true;
    } else if (component === 'Profil') {
      this.isProfil = true;
    } else if (component === 'Practitioner') {
      this.isPractitioner = true;
    }
  }

  public getAge(birthdate): string {
    const years: number = moment().diff(birthdate, 'years');
    if (years >= 0) return years.toString() + ' ans';
    return '-';
  }

  public onCloseModal(): void {
    this.subscriptions.push(
      this.patientDataService.getPatientById(this.patientSelected.id).subscribe((patient) => {
        //console.log("onCloseModal this.patientSelected.id ", this.patientSelected.id);
        this.patientSelected = patient;
        //comment because it create problem in return patient
        //this.refreshPatient.emit();
        this.refreshInformation();
      }),
    );
    this.btnClose.nativeElement.click();
  }

  public closeModal(): void {
    this.isPersonalDetail = false;
    this.isProfil = false;
    this.isPractitioner = false;
  }
}
