import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AssessmentItem } from '../walk-assessment-item.model';
import { Options } from '@angular-slider/ngx-slider';
import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-walk-assessment-item',
  templateUrl: './walk-assessment-item.component.html',
  styleUrls: ['./walk-assessment-item.component.scss'],
})
export class WalkAssessmentItemComponent implements OnInit, OnChanges {
  @Input() item: AssessmentItem;
  @Input() itemToCompare: AssessmentItem;

  public minValue: number = 0;
  public highValue: number;
  public options: Options;

  public title: string;
  public unit: string;
  public value: string;
  public min: number;
  public max: number;
  public step: number;
  public sliderValue: number;
  public finalValue: string;
  public isOffLimit: boolean = false;

  constructor() {}

  ngOnChanges() {
    this.ngOnInit();
  }

  ngOnInit() {
    if (this.item) {
      this.title = this.item.title;
      this.unit = this.item.unit;
      this.min = this.item.min;
      this.max = this.item.max;
      const value = this.item.value;
      if (this.item.title == i18nKey('app.walk.assessment.walkingSymmetry')) this.step = 1;
      else this.step = this.item.max;
      this.value = parseFloat(value).toFixed(3);
      this.finalValue = this.value.toString() + this.unit;
      if (this.item.value < this.item.min || this.item.value > this.item.max) {
        this.isOffLimit = true;
      }
    }
    /*
    if (this.item) {
      this.title = this.item.title;
      this.unit = this.item.unit;
      this.min = this.item.min;
      this.max = this.item.max;
      const value = this.item.value;
      this.value = parseFloat(value).toFixed(3);
      this.finalValue = this.value.toString() + this.unit;
      console.log("this.item.value is ", this.item.value);
      this.options = {
        floor: this.item.min,
        ceil: this.item.max,
        showTicksValues: (this.item.title == i18nKey('app.walk.assessment.walkingSymmetry')),
        showSelectionBar: true,
        readOnly: true,
        stepsArray: [
          { value: this.item.min},
          { value: 0, legend: "0" },
          { value: this.item.value },
          { value: this.item.max },
        ],
        translate: (value: number): string => {
          if (value == this.item.min) {
            return value + '';
          } else if (value == this.item.max) {
            return value + '';
          } else if (value == this.item.value) {
              return value + ' ' + this.unit;
          } else {
            return '';
          }
        },
        getPointerColor: (value: number): string => {
          if (value == this.item.value) {
            return 'orange';
          }
          return '#ffe4d1';
        },
      };
    }*/
    if (this.itemToCompare) {
      this.minValue = this.item.value > this.itemToCompare.value ? this.itemToCompare.value : this.item.value;
      this.highValue = this.item.value < this.itemToCompare.value ? this.itemToCompare.value : this.item.value;
      this.options = {
        floor: this.item.min,
        ceil: this.item.max,
        showSelectionBar: true,
        step: 0.001,
        readOnly: true,
        translate: (value: number): string => {
          if (value == this.min) {
            return value + '';
          } else if (value == this.max) {
            return value + '';
          } else {
            return '';
          }
        },
        getPointerColor: (value: number): string => {
          if (value == this.itemToCompare.value) {
            return 'red';
          }
          if (value == this.item.value) {
            return 'green';
          }
          return '#ffe4d1';
        },
      };
    }
  }
}
