/// <reference types="web-bluetooth" />

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, interval } from 'rxjs';
import { GripState } from './grip-state.model';
import * as bluetoothConst from 'src/app/tools/bluetooth.device.constants';
import { CookieService } from 'ngx-cookie-service';
import { switchMap, share, startWith, shareReplay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from 'src/app/services/snack-bar.service';

const GripMeasureMultiplier = bluetoothConst.GRIP_MEASUREMENT_MULTIPLIER_DENOMINATOR;
let bleCharacteristic = null;

@Component({
  selector: 'app-grip-monitor',
  templateUrl: 'grip-monitor.component.html',
  providers: [CookieService],
})
export class GripMonitorComponent implements OnInit, OnDestroy {
  @Input() config: boolean;
  value: string | number = '--';
  device: any = {};
  public gripState: GripState;
  public finishReadData: boolean = false;
  public lastCheckout: Date;
  public ArrayValuees: number[];
  public ArrayTimestamp: number[];
  public baseLine: number = 0;
  public coefMeasure: number = 0;
  public ArrayBaseLine: number[];
  public deviceName: string;
  public gripV2: boolean = false;
  public gripV3: boolean = false;

  subject$ = new Subject();
  public measures = this.subject$ as Observable<string>;

  public firstPacket: boolean = false;
  public request = '';
  public stopscanning: boolean = false;
  public deviceExist: boolean = false;
  public timerValue: number = 0;
  interval: any;

  constructor(private translateService: TranslateService, private snackBarService: SnackBarService) {}

  startTimer() {
    this.interval = setInterval(() => {
      this.timerValue++;
    }, 1000); // Augmente timerValue toutes les secondes
  }

  stopTimer() {
    this.timerValue = 0;
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  async connectGripDevice() {
    console.log('start connection device');
    try {
      this.device = await navigator.bluetooth.requestDevice({
        filters: [
          {
            namePrefix: bluetoothConst.GripPrefixe,
          },
        ],
        optionalServices: [
          bluetoothConst.GripUIServiceBattery,
          bluetoothConst.GripUIServiceData,
          bluetoothConst.GripUIV2Service,
        ],
      });
      await this.connectToBluetoothGrip();
    } catch (err) {
      console.error('Connection failed' + err);
    }
  }

  getSupportedProperties(characteristic) {
    let supportedProperties = [];
    for (const p in characteristic.properties) {
      if (characteristic.properties[p] === true) {
        supportedProperties.push(p.toUpperCase());
      }
    }
    return '[' + supportedProperties.join(', ') + ']';
  }

  async scanBlutoothGrip() {
    console.log('Requesting any Bluetooth Device...');
    navigator.bluetooth
      .getDevices()
      .then(async (devices) => {
        console.log('devices list is ', devices);
        for (const device of devices) {
          if (device.name.includes(bluetoothConst.GripPrefixe)) {
            this.startTimer();
            console.log('device.name ', device);
            this.deviceExist = true;
            this.device = await device;
            await console.log('Watching advertisements from "' + device.name + '"...');
            await this.device.watchAdvertisements().then(() => {
              this.connectToBluetoothGrip();
            });
          }
        }
      })
      .then(() => {
        if (!this.deviceExist) {
          console.log('GripMonitorComponent - scanBlutoothGrip: Err-BLE-06 ');
          const errMsg = this.translateService.instant('Err-BLE-06');
          this.snackBarService.show(errMsg, 'danger');
        }
      })
      .catch((error) => {
        console.log('GripMonitorComponent: error in scanBlutoothGrip : ', error);
        /*const errMsg = this.translateService.instant('Err-BLE-06');
        this.snackBarService.show(errMsg, 'danger');*/
      });
    /*
      .finally(() => {
        console.log('GripMonitorComponent: Finally je reconnecte');
        if (!this.stopscanning) this.scanBlutoothGrip();
      });*/
    shareReplay(1);
  }

  onForgetBluetoothDeviceButtonClick() {
    navigator.bluetooth
      .getDevices()
      .then((devices) => {
        for (const device of devices) {
          if (device.name.includes(bluetoothConst.GripPrefixe)) {
            if (!device) {
              throw new Error('No Bluetooth device to forget');
            }
            console.log('Forgetting ' + device.name + 'Bluetooth device...');
            return device.forget();
          }
        }
      })
      .then(() => {
        console.log('  > Bluetooth device has been forgotten.');
      })
      .catch((error) => {
        console.log('Argh1! ' + error);
      });
  }

  ngOnDestroy() {
    this.stopscanning = true;
    this.stopTimer();

    try {
      if (this.device && this.device.gatt) this.device.gatt.disconnect();
    } catch (e) {
      console.log(`disconnect. gatt disconnect`, e);
    } finally {
      this.device = undefined;
    }
  }

  async connectToBluetoothGrip() {
    await this.device.gatt
      .connect()
      .then((server) => {
        // Note that we could also get all services that match a specific UUID by
        // passing it to getPrimaryServices().
        console.log('connectToBluetoothGrip : Getting Services...');
        return server.getPrimaryServices();
      })
      .then((services) => {
        console.log('connectToBluetoothGrip : Getting Services...');
        services.forEach((service) => {
          console.log('connectToBluetoothGrip : get service uuid...', service.uuid);
          this.gripV2 = service.uuid === bluetoothConst.GripUIV2Service;
          console.log('connectToBluetoothGrip : this is a version 2 ', this.gripV2);
        });
      })
      .then(() => {
        if (this.gripV2) this.connectToBluetoothGripV2();
        else this.connectToBluetoothGripV3();
        this.stopTimer();
      })
      .catch((error) => {
        console.log('this.timerValue is ', this.timerValue);
        if (this.timerValue > 5) {
          console.log('GripMonitorComponent -connectToBluetoothGrip : Err-BLE-07 : ', error);
          const errMsg = this.translateService.instant('Err-BLE-07');
          this.snackBarService.show(errMsg, 'danger');
        }
      })
      .finally(() => {
        console.log('GripMonitorComponent - connectToBluetoothGrip : Finally je reconnecte');
        if (!this.stopscanning) this.connectToBluetoothGrip();
      });
  }

  async connectToBluetoothGripV2() {
    console.log('call connectToBluetoothGripV2');
    this.stopscanning = true;
    try {
      await this.device.gatt.connect();
      console.log('call connectToBluetoothGrip V2 : this.device.gatt.connect();');
      const service = await this.device.gatt.getPrimaryService(bluetoothConst.GripUIV2Service);
      this.stopscanning = await true;
      console.log('call connectToBluetoothGrip V2 : service; : ', service);
      this.device.addEventListener('gattserverdisconnected', this.verifConnectionDevice.bind(this));
      console.log('call connectToBluetoothGrip V2 : addEventListener; : ', service);
      bleCharacteristic = await service.getCharacteristic(bluetoothConst.GripV2Characteristic);
      await console.log('call connectToBluetoothGrip V2 : bleCharacteristic; : ', bleCharacteristic);
      await this.sleep(500);
      await bleCharacteristic.addEventListener('characteristicvaluechanged', this.handleValueChangedV2.bind(this));
      await this.sleep(500);
      await bleCharacteristic.startNotifications();
      await this.sleep(500);
      await this.setDefautPackageV2();
      await this.sleep(500);
      await this.getBatteryLevel();
      await this.sleep(2000);
      this.gripState.gripConnected = await true;
      this.gripState.characteristic = await bleCharacteristic;
      await this.startMeasureV2();
    } catch (error) {
      console.log('GripMonitorComponent-connectToBluetoothGripV2 ', error);
      const errMsg = this.translateService.instant('Err-BLE-07');
      this.snackBarService.show(errMsg, 'danger');
    } finally {
      console.log('GripMonitorComponent-connectToBluetoothGripV2 : Finally je reconnecte V2');
      if (!this.gripState.gripConnected) this.connectToBluetoothGripV2();
    }
  }

  async connectToBluetoothGripV3() {
    console.log('call connectToBluetoothGripV3');
    this.stopscanning = true;
    await this.device.gatt
      .connect()
      .then((server) => {
        console.log('Getting V3 : server...', server);
        return server.getPrimaryService(bluetoothConst.GripUIServiceBattery);
      })
      .then((service) => {
        console.log('Getting Battery Level Characteristic...');
        this.stopscanning = true;
        return service.getCharacteristic(bluetoothConst.GripCharacteristicBattery);
      })
      .then((characteristic) => {
        console.log('Reading Battery Level...');
        return characteristic.readValue();
      })
      .then((value) => {
        this.gripState.batteryLevel = value.getUint8(0);
        console.log('> Battery Level is ' + this.gripState.batteryLevel + '%');
        this.gripState.gripConnected = true;
      })
      .then(() => {
        this.startMeasure();
      })
      .catch((error) => {
        console.log('GripMonitorComponent-connectToBluetoothGripV3 ', error);
        const errMsg = this.translateService.instant('Err-BLE-07');
        this.snackBarService.show(errMsg, 'danger');
      })
      .finally(() => {
        console.log('GripMonitorComponent : Finally je reconnecte V3');
        if (!this.gripState.gripConnected) this.connectToBluetoothGripV3();
      });
  }

  ngOnInit() {
    this.gripState = {
      acquisitionActive: false,
      characteristic: undefined,
      nbPoints: 0,
      gripConnected: false,
      hand: ['LEFT', 'RIGHT'],
      batteryLevel: 0,
      gripCalibrated: false,
    };
    if (!this.config) this.scanBlutoothGrip();
    interval(2000).pipe(
      startWith(0),
      switchMap(() => this.scanBlutoothGrip()),
      share(),
    );
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  getConnectionStatus() {
    return this.gripState.gripConnected;
  }

  verifConnectionDevice(event) {
    const device = event.target.value;
    if (!device) {
      this.gripState.gripConnected = false;
    }
  }

  async startMeasure() {
    console.log('start measure by getting data ');
    this.ArrayBaseLine = [];
    const service = await this.device.gatt.getPrimaryService(bluetoothConst.GripUIServiceData);
    console.log('service date is ', service);
    this.device.addEventListener('gattserverdisconnected', this.verifConnectionDevice.bind(this));
    console.log('add event listner on service');
    bleCharacteristic = await service.getCharacteristic(bluetoothConst.GripCharacteristicData);
    console.log('bleCharacteristic data is ', bleCharacteristic);
    await this.sleep(500);
    await bleCharacteristic.addEventListener('characteristicvaluechanged', this.handleValueChanged.bind(this));
    console.log('add event listner on characteristic');
    await this.sleep(500);
    await bleCharacteristic.startNotifications();
    console.log('startNotifications');
    await this.getBaseline();
  }

  async startMeasureV2() {
    this.ArrayBaseLine = [];
    var string = '0x56';
    var bytestoWrite = new Uint8Array(1);
    let value = parseInt(string.substr(2, 2), 16);
    bytestoWrite[0] = value;
    await bleCharacteristic.writeValue(bytestoWrite);
    await this.sleep(600);
    await this.getBaseline();
  }

  async getBaseline() {
    this.request = 'GET_BASELINE';
    console.log('start measure by getting baseline ');
    this.ArrayValuees = [];
    this.ArrayBaseLine = [];
    if (this.gripV2) this.startSamplingv2();
    else this.startSampling();
    this.lastCheckout = new Date();
  }

  async getValue() {
    this.request = 'GET_MEASURE';
    console.log('start measure by getting baseline ');
    this.ArrayValuees = [];
    this.ArrayTimestamp = [];
    if (this.gripV2) this.startSamplingv2();
    else this.startSampling();
    this.lastCheckout = new Date();
  }

  async startSampling() {
    const service = await this.device.gatt.getPrimaryService(bluetoothConst.GripUIServiceData);
    bleCharacteristic = await service.getCharacteristic(bluetoothConst.GripCharacteristicStartDataMeasure);
    var string = '0X000000000';
    var bytestoWrite = new Uint8Array(8);
    let value = parseInt(string.substr(2, 2), 16);
    let i: number;
    for (i = 0; i < 7; i++) bytestoWrite[i] = 0;
    bleCharacteristic.writeValue(bytestoWrite);
  }

  async stopSampling() {
    const service = await this.device.gatt.getPrimaryService(bluetoothConst.GripUIServiceData);
    bleCharacteristic = await service.getCharacteristic(bluetoothConst.GripCharacteristicStopDataMeasure);
    var string = '0X000000000';
    var bytestoWrite = new Uint8Array(8);
    let value = parseInt(string.substr(2, 2), 16);
    let i: number;
    for (i = 0; i < 7; i++) bytestoWrite[i] = 0;
    bleCharacteristic.writeValue(bytestoWrite);
  }

  handleValueChangedV2(event) {
    const value: DataView = event.target.value;
    console.log('data view from handleValueChanged V2 ', value, ' this.request ', this.request);
    if (this.request == 'GET_BATTERY_LEVEL') {
      //console.log('data view from handleBatteryChanged ', value);
      const batRawVal = value.getInt8(9) * 256 + value.getInt8(10);
      let voltage = 154 * (4095 - batRawVal);
      voltage = voltage / 1000;
      console.log('voltage battery is ', voltage);
      if (voltage > 420) this.gripState.batteryLevel = 100;
      else if (voltage < 340) this.gripState.batteryLevel = 0;
      else {
        const index = 420 - voltage;
        this.gripState.batteryLevel = bluetoothConst.GRIP_BATTERY[Math.floor(index)];
      }
      console.log('this.gripState.batteryLevel ', this.gripState.batteryLevel);
      this.stopSamplingv2();
      this.request = '';
    } else if (this.request == 'GET_BASELINE' || this.request == 'GET_MEASURE') {
      let dateTime = new Date();
      const nbMSecMeasure = bluetoothConst.GRIP_TIME_MEASURE * 1000;
      const nbMsecBaseline = bluetoothConst.GRIP_TIME_BASELINE * 1000;
      const nbMSec = this.request == 'GET_BASELINE' ? nbMsecBaseline : nbMSecMeasure;
      if (dateTime.getTime() - this.lastCheckout.getTime() <= nbMSec) {
        if (bleCharacteristic) {
          let measureVal = value.getInt8(5) * 256 * 256 + value.getInt8(6) * 256 + value.getInt8(7);
          measureVal = measureVal / 10000;
          if (this.request == 'GET_BASELINE') {
            measureVal = parseFloat(measureVal.toFixed(2));
            this.ArrayBaseLine.push(measureVal);
          } else {
            measureVal = parseFloat((measureVal - this.baseLine).toFixed(2));
            let timestampVal = value.getInt8(3) * 256 + value.getInt8(4);
            timestampVal = parseFloat(timestampVal.toFixed(2));
            this.subject$.next(measureVal + '|' + timestampVal);
            this.ArrayValuees.push(measureVal);
            this.ArrayTimestamp.push(timestampVal);
          }
        } else {
          console.log('characteristic is null : device disconnected');
        }
      } else {
        if (this.request == 'GET_BASELINE') {
          this.baseLine = this.ArrayBaseLine.reduce((a, b) => a + b, 0) / this.ArrayBaseLine.length;
          console.log('this.baseline is ', this.baseLine);
          this.gripState.gripCalibrated = true;
        }
        this.request = '';
        this.subject$.complete();
        this.stopSamplingv2();
        //bleCharacteristic.stopNotifications();
        //create a new subject for the next measure
        this.subject$.unsubscribe();
        this.subject$ = new Subject();
        this.measures = this.subject$ as Observable<string>;
      }
    }
  }

  setDefautPackageV2() {
    var string = '0x76';
    var bytestoWrite = new Uint8Array(1);
    let value = parseInt(string.substr(2, 2), 16);
    bytestoWrite[0] = value;
    bleCharacteristic.writeValue(bytestoWrite);
  }

  stopSamplingv2() {
    var string = '0x10';
    var bytestoWrite = new Uint8Array(1);
    let value = parseInt(string.substr(2, 2), 16);
    bytestoWrite[0] = value;
    bleCharacteristic.writeValue(bytestoWrite);
  }

  startSamplingv2() {
    var string = '0x11';
    var bytestoWrite = new Uint8Array(1);
    let value = parseInt(string.substr(2, 2), 16);
    bytestoWrite[0] = value;
    console.log('battery startSampling : write value ', bytestoWrite);
    bleCharacteristic.writeValue(bytestoWrite);
  }

  handleValueChanged(event) {
    const value: DataView = event.target.value;
    console.log('data view from handleValueChanged ', value, ' this.request ', this.request);
    if ((this.request == 'GET_BASELINE' || this.request == 'GET_MEASURE') && value.byteLength < 20) {
      let dateTime = new Date();
      const nbMSecMeasure = bluetoothConst.GRIP_TIME_MEASURE * 1000;
      const nbMsecBaseline = bluetoothConst.GRIP_TIME_BASELINE * 1000;
      const nbMSec = this.request == 'GET_BASELINE' ? nbMsecBaseline : nbMSecMeasure;
      if (dateTime.getTime() - this.lastCheckout.getTime() <= nbMSec) {
        if (bleCharacteristic) {
          let measureVal = value.getInt8(6) * 256 * 256 + value.getInt8(5) * 256 + value.getInt8(4);
          let measureVal1 = value.getInt8(9) * 256 * 256 + value.getInt8(8) * 256 + value.getInt8(7);
          let measureVal2 = value.getInt8(12) * 256 * 256 + value.getInt8(11) * 256 + value.getInt8(10);
          let measureVal3 = value.getInt8(15) * 256 * 256 + value.getInt8(14) * 256 + value.getInt8(13);
          let measureVal4 = value.getInt8(18) * 256 * 256 + value.getInt8(17) * 256 + value.getInt8(16);
          //measureVal = measureVal / 10000;
          console.log('measureVal is ', measureVal, ' measureVal ', measureVal2);
          if (this.request == 'GET_BASELINE') {
            this.ArrayBaseLine.push(parseFloat(measureVal.toFixed(2)));
            this.ArrayBaseLine.push(parseFloat(measureVal1.toFixed(2)));
            this.ArrayBaseLine.push(parseFloat(measureVal2.toFixed(2)));
            this.ArrayBaseLine.push(parseFloat(measureVal3.toFixed(2)));
            this.ArrayBaseLine.push(parseFloat(measureVal4.toFixed(2)));
          } else {
            this.ArrayValuees.push(parseFloat(((measureVal - this.baseLine) / 10000).toFixed(2)));
            this.ArrayValuees.push(parseFloat(((measureVal1 - this.baseLine) / 10000).toFixed(2)));
            this.ArrayValuees.push(parseFloat(((measureVal2 - this.baseLine) / 10000).toFixed(2)));
            this.ArrayValuees.push(parseFloat(((measureVal3 - this.baseLine) / 10000).toFixed(2)));
            this.ArrayValuees.push(parseFloat(((measureVal4 - this.baseLine) / 10000).toFixed(2)));
            let timestampVal =
              value.getInt8(0) * 256 * 256 * 256 +
              value.getInt8(1) * 256 * 256 +
              value.getInt8(2) * 256 +
              value.getInt8(3);
            timestampVal = parseFloat((timestampVal / 10000).toFixed(2));
            this.subject$.next((measureVal - this.baseLine) / 10000 + '|' + timestampVal);
            this.subject$.next((measureVal1 - this.baseLine) / 10000 + '|' + timestampVal);
            this.subject$.next((measureVal2 - this.baseLine) / 10000 + '|' + timestampVal);
            this.subject$.next((measureVal3 - this.baseLine) / 10000 + '|' + timestampVal);
            this.subject$.next((measureVal4 - this.baseLine) / 10000 + '|' + timestampVal);
            this.ArrayTimestamp.push(timestampVal);
          }
        } else {
          console.log('characteristic is null : device disconnected');
        }
      } else {
        if (this.request == 'GET_BASELINE') {
          this.baseLine = this.ArrayBaseLine.reduce((a, b) => a + b, 0) / this.ArrayBaseLine.length;
          console.log('this.baseline is ', this.baseLine);
          this.gripState.gripCalibrated = true;
        }
        this.request = '';
        this.subject$.complete();
        this.stopSampling();
        //create a new subject for the next measure
        this.subject$.unsubscribe();
        this.subject$ = new Subject();
        this.measures = this.subject$ as Observable<string>;
      }
    }
  }

  async getBatteryLevel() {
    this.request = 'GET_BATTERY_LEVEL';
    await this.startSamplingv2();
  }
}
