import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterDateRange',
})
export class FilterDateRangePipe implements PipeTransform {
  transform(row: any, f1: Date, f2?: Date): any {
    f1 == null ? (f1 = new Date('1995-12-25T11:30:00.000Z')) : f1;
    f2 == null ? (f2 = new Date()) : f2;
    if (f1 == null || f2 == null || f1 >= f2) {
      return row;
    }
    return row.filter((x) => {
      return new Date(x.date) >= new Date(f1) && new Date(x.date) <= new Date(f2);
    });
  }
}
