import { Injectable } from '@angular/core';
import { RemoteInteraction } from './remote-interaction.service';
import { AnalysisResults } from '../models/analysis-results.model';
import { INDICATORS } from '../models/subindicators.model';
import { ComparisonGraph } from 'src/app/modules/common/models/comparison.model';

@Injectable()
export class RombergComparisonService {
  public results: AnalysisResults;

  constructor(private remoteInteraction: RemoteInteraction) {
    // empty
  }

  public async getAllGraph(subindicator: string, results: AnalysisResults): Promise<ComparisonGraph[]> {
    return Promise.all([
      this.getSurfaceYF(subindicator, results),
      this.getSurfaceYO(subindicator, results),
      /* no score models
      this.getScoreYO(subindicator, results),
      this.getScoreYF(subindicator, results), */
      this.getDensityYO(subindicator, results),
      this.getDensityYF(subindicator, results),
      this.getVarianceLateraleYO(subindicator, results),
      this.getVarianceLateraleYF(subindicator, results),
      this.getSpeedMoyYO(subindicator, results),
      this.getSpeedMoyYF(subindicator, results),
    ]);
  }

  private transformUrl(url: string) {
    //return url.replace('https://', 'http://');
    return url;
  }

  private async getSurfaceYF(subIndicator: string, results: AnalysisResults): Promise<ComparisonGraph> {
    const datas = await this.remoteInteraction.getJsonModel(
      this.transformUrl(results.models[INDICATORS.surfaceYF + '-' + subIndicator]),
    );
    return this.createComparisonGraph(datas, results.SurfaceYF, 'Checkup.Romberg.Summary.SurfaceYF');
  }

  private async getSurfaceYO(subIndicator: string, results: AnalysisResults): Promise<ComparisonGraph> {
    const datas = await this.remoteInteraction.getJsonModel(
      this.transformUrl(results.models[INDICATORS.surfaceYO + '-' + subIndicator]),
    );
    return this.createComparisonGraph(datas, results.SurfaceYO, 'Checkup.Romberg.Summary.SurfaceYO');
  }
  private async getDensityYO(subIndicator: string, results: AnalysisResults): Promise<ComparisonGraph> {
    const datas = await this.remoteInteraction.getJsonModel(
      this.transformUrl(results.models[INDICATORS.swayDensityYO + '-' + subIndicator]),
    );
    return this.createComparisonGraph(datas, results.SwayDensityYO, 'Checkup.Romberg.Summary.SwayDensityYO');
  }
  private async getDensityYF(subIndicator: string, results: AnalysisResults): Promise<ComparisonGraph> {
    const datas = await this.remoteInteraction.getJsonModel(
      this.transformUrl(results.models[INDICATORS.swayDensityYF + '-' + subIndicator]),
    );
    return this.createComparisonGraph(datas, results.SwayDensityYF, 'Checkup.Romberg.Summary.SwayDensityYF');
  }
  private async getVarianceLateraleYO(subIndicator: string, results: AnalysisResults): Promise<ComparisonGraph> {
    const datas = await this.remoteInteraction.getJsonModel(
      this.transformUrl(results.models[INDICATORS.varianceLateraleYO + '-' + subIndicator]),
    );
    return this.createComparisonGraph(datas, results.VarianceLateraleYO, 'Checkup.Romberg.Summary.VarianceLateraleYO');
  }
  private async getVarianceLateraleYF(subIndicator: string, results: AnalysisResults): Promise<ComparisonGraph> {
    const datas = await this.remoteInteraction.getJsonModel(
      this.transformUrl(results.models[INDICATORS.varianceLateraleYF + '-' + subIndicator]),
    );
    return this.createComparisonGraph(datas, results.VarianceLateraleYF, 'Checkup.Romberg.Summary.VarianceLateraleYF');
  }
  private async getSpeedMoyYO(subIndicator: string, results: AnalysisResults): Promise<ComparisonGraph> {
    const datas = await this.remoteInteraction.getJsonModel(
      this.transformUrl(results.models[INDICATORS.vitessemoyenneYO + '-' + subIndicator]),
    );
    return this.createComparisonGraph(datas, results.SpeedMoyYO, 'Checkup.Romberg.Summary.SpeedMoyYO');
  }
  private async getSpeedMoyYF(subIndicator: string, results: AnalysisResults): Promise<ComparisonGraph> {
    const datas = await this.remoteInteraction.getJsonModel(
      this.transformUrl(results.models[INDICATORS.vitessemoyenneYF + '-' + subIndicator]),
    );
    return this.createComparisonGraph(datas, results.SpeedMoyYF, 'Checkup.Romberg.Summary.SpeedMoyYF');
  }
  private async getScoreYO(subIndicator: string, results: AnalysisResults): Promise<ComparisonGraph> {
    const datas = await this.remoteInteraction.getJsonModel(
      this.transformUrl(results.models[INDICATORS.scoreYO + '-' + subIndicator]),
    );
    return this.createComparisonGraph(datas, results.ScoreYO, 'Checkup.Romberg.Summary.ScoreYO');
  }
  private async getScoreYF(subIndicator: string, results: AnalysisResults): Promise<ComparisonGraph> {
    const datas = await this.remoteInteraction.getJsonModel(
      this.transformUrl(results.models[INDICATORS.scoreYF + '-' + subIndicator]),
    );
    return this.createComparisonGraph(datas, results.ScoreYF, 'Checkup.Romberg.Summary.ScoreYF');
  }

  private createComparisonGraph(datas: any, localstat: number, title: string) {
    const comparisonGraph = new ComparisonGraph();
    const dataset = this.transformJsonToArrayDesired(datas);
    dataset.push({ x: this.getMax(dataset) + 1, y: localstat, current: true, toCompare: false });
    comparisonGraph.graph = dataset;
    comparisonGraph.title = title;
    return comparisonGraph;
  }

  private transformJsonToArrayDesired(
    datas: number[][],
  ): Array<{ x: number; y: number; current: boolean; toCompare: boolean }> {
    return datas.map((data) => ({ x: data[1], y: data[2], current: false, toCompare: false }));
  }

  private getMax(datas: Array<{ x: number }>) {
    return Math.max(...datas.map((data) => data.x));
  }
}
