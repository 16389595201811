import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-walk-tutorial',
  templateUrl: './walk-tutorial.component.html',
  styleUrls: ['./walk-tutorial.component.scss'],
})
export class WalkTutorialComponent implements OnInit {
  public firstStep: boolean;

  constructor() {
    this.firstStep = true;
  }

  ngOnInit() {}

  public reset() {
    this.firstStep = true;
  }

  public goToNextStep(): void {
    this.firstStep = false;
  }

  public goToPreviousStep(): void {
    this.firstStep = true;
  }
}
