import { Component, OnInit, ViewChild, ElementRef, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Timer } from 'src/app/modules/time';
import { State } from '../shared/models/state.model';
import { WiiBoardService } from 'src/app/modules/force-platform';
import { NotificationService } from 'src/app/modules/core/services/notification/notification.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import {
  Course,
  CourseControllerService,
  NewWeighing,
  PatientDetail,
  UpdatePatient,
  Weighing,
  WeighingControllerService,
} from '@abilycare/dal-client';
import { WeightTutorialComponent } from './weight-tutorial/weight-tutorial.component';
import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import * as medicalConst from 'src/app/tools/medical.constants';
import { Location } from '@angular/common';
import { PatientDataService } from 'src/app/modules/abilycare-data-access/api/patient-data.service';
import { InformationConnexionComponent } from 'src/app/view/information-connexion/information-connexion.component';

@Component({
  selector: 'app-fried-weight',
  templateUrl: './weight.component.html',
  styleUrls: ['./weight.component.scss'],
  // styleUrls: ['./weight.component.scss', '../shared/scss/common.scss'],
  providers: [WiiBoardService],
})
export class WeightComponent implements OnInit {
  @ViewChild('btnClose', { static: true }) btnClose: ElementRef;
  @ViewChild('weightTutorial', { static: true }) weightTutorial: WeightTutorialComponent;
  @ViewChild('btnOpenQuit', { static: true }) btnOpenQuit: ElementRef;
  @ViewChild('btnQuitBeforeSave', { static: true }) btnQuitBeforeSave: ElementRef;
  @ViewChild('plateMonitor', { static: true }) plateMonitor: InformationConnexionComponent;

  public wiiConnected: boolean;
  public KinventConnected: boolean;
  public weight: number;
  public finalWeight: number;
  public tare: number;
  public loadingWeight: boolean;
  public weightCalculated: boolean;
  public weighingSaved: Weighing;
  /** timer */
  public timer: Timer = new Timer(medicalConst.TIME_TO_WEIGHING);
  public intervalWeight;

  public readonly boardReady: Observable<boolean>;

  public patient: PatientDetail;

  private state: State = new State();

  public returnTitle: string = i18nKey('Checkup.navbar.patient.back');
  public testName: string = i18nKey('navbar.massBalance');

  public newPage: string = '';
  public course: any;

  /** implemnting the gauge chart */
  gaugeOptions = {
    hasNeedle: true,
    needleColor: 'gray',
    needleUpdateSpeed: 1000,
    arcColors: ['rgb(44, 151, 222)', 'lightgray'],
    arcDelimiters: [30],
    rangeLabel: ['0', '200'],
    centralLabel: '90',
  };

  constructor(
    private wiiBoardService: WiiBoardService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private dataService: DataProviderService,
    private router: Router,
    private weightService: WeighingControllerService,
    private snackBarService: SnackBarService,
    private location: Location,
    private courseDataService: CourseControllerService,
    private patientDataService: PatientDataService,
  ) {
    this.wiiConnected = false;
    this.loadingWeight = false;
    this.weightCalculated = false;
    this.boardReady = this.wiiBoardService.wiiState.pipe(map((state) => state && state.wiiBoardConnected));
  }

  ngOnInit() {
    this.course = this.location.getState();
    this.dataService.storeHeaderShowed(false);
    this.patient = this.dataService.retrievePatient();
    this.weight = 0.0;
    this.finalWeight = 0.0;
    this.wiiBoardService.wiiBoardGetStatus().then((value) => {
      this.wiiConnected = value;
    });
  }

  // On click on another page, we check if test is started and display message
  public canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.loadingWeight) {
      this.btnOpenQuit.nativeElement.click();
      return false;
    }

    if (this.weightCalculated) {
      this.btnQuitBeforeSave.nativeElement.click();
      return false;
    }
    return true;
  }

  @HostListener('window:unload', ['$event'])
  public unloadHandler(event): void {
    if (this.loadingWeight) {
      this.stop();
    }
  }

  // On click on navigator button (before reload or quit), we check if test is started and display message
  @HostListener('window:beforeunload', ['$event'])
  public beforeUnloadHander(event: Event): boolean {
    if (this.loadingWeight) {
      this.btnOpenQuit.nativeElement.click();
      return false;
    }
    if (this.weightCalculated) {
      this.btnQuitBeforeSave.nativeElement.click();
      return false;
    }
    return true;
  }

  /**
   * Get Weight from wiiboard service.
   */
  public getWeight(): void {
    if (this.plateMonitor.KinventConnected) {
      this.getWeightFromKIvent();
    } else {
      this.wiiBoardService.wiiBoardGetStatus().then((value) => {
        this.wiiConnected = value;
        if (this.wiiConnected) {
          this.getWeightFromWii();
        }
      });
    }
  }

  private async getWeightFromKIvent() {
    let weights = [];
    this.loadingWeight = true;
    this.plateMonitor.getValue();
    this.timer.startReverse();
    await this.plateMonitor.measures.subscribe({
      next: (value) => {
        console.log('value from getWeightFromKIvent ', value);
        this.weight = parseFloat(value);
        weights.push(this.weight);
      },
      complete: () => {
        console.log('complete getting weight from measures');
        this.loadingWeight = false;
        this.weightCalculated = true;
        //console.log("weighs weight ", weights);
        this.weight = weights.reduce((p, c) => p + c, 0) / weights.length;
        //this.weight = this.getMax(weights);
        this.computeWeight();
      },
    });
  }

  private getMax(datas: Array<number>) {
    let max: number = 0;
    datas.forEach((value: number) => {
      if (value > max) max = value;
    });
    return max;
  }

  private getWeightFromWii(): void {
    this.backward();
    this.loadingWeight = true;
    this.wiiBoardService.wiiBoardGetWeight().then(
      (resp) => {
        if (isNaN(resp)) {
          this.notificationService.pushErrorNotifications(this.translateService.instant('wiiBoardNotConnected'));
          this.wiiBoardService.wiiBoardGetStatus().then((value) => {
            this.wiiConnected = value;
          });
          console.log('getWeightFromWii : isNaN');
        } else if (resp === 0) {
          this.notificationService.pushErrorNotifications(this.translateService.instant('wiiBoardAcquistionError'));
          this.wiiBoardService.wiiBoardGetStatus().then((value) => {
            this.wiiConnected = value;
          });
          console.log('getWeightFromWii : 0');
        } else {
          const respNumber: number = +resp.toFixed(1);
          this.weight = respNumber;
          this.computeWeight();
          this.weightCalculated = true;
        }
        this.loadingWeight = false;
        this.stop();
      },
      (err) => {
        this.wiiBoardService.wiiBoardGetStatus().then((value) => {
          this.wiiConnected = value;
        });
        this.weight = err;
        this.loadingWeight = false;
        this.notificationService.pushErrorNotifications(this.translateService.instant('wiiBoardNotConnected'));
        this.stop();
      },
    );
    this.timer.startReverse();
  }

  public computeWeight(): void {
    if (this.tare < 0) {
      this.tare = 0;
    }
    if (this.tare > this.weight) {
      this.tare = this.weight;
    }
    this.finalWeight = this.weight - (this.tare == null ? 0 : this.tare);
  }

  public backToMesure(): void {
    this.tare = null;
    this.backward();
    this.weightCalculated = false;
  }

  onCloseModal() {
    this.weightTutorial.reset();
    this.btnClose.nativeElement.click();
  }

  resetModal() {
    this.weightTutorial.reset();
  }
  private stop(): void {
    this.timer.stop();
    this.state.setStop();
  }
  private backward(): void {
    this.timer.resetReverse(medicalConst.TIME_TO_WEIGHING);
    this.state.setBackward();
  }

  /**
   * Method which save the weigth in database.
   */
  public validateWeight(): void {
    this.computeWeight();
    let objToCreate: NewWeighing = {};
    if (this.course && this.course.id) objToCreate.courseId = this.course.id;
    this.weightService.weighingControllerCreate(this.patient.id, this.finalWeight, objToCreate).subscribe(
      async (data) => {
        this.weightCalculated = await false;
        this.weighingSaved = await data;
        await this.updateLastTestForPatient();
        if (this.course && this.course.id) {
          await this.updateStatusAndScore(this.course);
          //await this.router.navigateByUrl('/courses/fried', { state: this.course });
        } /*else {
          await this.router.navigate(['/Patient/' + this.patient.id]);
        }*/
        await this.snackBarService.show(this.translateService.instant('app.weight.successSave'), 'success');
      },
      (error) => {
        console.log('Error :', error);
        this.snackBarService.show(this.translateService.instant('app.weight.errorSave'), 'danger');
      },
    );
  }

  public updateLastTestForPatient() {
    const updatePatient: UpdatePatient = {
      id: this.patient.id,
      lasttestdate: new Date(),
      lasttesttype: this.course && this.course.id ? 0 : 5,
    };

    this.patientDataService.updatePatient(updatePatient).subscribe((data) => {
      this.patientDataService.getPatientById(this.patient.id).subscribe((patient) => {
        this.dataService.setPatient(patient);
      });
    });
  }

  public async updateStatusAndScore(course: Course) {
    const that = this;
    await this.courseDataService.courseControllerUpdateScoreAndStatus(course.patientId, course.id).subscribe((res) => {
      //refresh navbar to display score
      //get the course object updated
      this.courseDataService.courseControllerFindById(course.id).subscribe((courseObj) => {
        that.router.navigateByUrl('/courses/fried', { state: courseObj });
      });
    });
  }

  public onSelectPage(newPage: string) {
    this.newPage = newPage;
  }

  public goTo() {
    this.weightCalculated = false;
    this.router.navigate([this.newPage]);
  }
}
