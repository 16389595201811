import { Injectable } from '@angular/core';
import { StaticItemReference, StaticDataControllerService } from '@abilycare/dal-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class StaticDataService {
  lang: string = '';
  constructor(
    private staticDataControllerService: StaticDataControllerService,
    private translateService: TranslateService,
  ) {
    this.lang = this.translateService.currentLang;
  }

  getAllEtiologies(): Observable<Array<StaticItemReference>> {
    return this.staticDataControllerService
      .staticDataControllerPathologyList(this.lang)
      .pipe(map((res) => res as Array<StaticItemReference>));
  }

  getAllSymptoms(): Observable<Array<StaticItemReference>> {
    return this.staticDataControllerService
      .staticDataControllerSymptomsList(this.lang)
      .pipe(map((res) => res as Array<StaticItemReference>));
  }

  getAllRhythms(): Observable<Array<StaticItemReference>> {
    return this.staticDataControllerService
      .staticDataControllerRhythmsList(this.lang)
      .pipe(map((res) => res as Array<StaticItemReference>));
  }

  getAllTemporalities(): Observable<Array<StaticItemReference>> {
    return this.staticDataControllerService
      .staticDataControllerTemporalitiesList(this.lang)
      .pipe(map((res) => res as Array<StaticItemReference>));
  }

  getAllLateralizations(): Observable<Array<StaticItemReference>> {
    return this.staticDataControllerService
      .staticDataControllerLateralizationsList(this.lang)
      .pipe(map((res) => res as Array<StaticItemReference>));
  }

  getAllLocations(): Observable<Array<StaticItemReference>> {
    return this.staticDataControllerService
      .staticDataControllerLocationsList(this.lang)
      .pipe(map((res) => res as Array<StaticItemReference>));
  }

  getAllCurrents(): Observable<Array<StaticItemReference>> {
    return this.staticDataControllerService
      .staticDataControllerCurrentList(this.lang)
      .pipe(map((res) => res as Array<StaticItemReference>));
  }
}
