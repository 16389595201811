<div>
  <app-fried-history-navbar
    [patient]="patient"
    [returnTitle]="returnTitle"
    (selectPage)="onSelectPage($event)"
  ></app-fried-history-navbar>
  <app-fried-custom-navbar
    [testName]="testName | translate"
    [analysisResult]="resultsWalk"
    [testData]="testData"
    [walkToCompare]="walkToCompare"
    [resultsWalkToCompare]="resultsWalkToCompare"
    [displayDoubleTask]="true"
  >
  </app-fried-custom-navbar>
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-7 simple-content-component" style="height: 50%;">
        <div>{{ 'Résultats' | translate }}</div>
        <div class="results-container flex-column">
          <div class="row assessment-item-container" *ngIf="displayComparison">
            <div class="col-lg-6" *ngFor="let item of items; let index = index">
              <div class="col-md-12 flex-column assessment-container" *ngIf="index % 2 == 0">
                <app-walk-assessment-item [item]="item" [itemToCompare]="itemsToCompare[index]">
                </app-walk-assessment-item>
              </div>
              <div class="col-md-12 flex-column assessment-container" *ngIf="index % 2 != 0">
                <app-walk-assessment-item [item]="item" [itemToCompare]="itemsToCompare[index]">
                </app-walk-assessment-item>
              </div>
            </div>
          </div>
        </div>
        <!--
        <div class="results-container flex-column">
          <div class="assessment-item-container" *ngIf="displayComparison">
            <div class="col-md-6" *ngFor="let item of items; let index = index">
              <app-walk-assessment-item [item]="item" [itemToCompare]="itemsToCompare[index]">
              </app-walk-assessment-item>
            </div>
          </div>
        </div>-->
      </div>

      <div class="flex-column col-md-4">
        <div class="graphics simple-content-component flex-column">
          <img
            class="pointer"
            src="assets/images/images/walk_graphic.svg"
            data-target="#walkGraphicsModal"
            data-toggle="modal"
            alt=""
          />
          <button class="btn btn-tertiary" data-target="#walkGraphicsModal" data-toggle="modal">
            {{ 'app.walk.access.graphic' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- graph model walk -->
<div
  class="modal fade"
  id="walkGraphicsModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="walkGraphicsModalTitle"
  aria-hidden="true"
  #walkGraphics
>
  <div class="modal-dialog modal-dialog-centered modal-extra-large" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="walkgraphicsModalModalTitle">
          {{ titleGraphic }}
        </h5>
        <button #btnCloseGraphics type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-walk-graphics
          *ngIf="resultsWalk"
          [resultsWalk]="resultsWalk"
          [testData]="testData"
          (changeTitle)="onChangeTitle($event)"
          [resultsWalkToCompare]="resultsWalkToCompare"
          [walkToCompare]="walkToCompare"
        ></app-walk-graphics>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" data-dismiss="modal" aria-label="Close">{{ 'Close' | translate }}</button>
      </div>
    </div>
  </div>
</div>
