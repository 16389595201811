/**
 * These tools are using to compute. For example, compute age with birthday
 */

export class Compute {
  public static age(birthDate: Date, today?: Date): number {
    if (!today) {
      today = new Date();
    }

    let age = today.getFullYear() - birthDate.getFullYear();
    if (today.getMonth() < birthDate.getMonth()) {
      age--;
    } else if (today.getMonth() === birthDate.getMonth()) {
      if (today.getDate() < birthDate.getDate()) {
        age--;
      }
    }
    return age;
  }
  /**
   * BMI
   * @param Height in cm
   * @param Weight in kg
   */
  public static BMI(height: number, weight: number, measuredWeight?: number): number {
    const usedWeight = measuredWeight ? measuredWeight : weight;
    let bmi: number;
    bmi = usedWeight / (height * height * 0.0001);
    bmi = Number(bmi.toPrecision(4));
    return bmi;
  }
}
