<app-custom-navbar [patient]="patient" [returnTitle]="returnTitle"></app-custom-navbar>
<div class="main-container container-fluid">
  <div class="bg">
    <div class="header row">
      <!-- TOTO TRANSLATE -->
      <h3 class="text-uppercase">{{ 'Patient.Clinical.Profile' | translate }}</h3>
      <button
        class="btn btn-tertiary"
        *ngIf="
          (rolesCheck.hasEditSyndrom | async) &&
          canEdit &&
          !patient.patattBrief.isHealthy &&
          !patient.patattBrief.isUnknown &&
          displayLeftChevron
        "
        (click)="addPatAtt()"
      >
        <i class="fa fa-plus-circle"></i> {{ 'Patient.Add_clinical_profile' | translate }}
      </button>
    </div>

    <div #masterContent class="flex-row master-content">
      <div class="button-container flex-row">
        <button
          class="btn btn-tertiary btn-chevron"
          *ngIf="displayChevron && displayLeftChevron"
          (click)="scrollLeft()"
        >
          <i class="fa fa-chevron-left icon"></i>
        </button>
      </div>

      <div class="content flex-row" #widgetsContent>
        <div
          class="content-add flex-row"
          *ngIf="
            (rolesCheck.hasEditSyndrom | async) &&
            canEdit &&
            !patient.patattBrief.isHealthy &&
            !patient.patattBrief.isUnknown
          "
        >
          <button class="btn btn-tertiary btn-plus" (click)="addPatAtt()">
            <i class="fa fa-plus-circle icon-plus"></i> {{ 'Patient.Add_clinical_profile' | translate }}
          </button>
        </div>
        <ng-container *ngFor="let item of patAttItems">
          <app-patatt-information
            *ngIf="isLoaded"
            [patient]="patient"
            [patAtt]="item"
            [canEdit]="canEdit"
            (deletePatAtt)="onDeletePatAtt($event)"
          ></app-patatt-information>
        </ng-container>
      </div>

      <div class="button-container flex-row">
        <button
          class="btn btn-tertiary btn-chevron"
          *ngIf="displayChevron && displayRightChevron"
          (click)="scrollRight()"
        >
          <i class="fa fa-chevron-right icon"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<button
  #btnOpenDeleteModal
  [hidden]="true"
  type="button"
  class="btn btn-primary"
  data-toggle="modal"
  data-target="#deleteModal"
  data-backdrop="static"
  data-keyboard="false"
  data-focus="true"
></button>

<!-- Modal -->
<div
  class="modal fade"
  id="deleteModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="deleteModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteModalLabel">{{ 'PatAtt.delete.title' | translate }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- TOTO TRANSLATE -->
        {{ 'PatAtt.delete.content' | translate }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-tertiary" (click)="deletePatatt()" data-dismiss="modal">
          {{ 'Oui' | translate }}
        </button>
        <button type="button" class="btn btn-primary" data-dismiss="modal">{{ 'Non' | translate }}</button>
      </div>
    </div>
  </div>
</div>
