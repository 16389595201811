import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, interval, of } from 'rxjs';
import { LocalInteraction } from './local-interaction.service';
import { RemoteInteraction } from './remote-interaction.service';
import { AnalysisResults } from '../models/analysis-results.model';
import { SensorConfiguration } from '../models/sensor-configuration.model';
import { SensorsState } from '../models/sensors-state.model';
import { BodyPosition } from '../models/body-position.model';
import { AnalysisParameters } from '../models/analysis-parameters.model';
import { AnalysisState, JobState } from '../models/analysis-state.model';
import { delay } from 'rxjs/operators';
import { UUID } from 'src/app/modules/core/services/uuid/uuid.service';

@Injectable()
export class ForcePlatformOrchestrator {
  public get sensorStatus$(): Observable<SensorsState> {
    return this.sensorStatusPrivate$.asObservable();
  }

  /**
   * FIXME update to another value, when tests are over
   */
  private readonly refreshInterval = 2000;

  private sensorState = new SensorsState();
  private sensorStatusPrivate$ = new BehaviorSubject<SensorsState>(this.sensorState);

  private sensorDiscoveryActivityPrivate$ = new BehaviorSubject(false);
  private sensorDiscoveryTimerPrivate: Subscription;
  private stateSetters: Array<(v: boolean, bat: number) => void> = [];

  private resultsState: AnalysisState;

  private configuration: SensorConfiguration[];

  public constructor(private uuid: UUID, private local: LocalInteraction, private remote: RemoteInteraction) {
    this.sensorDiscoveryActivityPrivate$.subscribe((isActive) => this.onSensorDiscoveryActivityChange(isActive));

    this.stateSetters[BodyPosition[BodyPosition.LeftFoot]] = (state: boolean, bat: number) => {
      this.sensorState.lfSensorConnected = state;
      this.sensorState.lfSensorBattery = bat;
    };
    this.stateSetters[BodyPosition[BodyPosition.RightFoot]] = (state: boolean, bat: number) => {
      this.sensorState.rfSensorConnected = state;
      this.sensorState.rfSensorBattery = bat;
    };
    this.stateSetters[BodyPosition[BodyPosition.Hips]] = (state: boolean, bat: number) => {
      this.sensorState.hipsSensorConnected = state;
      this.sensorState.hipsSensorBattery = bat;
    };
    this.stateSetters[BodyPosition[BodyPosition.Head]] = (state: boolean, bat: number) => {
      this.sensorState.headSensorConnected = state;
      this.sensorState.headSensorBattery = bat;
    };
  }

  public initSession(): string {
    return this.uuid.next();
  }

  public async loadSessionConfiguration(recordingSessionId: string): Promise<void> {
    const config = await this.remote.getSensorConfigurations();

    if (config.length !== 4) {
      throw new Error('Did not receive enough sensor configuration (need 4): ' + JSON.stringify(config));
    }

    this.configuration = config;
  }

  public startSensorDiscovery(recordingSessionId: string): void {
    this.sensorStatusPrivate$.next(new SensorsState());
    this.sensorDiscoveryActivityPrivate$.next(true);
  }

  public stopSensorDiscovery(recordingSessionId: string): void {
    this.sensorDiscoveryActivityPrivate$.next(false);
    if (this.sensorDiscoveryTimerPrivate) {
      this.sensorDiscoveryTimerPrivate.unsubscribe();
      this.sensorDiscoveryTimerPrivate = null;
    }
  }

  public async startRecording(recordingSessionId: string): Promise<void> {
    return this.local.startRecording(recordingSessionId).toPromise();
  }

  public async stopRecording(recordingSessionId: string): Promise<void> {
    return this.local.stopRecording(recordingSessionId).toPromise();
  }

  public pushMeasure(recordingSessionId: string): Observable<any> {
    return this.local.pushMeasure(recordingSessionId);
  }

  public async tearDownSession(recordingSessionId: string): Promise<void> {
    this.stopSensorDiscovery(recordingSessionId);
    await this.local.toggleRadioOff().toPromise();
    this.sensorState = new SensorsState();
    this.sensorStatusPrivate$.next(this.sensorState);
  }

  public async discardSessionData(recordingSessionId: string): Promise<void> {
    return this.remote.discardSessionData(recordingSessionId);
  }

  public async startDataAnalysis(recordingSessionId: string, parameters: AnalysisParameters): Promise<void> {
    const action1 = this.local.toggleRadioOff().toPromise();
    const action2 = this.remote.startDataAnalysis(recordingSessionId, parameters);

    await Promise.all([action1, action2]);
  }

  public isAnalysisComplete(recordingSessionId: string) {
    if (!this.resultsState) {
      return false;
    }
    if (this.resultsState.jobState === JobState.Running) {
      return false;
    }
    return true;
  }

  public async getAnalysisResultsForSession(recordingSessionId: string): Promise<AnalysisResults> {
    let state: AnalysisState;
    while (!state || state.jobState === JobState.Running) {
      state = await this.remote.getAnalysisState(recordingSessionId);
      await this.wait(1000);
    }
    if (state.jobState === JobState.Error) {
      throw new Error(state.errorCode);
    }
    const results = this.remote.getAnalysisResultsForSession(recordingSessionId);
    return results;
  }

  public async getAnalysisResultsForBilan(bilanId: number): Promise<AnalysisResults> {
    return this.remote.getAnalysisResultsForBilan(bilanId);
  }

  public async persistAnalysisResults(recordingSessionId: string, bilanId: number): Promise<void> {
    return this.remote.persistAnalysisResults(recordingSessionId, bilanId);
  }

  private onSensorDiscoveryActivityChange(state?: boolean): void {
    // protect against multiple runs
    const canStartPolling = state && !this.sensorDiscoveryTimerPrivate;

    if (canStartPolling) {
      this.turnOnTheRadio().then(() => {
        this.sensorState = new SensorsState();
        this.sensorDiscoveryTimerPrivate = this.setupSensorDiscovery();
      });
    } else {
      if (this.sensorDiscoveryTimerPrivate) {
        this.sensorDiscoveryTimerPrivate.unsubscribe();
        // this.sensorDiscoveryTimerPrivate = null;
      }
    }
  }

  private async turnOnTheRadio(): Promise<void> {
    let toogleOn = false;
    while (!toogleOn) {
      console.log(this.constructor.name + ': radio seems not responding. Have you tried turning it off and on again?');
      await this.local.toggleRadioOff().toPromise();
      await this.wait(10);
      toogleOn = await this.local.toggleRadioOn().toPromise();
      if (!toogleOn) {
        await this.wait(1000);
        if (this.sensorDiscoveryActivityPrivate$) {
          toogleOn = !this.sensorDiscoveryActivityPrivate$.value;
        }
      }
      toogleOn = true;
    }
  }

  private setupSensorDiscovery(): Subscription {
    if (!this.configuration) {
      throw new Error('Missing sensors configuration. Call setupSession() before.');
    }

    const subscription = interval(this.refreshInterval).subscribe((_) => {
      // console.log('Interval Observable', _);
      this.setupSubscribeSensorDiscovery();
    });

    return subscription;
  }

  private async wait(millis: number) {
    return of(true)
      .pipe(delay(millis))
      .toPromise();
  }

  private async setupSubscribeSensorDiscovery() {
    this.sensorState.loading = true;
    this.sensorStatusPrivate$.next(this.sensorState);

    const connectedSensors = await this.local.getConnectedSensorIds().toPromise();

    this.configuration
      // returns the connected state for all configured sensor
      .map((config) => {
        const sensor = connectedSensors.find((s) => s.name === config.sensorId);

        return { where: config.bodyPosition, state: !!sensor, battery: sensor ? sensor.battery : null };
      })
      // sets the correct state for all configured sensor (and not only those active)
      .forEach((truc) => {
        const action: (state: boolean, bat: number) => void = this.stateSetters[BodyPosition[truc.where]];
        action(truc.state, truc.battery);
      });

    this.sensorState.loading = false;
    this.sensorStatusPrivate$.next(this.sensorState);
  }
}
