import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'float' })
export class FloatDisplayed implements PipeTransform {
  public transform(float: number, numberOfFloat: number): string {
    numberOfFloat = numberOfFloat ? numberOfFloat : 1;
    if (float !== null && float !== undefined) {
      const n: string = float.toFixed(numberOfFloat).replace('.', ',');
      return n;
    }
    return null;
  }
}
