<div class="questionnary-description-container simple-content-component">
  <div class="title-container">
    <h2 class="title" style="text-align: center !important">{{ questionnary.name }}</h2>
  </div>

  <div class="description-container">
    <div [innerHtml]="questionnary.description"></div>
    <!-- <p class="description" >{{ questionnary.description }}</p>  -->
  </div>

  <div class="time-container">
    <p class="time" style="text-align: center !important">
      {{ questionnary.durationInMinutes }} {{ 'min' | translate }}
    </p>
  </div>

  <div class="buttons-container">
    <button class="btn btn-tertiary" [disabled]="!displayStart" (click)="start()">
      {{ 'app.questionnary.start.alone' | translate }}
    </button>
  </div>
</div>
