export class LongitudinalGraph {
  public graphYO: Array<{ x: Date; y: number }>;
  public graphYF: Array<{ x: Date; y: number }>;
  public graph: Array<{ x: Date; y: number }>;
  public title: string;

  constructor(graphYO: Array<{ x: Date; y: number }>, graphYF: Array<{ x: Date; y: number }>, title: string) {
    this.graphYO = graphYO;
    this.graph = graphYO;
    this.graphYF = graphYF;
    this.title = title;
  }
}
