import {
  Grip,
  GripControllerService,
  QuestionnaireReply,
  QuestionnaireReplyControllerService,
  Walking,
  WalkingControllerService,
  Weighing,
  WeighingControllerService,
} from '@abilycare/dal-client';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fried-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
/**
 * This component representes the informative div of the connexion with wiiBoard
 */
export class FriedNavbarComponent implements OnInit {
  @Input() currentPage: string;
  @Input() course: any;
  @Input() fromHistory: boolean;
  @Output() selectPage: EventEmitter<string> = new EventEmitter<string>();

  public hasGripTest: boolean;
  public hasQAPPATest: boolean;
  public hasCESTest: boolean;
  public hasWeightTest: boolean;
  public hasWalkingTest: boolean;

  public gripTest: Grip;
  public QappaTest: QuestionnaireReply;
  public CESTest: QuestionnaireReply;
  public weightTest: Weighing;
  public walkingTest: Walking;

  constructor(
    private router: Router,
    private gripService: GripControllerService,
    private questReplyService: QuestionnaireReplyControllerService,
    private walkingService: WalkingControllerService,
    private weighingService: WeighingControllerService,
  ) {}

  ngOnInit() {
    if (this.course && this.course.id) {
      this.gripService.gripControllerFindGripByCourseId(this.course.id).subscribe((data) => {
        this.hasGripTest = data.id ? true : false;
        this.gripTest = data;
      });
      this.walkingService.walkingControllerFindWalkingByCourseId(this.course.id).subscribe((data) => {
        this.hasWalkingTest = data.id ? true : false;
        this.walkingTest = data;
      });
      this.weighingService.weighingControllerFindWeightByCourseId(this.course.id).subscribe((data) => {
        this.hasWeightTest = data.id ? true : false;
        this.weightTest = data;
      });
      this.questReplyService
        .questionnaireReplyControllerFindQuestionnaryByCourseId(this.course.id, 12)
        .subscribe((data) => {
          this.hasQAPPATest = data.id ? true : false;
          this.QappaTest = data;
        });
      this.questReplyService
        .questionnaireReplyControllerFindQuestionnaryByCourseId(this.course.id, 11)
        .subscribe((data) => {
          this.hasCESTest = data.id ? true : false;
          this.CESTest = data;
        });
    }
  }

  goTo(page, test) {
    let obj = this.course;
    const returnObj = {
      fromCourse: 1,
      fromHistory: this.fromHistory,
    };
    if (this.hasGripTest && test === 'grip') {
      page = 'courses/fried/gripView?fromCourse=1';
      obj = [this.course, this.gripTest, returnObj];
    }
    if (this.hasQAPPATest && test === 'questionnary12') {
      page = 'courses/fried/questionnaryView?fromCourse=1';
      obj = [this.course, this.QappaTest, returnObj];
    }
    if (this.hasCESTest && test === 'questionnary11') {
      page = 'courses/fried/questionnaryView11?fromCourse=1';
      obj = [this.course, this.CESTest, returnObj];
    }
    if (this.hasWeightTest && test === 'weight') {
      page = 'courses/fried/weightView?fromCourse=1';
      obj = [this.course, this.weightTest, returnObj];
    }
    if (this.hasWalkingTest && test === 'walk') {
      page = 'courses/fried/walkView?fromCourse=1';
      obj = [this.course, this.walkingTest, returnObj];
    }

    this.selectPage.emit(page);
    if (obj && obj.status == -1 && obj.id) console.log('do nothing');
    else if (obj && (obj.id || obj.length > 0)) {
      this.router.navigateByUrl(page, { state: obj });
    } else if (this.course) {
      this.router.navigate([page]);
    } else this.router.navigate([page]);
  }

  public seeSynthesis() {
    this.router.navigateByUrl('courses/synthesis', { state: this.course });
  }
}
