import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Injectable()
export class TimeService {
  constructor(private translateService: TranslateService) {}

  public setDateFromString(dateStr: string): Date {
    if (dateStr.includes('NaN')) {
      return new Date();
    } else {
      return new Date(dateStr);
    }
  }

  // Moment.js : Selon les locales une semaine commence par un lundi ou un dimanche.
  // Or l'indice 0 de la semaine reste toujours le dimanche.
  public async getWeekDays(): Promise<string[]> {
    const weekDays: string = await this.translateService.get('Date.WeekDays').toPromise();
    const weekDaysArray = weekDays.split(',');
    return weekDaysArray;
  }

  public async getMonths(): Promise<string[]> {
    const months: string = await this.translateService.get('Date.Months').toPromise();
    const monthsArray = months.split(',');
    return monthsArray;
  }

  public async getWeeks(): Promise<string[]> {
    const months: string = await this.translateService.get('Date.Weeks').toPromise();
    const monthsArray = months.split(',');
    return monthsArray;
  }

  // input type date n'est bindé à une date mais à un string
  public getDateAsStringNeededByInput(date: Date): string {
    let month: number = date.getMonth();
    month++;
    let monthStr;
    if (month < 10) {
      monthStr = '0' + month;
    } else {
      monthStr = month;
    }
    let dayStr;
    if (date.getDate() < 10) {
      dayStr = '0' + date.getDate();
    } else {
      dayStr = date.getDate();
    }
    return date.getFullYear() + '-' + monthStr + '-' + dayStr;
  }
}
