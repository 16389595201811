<app-fried-history-navbar
  [patient]="patient"
  [returnTitle]="returnTitle"
  (selectPage)="onSelectPage($event)"
  *ngIf="!fromCourse || fromHistory"
></app-fried-history-navbar>
<app-custom-navbar
  [patient]="patient"
  [returnTitle]="returnTitle"
  (selectPage)="onSelectPage($event)"
  *ngIf="fromCourse && !fromHistory"
></app-custom-navbar>
<app-fried-custom-navbar [testName]="testName" [testData]="testData" *ngIf="!fromCourse" [displayDoubleTask]="false">
</app-fried-custom-navbar>
<app-courses-breadcrumb-navbar
  [testName]="testName | translate"
  [course]="course"
  *ngIf="fromCourse"
></app-courses-breadcrumb-navbar>
<div class="col-md-12">
  <div class="row">
    <div class="col-md-3 p-0" *ngIf="fromCourse">
      <app-fried-navbar
        [currentPage]="'grip'"
        (selectPage)="onSelectPage($event)"
        [course]="course"
        [fromHistory]="fromHistory"
      ></app-fried-navbar>
    </div>
    <div class="col-md-9 fried-info">
      <div class="row">
        <div class="col-md-7 simple-content-component">
          <div class="col-md-11 full-size-content-component">
            <div>{{ 'app.grip.mesurement' | translate }}</div>
            <div class="row" style="margin-bottom: 30px; font-weight: normal;">
              <div class="col-md-6" style="border-right: 1px solid #DCE2E7;">
                <div>
                  <div style="text-align: center;">
                    <div>
                      {{ 'app.grip.lefthand' | translate }}
                      <img
                        class="d-inline-block align-top"
                        width="20"
                        height="20"
                        src="assets/images/icons/lefthand.svg"
                        alt=""
                      />
                      <img
                        class="d-inline-block align-top"
                        width="20"
                        height="20"
                        src="assets/images/icons/righthand-desactivated.svg"
                        alt=""
                      />
                      <span style="margin-right: 10px; color:#70757a;">
                        {{ 'app.grip.righthand' | translate }}
                      </span>
                    </div>
                    <div style="text-align: center; margin-bottom: 30px; font-weight: normal;"></div>
                    <div style="text-align: center; margin-bottom: 30px; font-weight: normal;">
                      {{ 'app.grip.firstMesure' | translate }}
                    </div>
                    <div style="text-align: center; margin-bottom: 30px; font-weight: normal;">
                      <h3>{{ leftHandV1 | float: 2 }} Kg</h3>
                    </div>
                    <div style="text-align: center; margin-bottom: 30px; font-weight: normal;">
                      {{ 'app.grip.secondMesure' | translate }}
                    </div>
                    <div style="text-align: center; margin-bottom: 30px; font-weight: normal;">
                      <h3>{{ leftHandV2 | float: 2 }} Kg</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div>
                  <div style="text-align: center;">
                    <div style="text-align: center;">
                      <span style="margin-right: 10px; color:#70757a;">
                        {{ 'app.grip.lefthand' | translate }}
                      </span>
                      <img
                        class="d-inline-block align-top"
                        width="20"
                        height="20"
                        src="assets/images/icons/lefthand-desactivated.svg"
                        alt=""
                      />
                      <img
                        class="d-inline-block align-top"
                        width="20"
                        height="20"
                        src="assets/images/icons/righthand.svg"
                        alt=""
                      />
                      {{ 'app.grip.righthand' | translate }}
                    </div>
                    <div style="text-align: center; margin-bottom: 30px; font-weight: normal;"></div>
                    <div style="text-align: center; margin-bottom: 30px; font-weight: normal;">
                      {{ 'app.grip.firstMesure' | translate }}
                    </div>
                    <div style="text-align: center; margin-bottom: 30px; font-weight: normal;">
                      <h3>{{ rightHandV1 | float: 2 }} Kg</h3>
                    </div>
                    <div style="text-align: center; margin-bottom: 30px; font-weight: normal;">
                      {{ 'app.grip.secondMesure' | translate }}
                    </div>
                    <div style="text-align: center; margin-bottom: 30px; font-weight: normal;">
                      <h3>{{ rightHandV2 | float: 2 }} Kg</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 simple-content-component">
          <div style="text-align: center;">
            <div>
              <img
                class="d-inline-block align-top pointer"
                data-target="#gripGraphicsModal"
                data-toggle="modal"
                src="assets/images/images/graphique.svg"
                alt=""
              />
            </div>
            <div class="d-flex justify-content-center">
              <button class="btn btn-tertiary" data-target="#gripGraphicsModal" data-toggle="modal">
                {{ 'app.walk.access.graphic' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="gripGraphicsModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="gripGraphicsModalTitle"
  aria-hidden="true"
  #gripGraphics
>
  <div class="modal-dialog modal-dialog-centered modal-medium" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button #btnCloseGraphics type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-grip-graphics [grip]="grip"></app-grip-graphics>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" data-dismiss="modal" aria-label="Close">{{ 'Close' | translate }}</button>
      </div>
    </div>
  </div>
</div>
