<app-custom-navbar
  [patient]="patient"
  [returnTitle]="returnTitle"
  (selectPage)="onSelectPage($event)"
></app-custom-navbar>
<app-courses-breadcrumb-navbar [testName]="testName | translate" [course]="course"></app-courses-breadcrumb-navbar>
<div class="col-md-12">
  <div class="row">
    <div class="col-md-3 p-0">
      <app-fried-navbar
        [currentPage]="'weight'"
        (selectPage)="onSelectPage($event)"
        [course]="course"
      ></app-fried-navbar>
    </div>
    <div class="col-md-9 fried-info">
      <div class="row">
        <div class="col-md-11  full-size-content-component" style="font-weight: normal;">
          <img
            class="d-inline-block align-top"
            width="20"
            height="20"
            src="assets/images/icons/info-circle.svg"
            alt=""
          />
          {{ 'app.weight.protocol.followStep' | translate }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-7 simple-content-component">
          <div style="margin-bottom: 30px;">
            {{ 'app.weight.protocol.stepTitle' | translate }}
          </div>
          <div class="protocol-step">
            <img class="d-inline-block align-top" width="20" height="20" src="assets/images/icons/icon-1.svg" alt="" />
            {{ 'app.weight.protocol.step1' | translate }}
          </div>
          <div class="protocol-step">
            <img class="d-inline-block align-top" width="20" height="20" src="assets/images/icons/icon-2.svg" alt="" />
            {{ 'app.weight.protocol.step2' | translate }}
          </div>
          <div class="tutorial-button-container">
            <button
              (click)="resetModal()"
              class="btn btn-tertiary"
              data-target="#weightTutorialModal"
              data-toggle="modal"
            >
              {{ 'app.weight.protocol.tutorialLink' | translate }}
            </button>
          </div>
        </div>
        <div class="col-md-4 simple-content-component">
          <div>{{ 'app.weight.platform' | translate }}</div>
          <app-information-connexion #plateMonitor></app-information-connexion>
        </div>
      </div>
      <div class="row" *ngIf="!weightCalculated">
        <div class="col-md-11 simple-content-component">
          <div>{{ 'app.weight.mesurement' | translate }}</div>
          <div (click)="getWeight()" style="cursor:pointer;">
            <div style="text-align: center;">
              <div
                [ngClass]="{
                  'elipse-div-enable': wiiConnected || (plateMonitor.KinventConnected && plateMonitor.synchActivate),
                  'elipse-div-disabled':
                    !wiiConnected && (!plateMonitor.KinventConnected || !plateMonitor.synchActivate)
                }"
              >
                <img
                  class="watch-img d-inline-block align-top"
                  width="70"
                  height="70"
                  [src]="
                    wiiConnected || (plateMonitor.KinventConnected && plateMonitor.synchActivate)
                      ? 'assets/images/images/watch.svg'
                      : 'assets/images/images/watch-disable.svg'
                  "
                  alt=""
                />
              </div>
            </div>
            <div
              *ngIf="!loadingWeight"
              class="btn-tertiary text-uppercase"
              [ngClass]="{
                'start-test-button': wiiConnected || (plateMonitor.KinventConnected && plateMonitor.synchActivate),
                'start-test-button-disabled':
                  !wiiConnected && (!plateMonitor.KinventConnected || !plateMonitor.synchActivate)
              }"
            >
              {{ 'app.weight.startTest' | translate }}
            </div>
            <div *ngIf="loadingWeight" class="start-test-button-disabled btn-tertiary">
              {{ 'app.weight.testRunning' | translate }}
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <div class="timer" style="text-align: center;">
            <div>
              <span class="number timer">00:0{{ timer.secondes }}</span>
            </div>
          </div>
          <div class="number" style="text-align: center; ">
            <span class="number">{{ weight | float: 2 }} kg</span>
          </div>
        </div>
        <!--
        <div class="col-md-4 simple-content-component">
          <div>{{ 'app.weight.mesurement' | translate }}</div>
          <div style="text-align: center;">
            <span class="number">
                <app-gauge-chart
                canvasWidth="300"
                [needleValue]="weight"
                [options]="gaugeOptions"
              ></app-gauge-chart>
            </span>
          </div>
          <div>&nbsp;</div>
          <div class="number" style="text-align: center; ">
            <span class="number">{{ weight }} kg</span>
          </div>
        </div>
        -->
      </div>
      <div class="row" *ngIf="weightCalculated">
        <div class="col-md-7 simple-content-component flex-column justify-content-between">
          <div style="margin-bottom: 30px;">
            {{ 'app.weight.results' | translate }}
          </div>
          <div style="margin-bottom: 30px; font-weight: normal;">
            {{ 'app.weight.results.text' | translate }}
          </div>
          <div class="row content_weight">
            <div class="col-md-3">
              <div class="content_box">
                <div class="mesure-title">
                  {{ 'app.weight.poidsmesure' | translate }}
                </div>
                <div class="weight-display">
                  <span>{{ weight | float: 3 }} kg</span>
                </div>
              </div>
            </div>
            <div class="col-md-1">
              <div class="content_box" style="margin-top: 25px;">
                -
              </div>
            </div>
            <div class="col-md-3">
              <div class="content_box_no_border" style="margin-top: 21px;">
                <input
                  type="number"
                  min="0"
                  [(ngModel)]="tare"
                  (input)="computeWeight()"
                  (change)="computeWeight()"
                  placeholder="Tare"
                  style="width : 75%"
                />
                kg
              </div>
            </div>
            <div class="col-md-1">
              <div class="content_box" style="margin-top: 25px;">
                =
              </div>
            </div>
            <div class="col-md-3">
              <div class="content_box">
                <div class="mesure-title">
                  {{ 'app.weight.finalWeight' | translate }}
                </div>
                <div class="weight-display">
                  <span>{{ finalWeight | float: 1 }} kg</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-end">
            <button class="btn btn-tertiary" (click)="backToMesure()">
              {{ 'app.test.restart' | translate }}
            </button>
          </div>
        </div>
        <div class="col-md-4 simple-content-component">
          <div style="text-align: center;">
            <div>
              <img
                class="d-inline-block align-top pointer"
                data-target="#weightGraphicsModal"
                data-toggle="modal"
                src="assets/images/images/graphique.svg"
                alt=""
              />
            </div>
            <div class="d-flex justify-content-center">
              <button class="btn btn-tertiary" data-target="#weightGraphicsModal" data-toggle="modal">
                {{ 'app.walk.access.graphic' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 footer-content-component">
      <button *ngIf="weightCalculated" class="btn btn-primary general-button right-button" (click)="validateWeight()">
        {{ 'button.validation' | translate }}
      </button>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="weightTutorialModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="weightTutorialModalTitle"
  aria-hidden="true"
  #basicModal
>
  <div class="modal-dialog modal-dialog-centered modal-medium" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="weightTutorialModalTitle">
          {{ 'plateform.installation' | translate }}
        </h5>
        <button #btnClose type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-weight-tutorial #weightTutorial></app-weight-tutorial>
      </div>
      <div class="modal-footer d-flex justify-content-end">
        <button class="btn btn-primary" (click)="onCloseModal()">{{ 'Close' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<button #btnOpenQuit data-target="#weightQuitTestModal" data-toggle="modal" [hidden]="true"></button>
<div
  class="modal fade"
  id="weightQuitTestModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="weightQuitTestModalTitle"
  aria-hidden="true"
  #weightQuitTestModal
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="weightQuitTestModalModalTitle">
          {{ 'app.walk.impossible.quit.title' | translate }}
        </h5>
        <button #btnClosWeightQuitTestModal type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{ 'app.walk.impossible.quit.message' | translate }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">{{ 'Ok' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<button #btnQuitBeforeSave data-target="#weightQuitBeforeSaveModal" data-toggle="modal" [hidden]="true"></button>
<div
  class="modal fade"
  id="weightQuitBeforeSaveModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="weightQuitBeforeSaveModalTitle"
  aria-hidden="true"
  #weightQuitBeforeSaveModal
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="weightQuitBeforeSaveModalModalTitle">
          {{ 'app.quit.before.save.title' | translate }}
        </h5>
        <button #btnClosWeightQuitBeforeSaveModal type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p [innerHTML]="'app.weight.impossible.quit.before.save.message' | translate"></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-tertiary" (click)="goTo()" data-dismiss="modal">
          {{ 'app.quit.before.save.button.yes' | translate }}
        </button>
        <button type="button" class="btn btn-primary" data-dismiss="modal">{{ 'Non' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="weightGraphicsModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="weightGraphicsModalTitle"
  aria-hidden="true"
  #weightGraphics
>
  <div class="modal-dialog modal-dialog-centered modal-extra-large" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button #btnCloseGraphics type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-weight-graphics [finalWeight]="finalWeight" [patient]="patient"></app-weight-graphics>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" data-dismiss="modal" aria-label="Close">{{ 'Close' | translate }}</button>
      </div>
    </div>
  </div>
</div>
