<div class="container">
  <div class="form-group">
    <section class="ftco-section">
      <div class="formy flex-row" *ngIf="showFiles">
        <table class="table">
          <tr>
            <th colspan="2">{{ 'Patient.Notifications.listFiles' | translate }}</th>
          </tr>
          <ng-container *ngFor="let file of files">
            <tr>
              <td width="30%">
                <button class="btn-tertiary text-uppercase" type="button" (click)="downloadExport()">
                  <img src="assets/images/images/download.png" width="10%" height="10%" />
                </button>
              </td>
              <td>
                {{ 'Export.zip' }}
              </td>
            </tr>
          </ng-container>
        </table>
      </div>
    </section>
  </div>
</div>
