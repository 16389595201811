import { DoubleTask, DoubleTaskControllerService, Footwear } from '@abilycare/dal-client';
import { FootwearControllerService } from '@abilycare/dal-client';
import { WalkingAid } from '@abilycare/dal-client';
import { WalkingAidControllerService } from '@abilycare/dal-client';
import { PatientDetail } from '@abilycare/dal-client';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { FootWearDataService } from 'src/app/modules/abilycare-data-access';
import { ShoesSelectorValues } from 'src/app/modules/force-platform';
import { AnalysisResults } from 'src/app/modules/walk/models/analysis-results.model';

@Component({
  selector: 'app-fried-custom-navbar',
  templateUrl: './fried-custom-navbar.component.html',
  styleUrls: ['./fried-custom-navbar.component.scss'],
  providers: [FootWearDataService],
})
export class FriedCustomNavbarComponent implements OnInit {
  @Input() patient: PatientDetail;
  @Input() returnTitle: string;
  @Output() selectPage: EventEmitter<string> = new EventEmitter<string>();

  @Input() testName: string;

  @Input()
  public shoes: ShoesSelectorValues;

  @Input()
  public analysisResult: AnalysisResults;

  @Input()
  public testData: any;

  @Input()
  public walkToCompare: any;

  @Input()
  public resultsWalkToCompare: any;

  @Input()
  public displayDoubleTask: boolean;

  shoesType: string;
  stabilityAid: string;
  doubleTask: string;
  doubleTaskToCompare: string;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private footWearService: FootwearControllerService,
    private stabilityAidService: WalkingAidControllerService,
    private doubleTaskService: DoubleTaskControllerService,
  ) {}

  async ngOnInit() {
    console.log('this.testData is ', this.testData);
    if (this.testData.doubleTask) {
      await this.doubleTaskService
        .doubleTaskControllerFindById(this.testData.doubleTask, this.translateService.currentLang)
        .subscribe((res) => {
          console.log('res double task is ', res);
          this.doubleTask = res.label;
        });
    }

    if (this.walkToCompare && this.walkToCompare.doubleTask) {
      await this.doubleTaskService
        .doubleTaskControllerFindById(this.walkToCompare.doubleTask, this.translateService.currentLang)
        .subscribe((res) => {
          console.log('res doubleTaskToCompare is ', res);
          this.doubleTaskToCompare = res.label;
        });
    }

    /*
    await this.footWearService
      .footwearControllerFindById(this.testData.shoesType, this.translateService.currentLang)
      .subscribe((res) => {
        this.shoesType = res.translate;
      });

    await this.stabilityAidService
      .walkingAidControllerFindById(this.testData.stabilityAid, this.translateService.currentLang)
      .subscribe((res) => {
        this.stabilityAid = res.translate;
      });
      */
  }
}
