import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatSliderChange } from '@angular/material';
import { Assessment, WalkAssessmentType } from 'src/app/modules/walk/models/assessment.model';

@Component({
  selector: 'app-walk-appreciation',
  templateUrl: './walk-appreciation.component.html',
  styleUrls: ['./walk-appreciation.component.scss'],
})
export class WalkAppreciationComponent implements OnInit {
  @Output() public validate: EventEmitter<any> = new EventEmitter<any>();

  public minHesitantWalking: number = 1;
  public maxHesitantWalking: number = 5;
  public valueHesitantWalking: number = 3;
  public isHesitantWalkingNotEvaluated = true;

  public minSmallSteps: number = 1;
  public maxSmallSteps: number = 5;
  public valueSmallSteps: number = 3;
  public isSmallStepsNotEvaluated = true;

  public minIrregularWalking: number = 1;
  public maxIrregularWalking: number = 5;
  public valueIrregularWalking: number = 3;
  public isIrregularWalkingNotEvaluated = true;

  public minAsymmetricWalking: number = 1;
  public maxAsymmetricWalking: number = 5;
  public valueAsymmetricWalking: number = 3;
  public isAsymmetricWalkingNotEvaluated = true;

  public minArmSwingLoss: number = 1;
  public maxArmSwingLoss: number = 5;
  public valueArmSwingLoss: number = 3;
  public isArmSwingLossNotEvaluated = true;

  public minUnbalancedWalking: number = 1;
  public maxUnbalancedWalking: number = 5;
  public valueUnbalancedWalking: number = 3;
  public isUnbalancedWalkingNotEvaluated = true;

  thumbLabel = true;

  public step: number = 1;
  constructor() {}

  ngOnInit() {}

  formatLabel(value: number) {
    return value;
  }

  /**
   * Méthod to save value if the hesitant walking slider is moved.
   */
  public onValueHWChange(sliderChange: MatSliderChange): void {
    this.isHesitantWalkingNotEvaluated = false;
    this.valueHesitantWalking = sliderChange.value;
  }

  /**
   * Méthod to save value if the small steps slider is moved.
   */
  public onValueSSChange(sliderChange: MatSliderChange): void {
    this.isSmallStepsNotEvaluated = false;
    this.valueSmallSteps = sliderChange.value;
  }

  /**
   * Méthod to save value if the irregular walking slider is moved.
   */
  public onValueIWChange(sliderChange: MatSliderChange): void {
    this.isIrregularWalkingNotEvaluated = false;
    this.valueIrregularWalking = sliderChange.value;
  }

  /**
   * Méthod to save value if the asymmetric walking slider is moved.
   */
  public onValueAWChange(sliderChange: MatSliderChange): void {
    this.isAsymmetricWalkingNotEvaluated = false;
    this.valueAsymmetricWalking = sliderChange.value;
  }

  /**
   * Méthod to save value if the arm swing loss slider is moved.
   */
  public onValueASLChange(sliderChange: MatSliderChange): void {
    this.isArmSwingLossNotEvaluated = false;
    this.valueArmSwingLoss = sliderChange.value;
  }

  /**
   * Méthod to save value if the unbalanced walking slider is moved.
   */
  public onValueUWChange(sliderChange: MatSliderChange): void {
    this.isUnbalancedWalkingNotEvaluated = false;
    this.valueUnbalancedWalking = sliderChange.value;
  }

  /**
   * Method to save all visual appreciations values when the user click on the validate
   * button of the visual appreciations pop-up.
   */
  public viewResults(): void {
    const appreciations: Array<Assessment> = [
      {
        type: WalkAssessmentType.Hesitant,
        title: '',
        value: this.valueHesitantWalking,
        nonEvaluated: this.isHesitantWalkingNotEvaluated,
      },
      {
        type: WalkAssessmentType.SmallSteps,
        title: '',
        value: this.valueSmallSteps,
        nonEvaluated: this.isSmallStepsNotEvaluated,
      },
      {
        type: WalkAssessmentType.Irregular,
        title: '',
        value: this.valueIrregularWalking,
        nonEvaluated: this.isIrregularWalkingNotEvaluated,
      },
      {
        type: WalkAssessmentType.Asymetric,
        title: '',
        value: this.valueAsymmetricWalking,
        nonEvaluated: this.isAsymmetricWalkingNotEvaluated,
      },
      {
        type: WalkAssessmentType.SwingingArms,
        title: '',
        value: this.valueArmSwingLoss,
        nonEvaluated: this.isArmSwingLossNotEvaluated,
      },
      {
        type: WalkAssessmentType.Balance,
        title: '',
        value: this.valueUnbalancedWalking,
        nonEvaluated: this.isUnbalancedWalkingNotEvaluated,
      },
    ];
    this.validate.emit(appreciations);
  }
}
