/**
 * This class describes the content of a romberg test. eo = eyes open, ec = eyes close
 */
export class RombergTest {
  public idPatient: number;
  public date: Date;
  public settings: string = '';
  public averageSpeedEo: number;
  public averageSpeedEc: number;
  public swayDensityEo: number;
  public swayDensityEc: number;
  public surfaceEo: number;
  public surfaceEc: number;
  public lateralVarianceEo: number;
  public lateralVarianceEc: number;
  public durationEo: number;
  public durationEc: number;
  public fall: boolean; // if the patient was catched or not during the test
  public Id: number;
  public BilanId: number;
}
export enum EtatYeux {
  YO,
  YF,
}
