import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { catchError, delay, map, tap, flatMap, shareReplay } from 'rxjs/operators';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { AppConfigurationService } from 'src/app/app-configuration.service';
import { InterceptorHttpParams } from 'src/app/modules/core/services/http/interceptor-http-params';

function toLog<T>(where: string, logger: LoggerService) {
  return tap((r: T) => {
    logger.info('WalkSensors : (HTTP) ' + where + ': ' + r);
  });
}

@Injectable()
export class LocalInteraction {
  public static readonly toggleRadioOnPath = '/toggle_radio_state/start';
  public static readonly toggleRadioOffPath = '/toggle_radio_state/stop';
  public static readonly getConnectedSensorIdsPath = '/mtwconnected';
  public static readonly getConnectedSensorIdsWithBatteryPath = '/sensorsconnected';
  public static readonly getServiceVersion = '/GetServiceVersion';
  public static readonly startRecordingPath = '/startmesure';
  public static readonly stopRecordingPath = '/stopmesure';
  public static readonly pushMeasurePath = '/pushmesure?guid={guid}';

  private getVersionObs: Observable<number>;

  public constructor(
    private logger: LoggerService,
    private http: HttpClient,
    private appConfigurationService: AppConfigurationService,
  ) {}

  /**
   * Returns true if radio was enabled
   * Returns false if radio was not enabled
   */
  public toggleRadioOn() {
    this.logger.info(this.constructor.name + ': toggling radio ON');

    const url = this.appConfigurationService.walkPlatformBaseUri + LocalInteraction.toggleRadioOnPath;

    return this.get<{ ToggleRadioStateResult: string }>(url).pipe(
      toLog(`toggleRadioOff()`, this.logger),
      catchError((err, caught) => caught.pipe(delay(1000))),
      map((j) => j.ToggleRadioStateResult),
      map((p) => p === 'radio enabled'),
    );
  }

  public toggleRadioOff() {
    this.logger.info(this.constructor.name + ': toggling radio OFF');

    const url = this.appConfigurationService.walkPlatformBaseUri + LocalInteraction.toggleRadioOffPath;

    return this.get<void>(url).pipe(toLog(`toggleRadioOff()`, this.logger));
  }

  public getConnectedSensorIdsOld() {
    this.logger.info(this.constructor.name + ': getting connected sensors ids');

    const url = this.appConfigurationService.walkPlatformBaseUri + LocalInteraction.getConnectedSensorIdsPath;

    return this.get<{ MtwConnectedResult: string[] }>(url).pipe(
      toLog(`getConnectedSensorIds`, this.logger),
      map((j) => j.MtwConnectedResult),
      map((nameList) => nameList.map((name) => ({ name, battery: null as number }))),
    );
  }

  public getConnectedSensorIdsWithBattery() {
    this.logger.info(this.constructor.name + ': getting connected sensors ids');

    const url =
      this.appConfigurationService.walkPlatformBaseUri + LocalInteraction.getConnectedSensorIdsWithBatteryPath;

    return this.get<Array<{ name: string; battery: number }>>(url).pipe(toLog(`getConnectedSensorIds`, this.logger));
  }

  public getConnectedSensorIds() {
    return this.getVersion().pipe(
      flatMap((v) => {
        if (v < 404) {
          return this.getConnectedSensorIdsOld();
        } else {
          return this.getConnectedSensorIdsWithBattery();
        }
      }),
    );
  }

  public getVersion() {
    if (!this.getVersionObs) {
      const url = this.appConfigurationService.walkPlatformBaseUri + LocalInteraction.getServiceVersion;
      this.getVersionObs = this.get<{ GetServiceVersionResult: string }>(url).pipe(
        toLog(`getConnectedSensorIds`, this.logger),
        map((vesionObj) => {
          const versionElem = vesionObj.GetServiceVersionResult.split('.');
          return parseInt(versionElem[0], 10) * 100 + parseInt(versionElem[1], 10);
        }),
        shareReplay(1),
      );
    }

    return this.getVersionObs;
  }

  public startRecording(currentRecordingSessionId: string) {
    this.logger.info(this.constructor.name + ': started recording for guid: ', currentRecordingSessionId);

    const url = this.appConfigurationService.walkPlatformBaseUri + LocalInteraction.startRecordingPath;

    return this.get<void>(url).pipe(toLog(`startRecording(${currentRecordingSessionId})`, this.logger));
  }

  public stopRecording(currentRecordingSessionId: string) {
    this.logger.info(this.constructor.name + ': stopped recording for guid: ', currentRecordingSessionId);

    const url = this.appConfigurationService.walkPlatformBaseUri + LocalInteraction.stopRecordingPath;

    return this.get<void>(url).pipe(toLog(`stopRecording(${currentRecordingSessionId})`, this.logger));
  }

  public pushMeasure(currentRecordingSessionId: string): Observable<void> {
    this.logger.info(this.constructor.name + ': save recording for guid: ', currentRecordingSessionId);

    let url = this.appConfigurationService.walkPlatformBaseUri + LocalInteraction.pushMeasurePath;
    url = url.replace('{guid}', currentRecordingSessionId);

    //console.log('pushMeasure in local interaction ', url);

    return this.get<void>(url).pipe(toLog(`pushMeasure(${currentRecordingSessionId})`, this.logger));
  }

  private get<T>(url: string) {
    // Disable notification of error, handled in gui
    const params = new InterceptorHttpParams({ noNotificationOnError: true });
    return this.http.get<T>(url, { params });
  }
}
