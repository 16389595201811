<app-custom-navbar [patient]="patient" [returnTitle]="returnTitle"></app-custom-navbar>
<div class="main-container container-fluid">
  <div class="bg">
    <div class="header row">
      <!-- TOTO TRANSLATE -->
      <h3 class="text-uppercase">{{ 'Patient.Course.list' | translate }}</h3>
      <button class="btn btn-tertiary" *ngIf="canEdit" (click)="addCourse()">
        <i class="fa fa-plus-circle"></i> {{ 'Patient.Add_Course' | translate }}
      </button>
      <div *ngIf="error" class="col-md-12 alert alert-danger">
        <div>{{ 'Patient.course.add_error' | translate }}</div>
      </div>
    </div>

    <div #masterContent class="flex-row master-content">
      <div class="button-container flex-row">
        <button
          class="btn btn-tertiary btn-chevron"
          *ngIf="displayChevron && displayLeftChevron"
          (click)="scrollLeft()"
        >
          <i class="fa fa-chevron-left icon"></i>
        </button>
      </div>
      <div class="content flex-row" #widgetsContent>
        <ng-container *ngFor="let course of coursesList">
          <app-course-information
            [patient]="patient"
            [course]="course"
            [canEdit]="canEdit"
            (deleteCourse)="onDeleteCourse($event)"
          ></app-course-information>
        </ng-container>
      </div>
      <div class="button-container flex-row">
        <button
          class="btn btn-tertiary btn-chevron"
          *ngIf="displayChevron && displayRightChevron"
          (click)="scrollRight()"
        >
          <i class="fa fa-chevron-right icon"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<button
  #btnOpenDeleteModal
  [hidden]="true"
  type="button"
  class="btn btn-primary"
  data-toggle="modal"
  data-target="#deleteModal"
  data-backdrop="static"
  data-keyboard="false"
  data-focus="true"
></button>

<!-- Modal -->

<div
  class="modal fade"
  id="deleteModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="deleteModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteModalLabel">{{ 'Course.delete.title' | translate }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{ 'Course.delete.content' | translate }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-tertiary" (click)="deleteCourse()" data-dismiss="modal">
          {{ 'Oui' | translate }}
        </button>
        <button type="button" class="btn btn-primary" data-dismiss="modal">{{ 'Non' | translate }}</button>
      </div>
    </div>
  </div>
</div>
