import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Stats } from 'fs';
import { Subscription } from 'rxjs';
import { ForcePlatformOrchestrator } from 'src/app/modules/walk/services/orchestrator.service';
import { SensorsState } from '../../../../../modules/walk/models/sensors-state.model';

@Component({
  selector: 'app-forceplatform-sensor-connexion',
  templateUrl: './sensor-connexion.component.html',
  styleUrls: ['./sensor-connexion.component.scss'],
})
export class SensorConnexionComponent implements OnInit, OnDestroy {
  @Output() canLaunchTest: EventEmitter<boolean> = new EventEmitter<boolean>();
  public state: SensorsState = new SensorsState();
  public disabled: boolean = true;
  private subscription: Subscription;

  constructor(private orchestrator: ForcePlatformOrchestrator) {}

  public async ngOnInit() {
    // await this.orchestrator.loadSessionConfiguration(LocalStorageEx.currentWalkRecordingSession);
    // this.orchestrator.startSensorDiscovery(LocalStorageEx.currentWalkRecordingSession);
    await this.orchestrator.loadSessionConfiguration(null);
    this.orchestrator.startSensorDiscovery(null);
    this.subscription = this.orchestrator.sensorStatus$.subscribe((s) => {
      this.disabled = false;
      this.state = s;
      this.disabled = true;
      if (
        this.state.headSensorConnected &&
        this.state.hipsSensorConnected &&
        this.state.lfSensorConnected &&
        this.state.rfSensorConnected
      ) {
        this.canLaunchTest.emit(true);
      } else {
        this.canLaunchTest.emit(false);
      }
    });
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
    // this.orchestrator.stopSensorDiscovery(LocalStorageEx.currentWalkRecordingSession);
    this.orchestrator.stopSensorDiscovery(null);
  }
}
