import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export interface IGenderModel {
  value: string;
  text: string;
}

export const genderItems: IGenderModel[] = [
  {
    value: 'M',
    text: _('Gender.Male'),
  },
  {
    value: 'F',
    text: _('Gender.Female'),
  },
  {
    value: 'U',
    text: _('Gender.Unknown'),
  },
  {
    value: 'O',
    text: _('Gender.Other'),
  },
];
