import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PatAttDataService } from 'src/app/modules/abilycare-data-access/api/patatt-data.service';
import { PatattDetail, PatientDetail } from '@abilycare/dal-client';
import { PatientDataService } from 'src/app/modules/abilycare-data-access/api/patient-data.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { RolesCheckService } from 'src/app/modules/core/services/authentification/roles-check.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-patatt-list',
  templateUrl: './patatt-list.component.html',
  styleUrls: ['./patatt-list.component.scss'],
})
export class PatattListComponent implements OnInit, OnDestroy {
  @ViewChild('masterContent', { static: true }) masterContent: ElementRef<HTMLElement>;
  @ViewChild('widgetsContent', { static: true }) widgetsContent: ElementRef<HTMLElement>;
  @ViewChild('btnOpenDeleteModal', { static: true }) btnOpenDeleteModal: ElementRef;

  public patAttItems: Array<PatattDetail>;
  public patientId: number;
  public patient: PatientDetail = null;
  public canEdit = false;
  public displayChevron = true;
  public displayLeftChevron = false;
  public displayRightChevron = true;
  public patattId: number;

  public cursorScrollLeft: number = 0;

  public returnTitle: string = '';

  public isLoaded = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private patAttDataService: PatAttDataService,
    private patientDataService: PatientDataService,
    private dataService: DataProviderService,
    public rolesCheck: RolesCheckService,
    private patattDataService: PatAttDataService,
    private translateService: TranslateService,
  ) {
    this.returnTitle = this.translateService.instant('Patient.Profil.back_patient');
  }

  ngOnInit() {
    this.dataService.storeHeaderShowed(false);
    this.activatedRoute.paramMap.subscribe((params) => {
      this.patientId = parseInt(params.get('patientId').toString(), 10);
      this.getAllPatAtt();
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
  }

  public getPatient(): void {
    this.subscriptions.push(
      this.patientDataService.getPatientById(this.patientId).subscribe((patient: PatientDetail) => {
        this.patient = patient;
        this.isLoaded = true;

        if (patient.currentPractionerRoles && patient.currentPractionerRoles.length > 0) {
          patient.currentPractionerRoles.forEach((role) => {
            if (role === 'Owner' || role === 'TestDelegation') {
              this.canEdit = true;
            }
          });
        }

        if (this.masterContent.nativeElement.offsetWidth < this.widgetsContent.nativeElement.offsetWidth) {
          this.displayChevron = false;
        }
        if (!this.patAttItems || this.patAttItems.length <= 2) {
          this.displayChevron = false;
        }
      }),
    );
  }

  public getAllPatAtt(): void {
    this.subscriptions.push(
      this.patAttDataService.getAllPatAtt(this.patientId).subscribe((allPatat) => {
        this.patAttItems = allPatat.reverse();
        this.getPatient();
      }),
    );
  }

  public addPatAtt(): void {
    this.router.navigate(['PatAtt/', this.patientId, true, 'edition']);
  }

  public scrollRight(): void {
    this.cursorScrollLeft += 400;
    this.widgetsContent.nativeElement.scrollTo({
      left: this.cursorScrollLeft,
      behavior: 'smooth',
    });

    const offsetWidth: number = this.widgetsContent.nativeElement.offsetWidth;
    const scrollWidth: number = this.widgetsContent.nativeElement.scrollWidth;

    if (this.cursorScrollLeft + offsetWidth >= scrollWidth) {
      this.cursorScrollLeft = this.widgetsContent.nativeElement.scrollLeft;
      this.displayRightChevron = false;
    }
    this.displayLeftChevron = true;
  }

  public scrollLeft(): void {
    this.cursorScrollLeft -= 400;
    this.widgetsContent.nativeElement.scrollTo({
      left: this.cursorScrollLeft,
      behavior: 'smooth',
    });

    if (this.cursorScrollLeft <= 0) {
      this.cursorScrollLeft = 0;
      this.displayLeftChevron = false;
    }
    this.displayRightChevron = true;
  }

  public deletePatatt(): void {
    this.subscriptions.push(
      this.patattDataService.deletePatAtt(this.patient.id, this.patattId).subscribe((data) => {
        this.patAttItems = null;
        this.getAllPatAtt();
      }),
    );
  }

  public onDeletePatAtt(patattId): void {
    this.patattId = patattId;
    this.btnOpenDeleteModal.nativeElement.click();
  }
}
