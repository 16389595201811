<div>
  <!-- Delegation block -->
  <mat-accordion class="main-container">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title class="title">
          {{ 'Patient.delegation.title' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div
        *ngIf="patientDelegation && patientDelegation.length > 0; else elseNoDelegation"
        class="container-delegation flex-column"
      >
        <div class="content-delegation-container col-sm-12">
          <div *ngIf="delegateForTest && delegateForTest.length > 0" class="test-delegation-container col-sm-6 p-0">
            <div class="flex-column">
              <p class="info-title">{{ 'Patient.delegation.tests' | translate }}</p>
              <div class="d-flex chips-container">
                <p class="info-value chips" *ngFor="let practitionner of delegateForTest">
                  {{ practitionner }}
                </p>
              </div>
            </div>
          </div>

          <div
            *ngIf="delegateForResult && delegateForResult.length > 0"
            class="result-delegation-container col-sm-6 p-0"
          >
            <div class="flex-column">
              <p class="info-title">{{ 'Patient.delegation.results' | translate }}</p>
              <div class="d-flex chips-container">
                <p class="info-value chips" *ngFor="let practitionner of delegateForResult">
                  {{ practitionner }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="delegateBy" class="container-delegation flex-column">
        <div class="content-delegation-container col-sm-12">
          <div class="test-delegation-container col-sm-6 p-0">
            <div class="flex-column">
              <p class="info-title">
                {{ 'Délégué pour' | translate }}
                <span *ngIf="isDelegateForTest"> {{ 'Tests' | translate }} </span>
                <span *ngIf="isDelegateForTest && isDelegateForResult"> {{ 'and' | translate }} </span>
                <span *ngIf="isDelegateForResult"> {{ 'visualization' | translate }} </span>
                <span>{{ 'by' | translate }}</span>
              </p>
              <div class="d-flex chips-container">
                <p class="info-value chips">
                  {{ delegateBy }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-template #elseNoDelegation>
        <div class="container-delegation">
          <div class="content-delegation-container col-sm-12">
            <div class="col-sm-6 p-0">
              <p class="info-title">{{ 'Patient.no.delegation' | translate }}</p>
            </div>
          </div>
        </div>
      </ng-template>

      <div class="div-footer flex-row">
        <button *ngIf="checkCanDelegate()" class="btn btn-tertiary" (click)="clickOnDelegation()">
          {{ 'Modifier' | translate }}
        </button>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <!-- End delegation block-->

  <mat-accordion class="main-container" *ngFor="let patientItem of patientItems">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title class="title">
          {{ patientItem.title }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="flex-row wrap">
        <ng-container *ngFor="let item of patientItem.content">
          <div class="flex-column col-sm-4 div-info">
            <p class="info-title">{{ item.title }}</p>
            <p class="info-value" *ngIf="item.value; else elseBlock">{{ item.value | translate }}</p>
            <ng-template #elseBlock> - </ng-template>
          </div>
        </ng-container>
      </div>
      <div class="div-footer flex-row">
        <button
          class="btn btn-tertiary"
          data-toggle="modal"
          data-target="#modalPatientUpdate"
          data-backdrop="static"
          data-keyboard="false"
          data-focus="true"
          (click)="modify(patientItem.component)"
          *ngIf="
            (rolesCheck.hasManagePatient | async) ||
            ((rolesCheck.hasSelectPatientReferrer | async) && patientItem.component === 'Practitioner')
          "
        >
          {{ 'Modifier' | translate }}
        </button>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <div
    class="modal fade"
    id="modalPatientUpdate"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalPatientUpdateTitle"
    aria-hidden="true"
    #basicModal
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalPatientUpdateTitle">
            {{ 'Patient.Profil.patient_information' | translate }}
          </h5>
          <button #btnClose type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <app-patient-creation-profil-form *ngIf="isProfil" [patient]="patientSelected" (closeModal)="onCloseModal()">
          </app-patient-creation-profil-form>
          <app-patient-creation-personal-form
            *ngIf="isPersonalDetail"
            [patient]="patientSelected"
            (closeModal)="onCloseModal()"
          >
          </app-patient-creation-personal-form>
          <app-patient-creation-practitioner-form
            *ngIf="isPractitioner"
            [patient]="patientSelected"
            (closeModal)="onCloseModal()"
          ></app-patient-creation-practitioner-form>
        </div>
      </div>
    </div>
  </div>
</div>
