<div class="div-container installation" *ngIf="firstStep">
  <div class="col-md-11 rows-container">
    <div class="row">
      <div class="col-md-6 text-container">
        <p class="title-step">
          <span>{{ 'Checkup.Walk.Installation.Step1.Header' | translate }}</span
          >{{ 'Checkup.Walk.Installation.Step1.Text' | translate }}
        </p>
        <img
          class="img-step"
          src="assets/images/images/wak_installation_1.svg"
          alt="{{ 'Walk.Installation.Step1.Alt' | translate }}"
        />
      </div>
      <div class="col-md-6 text-container">
        <p class="title-step">
          <span>{{ 'Checkup.Walk.Installation.Step2.Header' | translate }}</span
          >{{ 'Checkup.Walk.Installation.Step2.Text' | translate }}
        </p>
        <img
          class="img-step"
          src="assets/images/images/wak_installation_2.svg"
          alt="{{ 'Walk.Installation.Step2.Alt' | translate }}"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 text-container">
        <p class="title-step">
          <span>{{ 'Checkup.Walk.Installation.Step3.Header' | translate }}</span
          >{{ 'Checkup.Walk.Installation.Step3.Text' | translate }}
        </p>
        <img
          class="img-step"
          src="assets/images/images/wak_installation_3.svg"
          alt="{{ 'Walk.Installation.Step3.Alt' | translate }}"
        />
      </div>
      <div class="col-md-6 text-container">
        <p class="title-step">
          <span>{{ 'Checkup.Walk.Installation.Step4.Header' | translate }}</span
          >{{ 'Checkup.Walk.Installation.Step4.Text' | translate }}
        </p>
        <img
          class="img-step"
          src="assets/images/images/wak_installation_4.svg"
          alt="{{ 'Walk.Installation.Step4.Alt' | translate }}"
        />
      </div>
    </div>
  </div>

  <div class="col-md-1">
    <img class="img-chevron" (click)="goToNextStep()" src="assets/images/icons/right-chevron.svg" alt="" />
  </div>
</div>

<div class="div-container protocol" *ngIf="!firstStep">
  <div class="col-md-1">
    <img class="img-chevron" (click)="goToPreviousStep()" src="assets/images/icons/left-chevron.svg" alt="" />
  </div>

  <div class="col-md-11 d-flex flex-column">
    <div class="row">
      <div class="col-lg-12">
        <div class="title">
          <span>{{ 'Checkup.Walk.Protocol.Text1' | translate }}</span>
          <span>{{ 'Checkup.Walk.Protocol.Text2' | translate }}</span>
          <span>{{ 'Checkup.Walk.Protocol.Text3' | translate }}</span>
        </div>
      </div>
    </div>

    <div class="row content-step">
      <div class="col-md-2 no-padding">
        <div class="step">
          <img
            src="assets/images/images/walk_protocol_1.svg"
            alt="{{ 'Checkup.Walk.Protocol.Step1.Alt' | translate }}"
          />
          <p class="step_text">{{ 'Checkup.Walk.Protocol.Step1' | translate }}</p>
        </div>
      </div>
      <div class="col-md-2 no-padding">
        <div class="step">
          <img
            src="assets/images/images/walk_protocol_2.svg"
            alt="{{ 'Checkup.Walk.Protocol.Step2.Alt' | translate }}"
          />
          <p class="step_text">{{ 'Checkup.Walk.Protocol.Step2' | translate }}</p>
        </div>
      </div>
      <div class="col-md-2 no-padding">
        <div class="step">
          <img
            src="assets/images/images/walk_protocol_3.svg"
            alt="{{ 'Checkup.Walk.Protocol.Step3.Alt' | translate }}"
          />
          <p class="step_text">{{ 'Checkup.Walk.Protocol.Step3' | translate }}</p>
        </div>
      </div>
      <div class="col-md-2 no-padding">
        <div class="step">
          <img
            src="assets/images/images/walk_protocol_4.svg"
            alt="{{ 'Checkup.Walk.Protocol.Step4.Alt' | translate }}"
          />
          <p class="step_text">{{ 'Checkup.Walk.Protocol.Step4' | translate }}</p>
        </div>
      </div>
      <div class="col-md-2 no-padding">
        <div class="step">
          <img
            src="assets/images/images/walk_protocol_5.svg"
            alt="{{ 'Checkup.Walk.Protocol.Step5.Alt' | translate }}"
          />
          <p class="step_text">{{ 'Checkup.Walk.Protocol.Step5' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
