import {
  NewPatient,
  PatientBrief,
  Practitioner,
  QuestionnaireControllerService,
  QuestionnaireSummary,
} from '@abilycare/dal-client';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { PatientDataService } from 'src/app/modules/abilycare-data-access/api/patient-data.service';
import { PractitionerDataService } from 'src/app/modules/abilycare-data-access/api/practitioner-data.service';
import { PractitionerSpecialityDataService } from 'src/app/modules/abilycare-data-access/api/practitioner-speciality-data.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { genderItems } from 'src/app/shared/models/gender-item.model';
import { lateralityItems } from 'src/app/shared/models/laterality-item.model';

@Component({
  selector: 'app-patient-creation-form',
  templateUrl: './patient-creation-form.component.html',
  styleUrls: ['./patient-creation-form.component.scss'],
})
export class PatientCreationFormComponent implements OnInit, OnDestroy {
  @Output() closeModal: EventEmitter<PatientBrief> = new EventEmitter();
  @Output() createMandQuest: EventEmitter<PatientBrief> = new EventEmitter();

  public practitioners: Array<Practitioner> = new Array<Practitioner>();
  public firstFormGroup: FormGroup;
  public secondFormGroup: FormGroup;
  public thirdFormGroup: FormGroup;
  public fourthFormGroup: FormGroup;
  public linear = true;
  public newPatient: NewPatient = null;
  public speciality: string = '';
  public age: string = '-';
  public imc: string = '-';
  public firstFormValid = true;
  public isOk: boolean = false;
  public minimumAge: number = 0;
  public isAgeValid: boolean = true;
  public isHeightValid: boolean = true;
  public isWeightValid: boolean = true;
  public maxDate: Date = new Date();
  public genders = genderItems;
  public lateralities = lateralityItems;
  private subscriptions: Subscription[] = [];

  public questionaireMandatryIds: QuestionnaireSummary[] = [];
  public hasQuestionnaryMandatary: boolean = false;
  public patientCreated: PatientBrief;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private patientDataService: PatientDataService,
    private practitionerDataService: PractitionerDataService,
    private practitionerSpecialityService: PractitionerSpecialityDataService,
    private dateAdapter: DateAdapter<any>,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private questionnaireService: QuestionnaireControllerService,
    private dataService: DataProviderService,
  ) {
    this.subscriptions.push(
      this.practitionerDataService.getAllPractitioners().subscribe(
        (practitioners) => {
          this.practitioners = practitioners;
          this.getPractitionerMe();
        },
        (error) => {
          console.error('PatientCreationFormComponent: Err-Practitionner-03 ', error);
          const errMsg = this.translateService.instant('Err-Practitionner-03');
          this.snackBarService.show(errMsg, 'danger');
        },
      ),
    );
    this.dateAdapter.setLocale('fr');
  }

  ngOnInit() {
    this.newPatient = null;
    this.firstFormGroup = this.formBuilder.group({
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      folderNumber: [''],
      gender: ['', Validators.required],
      birthDate: [Date, Validators.required],
      laterality: ['', Validators.required],
      height: [''],
      weight: [''],
      consent: [''],
    });
    this.secondFormGroup = this.formBuilder.group({
      phoneNumber: [''],
      mobilePhoneNumber: [''],
      email: [''],
      address: [''],
      city: [''],
      postalCode: [''],
    });

    const lang = this.translateService.currentLang;
    //see if there are mandatary questionnary
    this.questionnaireService.questionnaireControllerFind(lang).subscribe((questionnaireList) => {
      //console.log("questionnaireList is ", questionnaireList);
      questionnaireList.forEach((quest) => {
        if (quest.mandatary == true) {
          //console.log("this.currentPatient.id ", this.currentPatient.id);
          this.questionaireMandatryIds.push(quest);
          this.hasQuestionnaryMandatary = true;
        }
      });
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
    this.reset();
  }

  public getPractitionerMe(): void {
    this.subscriptions.push(
      this.practitionerDataService.getPractitionerMe().subscribe(
        (practitionerMe) => {
          this.getSpeciality(practitionerMe);
          this.minimumAge = practitionerMe.establishment.config.minimumAge;
          this.thirdFormGroup = this.formBuilder.group({
            practitioner: [practitionerMe, Validators.required],
            practitionerSpeciality: [''],
          });
          this.isOk = true;
        },
        (error) => {
          console.error('PatientCreationFormComponent : Err-Practitionner-01 ', error);
          const errMsg = this.translateService.instant('Err-Practitionner-01');
          this.snackBarService.show(errMsg, 'danger');
        },
      ),
    );
  }

  public onNextClick(): void {
    if (this.firstFormGroup.valid && this.isAgeValid && this.isHeightValid && this.isWeightValid) {
      this.firstFormValid = true;
    } else {
      this.firstFormValid = false;
    }
  }

  public compareFn(p1: Practitioner, p2: Practitioner): boolean {
    return p1 && p2 ? p1.id === p2.id : p1 === p2;
  }

  public getSpeciality(practitioner): void {
    this.subscriptions.push(
      this.practitionerSpecialityService.getPractitionerSpeciality(practitioner.specialityId).subscribe(
        (speciality) => {
          if (speciality) this.speciality = speciality.name;
        },
        (error) => {
          console.error('PatientCreationFormComponent: Err-Practitionner-02 ', error);
          const errMsg = this.translateService.instant('Err-Practitionner-02');
          this.snackBarService.show(errMsg, 'danger');
        },
      ),
    );
  }

  public onChangePractitioner(): void {
    const practitioner: Practitioner = this.thirdFormGroup.value.practitioner;
    this.getSpeciality(practitioner);
  }

  public onBirthdateChange(birthdate): void {
    if (birthdate) {
      const years = moment().diff(birthdate, 'years');

      this.age = years.toString();
      if (years < 0 || years < this.minimumAge) {
        this.isAgeValid = false;
      } else {
        this.isAgeValid = true;
      }
    }
  }

  public onCalculImc(): void {
    let weight = this.firstFormGroup.value.weight;
    let height = this.firstFormGroup.value.height / 100;
    if (weight && (weight < 20 || weight > 200)) {
      this.isWeightValid = false;
    } else this.isWeightValid = true;
    if (height && (this.firstFormGroup.value.height < 50 || this.firstFormGroup.value.height > 270)) {
      this.isHeightValid = false;
    } else this.isHeightValid = true;
    this.imc = '-';
    if (weight > 0 && height > 0 && this.isHeightValid && this.isWeightValid) {
      this.imc = parseFloat((weight / (height * height)).toFixed(1)).toString();
    }
  }

  public createNewPatient(): void {
    const firstFormValue = this.firstFormGroup.value;
    const secondFormValue = this.secondFormGroup.value;
    const thirdFormValue = this.thirdFormGroup.value;
    let fullAddress: string = secondFormValue.address + ' ' + secondFormValue.postalCode + ' ' + secondFormValue.city;
    fullAddress = fullAddress.trim();

    this.newPatient = {
      birthDate: new Date(firstFormValue.birthDate),
      consent: firstFormValue.consent === 'yes' ? true : false,
      folderNumber: firstFormValue.folderNumber,
      gender: firstFormValue.gender,
      lateralization: firstFormValue.laterality,
      lastName: firstFormValue.lastName,
      firstName: firstFormValue.firstName,
      height: firstFormValue.height ? parseInt(firstFormValue.height, 10) : 0,
      weight: firstFormValue.weight ? parseInt(firstFormValue.weight, 10) : 0,
      practitionerId: thirdFormValue.practitioner.id,
      address: fullAddress,
      email: secondFormValue.email,
      mobilePhoneNumber: secondFormValue.mobilePhoneNumber,
      phoneNumber: secondFormValue.phoneNumber,
    };

    this.subscriptions.push(
      this.patientDataService.createPatient(this.newPatient).subscribe(
        (patient) => {
          //this.reset();
          const patientBrief: PatientBrief = {
            id: patient.id,
            lastName: patient.lastName,
            firstName: patient.firstName,
            delegateTo: null,
          };
          this.snackBarService.show(this.translateService.instant('app.snack.create.patient.success'), 'success');
          this.patientCreated = patientBrief;
          this.closeModal.emit(patientBrief);
        },
        (error) => {
          console.error('PatientCreationFormComponent: Err-Patient-01 ', error);
          const errMsg = this.translateService.instant('Err-Patient-01');
          this.snackBarService.show(errMsg, 'danger');
        },
      ),
    );
  }

  public closeFrame() {
    this.createMandQuest.emit();
  }

  public goToQuestionnayMandatary(): void {
    //this.dataService.setPatient(this.patientCreated);
    this.closeModal.emit();
    this.goTo('/courses/fried/questionnary/' + this.questionaireMandatryIds[0].id);
  }

  public goTo(page) {
    this.router.navigate([page]);
  }

  public reset(): void {
    this.firstFormGroup.reset();
    this.secondFormGroup.reset();
    this.thirdFormGroup.reset();
  }
}
