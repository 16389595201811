<div class="main-container flex-column">
  <div id="content">
    <img src="{{ imgStatusUrl }}" class="ribbon" alt="" />
  </div>
  <div class="flex-row">
    <ng-container>
      <button class="btn-important" (click)="removeCourse()" *ngIf="!hasAtLeastOneTest">
        <i class="fa fa-times"></i>
      </button>
    </ng-container>
  </div>
  <div>
    <div class="header"></div>
    <div class="header">
      {{ 'Patient.Profil.course_fragility' | translate }} (Fried)
      <p class="value">(25 {{ 'min' | translate }} )</p>
    </div>
    <div class="header">
      <p class="value">
        {{ 'Patient.Profil.course_tests' | translate }}
      </p>
    </div>
    <div class="container-title flex-column" *ngIf="dateLastTest && hasAtLeastOneTest">
      <span>{{ 'Patient.Profil.course_last_test' | translate }} : {{ dateLastTest | localdate }}</span>
    </div>
    <ul style="list-style-type: none; margin: 0px; padding: 0px;">
      <li style="padding: 20px 0px;">
        <img
          class="d-inline-block align-top"
          width="20"
          height="20"
          src="assets/images/icons/check-circle-uncheck.svg"
          alt=""
          *ngIf="!hasWeightTest"
        />
        <img
          class="d-inline-block align-top"
          width="20"
          height="20"
          src="assets/images/icons/check-circle-check.svg"
          alt=""
          *ngIf="hasWeightTest"
        />
        {{ 'navbar.massBalance' | translate }}
        <span style="float: right;" *ngIf="!hasWeightTest">5 {{ 'min' | translate }}</span>
      </li>
      <li style="padding: 20px 0px;" *ngIf="course && course.id">
        <img
          class="d-inline-block align-top"
          width="20"
          height="20"
          src="assets/images/icons/check-circle-uncheck.svg"
          alt=""
          *ngIf="!hasQAPPATest"
        />
        <img
          class="d-inline-block align-top"
          width="20"
          height="20"
          src="assets/images/icons/check-circle-check.svg"
          alt=""
          *ngIf="hasQAPPATest"
        />
        {{ 'app.questionnary' | translate }}&nbsp;QAPPA
        <span style="float: right;" *ngIf="!hasQAPPATest">5 {{ 'min' | translate }}</span>
      </li>
      <li style="padding: 20px 0px;" *ngIf="course && course.id">
        <img
          class="d-inline-block align-top"
          width="20"
          height="20"
          src="assets/images/icons/check-circle-uncheck.svg"
          alt=""
          *ngIf="!hasCESTest"
        />
        <img
          class="d-inline-block align-top"
          width="20"
          height="20"
          src="assets/images/icons/check-circle-check.svg"
          alt=""
          *ngIf="hasCESTest"
        />
        {{ 'app.questionnary' | translate }}&nbsp;CES-D
        <span style="float: right;" *ngIf="!hasCESTest">5 {{ 'min' | translate }}</span>
      </li>
      <!--
      <li style="padding: 20px 0px;" *ngIf="!course || !course.id">
        <a [ngClass]="{ selected: currentPage === 'romberg' }" class="list-item" (click)="goTo('/courses/fried/romberg')">
          <img
            class="d-inline-block align-top"
            width="20"
            height="20"
            src="assets/images/icons/check-circle-uncheck.svg"
            alt=""
          />
          {{ 'navbar.rombergTest' | translate }}
          <span style="float: right;">5 {{ 'min' | translate }}</span>
        </a>
      </li>-->
      <li style="padding: 20px 0px;">
        <img
          class="d-inline-block align-top"
          width="20"
          height="20"
          src="assets/images/icons/check-circle-uncheck.svg"
          alt=""
          *ngIf="!hasGripTest"
        />
        <img
          class="d-inline-block align-top"
          width="20"
          height="20"
          src="assets/images/icons/check-circle-check.svg"
          alt=""
          *ngIf="hasGripTest"
        />
        {{ 'navbar.strengthTest' | translate }}
        <span style="float: right;" *ngIf="!hasGripTest">5 {{ 'min' | translate }}</span>
      </li>
      <li style="padding: 20px 0px;">
        <img
          class="d-inline-block align-top"
          width="20"
          height="20"
          src="assets/images/icons/check-circle-uncheck.svg"
          alt=""
          *ngIf="!hasWalkingTest"
        />
        <img
          class="d-inline-block align-top"
          width="20"
          height="20"
          src="assets/images/icons/check-circle-check.svg"
          alt=""
          *ngIf="hasWalkingTest"
        />
        {{ 'navbar.walkTest' | translate }}
        <span style="float: right;" *ngIf="!hasWalkingTest">5 {{ 'min' | translate }} </span>
      </li>
    </ul>
  </div>
  <div class="footer flex-column" *ngIf="course.status === 0">
    <button class="btn btn-forth" type="button" data-toggle="collapse" (click)="launchCourse()">
      {{ 'Patient.Profil.start_the_course' | translate }}
    </button>
  </div>
  <div class="footer flex-column" *ngIf="course.status === 1">
    <button class="btn btn-forth" type="button" data-toggle="collapse" (click)="launchCourse()">
      {{ 'Course.finish' | translate }}
    </button>
  </div>
  <div class="footer flex-column" *ngIf="course.status === 2 || course.status === -1">
    <button class="btn btn-forth" type="button" data-toggle="collapse" (click)="launchCourse()">
      {{ 'Course.see' | translate }}
    </button>
  </div>
</div>
