import { Component, ElementRef, ViewChild, Input, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { LongitudinalGraph } from 'src/app/modules/romberg/models/longitudinal.model';
import { TranslateService } from '@ngx-translate/core';
import { LocalDatePipe } from 'src/app/modules/time/pipes/localdate.pipe';

@Component({
  selector: 'app-graph-verticalbar',
  templateUrl: './verticalBar.component.html',
})
export class VerticalBarComponent implements OnInit {
  @Input()
  public idLongitudinal: string;

  @Input()
  public idTitre: string;

  @Input()
  public title: string;

  @Input()
  public data: LongitudinalGraph;

  @ViewChild('horizontalBar', { static: true })
  public histo: ElementRef;

  constructor(private translateService: TranslateService, private localDatePipe: LocalDatePipe) {}

  public ngOnInit(): void {
    const points: any[] = [];
    const pointsDateYO: any[] = [];
    const pointsDateYF: any[] = [];
    const labels: string[] = [];
    const labelsYF: string[] = [];

    for (const pointData of this.data.graphYO) {
      pointsDateYO.push(pointData.y);
      labels.push(this.localDatePipe.parse(pointData.x));
    }
    for (const pointData of this.data.graphYF) {
      pointsDateYF.push(pointData.y);
    }
    const datasets: any = { datasets: points };

    this.initGraph(labels, pointsDateYO, pointsDateYF);
  }

  private initGraph(labels: string[], pointsDateYO: any, pointsDateYF: any): void {
    const canvas = this.histo.nativeElement as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    const chartData = {
      labels,
      datasets: [
        {
          label: this.translateService.instant('Romberg.EyesOpen'),
          backgroundColor: '#9683EC',
          data: pointsDateYO,
        },
        {
          label: this.translateService.instant('Romberg.EyesClosed'),
          backgroundColor: '#096A09',
          data: pointsDateYF,
        },
      ],
    };

    const myChart = new Chart(canvas, {
      type: 'bar',
      data: chartData,
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                display: true,
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  }
}
