import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';

export enum Gender {
  NotFilled = 0,
  Unknown = 1,
  Male,
  Female,
  Other,
}

export const GenderArray = [
  { name: 'NotFilled', completeName: '-', value: Gender.NotFilled },
  { name: 'Unknown', completeName: i18nKey('Gender.Unknown'), value: Gender.Unknown },
  { name: 'Male', completeName: i18nKey('Gender.Male'), value: Gender.Male },
  { name: 'Female', completeName: i18nKey('Gender.Female'), value: Gender.Female },
  { name: 'Other', completeName: i18nKey('Gender.Other'), value: Gender.Other },
];
