import { QuestionnaireControllerService, QuestionnaireSummary } from '@abilycare/dal-client';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Console } from 'console';
import { Subscription } from 'rxjs/internal/Subscription';

export class QuestionnariesByFamily {
  family: string;
  list: Array<QuestionnaireSummary>;
}

@Component({
  selector: 'app-questionnary-list',
  templateUrl: './questionnary-list.component.html',
  styleUrls: ['./questionnary-list.component.scss'],
  // styleUrls: ['./questionnary-list.component.scss', '../../shared/scss/common.scss'],
})
export class QuestionnaryListComponent implements OnInit, OnDestroy {
  @Output() public questionnarySelected: EventEmitter<Array<QuestionnaireSummary>> = new EventEmitter();
  @Output() public displayQuestionnary: EventEmitter<QuestionnaireSummary> = new EventEmitter();

  @Input() public disabled: boolean = false;

  private subscriptions: Subscription[] = [];
  public questionnarySelectId: number = -1;
  public familyList: string[] = [];
  public questionnariesByFamily: Array<QuestionnariesByFamily> = new Array();
  public questionnariesListSelected: Array<QuestionnaireSummary> = new Array();

  constructor(
    private questionnaryService: QuestionnaireControllerService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.refresh();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
    this.familyList = null;
    this.questionnariesByFamily = null;
    this.questionnariesListSelected = null;
  }

  public refresh(): void {
    const lang = this.translateService.currentLang;
    this.questionnariesByFamily = [];
    this.questionnariesListSelected = [];

    this.subscriptions.push(
      this.questionnaryService.questionnaireControllerFind(lang).subscribe(
        (questionnariesList) => {
          // Creation of questionnaries filtered by family
          questionnariesList.forEach((questionnary) => {
            if (this.questionnariesByFamily && this.questionnariesByFamily.length > 0) {
              const found = this.questionnariesByFamily.filter(
                (questionnaryByFamily) => questionnaryByFamily.family === questionnary.family,
              );
              if (found && found.length === 1) {
                found[0].list.push(questionnary);
              } else {
                this.questionnariesByFamily.push({
                  family: questionnary.family,
                  list: [questionnary],
                });
              }
            } else {
              this.questionnariesByFamily.push({
                family: questionnary.family,
                list: [questionnary],
              });
            }
          });
          this.questionnarySelected.emit([]);
        },
        (error) => {
          console.log('Error questionnaireControllerFind', error);
        },
      ),
    );
  }

  // Call when checkbox change state. Add questionnary into an Array
  public onChangeState(questionnary: QuestionnaireSummary, isChecked: boolean): void {
    const result = this.questionnariesListSelected.filter((quest) => quest.id === questionnary.id);

    // Add questionnay into questionnariesListSelected
    if (isChecked && result && result.length === 0) {
      this.questionnariesListSelected.push(questionnary);
    } else if (!isChecked && result && result.length === 1) {
      // Delete questionnary of questionnariesListSelected
      const index = this.questionnariesListSelected.findIndex((quest) => quest.id === questionnary.id);
      if (index > -1) {
        this.questionnariesListSelected.splice(index, 1);
      }
    }
    this.questionnarySelected.emit(this.questionnariesListSelected);
  }

  public onClickQuestionnary(questionnary: QuestionnaireSummary): void {
    this.questionnarySelectId = questionnary.id;
    this.displayQuestionnary.emit(questionnary);
  }
}
