import { PatientDetail } from '@abilycare/dal-client';
import { Component, Input } from '@angular/core';
import { LocalDatePipe } from 'src/app/modules/time/pipes/localdate.pipe';

@Component({
  selector: 'app-rapport-header',
  templateUrl: './rapport-header.component.html',
  styleUrls: ['../rapport.common.scss', './rapport-header.component.scss'],
})
export class RapportHeaderComponent {
  @Input()
  public title: string;
  @Input()
  public establishmentLogo: string;
  @Input()
  public establishmentName: string;
  @Input()
  public patient: PatientDetail;
  @Input()
  public dateConsultation: string;
}
