import { ChangeDetectorRef, Component, ElementRef, ViewChild, Input, OnInit } from '@angular/core';
import { Chart, ChartConfiguration, ChartPoint } from 'chart.js';
import { LocalDatePipe } from 'src/app/modules/time/pipes/localdate.pipe';
import { LongitudinalGraph } from 'src/app/modules/walk/models/longitudinal.model';

@Component({
  selector: 'app-graph-timeline',
  templateUrl: './timelineChart.component.html',
})
export class TimelineChartComponent implements OnInit {
  @Input()
  public idLongitudinal2: string;

  @Input()
  public idTitre2: string;

  @Input()
  public title: string;

  @Input()
  public data: LongitudinalGraph;

  @Input()
  public width: number;

  @Input()
  public height: number;

  @Input()
  public min: number;

  @Input()
  public max: number;

  @ViewChild('horizontalBar', { static: true })
  public histo: ElementRef;

  constructor(private localDatePipe: LocalDatePipe) {}

  public ngOnInit(): void {
    const points: any[] = [];
    const pointsDate: any[] = [];
    const labels: string[] = [];
    const pointColors: string[] = [];
    let index = 0;
    for (const pointData of this.data.graph) {
      if (this.min && pointData.y == this.min) pointColors[index] = '#FF0000';
      else if (this.max && pointData.y == this.max) pointColors[index] = '#FF0000';
      else pointColors[index] = '#1b74e8';
      pointsDate.push(pointData);
      index++;
    }
    points.push({
      data: pointsDate,
      fill: false,
      showLine: true,
      borderColor: '#1b74e8',
      lineTension: 0,
      pointBorderColor: pointColors,
    });
    const datasets: any = { datasets: points };
    this.initGraph(datasets, labels);
  }

  private initGraph(datasets: any, labels: string[]): void {
    const canvas = this.histo.nativeElement as HTMLCanvasElement;

    let isResponsive = true;
    if (this.width && this.height) {
      canvas.height = this.height;
      canvas.width = this.width;
      isResponsive = false;
    }

    const ctx = canvas.getContext('2d');
    const ctorOptions: ChartConfiguration = {
      type: 'scatter',
      data: datasets,
      options: {
        responsive: isResponsive,
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const point = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] as ChartPoint;
              const date = this.localDatePipe.transform(point.x);
              return date + ' --- ' + (point.y as number).toFixed(1);
            },
          },
        },
        animation: { duration: 0 },
        legend: {
          display: false,
        },
        elements: {
          point: {
            pointStyle: 'circle',
          },
        },
        scales: {
          xAxes: [
            {
              type: 'time',
              position: 'bottom',
              time: {
                displayFormats: {
                  millisecond: 'YYYY-MM-DD',
                  second: 'YYYY-MM-DD',
                  minute: 'YYYY-MM-DD',
                  hour: 'YYYY-MM-DD',
                  day: 'YYYY-MM-DD',
                  week: 'YYYY-MM-DD',
                  month: 'YYYY-MM-DD',
                  quarter: 'YYYY-MM-DD',
                  year: 'YYYY-MM-DD HH:mm:ss',
                },
              },
              ticks: {
                minRotation: 45,
                maxRotation: 45,
                callback: (label) => {
                  const labelDate = label ? new Date(label) : null;
                  return labelDate ? this.localDatePipe.parse(labelDate, 'dd/MM/yyyy') : label;
                },
              },
            },
          ],
        },
      },
    };
    //console.log('GraphScatter', ctorOptions);
    const myChart = new Chart(ctx, ctorOptions);
  }
}
