<div class="main-container flex-column" *ngIf="isLoaded">
  <div class="header flex-row">
    <ng-container *ngIf="(rolesCheck.hasEditSyndrom | async) && canEdit; else elseLogo">
      <button class="btn btn-tertiary" *ngIf="isEditable" (click)="goToModify()">{{ 'Modifier' | translate }}</button>
      <button class="btn-important" (click)="removePatAtt()"><i class="fa fa-times"></i></button>
    </ng-container>
    <ng-template #elseLogo>
      <img src="./assets/images/icons/logo.svg" width="30" height="30" />
    </ng-template>
  </div>

  <div class="content flex-column">
    <div class="etiology-container">
      <p class="etiology-title title">{{ 'Patient.Etiologie' | translate }}</p>
      <p class="etiology-text value" *ngIf="patAttt.etiologyId; else elseBlock">{{ patAttt.etiology }}</p>
    </div>

    <div class="pathology-container">
      <p class="pathology-title title">{{ 'Patient.Pathology' | translate }}</p>
      <p class="pathology-text value" *ngIf="patAttt.pathologyId; else elseBlock">{{ patAttt.pathology }}</p>
    </div>

    <div class="deficits-type-container">
      <p class="deficits-type-title title">{{ 'Patient.Deficit_type' | translate }}</p>

      <div class="deficit-container" *ngIf="patAttt.symptoms; else elseBlock">
        <mat-accordion>
          <ng-container *ngFor="let deficit of patAttt.symptoms | slice: 0:3; let indexDef = index">
            <mat-expansion-panel (opened)="onOpened(indexDef)" (closed)="onClosed(indexDef)" class="expension-panel">
              <mat-expansion-panel-header>
                <mat-panel-title *ngIf="!symptomIsOpened[indexDef]">
                  {{ deficit.name | truncate: 20 }}
                  {{ 'Patient.Creationdate.Symptom' | translate }}
                  {{ patAttt.created | localdate }}
                </mat-panel-title>
                <mat-panel-title *ngIf="symptomIsOpened[indexDef]">
                  {{ deficit.name }}
                  {{ 'Patient.Creationdate.Symptom' | translate }}
                  {{ patAttt.created | localdate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="deficit-options">
                <div class="flex-column">
                  <span class="title">{{ 'Patient.Location' | translate }}: </span>
                  <span class="value">{{ deficit.location }}</span>
                </div>
                <div class="flex-column">
                  <span class="title">{{ 'Latéralisation' | translate }}: </span>
                  <span class="value">{{ deficit.lateralization }}</span>
                </div>
                <div class="flex-column">
                  <span class="title">{{ 'Patient.Temporality' | translate }}: </span>
                  <span class="value">{{ deficit.temporality }}</span>
                </div>
                <div class="flex-column">
                  <span class="title">{{ 'Patient.Rhythm' | translate }}: </span>
                  <span class="value">{{ deficit.rhythm }}</span>
                </div>
                <div class="flex-column">
                  <span class="title">{{ 'Patient.Current' | translate }}: </span>
                  <span class="value">{{ deficit.current }}</span>
                </div>
              </div>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
      </div>

      <div class="collapse multi-collapse" [id]="'tutu' + patAttt.id" *ngIf="patAttt.symptoms">
        <ng-container *ngFor="let deficit of patAttt.symptoms | slice: 3:patAttt.symptoms.length; let indexDef = index">
          <div class="deficit-container">
            <mat-expansion-panel
              (opened)="onOpened(indexDef + 3)"
              (closed)="onClosed(indexDef + 3)"
              class="expension-panel"
            >
              <mat-expansion-panel-header>
                <mat-panel-title *ngIf="!symptomIsOpened[indexDef + 3]">
                  {{ deficit.name | truncate: 20 }}
                </mat-panel-title>
                <mat-panel-title *ngIf="symptomIsOpened[indexDef + 3]">
                  {{ deficit.name }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="deficit-options">
                <div class="flex-column">
                  <span class="title">{{ 'Patient.Location' | translate }}: </span>
                  <span class="value">{{ deficit.location }}</span>
                </div>
                <div class="flex-column">
                  <span class="title">{{ 'Latéralisation' | translate }}: </span>
                  <span class="value">{{ deficit.lateralization }}</span>
                </div>
                <div class="flex-column">
                  <span class="title">{{ 'Patient.Temporality' | translate }}: </span>
                  <span class="value">{{ deficit.temporality }}</span>
                </div>
                <div class="flex-column">
                  <span class="title">{{ 'Patient.Rhythm' | translate }}: </span>
                  <span class="value">{{ deficit.rhythm }}</span>
                </div>
                <div class="flex-column">
                  <span class="title">{{ 'Patient.Current' | translate }}: </span>
                  <span class="value">{{ deficit.current }}</span>
                </div>
              </div>
            </mat-expansion-panel>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="footer flex-column">
    <button
      *ngIf="patAttt.symptoms && patAttt.symptoms.length > 3"
      class="btn btn-tertiary"
      type="button"
      data-toggle="collapse"
      [attr.data-target]="'#tutu' + patAttt.id"
      aria-expanded="false"
      [attr.aria-controls]="'tutu' + patAttt.id"
      (click)="openClose()"
    >
      <i class="fa fa-chevron-down icon" *ngIf="!isOpen"></i>
      <i class="fa fa-chevron-up icon" *ngIf="isOpen"></i>
    </button>
  </div>
</div>

<ng-template #elseBlock>
  <p class="value">{{ 'Patient.Not_specified' | translate }}</p>
</ng-template>
