import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  Router,
  NavigationStart,
  CanActivate,
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';

// import { environment } from 'environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';

import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

export interface ICanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class AuthGuard implements CanActivate, CanDeactivate<ICanComponentDeactivate> {
  public readonly redirectUrlAfterFirstLogin: string = '/Patient';

  constructor(private router: Router, private oauthService: OAuthService, private authService: AuthService) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (environment.skipAuthentication) {
      console.error('Authentication is skipped, check environment variable.');
      return true;
    }
    if (this.oauthService.hasValidAccessToken()) {
      console.log('AuthGuard: OK (valid token) for ' + state.url);
      /**
       * SFLAT-416
       */
      if (!state.url || state.url.length === 0 || state.url === '/') {
        this.router.navigate([this.redirectUrlAfterFirstLogin]);
      }
      // check role limitation if necessary.
      if (route.data && route.data.role) {
        const neededRole = route.data.role;
        return this.authService.hasRole(neededRole).pipe(
          tap((allowed) => {
            // log error
            if (!allowed) {
              console.warn(`Link to ${state.url} forbidden.`);
            }
          }),
        );
      }
      return true;
    } else {
      console.log('AuthGuard: FAIL (invalid token) for ' + state.url);
      const url = state.url;
      this.authService.stateUrl = url;
      this.router.navigate(['login']);
      return false;
    }
  }

  public canDeactivate(component: ICanComponentDeactivate) {
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
