import { Component, ElementRef, ViewChild, Input, OnInit } from '@angular/core';
import { Chart, ChartConfiguration, ChartDataSets } from 'chart.js';
import { ComparisonGraph } from 'src/app/modules/common/models/comparison.model';

/**
 * This graph is dedicated to comparaison graph.
 * It's to specific to be generalized
 */
@Component({
  selector: 'app-graph-horizontal',
  templateUrl: './horizontalBar.component.html',
})
export class HorizontalBarComponent implements OnInit {
  @Input()
  public title: string;

  @Input()
  public data: ComparisonGraph;

  @ViewChild('horizontalBar', { static: true })
  public histo: ElementRef;

  public ngOnInit(): void {
    const min = Math.min(...this.data.graph.map((pointOfData) => pointOfData.y));
    const max = Math.max(...this.data.graph.map((pointOfData) => pointOfData.y));
    const points: ChartDataSets[] = this.data.graph.map((pointOfData) => ({
      data: [
        { x: 0, y: pointOfData.y },
        { x: pointOfData.x, y: pointOfData.y },
      ],
      showLine: true,
      fill: false,
      borderColor: pointOfData.current
        ? 'rgba(0,128,0, 1)'
        : pointOfData.toCompare
        ? 'rgba(255, 0, 0, 1)'
        : 'rgba(0, 0, 255, 0.5)',
      //borderColor: pointOfData.current ? 'rgba(255, 0, 0, 1)' : 'rgba(0, 0, 255, 0.5)',
    }));
    //console.log('points in horizontalBar is ', points);
    const datasets = { datasets: points };
    this.initGraph(datasets, min, max);
  }

  private initGraph(datasets: any, ymin: number, ymax: number): void {
    const canvas = this.histo.nativeElement as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    const delta = (ymax - ymin) / 100;
    const ctorOptions: ChartConfiguration = {
      type: 'scatter',
      data: datasets,
      options: {
        animation: { duration: 0 },
        legend: {
          display: false,
        },
        elements: {
          point: {
            pointStyle: 'line',
          },
        },

        scales: {
          yAxes: [
            {
              ticks: {
                min: ymin - delta,
                max: ymax + delta,
              },
            },
          ],
        },
      },
    };
    //console.log('GraphScatter', ctorOptions);

    const myChart = new Chart(ctx, ctorOptions);
  }
}
