import { PatientPractitionerRelation, Practitioner } from '@abilycare/dal-client';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subscription } from 'rxjs';
import { PatientDataService } from 'src/app/modules/abilycare-data-access/api/patient-data.service';
import { PractitionerDataService } from 'src/app/modules/abilycare-data-access/api/practitioner-data.service';
import { RolesCheckService } from 'src/app/modules/core/services/authentification/roles-check.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';

@Component({
  selector: 'app-patient-delegate',
  templateUrl: './patient-delegate.component.html',
  styleUrls: ['./patient-delegate.component.scss'],
})
export class PatientDelegateComponent implements OnInit, OnDestroy {
  @Input() patientIdList: Array<number>;
  @Output() selectPractitionner: EventEmitter<{ list: Array<any>; type: string }> = new EventEmitter<{
    list: Array<any>;
    type: string;
  }>();
  @Output() deletePractitionner: EventEmitter<{ list: Array<any>; type: string }> = new EventEmitter<{
    list: Array<any>;
    type: string;
  }>();

  private subscriptions: Subscription[] = [];
  public practitionnersList: Array<Practitioner> = new Array<Practitioner>();
  public patientDelegationList: Array<PatientPractitionerRelation> = new Array<PatientPractitionerRelation>();

  public testDelegation: string = 'TestDelegation';
  public resultDelegation: string = 'ResultDelegation';
  public isLoaded: boolean = false;

  constructor(
    private practitionerDataService: PractitionerDataService,
    private patientDataService: PatientDataService,
    public rolesCheck: RolesCheckService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      forkJoin({
        allPractitionners: this.practitionerDataService.getAllPractitioners(),
        patientDelegationList: this.patientDataService.getAllDelegationsForMultiplePatients(this.patientIdList),
      }).subscribe(
        ({ allPractitionners, patientDelegationList }) => {
          this.practitionnersList = allPractitionners;
          this.patientDelegationList = patientDelegationList;
          this.isLoaded = true;
        },
        (error) => {
          console.error('PatientDelegateComponent : Err-Practitionner-03 ', error);
          const errMsg = this.translateService.instant('Err-Practitionner-03');
          this.snackBarService.show(errMsg, 'danger');
        },
      ),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.practitionnersList = null;
    this.patientDelegationList = null;
  }

  onSelectPractionner(practitionnerList) {
    this.selectPractitionner.emit(practitionnerList);
  }

  onDeletePractitionner(practitionnerList) {
    this.deletePractitionner.emit(practitionnerList);
  }
}
