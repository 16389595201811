import { PatientDetail, WeighingControllerService } from '@abilycare/dal-client';
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChange, ViewChild } from '@angular/core';
import * as Chart from 'chart.js';
import { ChartPoint } from 'chart.js';
import { LocalDatePipe } from 'src/app/modules/time/pipes/localdate.pipe';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { ChartType } from 'chart.js';

export interface IWeightDataset {
  label: string;
  data: Array<{ x: Date; y: number }>;
  lineTension: number;
  showLine: boolean;
  pointBorderColor: string[];
  pointBackgroundColor: string;
  pointRadius: number;
  pointBorderWidth: number;
}

@Component({
  selector: 'app-weight-graphics',
  templateUrl: './weight-graphics.component.html',
  styleUrls: ['./weight-graphics.component.scss'],
})
export class WeightGraphicsComponent implements OnInit, OnChanges {
  @ViewChild('canvasref', { static: true }) private canvasRef: ElementRef;
  @Input() finalWeight: number = -1;
  @Input() patient: PatientDetail;
  @Input() testData: any;

  public datasets: IWeightDataset[];
  private canvasElement: HTMLCanvasElement;
  private canvasContext: CanvasRenderingContext2D;
  public currentValue: number;

  public constructor(
    private localDatePipe: LocalDatePipe,
    private weightService: WeighingControllerService,
    private dataService: DataProviderService,
  ) {}

  public async ngOnInit() {
    this.patient = await this.dataService.retrievePatient();
    if (this.finalWeight > 0) this.currentValue = await this.finalWeight;
    else this.currentValue = await this.testData.value;
    const weightDataset: IWeightDataset = await {
      label: 'Checkup.Weight.Measure.Results',
      data: [],
      lineTension: 0,
      showLine: true,
      pointBorderColor: [],
      pointBackgroundColor: '',
      pointRadius: 4,
      pointBorderWidth: 2,
    };
    let index = 0;
    await weightDataset.data.push({ x: this.patient.created, y: this.patient.weight });
    //call if we are from creation weight interface, we use finalWeight and not the testData obj
    if ((await this.finalWeight) > 0) {
      index++;
      weightDataset.pointBorderColor[index] = '#FF0000';
      weightDataset.data.push({ x: new Date(), y: this.finalWeight });
      /* -- change to have the some graphics
        lastWeightDataset.pointBorderColor = '#FF0000';
        lastWeightDataset.data.push({ x: new Date(), y: this.finalWeight });
        */
    }

    await this.weightService.weighingControllerFindAllWeight(this.patient.id).subscribe((weighingList: any) => {
      //add weight patient to weigth dataset
      for (const weighingObj of weighingList) {
        index++;
        if (this.testData && weighingObj.id === this.testData.id) {
          weightDataset.pointBorderColor[index] = '#FF0000';
          weightDataset.data.push({ x: weighingObj.date, y: weighingObj.value });
        } else {
          weightDataset.pointBorderColor[index] = '';
          weightDataset.data.push({ x: weighingObj.date, y: weighingObj.value });
        }
      }
      weightDataset.data = weightDataset.data.sort((a, b) => {
        return +new Date(a.x) - +new Date(b.x);
      });
      //change we don't have the lastweight : just the weighing saved
      //this.datasets = [weightDataset, lastWeightDataset];
    });
    this.datasets = await [weightDataset];
    await this.refresh();
  }

  public refresh(): void {
    this.canvasElement = this.canvasRef.nativeElement as HTMLCanvasElement;
    this.canvasContext = this.canvasElement ? this.canvasElement.getContext('2d') : null;

    if (!this.canvasElement) console.warn('no element of weight canvas!');
    if (!this.canvasContext) console.warn('no context of weight canvas!');
    if (!this.datasets) console.warn('no data of weight canvas!');
    if (!this.canvasElement || !this.canvasContext || !this.datasets) return;

    const localdatasets: IWeightDataset[] = this.datasets;

    const configuration: Chart.ChartConfiguration = {
      type: 'scatter',
      data: {
        datasets: localdatasets,
      },
      options: {
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const point = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] as ChartPoint;
              const date = this.localDatePipe.transform(point.x);
              return date + ' --- ' + (point.y as number).toFixed(1);
            },
          },
        },
        animation: { duration: 0 },
        scales: {
          xAxes: [
            {
              type: 'time',
              position: 'bottom',
              time: {
                displayFormats: {
                  millisecond: 'YYYY-MM-DD',
                  second: 'YYYY-MM-DD',
                  minute: 'YYYY-MM-DD',
                  hour: 'YYYY-MM-DD',
                  day: 'YYYY-MM-DD',
                  week: 'YYYY-MM-DD',
                  month: 'YYYY-MM-DD',
                  quarter: 'YYYY-MM-DD',
                  year: 'YYYY-MM-DD HH:mm:ss',
                },
              },
              ticks: {
                minRotation: 45,
                maxRotation: 45,
                callback: (label) => {
                  const labelDate = label ? new Date(label) : null;
                  return labelDate ? this.localDatePipe.parse(labelDate, 'd/MM/yyyy') : label;
                },
              },
            },
          ],
        },
        events: ['click'],
        legend: {
          display: false,
        },
      },
    };
    const myChart = new Chart(this.canvasContext, configuration);
  }

  public ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    this.ngOnInit();
  }
}
