<div class="main-container">
  <div class="msg">
    <p>(<span class="red-text">*</span>){{ 'Patient.Profil.mandatory_field' | translate }}</p>
  </div>

  <div class="stepper-title">
    <p>Profil</p>
  </div>

  <form
    [formGroup]="profilFormGroup"
    class="needs-validation patient-form"
    [ngClass]="{ 'needs-validation': true, 'patient-form': true, 'was-validated': !firstFormValid }"
  >
    <div class="form-group formy col-sm-8">
      <label for="lastName">{{ 'Patient.Lastname' | translate }}<span class="red-text">*</span>:</label>
      <input class="form-control" id="lastName" formControlName="lastName" aria-describedby="lastName" required />
    </div>
    <div class="form-group formy col-sm-8">
      <label for="firstName">{{ 'Patient.Firstname' | translate }}<span class="red-text">*</span>:</label>
      <input class="form-control" id="firstName" placeholder="" formControlName="firstName" required />
    </div>
    <div class="form-group formy col-sm-8">
      <label for="folderNumber">{{ 'Patient.FolderNumber' | translate }}:</label>
      <input class="form-control" id="folderNumber" placeholder="" formControlName="folderNumber" />
    </div>
    <div class="form-group formy col-sm-6">
      <label for="gender">{{ 'Sexe' | translate }}<span class="red-text">*</span>:</label>
      <select class="form-control " id="gender" formControlName="gender" required>
        <option *ngFor="let gender of genders" [value]="gender.value">{{ gender.text | translate }}</option>
      </select>
    </div>
    <div class="flex-column form-group formy col-sm-8">
      <label for="birthDate">{{ 'Patient.BirthDate' | translate }}<span class="red-text">*</span>:</label>
      <div class="input-group">
        <input
          class="form-control"
          #birthDate
          (ngModelChange)="onBirthdateChange($event)"
          id="birthDate"
          formControlName="birthDate"
          [matDatepicker]="picker"
          placeholder="{{ 'formatDate' | translate }}"
          [max]="maxDate"
          required
        />
        <span class="input-group-prepend">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker touchUi #picker></mat-datepicker>
        </span>
        <p class="detail">
          {{ 'Patient.YearsOld' | translate }} : {{ age }} <ng-container>{{ 'ans' | translate }}</ng-container>
        </p>

        <div class="error-msg red-text">
          <p *ngIf="!isAgeValid">
            {{ 'Patient.Profil.minimum.years' | translate }} {{ minimumAge }} {{ 'ans' | translate }}
          </p>
        </div>
      </div>
    </div>
    <div class="form-group formy col-sm-6">
      <label for="laterality">{{ 'Patient.Profil.laterality' | translate }}<span class="red-text">*</span>:</label>
      <select class="form-control" id="laterality" formControlName="laterality" required>
        <option *ngFor="let laterality of lateralities" [value]="laterality.value">{{
          laterality.text | translate
        }}</option>
      </select>
    </div>

    <div class="form-group formy flex-column col-sm-6">
      <label>{{ 'Patient.Profil.consent' | translate }}<span class="red-text">*</span>:</label>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          formControlName="consent"
          name="consent"
          id="radioYes"
          value="yes"
          required
        />
        <label class="form-check-label" for="radioYes">
          {{ 'Oui' | translate }}
        </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          formControlName="consent"
          name="consent"
          id="radioNo"
          value="no"
          required
        />
        <label class="form-check-label" for="radioNo">
          {{ 'Non' | translate }}
        </label>
      </div>
    </div>
  </form>

  <div class="flex-row div-error-button">
    <div class="error-msg red-text">
      <p *ngIf="!firstFormValid">{{ 'Patient.Profil.fill_all_Field' | translate }}</p>
    </div>
    <div class="flex-row div-btn">
      <button class="btn btn-primary" (click)="validate()">{{ 'button.validation' | translate }}</button>
    </div>
  </div>
</div>
