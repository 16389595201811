// import { CommonModule } from '@angular/common';
import { ApiModule } from '@abilycare/dal-client';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ChartsModule } from 'ng2-charts';
import { NgxGaugeModule } from 'ngx-gauge';
import { AppModule } from 'src/app/app.module';
import { CommonModule as com } from '../common/common.module';
import { MathModule } from '../math/math.module';
import { RapportHeaderComponent } from './rapport-header/rapport-header.component';
import { RapportNewComponent } from './rapport-new/rapport-new.component';
import { RapportRombergPartComponent } from './rapport-romberg-part/rapport-romberg-part.component';
import { RapportSelectionComponent } from './rapport-selection/rapport-selection.component';
import { RapportWalkPartComponent } from './rapport-walk-part/rapport-walk-part.component';
import { RapportPraticienComponent } from './rapportPraticien/rapportPraticien.component';
import { TimeModule } from '../time/time.module';

@NgModule({
  declarations: [
    RapportNewComponent,
    RapportSelectionComponent,
    RapportHeaderComponent,
    RapportWalkPartComponent,
    RapportRombergPartComponent,
    RapportPraticienComponent,
  ],
  imports: [
    CommonModule,
    com,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    ChartsModule,
    MathModule,
    NgxGaugeModule,
    TimeModule,
  ],
  exports: [
    RapportNewComponent,
    RapportSelectionComponent,
    RapportHeaderComponent,
    RapportWalkPartComponent,
    RapportPraticienComponent,
  ],
})
export class RapportsModule {}
