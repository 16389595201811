import { BodyPosition } from './body-position.model';

export class SensorsState {
  public get platformReady(): boolean {
    return this.lfSensorConnected && this.rfSensorConnected && this.hipsSensorConnected && this.headSensorConnected;
  }
  public lfSensorConnected: boolean = false;
  public rfSensorConnected: boolean = false;
  public hipsSensorConnected: boolean = false;
  public headSensorConnected: boolean = false;
  public lfSensorBattery: number = null;
  public rfSensorBattery: number = null;
  public hipsSensorBattery: number = null;
  public headSensorBattery: number = null;
  public loading = false;
}
