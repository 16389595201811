<div class="main-container">
  <div *ngFor="let questionnaries of questionnariesByFamily">
    <div class="family-container">
      <p class="family">{{ questionnaries.family }}</p>
    </div>

    <button
      class="questionnary-container"
      [ngClass]="{ 'questionnary-active': questionnarySelectId === questionnary.id }"
      *ngFor="let questionnary of questionnaries.list"
      (click)="onClickQuestionnary(questionnary)"
      [disabled]="disabled"
    >
      <div class="checkbox-container-questionnary d-flex">
        <mat-checkbox
          class="dropdown-item-questionnary"
          (change)="onChangeState(questionnary, $event.checked)"
          [disabled]="disabled"
        ></mat-checkbox>
      </div>

      <div class="title-container">
        <p class="title">
          <font color="red">{{ questionnary.mandatary ? '*' : '' }}</font>
          {{ questionnary.name | truncate: 20 }}
        </p>
      </div>
      <div class="timer-container">
        <span class="time">{{ questionnary.durationInMinutes }} {{ 'min' | translate }}</span>
      </div>
    </button>
  </div>
</div>
