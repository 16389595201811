import { CourseControllerService, PatientDetail } from '@abilycare/dal-client';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataProviderService } from 'src/app/services/data-provider.service';

@Component({
  selector: 'app-history-navbar',
  templateUrl: './history-navbar.component.html',
  styleUrls: ['./history-navbar.component.scss'],
})
export class HistoryNavbarComponent implements OnInit {
  @Input() patient: PatientDetail;
  @Input() returnTitle: string;
  @Output() selectPage: EventEmitter<string> = new EventEmitter<string>();

  public lastScoreFragility: number;

  constructor(private courseController: CourseControllerService, private dataService: DataProviderService) {}

  async ngOnInit() {
    this.patient = await this.dataService.retrievePatient();
    await this.courseController
      .courseControllerFindLastCourse(this.patient.id)
      .subscribe((val) => (this.lastScoreFragility = val.score));
  }

  getFragilityScore(): any {
    this.courseController.courseControllerFindLastCourse(this.patient.id).subscribe((value) => {
      this.lastScoreFragility = value.score;
    });
    if (this.lastScoreFragility) return this.lastScoreFragility;
    else return '-';
  }
}
