import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  PatientPatattControllerService,
  NewPatattInPatient,
  Patatt,
  LoopbackCount,
  PatattDetail,
} from '@abilycare/dal-client';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class PatAttDataService {
  constructor(
    private patientPatattControllerService: PatientPatattControllerService,
    private translateService: TranslateService,
  ) {}

  createPatAtt(patientId: number, replacedPatattIds: Array<number>, newPatAtt: NewPatattInPatient): Observable<Patatt> {
    return this.patientPatattControllerService
      .patientPatattControllerCreate(patientId, replacedPatattIds, newPatAtt)
      .pipe(map((res) => res as Patatt));
  }

  deletePatAtt(patientId: number, patAttId: number): Observable<LoopbackCount> {
    return this.patientPatattControllerService
      .patientPatattControllerDelete(patientId, patAttId)
      .pipe(map((res) => res as LoopbackCount));
  }

  getAllPatAtt(patientId: number): Observable<Array<PatattDetail>> {
    const lang = this.translateService.currentLang;
    return this.patientPatattControllerService
      .patientPatattControllerFind(patientId, lang)
      .pipe(map((res) => res as Array<PatattDetail>));
  }
}
