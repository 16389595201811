export const SUBINDICATORS: string[] = ['all', 'ages', 'avc', 'par', 'patol', 'sains', 'ves'];
export enum INDICATORS {
  surfaceYF = 'Surface YF',
  surfaceYO = 'Surface YO',
  swayDensityYF = 'Sway Density YF',
  swayDensityYO = 'Sway Density YO',
  varianceLateraleYF = 'Variance Latérale YF',
  varianceLateraleYO = 'Variance Latérale YO',
  vitessemoyenneYF = 'Vitesse moyenne YF',
  vitessemoyenneYO = 'Vitesse moyenne YO',
  scoreYF = 'Score YF',
  scoreYO = 'Score YO',
}
