export class AnalysisResults {
  public ScoreYF: number;
  public ScoreYO: number;
  public SurfaceYF: number;
  public SurfaceYO: number;
  public SwayDensityYF: number;
  public SwayDensityYO: number;
  public VarianceLateraleYF: number;
  public VarianceLateraleYO: number;
  public SpeedMoyYO: number;
  public SpeedMoyYF: number;
  public models: string[] = [];
  public duration: number;
  public graphes: {
    statok: string;
    stabilo: string;
    stasym: string;
  };
}
