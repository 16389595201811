import { Injectable, EventEmitter } from '@angular/core';
import { PatientBrief, PatientDetail } from '@abilycare/dal-client';
import { LocalStorage } from '@ng-idle/core';

@Injectable({ providedIn: 'root' })
export class DataProviderService {
  private patient: PatientDetail;
  private showHeader: boolean;
  patientEmitter: EventEmitter<PatientDetail> = new EventEmitter<PatientDetail>();
  private localStorage: LocalStorage;
  private language: string = null;

  public constructor(localStorage: LocalStorage) {
    this.localStorage = localStorage;
  }

  setPatient(patient) {
    this.patientEmitter.emit(patient);
    this.patient = patient;
    this.localStorage.setItem('patient', JSON.stringify(this.patient));
  }

  getPatient(): EventEmitter<PatientDetail> {
    return this.patientEmitter;
  }

  public retrievePatient() {
    if (!this.patient) {
      this.patient = JSON.parse(this.localStorage.getItem('patient'));
    }
    return this.patient;
  }

  public storeHeaderShowed(showed: boolean) {
    this.showHeader = showed;
    this.localStorage.setItem('showHeader', JSON.stringify(this.showHeader));
  }

  public isHeaderShowed() {
    if (this.showHeader == null) {
      const data = this.localStorage.getItem('showHeader');
      if (data === 'false') {
        this.showHeader = false;
      } else {
        this.showHeader = true;
      }
    }
    return this.showHeader;
  }

  public setLanguage(language: string) {
    this.language = language;
    console.log(this.language);
    this.localStorage.setItem('language', JSON.stringify(this.language));
  }

  public getLanguage() {
    if (!this.language) {
      this.language = JSON.parse(this.localStorage.getItem('language'));
    }
    console.log(this.language);
    return this.language;
  }
}
