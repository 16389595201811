import { PatientDetail, UpdatePatient } from '@abilycare/dal-client';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subscription } from 'rxjs';
import { PatientDataService } from 'src/app/modules/abilycare-data-access/api/patient-data.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';

@Component({
  selector: 'app-patient-creation-health-form',
  templateUrl: './patient-creation-health-form.component.html',
  styleUrls: ['./patient-creation-health-form.component.scss'],
})
export class PatientCreationHealthFormComponent implements OnInit, OnDestroy {
  @Input() patient: PatientDetail;
  @Output() closeModal = new EventEmitter();

  public healthFormGroup: FormGroup;
  public imc: string = '-';
  private subscriptions: Subscription[] = [];
  public formValid: boolean = true;
  public isHeightValid: boolean = true;
  public isWeightValid: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private patientDataService: PatientDataService,
    private dataService: DataProviderService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.healthFormGroup = this.formBuilder.group({
      height: [this.patient.height],
      weight: [this.patient.weight],
    });
    this.onCalculImc();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
  }

  public validate(): void {
    if (this.healthFormGroup.valid && this.isHeightValid && this.isWeightValid) {
      this.formValid = true;
      const form = this.healthFormGroup.value;
      const updatePatient: UpdatePatient = {
        id: this.patient.id,
        height: form.height,
        weight: form.weight,
      };

      this.subscriptions.push(
        forkJoin({
          data: this.patientDataService.updatePatient(updatePatient),
          patient: this.patientDataService.getPatientById(this.patient.id),
        }).subscribe(
          ({ data, patient }) => {
            this.snackBarService.show(this.translateService.instant('app.snack.update.patient.success'), 'success');
            this.dataService.setPatient(patient);
            this.closeModal.emit();
          },
          (err) => {
            console.error('PatientCreationHealthFormComponent: Err-Patient-04 ', err);
            const errMsg = this.translateService.instant('Err-Patient-04');
            this.snackBarService.show(errMsg, 'danger');
          },
        ),
      );
    } else {
      this.formValid = false;
    }
  }

  public onCalculImc(): void {
    let weight = this.healthFormGroup.value.weight;
    let height = this.healthFormGroup.value.height / 100;
    if (weight && (weight < 20 || weight > 200)) {
      this.isWeightValid = false;
    } else this.isWeightValid = true;
    if (height && (this.healthFormGroup.value.height < 50 || this.healthFormGroup.value.height > 270)) {
      this.isHeightValid = false;
    } else this.isHeightValid = true;

    /*
    if (weight < 0) {
      weight = 0;
      this.healthFormGroup.controls.weight.setValue(0);
    }
    if (height < 0) {
      height = 0;
      this.healthFormGroup.controls.height.setValue(0);
    }
    if (weight > 500) {
      weight = 500;
      this.healthFormGroup.controls.weight.setValue(500);
    }
    if (height > 3) {
      height = 3;
      this.healthFormGroup.controls.height.setValue(300);
    }
*/
    this.imc = '-';
    if (weight > 0 && height > 0) {
      this.imc = parseFloat((weight / (height * height)).toFixed(1)).toString();
    }
  }

  public cancel(): void {
    this.closeModal.emit();
  }
}
