import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { AnalysisParameters } from '../models/analysis-parameters.model';
import { AnalysisState, JobState } from '../models/analysis-state.model';
import { AnalysisResults } from '../models/analysis-results.model';
import { AppConfigurationService } from 'src/app/app-configuration.service';
import * as medicalConst from 'src/app/tools/medical.constants';
/* import { LoggerService } from 'src/app/services/logger/logger.service';*/

@Injectable()
export class RemoteInteraction {
  public static readonly startDataAnalysisPath = '/analysis/{guid}/start';
  public static readonly getAnalysisStatePath = '/analysis/{guid}/state';
  public static readonly discardSessionDataPath = '/analysis/{guid}';
  public static readonly getAnalysisResultsForSessionPath = '/analysis/{guid}/{lang}';
  public static readonly getAnalysisResultsForBilanPath = '/analysis/{bilanId}/{lang}';

  public constructor(
    /* private logger: LoggerService,*/
    private http: HttpClient,
    private appConfigurationService: AppConfigurationService,
    private translateService: TranslateService,
  ) {}
  public async getJsonModel(url: string) {
    const x: Promise<any> = this.http
      .get(url)
      .pipe(
        map((r) => {
          /* this.logger.info('Json reçu #1', r);*/
          return r;
        }),
      )
      .toPromise();

    return x;
  }

  public async discardSessionData(currentRecordingSessionId: string): Promise<void> {
    /* this.logger.info(this.constructor.name + ': discarded recording for uuid: ', currentRecordingSessionId);*/

    let url = this.appConfigurationService.rombergProxyBaseUri + RemoteInteraction.discardSessionDataPath;
    url = url.replace('{guid}', currentRecordingSessionId);

    let x = this.http.delete(url);
    x = this.toLog(x, 'discardSessionData(' + currentRecordingSessionId + ')');
    return this.toPromise(x);
  }

  public async startDataAnalysis(currentRecordingSessionId: string, parameters: AnalysisParameters): Promise<void> {
    /* this.logger.info(this.constructor.name + ': started data analysis for uuid: ', currentRecordingSessionId);*/

    let url = this.appConfigurationService.rombergProxyBaseUri + RemoteInteraction.startDataAnalysisPath;
    url = url.replace('{guid}', currentRecordingSessionId);

    let x = this.http.post(url, parameters);
    x = this.toLog(x, 'startDataAnalysis(' + currentRecordingSessionId + ')');
    return this.toPromise(x);
  }

  public async getAnalysisState(currentRecordingSessionId: string): Promise<AnalysisState> {
    /* this.logger.info(this.constructor.name + ': fetching state for analysis with uuid: ', currentRecordingSessionId);*/

    let url = this.appConfigurationService.rombergProxyBaseUri + RemoteInteraction.getAnalysisStatePath;
    url = url.replace('{guid}', currentRecordingSessionId);

    const x = this.http
      .get(url)
      .pipe(map((j) => this.mapJsonToAnalysisState(j)))
      .toPromise();

    return x;
  }

  public async getAnalysisResultsForSession(currentRecordingSessionId: string): Promise<AnalysisResults> {
    /* this.logger.info(this.constructor.name + ': fetching analysis data for uuid: ', currentRecordingSessionId);*/
    const lang = this.translateService.currentLang;

    let url = this.appConfigurationService.rombergProxyBaseUri + RemoteInteraction.getAnalysisResultsForSessionPath;
    url = url.replace('{guid}', currentRecordingSessionId);
    url = url.replace('{lang}', lang);

    const x = this.http
      .get(url)
      .pipe(map((j) => this.mapJsonToAnalysisData(j)))
      .toPromise();

    return x;
  }

  public async getAnalysisResultsForBilan(bilanId: number): Promise<AnalysisResults> {
    /* this.logger.info(this.constructor.name + ': fetching analysis data for bilanId: ', bilanId);*/
    const lang = this.translateService.currentLang;

    let url = this.appConfigurationService.rombergProxyBaseUri + RemoteInteraction.getAnalysisResultsForBilanPath;
    url = url.replace('{bilanId}', '' + bilanId);
    url = url.replace('{lang}', lang);

    //console.log('getAnalysisResultsForBilan URL', url);

    const x = this.http
      .get(url)
      .pipe(map((j) => this.mapJsonToAnalysisData(j)))
      .toPromise();

    return x;
  }

  public async persistAnalysisResults(currentRecordingSessionId: string, bilanId: number): Promise<void> {
    console.error(
      this.constructor.name +
        ': DUMMY METHOD CALL: persistAnalysisResults(' +
        currentRecordingSessionId +
        ', ' +
        bilanId +
        ')',
    );

    /** DUMMY MOCK CRAP */
    await of(true)
      .pipe(delay(2000))
      .toPromise();
  }

  private mapJsonToAnalysisData(data: any): AnalysisResults {
    const out = new AnalysisResults();
    const j = data;
    this.mapVariables(j, out);
    this.mapModels(j, out);
    this.mapGraphes(j, out);
    return out;
  }

  private mapVariables(data: any, out: AnalysisResults) {
    const variables = data.features.ListVariables as any[];

    // map data.features.ListVariables into an array of number
    const values: number[] = variables.reduce((prev: number[], current) => {
      prev[current.Variable] = current.Values[0];
      return prev;
    }, new Array<number>());
    out.ScoreYF = values['Score YF'];
    out.ScoreYO = values['Score YO'];
    out.SurfaceYF = values['Surface YF'];
    out.SurfaceYO = values['Surface YO'];
    out.SwayDensityYF = values['Sway Density YF'];
    out.SwayDensityYO = values['Sway Density YO'];
    out.VarianceLateraleYF = values['Variance Latérale YF'];
    out.VarianceLateraleYO = values['Variance Latérale YO'];
    out.SpeedMoyYF = values['Vitesse Moyenne YF'];
    out.SpeedMoyYO = values['Vitesse Moyenne YO'];
    out.duration = medicalConst.TIME_TO_ROMBERG;
  }

  private mapModels(data: any, out: AnalysisResults) {
    const models = data.model;
    for (const key in models) {
      if (models.hasOwnProperty(key)) {
        out.models[key] = models[key];
      }
    }
  }

  private mapGraphes(data: any, out: AnalysisResults) {
    const v = {
      statok: data.statok,
      stabilo: data.stabilo,
      stasym: data.stasym,
      // semioAll: data.semioall,
      // semio0_17: data.semio[0],
      // semio18_39: data.semio[1],
      // semio40_59: data.semio[2],
      // semio60_79: data.semio[3],
      // semio80_200: data.semio[4],
    };
    out.graphes = v;
  }

  private mapJsonToAnalysisState(stateData: any): AnalysisState {
    const state = this.getJobState(stateData.state as string);
    const output = { jobState: state, errorCode: stateData.error };
    /* this.logger.info(this.constructor.name + ': mapJsonToAnalysisState(): ', output);*/
    return output;
  }

  private getJobState(state: string): JobState {
    switch (state) {
      case 'running':
        return JobState.Running;
      case 'done':
        return JobState.Done;
      case 'error':
        return JobState.Error;
      default:
        throw new Error('Unsupported value for ' + typeof JobState + ': ' + state);
    }
  }

  private toLog(source: Observable<object>, where: string): Observable<object> {
    return source.pipe(
      tap((r) => {
        /* this.logger.info(this.constructor.name + ': (HTTP) ' + where + ': ' + r);*/
        return r;
      }),
    );
  }

  private toPromise(source: Observable<object>): Promise<void> {
    return source
      .pipe(
        map((r) => {
          /**/
        }),
      )
      .toPromise();
  }
}
