<div class="shoes_selector">
  <div class="row">
    <div class="col-lg-12">
      <span class="page-sub-title">{{ 'chaussure' | translate }}</span>
      <select [ngModel]="value.shoesType" (ngModelChange)="setShoes($event)" [disabled]="disabled">
        <option *ngFor="let shoes of footWears" [ngValue]="shoes.alias">{{ shoes.translate }}</option>
      </select>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <span class="page-sub-title">{{ 'aideMarche' | translate }}</span>
      <select [ngModel]="value.walkingAid" (ngModelChange)="setHelp($event)" [disabled]="disabled">
        <option *ngFor="let help of walkingAid" [ngValue]="help.translate">{{ help.translate }}</option>
      </select>
    </div>
  </div>
</div>
