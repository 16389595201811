<div class="main-container">
  <div class="stepper-title">
    <p>{{ 'Patient.Profil.contact.information' | translate }}</p>
  </div>

  <form [formGroup]="personalFormGroup" class="patient-form">
    <div class="form-group formy col-sm-5">
      <label for="phoneNumber">{{ 'Patient.Profil.fix_number' | translate }}:</label>
      <input class="form-control" id="phoneNumber" placeholder="" formControlName="phoneNumber" />
    </div>
    <div class="form-group formy col-sm-5">
      <label for="mobilePhoneNumber">{{ 'Patient.Profil.mobile_number' | translate }}:</label>
      <input class="form-control" id="mobilePhoneNumber" placeholder="" formControlName="mobilePhoneNumber" />
    </div>
    <div class="form-group formy col-sm-8">
      <label for="email">{{ 'Patient.Email' | translate }}:</label>
      <input class="form-control" id="email" placeholder="" formControlName="email" />
    </div>
    <div class="form-group formy col-sm-8">
      <label for="address">{{ 'Patient.Profil.postal_address' | translate }}:</label>
      <input class="form-control" id="address" placeholder="" formControlName="address" />
    </div>
  </form>

  <div class="flex-row div-btn">
    <button class="btn btn-primary" (click)="validate()">{{ 'button.validation' | translate }}</button>
  </div>
</div>
