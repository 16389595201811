/**
 * 3rd-party modules
 */
import { NgModule } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { VersionService } from './services/version/version.service';
import { AuthGuard } from './services/authentification/guard.service';
import { AuthService } from './services/authentification/auth.service';
import { AuthConfig } from 'angular-oauth2-oidc';
import { authConfigFactory } from './services/authentification/authConfig.factory';
import { AppConfigurationService } from 'src/app/app-configuration.service';
import { NotificationService } from './services/notification/notification.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    // TranslateModule.forChild(),
  ],
  declarations: [],
  providers: [
    VersionService,
    AuthGuard,
    AuthService,
    NotificationService,
    {
      provide: AuthConfig,
      useFactory: authConfigFactory,
      deps: [AppConfigurationService],
    },
  ],
  exports: [],
})
export class CoreModule {}
