<div class=" main-container">
  <div
    class="patAtt flex-column pointer"
    *ngIf="patAttNumber > 0 && !isHealthy && !isUnknown"
    (click)="openAllPatatt()"
  >
    <p class="para nb-pathologie">{{ patAttNumber }} {{ 'Patient.Assigned_pathology' | translate }}</p>
    <p class=" para btn-tertiary">{{ 'Patient.Profil.details' | translate }}</p>
  </div>

  <div class="patAtt flex-column pointer" *ngIf="isHealthy" (click)="openAllPatatt()">
    <p class="para nb-pathologie">{{ 'Patient.Healthy_clinical_profile' | translate }}</p>
    <p class=" para btn-tertiary" (click)="openAllPatatt()">{{ 'Patient.Profil.details' | translate }}</p>
  </div>

  <div class="patAtt flex-column pointer" *ngIf="isUnknown" (click)="openAllPatatt()">
    <p class="para nb-pathologie">{{ 'Patient.Unknown_clinical_profile' | translate }}</p>
    <p class=" para btn-tertiary" (click)="openAllPatatt()">{{ 'Patient.Profil.details' | translate }}</p>
  </div>
</div>
