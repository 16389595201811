<app-custom-navbar [patient]="patient" [returnTitle]="returnTitle" *ngIf="!fromHistory"></app-custom-navbar>
<app-fried-history-navbar
  [patient]="patient"
  [returnTitle]="returnTitleHistory"
  *ngIf="fromHistory"
></app-fried-history-navbar>
<app-courses-breadcrumb-navbar [testName]="testName | translate" [course]="course"></app-courses-breadcrumb-navbar>
<div class="col-md-12">
  <div class="row">
    <div class="col-md-3 p-0">
      <app-fried-navbar [course]="course" [fromHistory]="fromHistory"></app-fried-navbar>
    </div>
  </div>
</div>

<div class="col-md-12 footer-content-component"></div>
