<app-custom-navbar
  [patient]="patient"
  [returnTitle]="returnTitle"
  (selectPage)="onSelectPage($event)"
></app-custom-navbar>
<app-courses-breadcrumb-navbar [testName]="testName | translate" [course]="course"></app-courses-breadcrumb-navbar>
<div class="col-md-12">
  <div class="row">
    <div class="col-md-3 p-0">
      <app-fried-navbar [currentPage]="'grip'" (selectPage)="onSelectPage($event)" [course]="course"></app-fried-navbar>
    </div>
    <div class="col-md-9 fried-info">
      <div class="row">
        <div class="col-md-11  full-size-content-component" style="font-weight: normal;">
          <img
            class="d-inline-block align-top"
            width="20"
            height="20"
            src="assets/images/icons/info-circle.svg"
            alt=""
          />
          {{ 'app.grip.protocol.followStep' | translate }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-7 simple-content-component">
          <div style="margin-bottom: 30px;">
            {{ 'app.grip.protocol.stepTitle' | translate }}
          </div>
          <div class="protocol-step">
            <img class="d-inline-block align-top" width="20" height="20" src="assets/images/icons/icon-1.svg" alt="" />
            {{ 'app.grip.protocol.step1' | translate }}
          </div>
          <div class="protocol-step">
            <img class="d-inline-block align-top" width="20" height="20" src="assets/images/icons/icon-2.svg" alt="" />
            {{ 'app.grip.protocol.step2' | translate }}
          </div>
          <div class="protocol-step">
            <img class="d-inline-block align-top" width="20" height="20" src="assets/images/icons/icon-3.svg" alt="" />
            {{ 'app.grip.protocol.step3' | translate }}
          </div>
          <!--
          <div class="tutorial-button-container">
            <button
              (click)="resetModal()"
              class="btn btn-tertiary"
              data-target="#weightTutorialModal"
              data-toggle="modal"
            >
              {{ 'app.weight.protocol.tutorialLink' | translate }}
            </button>
          </div>
          -->
        </div>
        <div class="col-md-4 simple-content-component">
          <app-grip-monitor #gripMonitor></app-grip-monitor>
          <!-- 
          <div style="text-align: center;" *ngIf="!gripSaved">
            <div style="text-align: center; margin-top: 25px; margin-bottom: 25px;">
              <img
                class="d-inline-block align-center pointer"
                src="assets/images/images/waiting.svg"
                *ngIf="!gripSaved"
              />
            </div>
            <div style="text-align: center;" *ngIf="!gripSaved">{{ 'app.grip.finish.mesurement' | translate }}</div>
        
          </div>
        --></div>
      </div>
      <div class="row" *ngIf="!gripSaved">
        <div class="col-md-11 full-size-content-component">
          <div>{{ 'app.grip.mesurement' | translate }}</div>
          <div class="col-md-6" class="timer" style="text-align: center;" *ngIf="gripMonitor.gripState.gripConnected">
            <div>
              <span class="number timer">00:0{{ timer.secondes }}</span>
            </div>
          </div>
          <div class="row" style="margin-bottom: 30px; font-weight: normal;">
            <div class="col-md-6" style="border-right: 1px solid #DCE2E7;">
              <div>
                <div style="text-align: center;">
                  <div>
                    {{ 'app.grip.lefthand' | translate }}
                    <img
                      class="d-inline-block align-top"
                      width="20"
                      height="20"
                      src="assets/images/icons/lefthand.svg"
                      alt=""
                    />
                    <img
                      class="d-inline-block align-top"
                      width="20"
                      height="20"
                      src="assets/images/icons/righthand-desactivated.svg"
                      alt=""
                    />
                    <span style="margin-right: 10px; color:#70757a;">
                      {{ 'app.grip.righthand' | translate }}
                    </span>
                  </div>
                  <div style="text-align: center; margin-bottom: 30px; font-weight: normal;"></div>
                  <div style="text-align: center;  margin-bottom: 30px; font-weight: normal;">
                    <input
                      class="form-control"
                      [disabled]="gripMonitor.gripState.gripConnected"
                      type="number"
                      id="leftHandV1"
                      (keypress)="numericOnly($event)"
                      min="0"
                      aria-describedby="leftHandV1"
                      (change)="validateMesure()"
                      [(ngModel)]="leftHandV1"
                      style="width : 30%"
                      placeholder="{{ 'app.grip.firstMesure' | translate }}"
                      required
                    />
                    <button
                      [disabled]="isTestLeftV1Running && !isTest1LeftHandV1Finished"
                      class="btn btn-tertiary"
                      *ngIf="gripMonitor.gripState.gripCalibrated"
                      style="text-align: center;"
                      (click)="
                        !isTest1LeftHandV1Finished ? startTestFromGrip('leftHandV1') : restartTestFromGrip('leftHandV1')
                      "
                    >
                      {{ isTest1LeftHandV1Finished ? restartTestTitle : startTestTitle }}
                    </button>
                  </div>
                  <div style="text-align: center; margin-bottom: 30px; font-weight: normal;">
                    <input
                      class="form-control"
                      type="number"
                      [disabled]="gripMonitor.gripState.gripConnected"
                      id="leftHandV2"
                      aria-describedby="leftHandV2"
                      (change)="validateMesure()"
                      min="0"
                      (keypress)="numericOnly($event)"
                      [(ngModel)]="leftHandV2"
                      style="width : 30%"
                      placeholder="{{ 'app.grip.secondMesure' | translate }}"
                    />
                    <button
                      class="btn btn-tertiary"
                      [disabled]="isTestLeftV2Running && !isTest1LeftHandV2Finished"
                      *ngIf="gripMonitor.gripState.gripCalibrated"
                      style="text-align: center;"
                      (click)="
                        !isTest1LeftHandV2Finished ? startTestFromGrip('leftHandV2') : restartTestFromGrip('leftHandV2')
                      "
                    >
                      {{ isTest1LeftHandV2Finished ? restartTestTitle : startTestTitle }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div>
                <div style="text-align: center;">
                  <div style="text-align: center;">
                    <span style="margin-right: 10px; color:#70757a;">
                      {{ 'app.grip.lefthand' | translate }}
                    </span>
                    <img
                      class="d-inline-block align-top"
                      width="20"
                      height="20"
                      src="assets/images/icons/lefthand-desactivated.svg"
                      alt=""
                    />
                    <img
                      class="d-inline-block align-top"
                      width="20"
                      height="20"
                      src="assets/images/icons/righthand.svg"
                      alt=""
                    />
                    {{ 'app.grip.righthand' | translate }}
                  </div>
                  <div style="text-align: center; margin-bottom: 30px; font-weight: normal;"></div>
                  <div style="text-align: center; margin-bottom: 30px; font-weight: normal;">
                    <input
                      class="form-control"
                      type="number"
                      id="rightHandV1"
                      [disabled]="gripMonitor.gripState.gripConnected"
                      aria-describedby="rightHandV1"
                      (change)="validateMesure()"
                      (keypress)="numericOnly($event)"
                      min="0"
                      [(ngModel)]="rightHandV1"
                      style="width : 30%"
                      placeholder="{{ 'app.grip.firstMesure' | translate }}"
                      required
                    />
                    <button
                      class="btn btn-tertiary"
                      [disabled]="isTestRightV1Running && !isTest1RightHandV1Finished"
                      *ngIf="gripMonitor.gripState.gripCalibrated"
                      style="text-align: center;"
                      (click)="
                        !isTest1RightHandV1Finished
                          ? startTestFromGrip('rightHandV1')
                          : restartTestFromGrip('rightHandV1')
                      "
                    >
                      {{ isTest1RightHandV1Finished ? restartTestTitle : startTestTitle }}
                    </button>
                  </div>
                  <div></div>
                  <div style="text-align: center; margin-bottom: 30px; font-weight: normal;">
                    <input
                      class="form-control"
                      type="number"
                      [disabled]="gripMonitor.gripState.gripConnected"
                      id="rightHandV2"
                      aria-describedby="rightHandV2"
                      (change)="validateMesure()"
                      min="0"
                      (keypress)="numericOnly($event)"
                      [(ngModel)]="rightHandV2"
                      style="width : 30%"
                      placeholder="{{ 'app.grip.secondMesure' | translate }}"
                    />
                    <button
                      class="btn btn-tertiary"
                      [disabled]="isTestRightV2Running && !isTest1RightHandV2Finished"
                      *ngIf="gripMonitor.gripState.gripCalibrated"
                      style="text-align: center;"
                      (click)="
                        !isTest1RightHandV2Finished
                          ? startTestFromGrip('rightHandV2')
                          : restartTestFromGrip('rightHandV2')
                      "
                    >
                      {{ isTest1RightHandV2Finished ? restartTestTitle : startTestTitle }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="gripSaved">
        <div class="col-md-7 simple-content-component">
          <div>{{ 'app.grip.mesurement' | translate }}</div>
          <div class="row" style="margin-bottom: 30px; font-weight: normal;">
            <div class="col-md-6" style="border-right: 1px solid #DCE2E7;">
              <div>
                <div style="text-align: center;">
                  <div>
                    {{ 'app.grip.lefthand' | translate }}
                    <img
                      class="d-inline-block align-top"
                      width="20"
                      height="20"
                      src="assets/images/icons/lefthand.svg"
                      alt=""
                    />
                    <img
                      class="d-inline-block align-top"
                      width="20"
                      height="20"
                      src="assets/images/icons/righthand-desactivated.svg"
                      alt=""
                    />
                    <span style="margin-right: 10px; color:#70757a;">
                      {{ 'app.grip.righthand' | translate }}
                    </span>
                  </div>
                  <div style="text-align: center; margin-bottom: 30px; font-weight: normal;"></div>
                  <div style="text-align: center; margin-bottom: 30px; font-weight: normal;">
                    {{ 'app.grip.firstMesure' | translate }}
                  </div>
                  <div style="text-align: center; margin-bottom: 30px; font-weight: normal;">
                    <h3>{{ leftHandV1 | float: 2 }} Kg</h3>
                  </div>
                  <div style="text-align: center; margin-bottom: 30px; font-weight: normal;">
                    {{ 'app.grip.secondMesure' | translate }}
                  </div>
                  <div style="text-align: center; margin-bottom: 30px; font-weight: normal;">
                    <h3>{{ leftHandV2 | float: 2 }} Kg</h3>
                  </div>
                </div>
                <!--
                <div class="flex-row div-modify">
                  <button class="btn btn-tertiary" (click)="modify()">
                    {{ 'app.test.restart' | translate }}
                  </button>
                </div>
                -->
              </div>
            </div>
            <div class="col-md-6">
              <div>
                <div style="text-align: center;">
                  <div style="text-align: center;">
                    <span style="margin-right: 10px; color:#70757a;">
                      {{ 'app.grip.lefthand' | translate }}
                    </span>
                    <img
                      class="d-inline-block align-top"
                      width="20"
                      height="20"
                      src="assets/images/icons/lefthand-desactivated.svg"
                      alt=""
                    />
                    <img
                      class="d-inline-block align-top"
                      width="20"
                      height="20"
                      src="assets/images/icons/righthand.svg"
                      alt=""
                    />
                    {{ 'app.grip.righthand' | translate }}
                  </div>
                  <div style="text-align: center; margin-bottom: 30px; font-weight: normal;"></div>
                  <div style="text-align: center; margin-bottom: 30px; font-weight: normal;">
                    {{ 'app.grip.firstMesure' | translate }}
                  </div>
                  <div style="text-align: center; margin-bottom: 30px; font-weight: normal;">
                    <h3>{{ rightHandV1 | float: 2 }} Kg</h3>
                  </div>
                  <div style="text-align: center; margin-bottom: 30px; font-weight: normal;">
                    {{ 'app.grip.secondMesure' | translate }}
                  </div>
                  <div style="text-align: center; margin-bottom: 30px; font-weight: normal;">
                    <h3>{{ rightHandV2 | float: 2 }} Kg</h3>
                  </div>
                </div>
              </div>
              <!--
              <div class="flex-row div-modify">
                <button class="btn btn-tertiary" (click)="modify()">
                  {{ 'app.test.restart' | translate }}
                </button>
              </div>
              -->
            </div>
          </div>
        </div>
        <div class="col-md-4 simple-content-component">
          <div>
            <img
              class="d-inline-block align-top pointer"
              data-target="#gripGraphicsModal"
              data-toggle="modal"
              src="assets/images/images/graphique.svg"
              alt=""
              *ngIf="gripSaved"
            />
          </div>
          <div class="d-flex justify-content-center">
            <button class="btn btn-tertiary" data-target="#gripGraphicsModal" data-toggle="modal">
              {{ 'app.walk.access.graphic' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 footer-content-component">
      <button *ngIf="gripCalculated" class="btn btn-primary general-button right-button" (click)="saveGrip()">
        {{ 'button.validation' | translate }}
      </button>
    </div>
  </div>
</div>
<!--
<div
  class="modal fade"
  id="weightTutorialModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="weightTutorialModalTitle"
  aria-hidden="true"
  #basicModal
>
  <div class="modal-dialog modal-dialog-centered modal-medium" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="weightTutorialModalTitle">
          {{ 'plateform.installation' | translate }}
        </h5>
        <button #btnClose type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-weight-tutorial #weightTutorial></app-weight-tutorial>
      </div>
      <div class="modal-footer d-flex justify-content-end">
        <button class="btn btn-primary" (click)="onCloseModal()">{{ 'Close' | translate }}</button>
      </div>
    </div>
  </div>
</div>
-->
<button #btnOpenQuit data-target="#gripQuitTestModal" data-toggle="modal" [hidden]="true"></button>

<div
  class="modal fade"
  id="gripQuitTestModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="griptQuitTestModalTitle"
  aria-hidden="true"
  #weightQuitTestModal
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="gripQuitTestModalModalTitle">
          {{ 'app.walk.impossible.quit.title' | translate }}
        </h5>
        <button #btnClosWeightQuitTestModal type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{ 'app.walk.impossible.quit.message' | translate }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">{{ 'Ok' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<button #btnQuitBeforeSave data-target="#gripQuitBeforeSaveModal" data-toggle="modal" [hidden]="true"></button>
<div
  class="modal fade"
  id="gripQuitBeforeSaveModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="gripQuitBeforeSaveModalTitle"
  aria-hidden="true"
  #weightQuitBeforeSaveModal
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="gripQuitBeforeSaveModalModalTitle">
          {{ 'app.quit.before.save.title' | translate }}
        </h5>
        <button #btnClosGripQuitBeforeSaveModal type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p [innerHTML]="'app.grip.impossible.quit.before.save.message' | translate"></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-tertiary" (click)="goTo()" data-dismiss="modal">
          {{ 'app.quit.before.save.button.yes' | translate }}
        </button>
        <button type="button" class="btn btn-primary" data-dismiss="modal">{{ 'Non' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="gripGraphicsModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="gripGraphicsModalTitle"
  aria-hidden="true"
  #gripGraphics
>
  <div class="modal-dialog modal-dialog-centered modal-medium" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button #btnCloseGraphics type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-grip-graphics [grip]="grip"></app-grip-graphics>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" data-dismiss="modal" aria-label="Close">{{ 'Close' | translate }}</button>
      </div>
    </div>
  </div>
</div>
