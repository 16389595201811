import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ServiceDalConfig } from './service-dal.config';

@Injectable()
export class ServiceDal {
  constructor(private httpClient: HttpClient, private configuration: ServiceDalConfig) {}

  public get daluri() {
    return this.configuration.uriBase;
  }
}
