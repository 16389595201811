<div id="print-section">
  <!-- WALKING PATIENT REPORT -->
  <div *ngIf="(typeRapport == 'Walk' || !typeRapport) && walkAnalysisResult && patient">
    <div class="container-fluid">
      <div id="print-section-Walk">
        <app-rapport-header
          [dateConsultation]="dateConsultation"
          [patient]="patient"
          [establishmentLogo]="currentEstablishmentLogo"
          [establishmentName]="currentEstablishmentName"
          [title]="titleWalk"
        ></app-rapport-header>
        <hr />
        <app-rapport-walk-part
          [shoes]="shoesSelectorValues"
          [analysisResult]="walkAnalysisResult"
          [agePatient]="agePatient"
          [doubleTask]="doubleTask"
        ></app-rapport-walk-part>
      </div>
    </div>
  </div>
  <!-- ROMBERG PATIENT REPORT -->
  <div *ngIf="(typeRapport == 'Romberg' || !typeRapport) && rombergAnalysisResult">
    <div class="container-fluid">
      <div id="print-section-Romberg">
        <app-rapport-header
          [dateConsultation]="dateConsultation"
          [patient]="patient"
          [establishmentLogo]="currentEstablishmentLogo"
          [establishmentName]="currentEstablishmentName"
          [title]="titleRomberg"
        ></app-rapport-header>
        <hr />
        <app-rapport-romberg-part
          [shoes]="shoesSelectorValues"
          [analysisResult]="rombergAnalysisResult"
          [openedEyesLeftSupport]="openedEyesLeftSupport"
          [closedEyesLeftSupport]="closedEyesLeftSupport"
          [typePlate]="typePlate"
        >
        </app-rapport-romberg-part>
      </div>
    </div>
  </div>
  <div class="cleareanceformenu"></div>
</div>
<div class="col-md-12 footer-content-rapport">
  <button class="btn btn-primary" (click)="returnChoiceReport()">
    {{ 'previous' | translate }}
  </button>
  <button class="btn btn-primary" (click)="printPatientReport()">{{ 'print' | translate }}</button>
</div>
