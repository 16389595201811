<app-fried-history-navbar
  [patient]="patient"
  [returnTitle]="returnTitle"
  (selectPage)="onSelectPage($event)"
></app-fried-history-navbar>
<app-fried-custom-navbar
  [testName]="testName"
  [analysisResult]="analyisResult"
  [testData]="testData"
  [walkToCompare]="rombergToCompare"
  [resultsWalkToCompare]="analyisResultToCompare"
  [displayDoubleTask]="false"
>
</app-fried-custom-navbar>
<div class="col-md-12">
  <div class="row">
    <div class="col-md-7 simple-content-component" style="height: 50%;" *ngIf="showdata">
      <div>{{ 'app.romberg.results' | translate }}</div>
      <div class="result-summary">
        <div class="row">
          <div class="col-lg-6" style="border-right: 1px solid #DCE2E7; text-align: center;">
            <div class="row">
              <div class="col-lg-12 content-box">
                <img
                  class="d-inline-block align-top"
                  width="20"
                  height="20"
                  src="assets/images/icons/eye-open.svg"
                  alt=""
                />
                <span style="margin-right: 10px; color:#70757a;text-transform: uppercase;">{{
                  'Romberg.EyesOpen' | translate
                }}</span>
              </div>
            </div>
            <div class="col-md-12 flex-column assessment-container">
              <span class="text title">{{ 'Checkup.Romberg.Summary.ScoreYO' | translate }}</span>
              <span class="text value">
                {{ analyisResult.ScoreYO | float: 1 }} | {{ analyisResultToCompare.ScoreYO | float: 1 }}
              </span>
              <div class="custom-slider">
                <ngx-slider
                  [(value)]="valueEyesScoreOpen"
                  [(highValue)]="highValueEyesScoreOpen"
                  [options]="optionsEyesScore"
                >
                </ngx-slider>
              </div>
            </div>
            <div class="col-md-12 flex-column assessment-container">
              <span class="text title">{{ 'Checkup.Romberg.Summary.SurfaceYO' | translate }}</span>
              <span class="text value">
                {{ analyisResult.SurfaceYO | float: 1 }} &nbsp;cm<sup>2</sup> |
                {{ analyisResultToCompare.SurfaceYO | float: 1 }} &nbsp;cm<sup>2</sup>
              </span>
              <div class="custom-slider">
                <ngx-slider
                  [(value)]="valueEyesAreaOpen"
                  [(highValue)]="highValueEyesAreaOpen"
                  [options]="optionsEyesArea"
                >
                </ngx-slider>
              </div>
            </div>
            <div class="col-md-12 flex-column assessment-container">
              <span class="text title">{{ 'Checkup.Romberg.Summary.SwayDensityYO' | translate }}</span>
              <span class="text value">
                {{ analyisResult.SwayDensityYO | float: 1 }} &nbsp;s |
                {{ analyisResultToCompare.SwayDensityYO | float: 1 }} &nbsp;s
              </span>
              <div class="custom-slider">
                <ngx-slider
                  [(value)]="valueEyesSwayDensityOpen"
                  [(highValue)]="highValueEyesSwayDensityOpen"
                  [options]="optionsEyesSwayDensity"
                >
                </ngx-slider>
              </div>
            </div>
            <div class="col-md-12 flex-column assessment-container">
              <span class="text title">{{ 'Checkup.Romberg.Summary.VarianceLateraleYO' | translate }}</span>
              <span class="text value">
                {{ analyisResult.VarianceLateraleYO | float: 3 }}
                | {{ analyisResultToCompare.VarianceLateraleYO | float: 3 }}
              </span>
              <div class="custom-slider">
                <ngx-slider
                  [(value)]="valueEyesLateralVarianceOpen"
                  [(highValue)]="highValueEyesLateralVarianceOpen"
                  [options]="optionsEyesLateralVariance"
                >
                </ngx-slider>
              </div>
            </div>
            <div class="col-md-12 flex-column assessment-container">
              <span class="text title">{{ 'Checkup.Romberg.Summary.SpeedMoyYO' | translate }}</span>
              <span class="text value">
                {{ analyisResult.SpeedMoyYO | float: 1 }} &nbsp;mm.s<sup>-1</sup> |
                {{ analyisResultToCompare.SpeedMoyYO | float: 1 }} &nbsp;mm.s<sup>-1</sup>
              </span>
              <div class="custom-slider">
                <ngx-slider
                  [(value)]="valueEyesAverageSpeedOpen"
                  [(highValue)]="highValueEyesAverageSpeedOpen"
                  [options]="optionsAverageSpeed"
                >
                </ngx-slider>
              </div>
            </div>
            <div
              class="col-md-12 flex-column assessment-container"
              *ngIf="
                testData.typePlate == 'Kinvent' &&
                rombergToCompare.typePlate == 'Kinvent' &&
                testData.openedEyesLeftSupport &&
                rombergToCompare.openedEyesLeftSupport
              "
            >
              <span class="text title">{{ 'Checkup.Romberg.Summary.OpenedprcSupport' | translate }}</span>
              <span class="text value">
                {{ testData.openedEyesLeftSupport | number: '1.0-0' }}% -
                {{ 100 - testData.openedEyesLeftSupport | number: '1.0-0' }}% |
                {{ rombergToCompare.openedEyesLeftSupport | number: '1.0-0' }}% -
                {{ 100 - rombergToCompare.openedEyesLeftSupport | number: '1.0-0' }}%
              </span>
              <div class="custom-slider">
                <ngx-slider
                  [(value)]="valueEyesPrcLeftSupportOpen"
                  [(highValue)]="highValueEyesPrcLeftSupportOpen"
                  [options]="optionsPrcLeftSupportOpen"
                >
                </ngx-slider>
              </div>
            </div>
          </div>
          <div class="col-lg-6" style="border-right: 1px solid #DCE2E7; text-align: center;">
            <div class="row">
              <div class="col-lg-12 content-box">
                <img
                  class="d-inline-block align-top"
                  width="20"
                  height="20"
                  src="assets/images/icons/eye-close.svg"
                  alt=""
                />
                <span style="margin-right: 10px; color:#70757a; text-transform: uppercase;">{{
                  'Romberg.EyesClosed' | translate
                }}</span>
              </div>
            </div>
            <div class="col-md-12 flex-column assessment-container">
              <span class="text title">{{ 'Checkup.Romberg.Summary.ScoreYF' | translate }}</span>
              <span class="text value">
                {{ analyisResult.ScoreYF | float: 1 }} | {{ analyisResultToCompare.ScoreYF | float: 1 }}
              </span>
              <div class="custom-slider">
                <ngx-slider
                  [(value)]="valueEyesScoreClose"
                  [(highValue)]="highValueEyesScoreClose"
                  [options]="optionsEyesScoreClose"
                >
                </ngx-slider>
              </div>
            </div>
            <div class="col-md-12 flex-column assessment-container">
              <span class="text title">{{ 'Checkup.Romberg.Summary.SurfaceYF' | translate }}</span>
              <span class="text value">
                {{ analyisResult.SurfaceYF | float: 1 }} &nbsp;cm<sup>2</sup> |
                {{ analyisResultToCompare.SurfaceYF | float: 1 }} &nbsp;cm<sup>2</sup>
              </span>
              <div class="custom-slider">
                <ngx-slider
                  [(value)]="valueEyesAreaClose"
                  [(highValue)]="highValueEyesAreaClose"
                  [options]="optionsEyesAreaClose"
                >
                </ngx-slider>
              </div>
            </div>
            <div class="col-md-12 flex-column assessment-container">
              <span class="text title">{{ 'Checkup.Romberg.Summary.SwayDensityYF' | translate }}</span>
              <span class="text value">
                {{ analyisResult.SwayDensityYF | float: 1 }} &nbsp;s |
                {{ analyisResultToCompare.SwayDensityYF | float: 1 }} &nbsp;s
              </span>
              <div class="custom-slider">
                <ngx-slider
                  [(value)]="valueEyesSwayDensityClose"
                  [(highValue)]="highValueEyesSwayDensityClose"
                  [options]="optionsEyesSwayDensityClose"
                >
                </ngx-slider>
              </div>
            </div>
            <div class="col-md-12 flex-column assessment-container">
              <span class="text title">{{ 'Checkup.Romberg.Summary.VarianceLateraleYF' | translate }}</span>
              <span class="text value">
                {{ analyisResult.VarianceLateraleYF | float: 3 }}
                | {{ analyisResultToCompare.VarianceLateraleYF | float: 3 }}
              </span>
              <div class="custom-slider">
                <ngx-slider
                  [(value)]="valueEyesLateralVarianceClose"
                  [(highValue)]="highValueEyesLateralVarianceClose"
                  [options]="optionsEyesLateralVarianceClose"
                >
                </ngx-slider>
              </div>
            </div>
            <div class="col-md-12 flex-column assessment-container">
              <span class="text title">{{ 'Checkup.Romberg.Summary.SpeedMoyYF' | translate }}</span>
              <span class="text value">
                {{ analyisResult.SpeedMoyYF | float: 1 }} &nbsp;mm.s<sup>-1</sup> |
                {{ analyisResultToCompare.SpeedMoyYF | float: 1 }} &nbsp;mm.s<sup>-1</sup>
              </span>
              <div class="custom-slider">
                <ngx-slider
                  [(value)]="valueEyesAverageSpeedClose"
                  [(highValue)]="highValueEyesAverageSpeedClose"
                  [options]="optionsAverageSpeedClose"
                >
                </ngx-slider>
              </div>
            </div>
            <div
              class="col-md-12 flex-column assessment-container"
              *ngIf="
                testData.typePlate == 'Kinvent' &&
                rombergToCompare.typePlate == 'Kinvent' &&
                testData.closedEyesLeftSupport &&
                rombergToCompare.closedEyesLeftSupport
              "
            >
              <span class="text title">{{ 'Checkup.Romberg.Summary.ClosedprcSupport' | translate }}</span>
              <span class="text value">
                {{ testData.closedEyesLeftSupport | number: '1.0-0' }}% -
                {{ 100 - testData.closedEyesLeftSupport | number: '1.0-0' }}% |
                {{ rombergToCompare.closedEyesLeftSupport | number: '1.0-0' }}% -
                {{ 100 - rombergToCompare.closedEyesLeftSupport | number: '1.0-0' }}%
              </span>
              <div class="custom-slider">
                <ngx-slider
                  [(value)]="valueEyesPrcLeftSupportClose"
                  [(highValue)]="highValueEyesPrcLeftSupportClose"
                  [options]="optionsPrcLeftSupportClose"
                >
                </ngx-slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="graphics simple-content-component flex-column">
        <img
          class="d-inline-block align-top pointer"
          (click)="openGraphModal()"
          data-target="#rombergGraphModal"
          data-toggle="modal"
          src="assets/images/images/graphique.svg"
          alt=""
        />
        <button
          (click)="openGraphModal()"
          data-target="#rombergGraphModal"
          data-toggle="modal"
          class="btn btn-tertiary"
        >
          {{ 'app.walk.access.graphic' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Graph model romberg -->
<div
  class="modal fade"
  id="rombergGraphModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="rombergGraphModalTitle"
  aria-hidden="true"
  #rombergGraphModal
>
  <div class="modal-dialog modal-dialog-centered modal-large" style="max-width: 1500px;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="rombergGraphModalTitle">
          {{ graphTitle }}
        </h5>
        <button #btnCloseGraphModal type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="romberg-graph">
          <div class="statokinesigram" *ngIf="isStatokinesigram">
            <div class="row">
              <ng-template #zoomstatokinesigram>
                <div class="zoom" (click)="closeChart()">
                  <img *ngIf="standardGraph" [attr.src]="data.statok | secure | async" alt="" />
                  <img *ngIf="asymetrieGraph" [attr.src]="data.statsym | secure | async" alt="" />
                  <img
                    *ngIf="standardGraph"
                    [attr.src]="dataToCompare.statok | secure | async"
                    height="400px;"
                    alt=""
                  />
                  <img
                    *ngIf="asymetrieGraph"
                    [attr.src]="dataToCompare.statsym | secure | async"
                    height="400px;"
                    alt=""
                  />
                </div>
              </ng-template>
              <div *ngIf="!zoom; else zoomstatokinesigram" class="col-lg-10 offset-lg-1" style="text-align : center;">
                <div class="row">
                  <div class="col-lg-12">
                    <button
                      (click)="changeToStandard()"
                      type="button"
                      class="btn btn-primary choice-button"
                      [class.selected]="standardGraph"
                    >
                      {{ 'Checkup.Romberg.Graph.Statok.Standard' | translate }}
                    </button>
                    <button
                      (click)="changeToAsymetrie()"
                      type="button"
                      class="btn btn-primary choice-button"
                      [class.selected]="asymetrieGraph"
                    >
                      {{ 'Checkup.Romberg.Graph.Statok.Asymetry' | translate }}
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12" (click)="zoomGraph()" style="cursor: zoom-in;">
                    <img *ngIf="standardGraph" [attr.src]="data.statok | secure | async" height="400px;" alt="" />
                    <img *ngIf="asymetrieGraph" [attr.src]="data.statsym | secure | async" height="400px;" alt="" />
                    <img src="assets/images/icons/rounded-rectangle-green.svg" style="width:25px; height:30px;" />
                    {{ testData.date | localdate: 'datetime' }}
                    <img
                      *ngIf="standardGraph"
                      [attr.src]="dataToCompare.statok | secure | async"
                      height="400px;"
                      alt=""
                    />
                    <img
                      *ngIf="asymetrieGraph"
                      [attr.src]="dataToCompare.statsym | secure | async"
                      height="400px;"
                      alt=""
                    />
                    <img src="assets/images/icons/rounded-rectangle.svg" style="width:25px; height:30px;" />
                    {{ rombergToCompare.date | localdate: 'datetime' }}
                  </div>
                </div>
              </div>
              <div class="col-lg-1">
                <img
                  (click)="goToGraph('stabilogram')"
                  src="assets/images/icons/right-chevron.svg"
                  width="80px;"
                  alt=""
                  style="margin-top: 210px; cursor: pointer;"
                />
              </div>
            </div>
          </div>
          <div class="stabilogram" *ngIf="isStabilogram">
            <div class="row">
              <div class="col-lg-1">
                <img
                  (click)="goToGraph('statokinesigram')"
                  src="assets/images/icons/left-chevron.svg"
                  width="80px;"
                  alt=""
                  style="margin-top: 150px; cursor: pointer;"
                />
              </div>
              <ng-template #zoomstabilogram>
                <div class="zoom" (click)="closeChart()">
                  <img [attr.src]="data.stabilo | secure | async" alt="" />
                  <img [attr.src]="dataToCompare.stabilo | secure | async" alt="" />
                </div>
              </ng-template>
              <div
                *ngIf="!zoom; else zoomstabilogram"
                (click)="zoomGraph()"
                class="col-lg-10"
                style="text-align : center; cursor: zoom-in;"
              >
                <img [attr.src]="data.stabilo | secure | async" height="400px;" alt="" />
                <img src="assets/images/icons/rounded-rectangle-green.svg" style="width:25px; height:30px;" />
                {{ testData.date | localdate: 'datetime' }}
                <img [attr.src]="dataToCompare.stabilo | secure | async" height="400px;" alt="" />
                <img src="assets/images/icons/rounded-rectangle.svg" style="width:25px; height:30px;" />
                {{ rombergToCompare.date | localdate: 'datetime' }}
              </div>
              <div class="col-lg-1">
                <img
                  (click)="goToGraph('comparison')"
                  src="assets/images/icons/right-chevron.svg"
                  width="80px;"
                  alt=""
                  style="margin-top: 150px; cursor: pointer;"
                />
              </div>
            </div>
          </div>
          <div class="comparison" *ngIf="isComparison">
            <div class="row">
              <div class="col-lg-1">
                <img
                  (click)="goToGraph('stabilogram')"
                  src="assets/images/icons/left-chevron.svg"
                  width="80px;"
                  alt=""
                  style="margin-top: 270px; cursor: pointer;"
                />
              </div>
              <div class="col-lg-10" style="text-align : center;">
                <div class="comparison-container">
                  <meta charset="utf-8" />
                  <!--
                  <div class="optionsBar row">
                    <button
                      *ngFor="let title of typesOfComparisons"
                      (click)="onSelectGraphComparaison(title)"
                      type="button"
                      class="btn btn-primary choice-button"
                      [class.selected]="title === choiceOfComparison"
                    >
                      {{ title | translate }}
                    </button>
                  </div>
                  -->
                  <div *ngIf="recupComparaison" class="test">
                    <ng-template #zoompart>
                      <div class="zoom" (click)="closeChart()">
                        <div style="width: 90%; margin: 0 auto;">
                          <app-graph-horizontal
                            [title]="comparisonGraphSelected.title"
                            [data]="comparisonGraphSelected"
                          ></app-graph-horizontal>
                        </div>
                      </div>
                    </ng-template>

                    <div *ngIf="!zoom; else zoompart" class="setOfGraph">
                      <div>
                        <img src="assets/images/icons/rounded-rectangle-green.svg" style="width:25px; height:30px;" />
                        {{ testData.date | localdate: 'datetime' }}
                        <img src="assets/images/icons/rounded-rectangle.svg" style="width:25px; height:30px;" />
                        {{ rombergToCompare.date | localdate: 'datetime' }}
                      </div>
                      <div
                        *ngFor="let data of comparisonDatas; let i = index"
                        class="graphic2"
                        (click)="comparisonChartClicked(data)"
                      >
                        <app-graph-horizontal [title]="data.title" [data]="data"></app-graph-horizontal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--
              <div class="col-lg-1">
                <img
                  (click)="goToGraph('longitudinal')"
                  src="assets/images/icons/right-chevron.svg"
                  width="80px;"
                  alt=""
                  style="margin-top: 270px; cursor: pointer;"
                />
              </div>-->
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-end">
        <button class="btn btn-primary" (click)="closeGraphModal()">
          {{ 'Close' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Graph model romberg -->
<!--
<div
  class="modal fade"
  id="rombergGraphModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="rombergGraphModalTitle"
  aria-hidden="true"
  #rombergGraphModal
>
  <div class="modal-dialog modal-dialog-centered modal-large" style="max-width: 1500px;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="rombergGraphModalTitle">
          {{ graphTitle }}
        </h5>
        <button #btnCloseGraphModal type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="romberg-graph">
          <div class="statokinesigram" *ngIf="isStatokinesigram">
            <div class="row">
              <div *ngIf="!zoom; else zoomstatokinesigram" class="col-lg-10 offset-lg-1" style="text-align : center;">
                <div class="row">
                  <div class="col-lg-12">
                    <button
                      (click)="changeToStandard()"
                      type="button"
                      class="btn btn-primary choice-button"
                      [class.selected]="standardGraph"
                    >
                      {{ 'Checkup.Romberg.Graph.Statok.Standard' | translate }}
                    </button>
                    <button
                      (click)="changeToAsymetrie()"
                      type="button"
                      class="btn btn-primary choice-button"
                      [class.selected]="asymetrieGraph"
                    >
                      {{ 'Checkup.Romberg.Graph.Statok.Asymetry' | translate }}
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12" (click)="zoomGraph()" style="cursor: zoom-in;">
                    <img *ngIf="standardGraph" [attr.src]="data.statok | secure | async" height="400px;" alt=""/>
                    <img *ngIf="asymetrieGraph" [attr.src]="data.statsym | secure | async" height="400px;" alt=""/>
                    <img *ngIf="standardGraph" [attr.src]="dataToCompare.statok | secure | async"  height="400px;" alt=""/>                  
                    <img *ngIf="asymetrieGraph" [attr.src]="dataToCompare.statsym | secure | async" height="400px;" alt="" />
                  </div>
                  <div class="col-lg-12" >
                    <span class="text-align : center">
                    <img
                      src="assets/images/icons/rounded-rectangle-green.svg"
                      style="width:25px; height:30px;"
                    />
                    {{ testData.date | date: 'dd/MM/yyyy hh:mm:ss' }}</span>
                    <span class="text-align : center"></span>
                    <span class="text-align : center">
                      <img src="assets/images/icons/rounded-rectangle.svg" style="width:25px; height:30px;" />
                      {{ rombergToCompare.date | date: 'dd/MM/yyyy hh:mm:ss' }}</span>      
                  </div>
                </div>
              </div>
              <div class="col-lg-1">
                <img
                  (click)="goToGraph('stabilogram')"
                  src="assets/images/icons/right-chevron.svg"
                  width="80px;"
                  alt=""
                  style="margin-top: 210px; cursor: pointer;"
                />
              </div>
            </div>
          </div>
          <div class="stabilogram" *ngIf="isStabilogram">
            <div class="row">
              <div class="col-lg-1">
                <img
                  (click)="goToGraph('statokinesigram')"
                  src="assets/images/icons/left-chevron.svg"
                  width="80px;"
                  alt=""
                  style="margin-top: 150px; cursor: pointer;"
                />
              </div>
              <div
                *ngIf="!zoom; else zoomstabilogram"
                (click)="zoomGraph()"
                class="col-lg-10"
                style="text-align : center; cursor: zoom-in;"
              >
                <img [attr.src]="data.stabilo | secure | async" height="400px;" alt="" />
                <img [attr.src]="dataToCompare.stabilo | secure | async" height="400px;" alt="" />
              </div>
              <div class="col-lg-10" >
                <span class="text-align : center">
                <img
                  src="assets/images/icons/rounded-rectangle-green.svg"
                  style="width:25px; height:30px;"
                />
                {{ testData.date | date: 'dd/MM/yyyy hh:mm:ss' }}</span>
                <span class="text-align : center"></span>
                <span class="text-align : center">
                  <img src="assets/images/icons/rounded-rectangle.svg" style="width:25px; height:30px;" />
                  {{ rombergToCompare.date | date: 'dd/MM/yyyy hh:mm:ss' }}</span>      
              </div>
              <div class="col-lg-1">
                <img
                  (click)="goToGraph('comparison')"
                  src="assets/images/icons/right-chevron.svg"
                  width="80px;"
                  alt=""
                  style="margin-top: 150px; cursor: pointer;"
                />
              </div>
            </div>
          </div>
          <div class="comparison" *ngIf="isComparison">
            <div class="row">
              <div class="col-lg-1">
                <img
                  (click)="goToGraph('stabilogram')"
                  src="assets/images/icons/left-chevron.svg"
                  width="80px;"
                  alt=""
                  style="margin-top: 270px; cursor: pointer;"
                />
              </div>
              <div class="col-lg-10" style="text-align : center;">
                <div class="comparison-container">

                  <div *ngIf="recupComparaison" class="test">
                    <ng-template #zoompart>
                      <div class="zoom" (click)="closeChart()">
                        <div style="width: 90%; margin: 0 auto;">
                          <app-graph-horizontal
                            [title]="comparisonGraphSelected.title"
                            [data]="comparisonGraphSelected"
                          ></app-graph-horizontal>
                        </div>
                      </div>
                    </ng-template>

                    <div *ngIf="!zoom; else zoompart" class="setOfGraph">
                      <div
                        *ngFor="let data of comparisonDatas; let i = index"
                        class="graphic2"
                        (click)="comparisonChartClicked(data)"
                      >
                        <app-graph-horizontal [title]="data.title" [data]="data"></app-graph-horizontal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-end">
        <button class="btn btn-primary" (click)="closeGraphModal()">
          {{ 'Close' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
-->
