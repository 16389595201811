export class AnalysisResultsDetailByAge {
  public name: number;
  public ageMin: number;
  public ageMax: number;
  public avgSpeed: number;
  public fluidity: number;
  public regularity: number;
  public pace: number;
  public stability: number;
  public symmetry: number;
  public synchronization: number;
  public vigor: number;
}
