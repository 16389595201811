<div class="simple-content-component main-content">
  <div class="buttons-container d-flex">
    <div></div>
    <h5 class="question-family">{{ questionnaryDetails.name }}</h5>
    <button #btnOpenQuit class="close" data-target="#questionnaryQuitTestModal" data-toggle="modal">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!-- Stepper -->
  <mat-horizontal-stepper
    [linear]="isLinear"
    class="questionnary-steps"
    #stepper
    (animationDone)="setFocus()"
    (selectionChange)="onStepChange($event)"
    *ngIf="isLoaded"
  >
    <mat-step *ngFor="let question of questionnaryDetails.questions; let questionIndex = index">
      <form>
        <div class="column">
          <div class="question-container">
            <h5
              class="question-family"
              *ngIf="questionnaryDetails.questionFamilies && questionnaryDetails.questionFamilies.length > 0"
            >
              {{ questionnaryDetails.questionFamilies[question.familyIndex] }}
            </h5>
            <h5 class="question">{{ question.text }}</h5>
          </div>
          <div class="flex-row">
            <div class="flex-column col-sm-6">
              <div class="answers-container flex-column justify-content-between">
                <!-- INPUT FLOAT / INPUT INTEGER -->
                <div *ngIf="!question.answers">
                  <div *ngIf="question.type === 'integer' || question.type === 'float'">
                    <input
                      id="number{{ questionIndex }}"
                      class="input-questionnary form-control"
                      [ngClass]="{ 'ng-invalid': isInvalid }"
                      type="number"
                      (keypress)="numericOnly($event)"
                      [min]="question.min"
                      [max]="question.max"
                      [(ngModel)]="numberValue"
                      name="number"
                      (ngModelChange)="onNumberValueChange(question.type, question.min, question.max)"
                      [required]="!question.optional"
                      autofocus
                    />
                  </div>

                  <!-- INPUT DATE -->
                  <div *ngIf="question.type === 'date'" class="d-flex">
                    <input
                      id="date{{ questionIndex }}"
                      class="input-questionnary form-control"
                      [matDatepicker]="picker"
                      placeholder="{{ 'formatDate' | translate }}"
                      [max]="maxDate"
                      [(ngModel)]="dateValue"
                      name="date"
                      (ngModelChange)="onDateValueChange($event)"
                      [required]="!question.optional"
                      autofocus
                    />
                    <span class="input-group-prepend">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker touchUi #picker></mat-datepicker>
                    </span>
                  </div>

                  <!-- INPUT TEXT : Warning ! i omit required -->
                  <div *ngIf="question.type === 'text'">
                    <input
                      id="text{{ questionIndex }}"
                      class="input-questionnary form-control"
                      type="text"
                      [(ngModel)]="textValue"
                      name="text"
                      (ngModelChange)="onTextValueChange($event)"
                      [required]="!question.optional"
                      autofocus
                    />
                  </div>
                </div>

                <!-- RADIO BUTTON -->
                <div *ngIf="question.type === 'singlechoice'">
                  <mat-radio-group
                    [(ngModel)]="radioValue"
                    class="radio-checkbox-container"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <mat-radio-button
                      id="radio{{ currentStepIndex }}index{{ index }}"
                      class="radio-button"
                      *ngFor="let answer of question.answers; let index = index"
                      [value]="index"
                      (change)="onChangeStateRadio(index)"
                      >{{ answer.text }}</mat-radio-button
                    >
                  </mat-radio-group>
                </div>

                <!-- CHECKBOX -->
                <div class="radio-checkbox-container" *ngIf="question.type === 'multiplechoice'">
                  <mat-checkbox
                    *ngFor="let answer of checkboxQuestionListToDisplay[currentStepIndex]; let index = index"
                    class="checkbox dropdown-item-questionnary"
                    (change)="onChangeStateCheckbox($event.checked, answer.index)"
                    [value]="answer.index"
                    [checked]="answer.checked"
                    >{{ answer.text }}</mat-checkbox
                  >
                </div>
              </div>
              <!-- Fake buttons, they will call on click on gonext and goback -->
              <div style="visibility: hidden; width: 0; height: 0;">
                <button
                  class="btn btn-tertiary"
                  #btnBack
                  *ngIf="displayBack"
                  [hidden]="true"
                  matStepperPrevious
                ></button>
                <button
                  class="btn btn-primary"
                  #btnNext
                  mat-button
                  [disabled]="disabledNextButton"
                  [hidden]="true"
                  matStepperNext
                ></button>
              </div>
            </div>
            <div class="flex-column col-sm-6">
              <div [innerHTML]="question.comment"></div>
            </div>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step *ngIf="canDisplayScore" state="score">
      <h5 class="question-family">{{ 'Score' | translate }}</h5>
      <form class="flex-column">
        <div class="question-container">
          <div class="score-container" *ngFor="let score of scoreDefinition">
            <h4 class="title-score">{{ score.name }} : {{ score.description }}</h4>
            <h4 class="value-score">{{ score.score }}</h4>
          </div>
        </div>
      </form>
    </mat-step>

    <!-- Icon overrides -->
    <ng-template matStepperIcon="done" let-index="index">
      {{ index + 1 }}
    </ng-template>
    <ng-template matStepperIcon="score">
      R
    </ng-template>
  </mat-horizontal-stepper>

  <div class="buttons-container-bottom border-top">
    <button *ngIf="displayBack" class="btn btn-tertiary mr-5" (click)="goBack()">
      {{ 'app.questionnary.previous.question' | translate }}
    </button>
    <button
      *ngIf="!displayBack"
      class="btn btn-tertiary mr-5"
      data-target="#questionnaryQuitTestModal"
      data-toggle="modal"
    >
      {{ 'app.questionnary.cancel' | translate }}
    </button>
    <button
      *ngIf="!isLastStep && !canClickAfterScore"
      class="btn btn-primary"
      [disabled]="disabledNextButton"
      (click)="goNext()"
    >
      {{ 'app.questionnary.next.question' | translate }}
    </button>
    <button
      *ngIf="isLastStep && !canClickAfterScore"
      class="btn btn-primary"
      [disabled]="!lastQuestionAnswered"
      (click)="validate()"
    >
      {{ 'app.questionnary.validate' | translate }}
    </button>

    <button *ngIf="canClickAfterScore" class="btn btn-primary" (click)="finish()">
      {{ 'app.questionnary.end' | translate }}
    </button>
  </div>
</div>

<div
  class="modal fade"
  id="questionnaryQuitTestModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="questionnaryQuitTestModalTitle"
  aria-hidden="true"
  #questionnaryQuitTestModal
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="questionnaryQuitTestModalModalTitle">
                    {{ 'app.walk.impossible.quit.title' | translate }}
                </h5> -->
        <button #btnQuestionnaryQuitTestModal type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{ 'app.questionnary.quit.message' | translate }}
      </div>
      <div class="modal-footer d-flex justify-content-around">
        <button type="button" class="btn btn-tertiary" (click)="stopTest()">{{ 'oui' | translate }}</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal">{{ 'non' | translate }}</button>
      </div>
    </div>
  </div>
</div>
