import { Roles } from 'src/app/modules/core/roles.enum';
import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';

export interface NavItem {
  routerLink: string;
  title: string;
  neededRole: Roles;
}

export const navItems: NavItem[] = [
  {
    routerLink: '/Patient',
    title: i18nKey('Nav.Title.Patients'),
    neededRole: null,
  },
  {
    routerLink: '/Bilans',
    title: i18nKey('Nav.Title.Bilan'),
    neededRole: Roles.accessResults,
  },
  /*{
    routerLink: '/Parcours',
    title: i18nKey('Nav.Title.Parcours'),
    neededRole: null,
  },*/
];
