import { Injectable } from '@angular/core';

export interface Logger {
  info: (message?: any, ...optionalParams: any[]) => void;
  warn: (message?: any, ...optionalParams: any[]) => void;
  error: (message?: any, ...optionalParams: any[]) => void;
}
const noop = (): any => undefined;

@Injectable()
export class LoggerService implements Logger {
  public constructor() {
    // TODO check how to configure it
    // if (!environment.production) {
    this.activate();
    // } else {
    //  this.deactivate();
    // }
  }
  public activate() {
    this.info = console.log.bind(console);
    this.warn = console.warn.bind(console);
    this.error = console.error.bind(console);
  }
  public deactivate() {
    this.info = noop;
    this.warn = noop;
    this.error = noop;
  }
  public info: (message?: any, ...optionalParams: any[]) => void = (): any => undefined;
  public warn: (message?: any, ...optionalParams: any[]) => void = (): any => undefined;
  public error: (message?: any, ...optionalParams: any[]) => void = (): any => undefined;
}
