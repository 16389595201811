<app-custom-navbar
  [patient]="patient"
  [returnTitle]="returnTitle"
  (selectPage)="onSelectPage($event)"
></app-custom-navbar>
<app-courses-breadcrumb-navbar
  [testName]="testName | translate"
  [course]="course"
  [fromSynthesis]="true"
></app-courses-breadcrumb-navbar>
<div class="col-md-12">
  <div class="row">
    <div class="col-md-3 p-0">
      <app-fried-navbar
        [currentPage]="'weight'"
        (selectPage)="onSelectPage($event)"
        [course]="course"
      ></app-fried-navbar>
    </div>
    <div class="col-md-9 fried-info">
      <div class="row">
        <div class="col-md-5 simple-content-component">
          <table style="width:  100%; border: 2px solid white; height: 30px; position: relative;">
            <tr class="scorescale-indicator">
              <td *ngFor="let i of [0, 1, 2, 3, 4, 5]">
                <img src="assets/images/images/indicator.png" [ngClass]="{ 'is-hidden': i !== score }" />
              </td>
            </tr>
            <tr class="scorescale">
              <th class="score0">
                <div>0</div>
              </th>
              <th class="score1">
                <div>1</div>
              </th>
              <th class="score2">
                <div>2</div>
              </th>
              <th class="score3">
                <div>3</div>
              </th>
              <th class="score4">
                <div>4</div>
              </th>
              <th class="score5">
                <div>5</div>
              </th>
            </tr>
          </table>
        </div>
        <div class="col-md-5 simple-content-component">
          <ul style="list-style-type: none; margin: 0px; padding: 0px; text-align: right;">
            <img
              class="d-inline-block align-top"
              width="20"
              height="20"
              src="assets/images/icons/info-circle.svg"
              alt=""
              data-target="#cedTutorialModal"
              data-toggle="modal"
            />
          </ul>
          <ul style="list-style-type: none; margin: 0px; padding: 0px; text-align: center;">
            <img
              class="d-inline-block align-top"
              *ngIf="!resultsCours[2].state"
              width="20"
              height="20"
              src="assets/images/images/indicator_green.png"
              alt=""
            />
            <img
              class="d-inline-block align-top"
              *ngIf="resultsCours[2].state"
              width="20"
              height="20"
              src="assets/images/images/alert_red.png"
              alt=""
            />
            <span *ngIf="resultsCours[2].testName === 'Questionnary11'">
              {{ 'app.questionnary' | translate }} CES-D:
            </span>
          </ul>
          <br />
          <span *ngIf="resultsCours[2].testName === 'Questionnary11'">
            {{ 'Course.synthesis.questionnary.anserws' | translate }} 7
            {{ getAnserwsValue(6, resultsCours[2].detail.question7) }}
            {{ 'Course.synthesis.questionnary.anserws' | translate }} 9
            {{ getAnserwsValue(8, resultsCours[2].detail.question9) }}
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5 simple-content-component">
          <ul style="list-style-type: none; margin: 0px; padding: 0px; text-align: right;">
            <img
              class="d-inline-block align-top"
              width="20"
              height="20"
              src="assets/images/icons/info-circle.svg"
              data-target="#weightTutorialModal"
              data-toggle="modal"
              alt=""
            />
          </ul>
          <ul style="list-style-type: none; margin: 0px; padding: 0px; text-align: center;">
            <img
              class="d-inline-block align-top"
              *ngIf="!resultsCours[0].state"
              width="20"
              height="20"
              src="assets/images/images/indicator_green.png"
              alt=""
            />
            <img
              class="d-inline-block align-top"
              *ngIf="resultsCours[0].state"
              width="20"
              height="20"
              src="assets/images/images/alert_red.png"
              alt=""
            />
            <span> {{ 'navbar.massBalance' | translate }} : </span>
          </ul>
          <div class="modal-body" *ngIf="showGraph">
            <app-graph-timeline
              [title]="weightGraph.title"
              [data]="weightGraph"
              [min]="minWeight"
              [max]="maxWeight"
            ></app-graph-timeline>
          </div>
          <div class="modal-body">
            <span>
              {{ 'Course.synthesis.weight.diff' | translate }}
              {{ resultsCours[0].detail.patientWeight - resultsCours[0].detail.courseWeight | float: 2 }}
              Kg,
              {{ 'Course.synthesis.weight.prc' | translate }}
              {{ (resultsCours[0].detail.patientWeight * 100) / resultsCours[0].detail.courseWeight - 100 | float: 2 }}
              %.
            </span>
          </div>
        </div>
        <div class="col-md-5 simple-content-component">
          <ul style="list-style-type: none; margin: 0px; padding: 0px; text-align: right;">
            <img
              class="d-inline-block align-top"
              width="20"
              height="20"
              src="assets/images/icons/info-circle.svg"
              data-target="#qappaTutorialModal"
              data-toggle="modal"
              alt=""
            />
          </ul>
          <ul style="list-style-type: none; margin: 0px; padding: 0px; text-align: center;">
            <img
              class="d-inline-block align-top"
              *ngIf="!resultsCours[1].state"
              width="20"
              height="20"
              src="assets/images/images/indicator_green.png"
              alt=""
            />
            <img
              class="d-inline-block align-top"
              *ngIf="resultsCours[1].state"
              width="20"
              height="20"
              src="assets/images/images/alert_red.png"
              alt=""
            />
            <span> {{ 'app.questionnary' | translate }} QAPPA: </span>
          </ul>
          <div class="modal-body" *ngIf="showGraph">
            <app-graph-timeline [title]="questGraph.title" [data]="questGraph"></app-graph-timeline>
          </div>
          <div class="modal-body">
            <span>
              {{ 'Course.synthesis.qappa.activity' | translate }}
              {{ resultsCours[1].detail.physicalScore }} kcal/ {{ 'Course.synthesis.qappa.week' | translate }}.
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5 simple-content-component">
          <ul style="list-style-type: none; margin: 0px; padding: 0px; text-align: right;">
            <img
              class="d-inline-block align-top"
              width="20"
              height="20"
              src="assets/images/icons/info-circle.svg"
              data-target="#gripTutorialModal"
              data-toggle="modal"
              alt=""
            />
          </ul>
          <ul style="list-style-type: none; margin: 0px; padding: 0px; text-align: center;">
            <img
              class="d-inline-block align-top"
              *ngIf="!resultsCours[3].state"
              width="20"
              height="20"
              src="assets/images/images/indicator_green.png"
              alt=""
            />
            <img
              class="d-inline-block align-top"
              *ngIf="resultsCours[3].state"
              width="20"
              height="20"
              src="assets/images/images/alert_red.png"
              alt=""
            />
            <span> {{ 'navbar.strengthTest' | translate }} : </span>
          </ul>
          <div
            class="col-md-5 d-flex description-container"
            *ngIf="hand == 'G'"
            style="text-align: center; margin-bottom: 20px; font-weight: normal;"
          >
            <img
              class="d-inline-block align-top"
              width="20"
              height="20"
              src="assets/images/icons/lefthand.svg"
              alt=""
            />
            {{ 'app.grip.lefthand' | translate }}
          </div>
          <div
            class="col-md-5 d-flex .description-container"
            *ngIf="hand == 'D'"
            style="text-align: center; margin-bottom: 20px; font-weight: normal;"
          >
            <img
              class="d-inline-block align-top"
              width="20"
              height="20"
              src="assets/images/icons/righthand.svg"
              alt=""
            />
            {{ 'app.grip.righthand' | translate }}
          </div>
          <div class="modal-body" *ngIf="showGraph">
            <app-graph-timeline [title]="gripGraph.title" [data]="gripGraph"></app-graph-timeline>
          </div>
          <div class="modal-body">
            <span>
              {{ resultsCours[3].detail.strengthValue }} Kg {{ 'Course.synthesis.grip' | translate }} :
              {{ resultsCours[3].detail.bmi | float: 2 }}
            </span>
          </div>
        </div>
        <div class="col-md-5 simple-content-component">
          <ul style="list-style-type: none; margin: 0px; padding: 0px; text-align: right;">
            <img
              class="d-inline-block align-top"
              width="20"
              height="20"
              src="assets/images/icons/info-circle.svg"
              data-target="#walkTutorialModal"
              data-toggle="modal"
              alt=""
            />
          </ul>
          <ul style="list-style-type: none; margin: 0px; padding: 0px; text-align: center;">
            <img
              class="d-inline-block align-top"
              *ngIf="!resultsCours[4].state"
              width="20"
              height="20"
              src="assets/images/images/indicator_green.png"
              alt=""
            />
            <img
              class="d-inline-block align-top"
              *ngIf="resultsCours[4].state"
              width="20"
              height="20"
              src="assets/images/images/alert_red.png"
              alt=""
            />
            <span>
              {{ 'navbar.walkTest' | translate }} :
              {{ 'Checkup.walk.averageSpeed' | translate }}
            </span>
          </ul>
          <div class="modal-body" *ngIf="showGraph">
            <app-graph-timeline [title]="walkGraph.title" [data]="walkGraph"></app-graph-timeline>
          </div>
          <div class="modal-body">
            <span>
              {{ resultsCours[4].detail.speedValue | float: 2 }} m/s
              {{ 'Course.synthesis.walk.test' | translate }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- modal ced Info -->
<div
  class="modal fade"
  id="cedTutorialModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="cedTutorialModalTitle"
  aria-hidden="true"
  #cedTutorialModal
>
  <div class="modal-dialog modal-dialog-centered modal-small" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="cedTutorialModalTitle">
          {{ 'Course.info.ced.title' | translate }}
        </h5>
        <button #btnCloseCed type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body my-translated-paragraph center">
        {{ 'Course.info.ced.content' | translate }}
      </div>
      <div class="modal-footer d-flex justify-content-end">
        <button class="btn btn-primary" (click)="onCloseModal()">{{ 'Close' | translate }}</button>
      </div>
    </div>
  </div>
</div>
<!-- modal weight Info -->
<div
  class="modal fade"
  id="weightTutorialModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="weightTutorialModalTitle"
  aria-hidden="true"
  #weightTutorialModal
>
  <div class="modal-dialog modal-dialog-centered modal-small" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="weightTutorialModalTitle">
          {{ 'Course.info.weight.title' | translate }}
        </h5>
        <button #btnCloseWeight type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body my-translated-paragraph center">
        {{ 'Course.info.weight.content' | translate }}
      </div>
      <div class="modal-footer d-flex justify-content-end">
        <button class="btn btn-primary" (click)="onCloseModal()">{{ 'Close' | translate }}</button>
      </div>
    </div>
  </div>
</div>
<!-- modal qappa Info -->
<div
  class="modal fade"
  id="qappaTutorialModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="qappaTutorialModalTitle"
  aria-hidden="true"
  #qappaTutorialModal
>
  <div class="modal-dialog modal-dialog-centered modal-small" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="qappaTutorialModalTitle">
          {{ 'Course.info.qappa.title' | translate }}
        </h5>
        <button #btnCloseQappa type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body my-translated-paragraph center">
        {{ 'Course.info.qappa.content' | translate }}
      </div>
      <div class="modal-footer d-flex justify-content-end">
        <button class="btn btn-primary" (click)="onCloseModal()">{{ 'Close' | translate }}</button>
      </div>
    </div>
  </div>
</div>
<!-- modal grip Info -->
<div
  class="modal fade"
  id="gripTutorialModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="gripTutorialModalTitle"
  aria-hidden="true"
  #gripTutorialModal
>
  <div class="modal-dialog modal-dialog-centered modal-small" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="gripTutorialModalTitle">
          {{ 'Course.info.grip.title' | translate }}
        </h5>
        <button #btnCloseGrip type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body my-translated-paragraph center">
        {{ 'Course.info.grip.content' | translate }}
      </div>
      <div class="modal-footer d-flex justify-content-end">
        <button class="btn btn-primary" (click)="onCloseModal()">{{ 'Close' | translate }}</button>
      </div>
    </div>
  </div>
</div>
<!-- modal walk Info -->
<div
  class="modal fade"
  id="walkTutorialModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="walkTutorialModalTitle"
  aria-hidden="true"
  #walkTutorialModal
>
  <div class="modal-dialog modal-dialog-centered modal-small" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="walkTutorialModalTitle">
          {{ 'Course.info.walk.title' | translate }}
        </h5>
        <button #btnCloseWalk type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body my-translated-paragraph center">
        {{ 'Course.info.walk.content' | translate }}
      </div>
      <div class="modal-footer d-flex justify-content-end">
        <button class="btn btn-primary" (click)="onCloseModal()">{{ 'Close' | translate }}</button>
      </div>
    </div>
  </div>
</div>
