import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { WiiBoardService } from 'src/app/modules/force-platform/services/wiiboard.service';

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, TranslateModule.forChild()],
  declarations: [],
  providers: [WiiBoardService],
  exports: [],
})
export class ForcePlatformModule {}
