<div class="section-2">
  <div class="row type-h">
    <div class="col-md-4 col-xs-12 d-flex">
      <div class="col-md-10 col-xs-12" *ngIf="testData && testData.questionnaireId">
        {{ 'app.questionnary' | translate }} :
        {{ testName | translate }}
      </div>
      <div class="col-md-10 col-xs-12" *ngIf="testData && !testData.questionnaireId">
        {{ testName | translate }}
      </div>
    </div>
    <div class="col-md-4 col-xs-12 d-flex container-foldernumber">
      <div class="col-md-10 col-xs-12">
        <div class="info-1" *ngIf="!walkToCompare && testData">
          {{ 'Date' | translate }} :
          {{ testData.date | localdate: 'datetime' }}
        </div>
        <div class="info-1" *ngIf="walkToCompare">
          {{ 'Date' | translate }} :
          <span
            ><img src="assets/images/icons/rounded-rectangle-green.svg" style="width:25px; height:30px;" />
            {{ testData.date | localdate: 'datetime' }}</span
          >
          |
          <span
            ><img src="assets/images/icons/rounded-rectangle.svg" style="width:25px; height:30px;" />
            {{ walkToCompare.date | localdate: 'datetime' }}</span
          >
        </div>
      </div>
    </div>
    <div class="col-md-4 col-xs-12 d-flex container-foldernumber" *ngIf="testData && testData.score">
      <div class="col-md-10 col-xs-12">
        <div class="info-1">{{ 'score' | translate }} : {{ testData.score ? testData.score : 'NA' }}</div>
      </div>
    </div>
  </div>

  <div class="row type-h">
    <div class="col-md-4 col-xs-12 d-flex container-foldernumber" *ngIf="testData && testData.shoesType">
      <div class="col-md-10 col-xs-12">
        <div class="info-1" *ngIf="!walkToCompare">
          {{ 'Rapport.TypeDeChaussure' | translate }} :
          {{ testData.shoesType }}
        </div>
        <div class="info-1" *ngIf="walkToCompare">
          {{ 'Rapport.TypeDeChaussure' | translate }} :
          <span
            ><img src="assets/images/icons/rounded-rectangle-green.svg" style="width:25px; height:30px;" />
            {{ testData.shoesType }}</span
          >
          |
          <span
            ><img src="assets/images/icons/rounded-rectangle.svg" style="width:25px; height:30px;" />
            {{ walkToCompare.shoesType }}</span
          >
        </div>
      </div>
    </div>
    <div class="col-md-4 col-xs-12 d-flex container-foldernumber" *ngIf="testData && testData.stabilityAid">
      <div class="col-md-10 col-xs-12 group-3">
        <div class="info-1" *ngIf="!walkToCompare">
          {{ 'app.romberg.help.stability' | translate }} :
          {{ testData.stabilityAid }}
        </div>
        <div class="info-1" *ngIf="walkToCompare">
          {{ 'app.romberg.help.stability' | translate }} :
          <span
            ><img src="assets/images/icons/rounded-rectangle-green.svg" style="width:25px; height:30px; " />
            {{ testData.stabilityAid }}
          </span>
          |
          <span
            ><img src="assets/images/icons/rounded-rectangle.svg" style="width:25px; height:30px; " />
            {{ walkToCompare.stabilityAid }}</span
          >
        </div>
      </div>
    </div>
    <div class="col-md-4 col-xs-12 d-flex container-foldernumber" *ngIf="testData && testData.typePlate">
      <div class="col-md-10 col-xs-12 group-3">
        <div class="info-1" *ngIf="!walkToCompare">
          {{ 'Rapport.TypePlate' | translate }} :
          {{ testData.typePlate }}
        </div>
        <div class="info-1" *ngIf="walkToCompare">
          {{ 'Rapport.TypePlate' | translate }} :
          <span
            ><img src="assets/images/icons/rounded-rectangle-green.svg" style="width:25px; height:30px; " />
            {{ testData.typePlate }}
          </span>
          |
          <span
            ><img src="assets/images/icons/rounded-rectangle.svg" style="width:25px; height:30px; " />
            {{ walkToCompare.typePlate }}</span
          >
        </div>
      </div>
    </div>
    <div class="col-md-4 col-xs-12 d-flex container-foldernumber" *ngIf="testData && displayDoubleTask">
      <div class="col-md-10 col-xs-12 group-3">
        <div class="info-1" *ngIf="!walkToCompare">
          {{ 'doubleTask' | translate }} :
          {{ doubleTask ? doubleTask : 'N/A' }}
        </div>
        <div class="info-1" *ngIf="walkToCompare">
          {{ 'doubleTask' | translate }} :
          <span
            ><img src="assets/images/icons/rounded-rectangle-green.svg" style="width:25px; height:30px; " />
            {{ doubleTask ? doubleTask : 'N/A' }}
          </span>
          |
          <span
            ><img src="assets/images/icons/rounded-rectangle.svg" style="width:25px; height:30px; " />
            {{ doubleTaskToCompare ? doubleTaskToCompare : 'N/A' }}</span
          >
        </div>
      </div>
    </div>
    <div
      class="col-md-4 col-xs-12 d-flex container-foldernumber"
      *ngIf="analysisResult && analysisResult.graphes && analysisResult.graphes.semioInfo"
    >
      <div class="col-md-10 col-xs-12">
        <div class="info-1" *ngIf="!resultsWalkToCompare">
          {{ 'Rapport.sensdemietour' | translate }} : {{ analysisResult.graphes.semioInfo.uTurn | translate }}
        </div>
        <div class="info-1" *ngIf="resultsWalkToCompare && resultsWalkToCompare.graphes">
          {{ 'Rapport.sensdemietour' | translate }} :
          <span
            ><img src="assets/images/icons/rounded-rectangle-green.svg" style="width:25px; height:30px; " />
            {{ analysisResult.graphes.semioInfo.uTurn | translate }}
          </span>
          |
          <span
            ><img src="assets/images/icons/rounded-rectangle.svg" style="width:25px; height:30px; " />
            {{ resultsWalkToCompare.graphes.semioInfo.uTurn | translate }}</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
