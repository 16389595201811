<div class="main-container">
  <!-- LOCOGRAM -->
  <div *ngIf="displayLocogram" class="graphic-zoom-container">
    <div class="col-md-11 flex-column">
      <div class="d-flex align-items-center">
        <div class="col-md-6 img-container">
          <img class="img-graphic" [attr.src]="resultsWalk.graphes.loco | secure | async" />
          <span *ngIf="testData">
            <img
              src="assets/images/icons/rounded-rectangle-green.svg"
              style="width:25px; height:30px;"
              *ngIf="resultsWalkToCompare"
            />
            {{ testData.date | localdate: 'datetime' }}</span
          >
        </div>
        <div class="col-md-6 img-container" *ngIf="resultsWalkToCompare">
          <img class="img-graphic" [attr.src]="resultsWalkToCompare.graphes.loco | secure | async" />
          <span>
            <img src="assets/images/icons/rounded-rectangle.svg" style="width:25px; height:30px;" />
            {{ walkToCompare.date | localdate: 'datetime' }}</span
          >
        </div>
        <div class="col-md-6 description-container" *ngIf="!resultsWalkToCompare">
          <p>{{ 'app.walk.locogram.description' | translate }}</p>
        </div>
      </div>
    </div>
    <img class="pointer" (click)="goToRotagram()" src="assets/images/icons/right-chevron.svg" width="80px;" alt="" />
  </div>

  <!-- ROTAGRAM -->
  <div *ngIf="displayRotagram" class="graphic-zoom-container">
    <img class="pointer" (click)="goToLocogram()" src="assets/images/icons/left-chevron.svg" width="80px;" alt="" />
    <div class="col-md-11 flex-column">
      <div class="d-flex align-items-center">
        <div class="col-md-6 img-container">
          <img class="img-graphic" [attr.src]="resultsWalk.graphes.rota | secure | async" />
          <span *ngIf="testData">
            <img
              src="assets/images/icons/rounded-rectangle-green.svg"
              style="width:25px; height:30px;"
              *ngIf="resultsWalkToCompare"
            />
            {{ testData.date | localdate: 'datetime' }}</span
          >
        </div>
        <div class="col-md-6 img-container" *ngIf="resultsWalkToCompare">
          <img class="img-graphic" [attr.src]="resultsWalkToCompare.graphes.rota | secure | async" />
          <span>
            <img src="assets/images/icons/rounded-rectangle.svg" style="width:25px; height:30px;" />
            {{ walkToCompare.date | localdate: 'datetime' }}</span
          >
        </div>
        <div class="col-md-6 description-container" *ngIf="!resultsWalkToCompare">
          <p>{{ 'app.walk.rotagram.description' | translate }}</p>
        </div>
      </div>
    </div>
    <img class="pointer" (click)="goToComparaison()" src="assets/images/icons/right-chevron.svg" width="80px;" alt="" />
  </div>

  <!-- COMPARAISON -->
  <div *ngIf="displayComparaison" class="graphic-zoom-container">
    <img (click)="goToRotagram()" src="assets/images/icons/left-chevron.svg" width="80px;" alt="" />
    <div class="col-md-10 flex-column">
      <div class="d-flex align-items-center">
        <app-walk-comparaison
          [results]="resultsWalk"
          [resultsToCompare]="resultsWalkToCompare"
          [testData]="testData"
          [walkToCompare]="walkToCompare"
        ></app-walk-comparaison>
      </div>
    </div>
    <img class="pointer" (click)="goToSemiogram()" src="assets/images/icons/right-chevron.svg" width="80px;" alt="" />
  </div>

  <!-- SEMIOGRAM -->
  <div *ngIf="displaySemiogram" class="graphic-zoom-container">
    <img class="pointer" (click)="goToComparaison()" src="assets/images/icons/left-chevron.svg" width="80px;" alt="" />
    <div class="col-md-10 flex-column">
      <div class="d-flex align-items-center">
        <app-walk-semiogram
          [results]="resultsWalk"
          [resultsWalkToCompare]="resultsWalkToCompare"
          [testData]="testData"
          [walkToCompare]="walkToCompare"
        ></app-walk-semiogram>
      </div>
    </div>
    <img
      *ngIf="!resultsWalkToCompare"
      class="pointer"
      (click)="goToLongitudinal()"
      src="assets/images/icons/right-chevron.svg"
      width="80px;"
      alt=""
    />
  </div>

  <!-- LONGITUDINAL -->
  <div *ngIf="displayLongitudinal && !resultsWalkToCompare" class="graphic-zoom-container">
    <img class="pointer" (click)="goToSemiogram()" src="assets/images/icons/left-chevron.svg" width="80px;" alt="" />
    <div class="col-md-11 flex-column">
      <div class="d-flex align-items-center">
        <app-walk-longitudinal [results]="resultsWalk" [currentWalkRecordingSession]="currentWalkRecordingSession">
        </app-walk-longitudinal>
      </div>
      <div class="d-flex align-items-center" *ngIf="resultsWalkToCompare">
        <app-walk-longitudinal [results]="resultsWalk"></app-walk-longitudinal>
      </div>
    </div>
  </div>
</div>
