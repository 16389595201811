import { Grip, GripControllerService } from '@abilycare/dal-client';
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Chart from 'chart.js';
import { ChartPoint } from 'chart.js';
import { LocalDatePipe } from 'src/app/modules/time/pipes/localdate.pipe';
import { DataProviderService } from 'src/app/services/data-provider.service';

export interface IGripDataset {
  label: string;
  data: Array<{ x: Date; y: number }>;
  lineTension: number;
  showLine: boolean;
  pointBorderColor: string;
  pointBackgroundColor: string;
  pointRadius: number;
  pointBorderWidth: number;
}

@Component({
  selector: 'app-grip-graphics',
  templateUrl: './grip-graphics.component.html',
  styleUrls: ['./grip-graphics.component.scss'],
})
export class GripGraphicsComponent implements OnInit, OnChanges {
  @ViewChild('graphLeftHand', { static: true }) public graphLeftHand: ElementRef<HTMLCanvasElement>;
  @ViewChild('graphRightHand', { static: true }) public graphRightHand: ElementRef<HTMLCanvasElement>;

  //@ViewChild('canvasref', { static: true }) private canvasRef: ElementRef;

  @Input() grip: Grip;

  public datasetsLeft: IGripDataset[];
  public datasetsRight: IGripDataset[];
  private canvasElement: HTMLCanvasElement;
  private canvasContext: CanvasRenderingContext2D;

  public constructor(
    private localDatePipe: LocalDatePipe,
    private gripService: GripControllerService,
    private dataService: DataProviderService,
    private translateService: TranslateService,
  ) {}

  public ngOnInit() {
    this.gripService
      .gripControllerFindAllGrip(this.dataService.retrievePatient().id)
      .subscribe((consultationList: any) => {
        const gripDataset: IGripDataset = {
          label: 'Checkup.grip.Measure.Results',
          data: [],
          lineTension: 0,
          showLine: true,
          pointBorderColor: '',
          pointBackgroundColor: '',
          pointRadius: 4,
          pointBorderWidth: 2,
        };

        const gripDatasetRight: IGripDataset = {
          label: 'Checkup.grip.Measure.Results.right',
          data: [],
          lineTension: 0,
          showLine: true,
          pointBorderColor: '',
          pointBackgroundColor: '',
          pointRadius: 4,
          pointBorderWidth: 2,
        };

        const lastGripDataset: IGripDataset = {
          label: 'Checkup.grip.Measure.Results',
          data: [],
          lineTension: 0,
          showLine: true,
          pointBorderColor: '',
          pointBackgroundColor: '',
          pointRadius: 5,
          pointBorderWidth: 3,
        };

        for (const consultation of consultationList) {
          //create dataset for left hand mesure
          if (consultation.leftHandV2 && consultation.leftHandV2 > consultation.leftHandV1)
            gripDataset.data.push({ x: consultation.date, y: consultation.leftHandV2 });
          else gripDataset.data.push({ x: consultation.date, y: consultation.leftHandV1 });
          //create dataset for right hand mesure
          if (consultation.leftHandV2 && consultation.rightHandV2 > consultation.rightHandV1)
            gripDatasetRight.data.push({ x: consultation.date, y: consultation.rightHandV2 });
          else gripDatasetRight.data.push({ x: consultation.date, y: consultation.rightHandV1 });
        }

        /* voir si on met le dernier

        if (this.grip.leftHandV1 > 0) {
          lastGripDataset.pointBorderColor = '#FF0000';
          lastGripDataset.data.push({ x: new Date(), y: this.grip.leftHandV1 });
        }*/

        gripDataset.data = gripDataset.data.sort((a, b) => {
          return +new Date(a.x) - +new Date(b.x);
        });
        gripDatasetRight.data = gripDatasetRight.data.sort((a, b) => {
          return +new Date(a.x) - +new Date(b.x);
        });
        //this.datasets = [gripDataset, lastGripDataset];
        this.datasetsLeft = [gripDataset];
        this.datasetsRight = [gripDatasetRight];
        this.refresh();
      });
  }

  public refresh(): void {
    const localdatasetsLeft: IGripDataset[] = this.datasetsLeft;
    const localdatasetsRight: IGripDataset[] = this.datasetsRight;

    this.configureChart(this.graphLeftHand, localdatasetsLeft);
    this.configureChart(this.graphRightHand, localdatasetsRight);
  }

  public configureChart(graphElemnt: ElementRef, dataset: any): void {
    const configuration: Chart.ChartConfiguration = {
      type: 'scatter',
      data: {
        datasets: dataset,
      },
      options: {
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const point = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] as ChartPoint;
              const date = this.localDatePipe.transform(point.x);
              return date + ' --- ' + (point.y as number).toFixed(1);
            },
          },
        },
        animation: { duration: 0 },
        scales: {
          xAxes: [
            {
              type: 'time',
              position: 'bottom',
              time: {
                displayFormats: {
                  millisecond: 'YYYY-MM-DD',
                  second: 'YYYY-MM-DD',
                  minute: 'YYYY-MM-DD',
                  hour: 'YYYY-MM-DD',
                  day: 'YYYY-MM-DD',
                  week: 'YYYY-MM-DD',
                  month: 'YYYY-MM-DD',
                  quarter: 'YYYY-MM-DD',
                  year: 'YYYY-MM-DD HH:mm:ss',
                },
              },
              ticks: {
                minRotation: 45,
                maxRotation: 45,
                callback: (label) => {
                  const labelDate = label ? new Date(label) : null;
                  return labelDate ? this.localDatePipe.parse(labelDate, 'dd/MM/yyyy') : label;
                },
              },
            },
          ],
        },
        events: ['click'],
        legend: {
          display: false,
        },
      },
    };

    const elt = graphElemnt.nativeElement as HTMLCanvasElement;
    const ctx = elt ? elt.getContext('2d') : null;

    if (!elt) console.warn('no element of grip canvas!');
    if (!ctx) console.warn('no context of grip canvas!');
    if (!dataset) console.warn('no data of grip canvas!');
    if (!elt || !ctx || !dataset) return;
    const myChart = new Chart(ctx, configuration);
  }

  public ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    this.ngOnInit();
  }
}
