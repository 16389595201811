import { Course, PatientControllerService, PatientDetail } from '@abilycare/dal-client';
import { Component, OnInit } from '@angular/core';
import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-fried',
  templateUrl: './fried.component.html',
  styleUrls: ['./fried.component.scss'],
})
export class FriedComponent implements OnInit {
  public patient: PatientDetail;
  public testName: string = '';
  public returnTitle: string = i18nKey('Checkup.navbar.patient.back');
  public course: any;
  public fromHistory: boolean = true;
  public returnTitleHistory: string = i18nKey('Checkup.navbar.history.back');

  constructor(
    private dataService: DataProviderService,
    private location: Location,
    private route: ActivatedRoute,
    private patientService: PatientControllerService,
  ) {
    this.dataService.storeHeaderShowed(false);
  }

  ngOnInit() {
    this.patient = this.dataService.retrievePatient();
    this.course = this.location.getState();
    if (this.course && this.course.patientId) {
      this.patientService.patientControllerFindById(this.course.patientId).subscribe((p) => {
        this.patient = p;
      });
    }
    this.route.queryParams.subscribe((params) => {
      this.fromHistory = params.fromHistory == 1;
    });
  }
}
