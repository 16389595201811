import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, from as fromPromise, Subscription } from 'rxjs';
import { flatMap, map, filter } from 'rxjs/operators';

import { OAuthService, AuthConfig, JwksValidationHandler, OAuthSuccessEvent } from 'angular-oauth2-oidc';

import { Roles } from '../../roles.enum';
import { VersionService } from '../version/version.service';
import { AppConfigurationService } from 'src/app/app-configuration.service';
import {
  EstablishmentControllerService,
  LoginControllerService,
  PractitionerControllerService,
} from '@abilycare/dal-client';
import { NotificationService } from '../notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from 'src/app/services/snack-bar.service';

@Injectable()
export class AuthService implements OnDestroy {
  public readonly logoutDisplayModal = new BehaviorSubject<boolean>(false);
  public readonly loginErrorDisplayModal = new BehaviorSubject<boolean>(false);
  public readonly appVersionDisplayModal = new BehaviorSubject<boolean>(false);

  public implicit = true;
  public stateUrl = null;

  public readonly isConnected = new BehaviorSubject<boolean>(false);
  public readonly isDisabled = new BehaviorSubject<boolean>(false);
  private readonly currentClaims = new BehaviorSubject<any>(null);

  private subscriptions: Subscription[] = [];

  constructor(
    private oauthService: OAuthService,
    private oauthConfig: AuthConfig,
    private appConfigurationService: AppConfigurationService,
    private versionService: VersionService,
    private practitionnerService: PractitionerControllerService,
    private notificationService: NotificationService,
    private loginService: LoginControllerService,
    private establishmentService: EstablishmentControllerService,
    private translateService: TranslateService,
    private snackBarService: SnackBarService,
  ) {
    this.currentClaims.next(this.oauthService.getIdentityClaims());
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
  }

  public init() {
    this.oauthConfig.oidc = this.implicit;
    this.oauthService.configure(this.oauthConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    console.log('authService.init : version ', this.versionService.getAppVersion());
    this.subscriptions.push(
      this.currentClaims.subscribe((claims) => {
        if (claims) {
          this.practitionnerService.practitionerControllerMe().subscribe(
            async (me) => {
              this.establishmentService
                .establishmentControllerGetEstablishment(me.establishment.id)
                .subscribe((est) => {
                  if (est.config.disabled) {
                    this.isDisabled.next(true);
                    //throw new Error("establishment disabled.");
                  }
                  /*
                  this.loginService.loginControllerGetuserStatus(me.name)
                  .subscribe((userStatus) => {
                   if (!userStatus) {
                      this.notificationService.pushErrorNotifications('Profil invalide.');
                      this.loginErrorDisplayModal.next(true);
                      // this.oauthService.logOut();
                      this.currentClaims.next(null);
                      //throw new Error("user disabled.");
                   }    */

                  // last version use by the user.
                  let lastVersion = null;
                  if (me.lastConnection) lastVersion = me.lastConnection.version;
                  console.log('last version is ', lastVersion);
                  if (lastVersion !== this.versionService.getAppVersion()) {
                    this.appVersionDisplayModal.next(true);
                  }
                  //});
                });
              this.oauthService.clearHashAfterLogin = false;
              this.isConnected.next(true);
            },
            (error) => {
              // if no id is return we logout.
              // TODO change error message depending of error and translate.
              this.notificationService.pushErrorNotifications('Profil invalide.');
              this.loginErrorDisplayModal.next(true);
              // this.oauthService.logOut();
              this.currentClaims.next(null);
            },
          );
        } else {
          this.isConnected.next(false);
        }
      }),
    );

    this.subscriptions.push(
      this.oauthService.events.pipe(filter((e) => e.type === 'logout')).subscribe(() => {
        this.currentClaims.next(null);
      }),
    );

    this.subscriptions.push(
      this.oauthService.events.pipe(filter((e) => e.type === 'token_received')).subscribe(() => {
        const claims = this.oauthService.getIdentityClaims();
        this.currentClaims.next(claims);
      }),
    );

    // Load Discovery Document and then try to login the user
    return fromPromise(
      this.oauthService
        .loadDiscoveryDocument(this.appConfigurationService.wso2Parameters.wellkown)
        .then(() => {
          // Cette méthode essaie simplement de parser les token(s) dans l'URL lorsque
          // le serveur d'authentification redirige l'utilisateur vers l'application web
          // Elle ne redirige pas l'utilisateur vers la page de connexion
          return this.oauthService.tryLogin({
            onTokenReceived: (opt) => {
              this.stateUrl = opt.state;
              this.oauthService.setupAutomaticSilentRefresh();
            },
          });
        })
        .catch((error) => {
          console.error('AuthService :  ', error);
          const errMsg = this.translateService.instant(error);
          this.snackBarService.show(errMsg, 'danger');
        }),
    );
    /*
    return fromPromise(
      this.oauthService.loadDiscoveryDocument(this.appConfigurationService.wso2Parameters.wellkown).then(() => {
        // This method just tries to parse the token(s) within the url when
        // the auth-server redirects the user back to the web-app
        // It dosn't send the user the the login page
        return this.oauthService.tryLogin({
          onTokenReceived: (opt) => {
            this.stateUrl = opt.state;
            this.oauthService.setupAutomaticSilentRefresh();
          },
        });
      }),
    );*/
  }
  /**
   * Really log out (see askLogOut for confirmation process)
   */
  public logOut() {
    this.logoutDisplayModal.next(false);
    this.oauthService.logOut();
  }

  /**
   * Ask for log out, UI may show a confirmation dialog
   */
  public askLogOut(): void {
    this.logoutDisplayModal.next(true);
  }

  /**
   * Cancel ask for log out, UI may hide a confirmation dialog
   */
  public cancelLogOut(): any {
    this.logoutDisplayModal.next(false);
  }

  public getIdentityClaims(): any {
    // TODO get more specific data (name, email, role etc...)
    return this.oauthService.getIdentityClaims();
  }

  public initImplicitFlow() {
    this.oauthService.initImplicitFlow(this.stateUrl);
  }

  public loginWithPasswordFlow(login: string, password: string): Observable<void> {
    // public loginWithPasswordFlow(login: string, password: string): any {
    return fromPromise(this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(login, password)).pipe(
      flatMap(() => {
        return new Observable<void>();
      }),
    );
  }

  /**
   * Get roles of current user.
   */
  public get roles(): Observable<string[]> {
    return this.currentClaims.pipe(map((claim) => (claim ? claim.roles : [])));
  }

  /**
   * Check if role is present.
   * @param neededRole Role to check
   */
  public hasRole(neededRole: Roles) {
    return this.roles.pipe(map((roles) => (roles.find((r) => r === neededRole) ? true : false)));
  }

  /**
   * Check if role is present at the current moment
   * May change during initialisation, prefer hasRole which return Observable
   * @param neededRole Role to check
   */
  public hasRoleInstant(neededRole: Roles) {
    const instantClaimValue = this.currentClaims.value;
    if (!instantClaimValue || !instantClaimValue.roles) {
      return false;
    }
    return instantClaimValue.roles.find((r) => r === neededRole) ? true : false;
  }

  // public get establishment() {
  //   return this.theEstablisment;
  // }
}
