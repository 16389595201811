//Bluetooth information for Grip
export const GripPrefixe: string = 'KFORCEGrip';
/**
 *
 * V2 Grip Information Service and Characteristic
 */
export const GripUIV2Service: string = '49535343-fe7d-4ae5-8fa9-9fafd205e455';
export const GripV2Characteristic: string = '49535343-1e4d-4bd9-ba61-23c647249616';

/**
 *
 * V3 Grip Information Service and Characteristic
 */

export const GripUIService: string = '00000000-0415-479b-9dfea1d9a6a2dc3c'; //'00000000-0415-479b-9dfea1d9a6a2dc3c';
export const GripUIServiceBattery: string = '00000300-0415-479b-9dfe-a1d9a6a2dc3c';
export const GripCharacteristicBattery: string = '00000302-0415-479b-9dfe-a1d9a6a2dc3c';
export const GripUIServiceData: string = '00000100-0415-479b-9dfe-a1d9a6a2dc3c';
export const GripCharacteristicData: string = '00000103-0415-479b-9dfe-a1d9a6a2dc3c';
export const GripCharacteristicStartDataMeasure: string = '00000101-0415-479b-9dfe-a1d9a6a2dc3c';
export const GripCharacteristicStopDataMeasure: string = '00000102-0415-479b-9dfe-a1d9a6a2dc3c';
export const GripCharacteristicBaseLine: string = '00000130-0415-479b-9dfe-a1d9a6a2dc3c';

/**
 *
 * V3 Plate Information Service and Characteristic
 */
export const PlateLPrefixe = 'KFORCEPlateL';
export const PlateRPrefixe = 'KFORCEPlateR';
export const PlateUIService: string = '00000000-0415-479b-9dfea1d9a6a2dc3c'; //'00000000-0415-479b-9dfea1d9a6a2dc3c';
export const PlateUIServiceBattery: string = '00000300-0415-479b-9dfe-a1d9a6a2dc3c';
export const PlateCharacteristicBattery: string = '00000302-0415-479b-9dfe-a1d9a6a2dc3c';
export const PlateCharacteristicKeepAlive: string = '00000306-0415-479b-9dfe-a1d9a6a2dc3c';
export const PlateUIServiceData: string = '00000100-0415-479b-9dfe-a1d9a6a2dc3c';
export const PlateCharacteristicData: string = '00000103-0415-479b-9dfe-a1d9a6a2dc3c';
export const PlateCharacteristicStartDataMeasure: string = '00000101-0415-479b-9dfe-a1d9a6a2dc3c';
export const PlateCharacteristicStopDataMeasure: string = '00000102-0415-479b-9dfe-a1d9a6a2dc3c';
export const PlateCharacteristicBaseLine: string = '00000130-0415-479b-9dfe-a1d9a6a2dc3c';
export const PlateServiceSync: string = '00000200-0415-479b-9dfe-a1d9a6a2dc3c';
export const PlateCharacteristicReadSync: string = '00000202-0415-479b-9dfe-a1d9a6a2dc3c';
export const PlateCharacteristicWriteSync: string = '00000201-0415-479b-9dfe-a1d9a6a2dc3c';
export const PlateCharacteristicStartSync: string = '00000203-0415-479b-9dfe-a1d9a6a2dc3c';
export const PlateCharacteristicResponseSync: string = '00000204-0415-479b-9dfe-a1d9a6a2dc3c';

// Waiting duration for getting measure grip in seconde
export const GRIP_TIME_MEASURE: number = 5;

// Waiting duration for getting baseline grip in seconde
export const GRIP_TIME_BASELINE: number = 2;

//Bluetooth information for Plate : olde V2
/*
export const PlateUIService: string = '49535343-fe7d-4ae5-8fa9-9fafd205e455';
export const PlateUICharacteristic: string = '49535343-1e4d-4bd9-ba61-23c647249616';
*/

// Waiting duration for getting measure plate in seconde
export const PLATE_TIME_MEASURE: number = 5;
// Waiting duration for getting baseline grip in seconde
export const PLATE_TIME_BASELINE: number = 2;

// MEASUREMENT_MULTIPLIER_DENOMINATOR for coef
export const GRIP_MEASUREMENT_MULTIPLIER_DENOMINATOR = 1000000.0;

export const GRIP_BATTERY: number[] = [
  100,
  100,
  100,
  99,
  99,
  99,
  98,
  98,
  97,
  97,
  97,
  96,
  96,
  95,
  94,
  94,
  93,
  93,
  92,
  91,
  90,
  90,
  89,
  88,
  87,
  86,
  86,
  85,
  84,
  83,
  82,
  81,
  80,
  79,
  77,
  76,
  75,
  74,
  73,
  72,
  70,
  69,
  68,
  66,
  65,
  64,
  62,
  61,
  59,
  58,
  56,
  55,
  53,
  51,
  50,
  48,
  46,
  45,
  43,
  41,
  39,
  38,
  36,
  34,
  32,
  30,
  28,
  26,
  24,
  22,
  20,
  18,
  16,
  14,
  11,
  9,
  7,
  5,
  2,
  1,
  1,
  1,
  0,
];
