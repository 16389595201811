import {
  InlineObject,
  NewPatient,
  Patient,
  PatientBrief,
  PatientControllerService,
  PatientDelegation,
  PatientDelegationControllerService,
  PatientDetail,
  PatientPractitionerRelation,
  UpdatePatient,
} from '@abilycare/dal-client';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { PatientDelegateComponent } from 'src/app/view/patient/patient-delegate/patient-delegate.component';

@Injectable({ providedIn: 'root' })
export class PatientDataService {
  constructor(
    private patientControllerService: PatientControllerService,
    private patientDelegationControllerService: PatientDelegationControllerService,
  ) {}

  public getAllPatients(): Observable<PatientBrief[]> {
    return this.patientControllerService.patientControllerFind().pipe(map((res) => res as PatientBrief[]));
  }

  public getPatientById(idPatient: number): Observable<PatientDetail> {
    return this.patientControllerService.patientControllerFindById(idPatient).pipe(map((res) => res as PatientDetail));
  }

  public createPatient(newPatient: NewPatient): Observable<Patient> {
    return this.patientControllerService.patientControllerCreate(newPatient).pipe(map((res) => res as Patient));
  }

  public updatePatient(patient: UpdatePatient): Observable<any> {
    return this.patientControllerService
      .patientControllerUpdateById(patient.id, patient)
      .pipe(map((res) => res as any));
  }

  public deletePatient(idPatient: number): Observable<any> {
    return this.patientControllerService.patientControllerDeleteById(idPatient).pipe(map((res) => res as Patient));
  }

  public filterPatient(
    delegateForTest: boolean,
    delegateForResult: boolean,
    isOwner: boolean,
    allOtherUser: boolean,
    referringPractitionner: number,
  ): Observable<PatientBrief[]> {
    return this.patientControllerService
      .patientControllerFind('', delegateForTest, delegateForResult, isOwner, allOtherUser, referringPractitionner)
      .pipe(map((res) => res as PatientBrief[]));
  }

  public delegatePatient(
    patientId: number,
    practitionerId: number,
    type: 'TestDelegation' | 'ResultDelegation',
  ): Observable<any> {
    return this.patientDelegationControllerService
      .patientDelegationControllerCreate(patientId, type, practitionerId)
      .pipe(map((res) => res as any));
  }

  public addDelegationPatientsMultiple(objectDelegate: InlineObject): Observable<PatientPractitionerRelation[]> {
    return this.patientDelegationControllerService
      .patientDelegationControllerCreateMultiple(objectDelegate)
      .pipe(map((res) => res as any));
  }

  public deleteDelegationPatientsMultiple(objectDelegate: InlineObject): Observable<any> {
    return this.patientDelegationControllerService
      .patientDelegationControllerDeleteMultiple(objectDelegate)
      .pipe(map((res) => res as any));
  }

  public getAllDelegationsForPatient(patientId: number): Observable<PatientDelegation[]> {
    return this.patientDelegationControllerService
      .patientDelegationControllerFind(patientId)
      .pipe(map((res) => res as any));
  }

  public getAllDelegationsForMultiplePatients(patientListId: Array<number>): Observable<PatientPractitionerRelation[]> {
    return this.patientDelegationControllerService
      .patientDelegationControllerGetMultiple(patientListId)
      .pipe(map((res) => res as any));
  }
}
