import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Course, CourseControllerService, CourseWithRelations, NewCourse, PatientDetail } from '@abilycare/dal-client';
import { PatientDataService } from 'src/app/modules/abilycare-data-access/api/patient-data.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { RolesCheckService } from 'src/app/modules/core/services/authentification/roles-check.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.scss'],
})
export class CourseListComponent implements OnInit, OnDestroy {
  @ViewChild('masterContent', { static: true }) masterContent: ElementRef<HTMLElement>;
  @ViewChild('widgetsContent', { static: true }) widgetsContent: ElementRef<HTMLElement>;
  @ViewChild('btnOpenDeleteModal', { static: true }) btnOpenDeleteModal: ElementRef;

  public coursesList: CourseWithRelations;
  public patientId: number;
  public patient: PatientDetail = null;
  public canEdit = false;
  public displayChevron = true;
  public displayLeftChevron = false;
  public displayRightChevron = true;
  public courseId: number;
  public hasCoursInProgress: boolean = false;

  public cursorScrollLeft: number = 0;

  public returnTitle: string = '';

  public isLoaded = false;

  private subscriptions: Subscription[] = [];

  public error: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private courseDataService: CourseControllerService,
    private patientDataService: PatientDataService,
    private dataService: DataProviderService,
    public rolesCheck: RolesCheckService,
    private translateService: TranslateService,
  ) {
    this.returnTitle = this.translateService.instant('Patient.Profil.back_patient');
  }

  ngOnInit() {
    this.dataService.storeHeaderShowed(false);
    this.activatedRoute.paramMap.subscribe((params) => {
      this.patientId = parseInt(params.get('patientId').toString(), 10);
      this.getPatient();
      this.getAllCourses();
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
  }

  public getPatient(): void {
    this.subscriptions.push(
      this.patientDataService.getPatientById(this.patientId).subscribe((patient: PatientDetail) => {
        this.patient = patient;
        this.isLoaded = true;
        if (patient.currentPractionerRoles && patient.currentPractionerRoles.length > 0) {
          patient.currentPractionerRoles.forEach((role) => {
            if (role === 'Owner' || role === 'TestDelegation') {
              this.canEdit = true;
            }
          });
        }

        if (this.masterContent.nativeElement.offsetWidth < this.widgetsContent.nativeElement.offsetWidth) {
          this.displayChevron = false;
        }
        if (!this.coursesList) {
          this.displayChevron = false;
        }
      }),
    );
  }

  public getAllCourses(): void {
    let that = this;
    this.subscriptions.push(
      this.courseDataService.courseControllerFindAllCourse(this.patientId).subscribe((allCourses) => {
        this.coursesList = allCourses;
        if (this.coursesList != null) {
          var resultArray = Object.keys(allCourses).map(async function(courseIndex) {
            let courseObj = allCourses[courseIndex];
            await that.updateStatusAndScore(courseObj);
            that.hasCoursInProgress = (await courseObj.status) === 0 || courseObj.status === 1;
            if (that.hasCoursInProgress)
              that.hasCoursInProgress =
                (await that.hasCoursInProgress) && (courseObj.status === 0 || courseObj.status === 1);
          });
        }
      }),
    );
  }

  public async updateStatusAndScore(course: Course) {
    await this.courseDataService.courseControllerUpdateScoreAndStatus(course.patientId, course.id).subscribe((res) => {
      console.log('course updated status and score ');
    });
  }

  public addCourse(): void {
    let newCourse: NewCourse = {};
    //set this.error = true; if parcours exists
    if (this.hasCoursInProgress) {
      this.error = true;
    } else {
      this.courseDataService.courseControllerCreate(this.patientId, newCourse).subscribe((data) => {
        this.getAllCourses();
      });
    }
  }

  public scrollRight(): void {
    this.cursorScrollLeft += 400;
    this.widgetsContent.nativeElement.scrollTo({
      left: this.cursorScrollLeft,
      behavior: 'smooth',
    });

    const offsetWidth: number = this.widgetsContent.nativeElement.offsetWidth;
    const scrollWidth: number = this.widgetsContent.nativeElement.scrollWidth;

    if (this.cursorScrollLeft + offsetWidth >= scrollWidth) {
      this.cursorScrollLeft = this.widgetsContent.nativeElement.scrollLeft;
      this.displayRightChevron = false;
    }
    this.displayLeftChevron = true;
  }

  public scrollLeft(): void {
    this.cursorScrollLeft -= 400;
    this.widgetsContent.nativeElement.scrollTo({
      left: this.cursorScrollLeft,
      behavior: 'smooth',
    });

    if (this.cursorScrollLeft <= 0) {
      this.cursorScrollLeft = 0;
      this.displayLeftChevron = false;
    }
    this.displayRightChevron = true;
  }

  public deleteCourse(): void {
    this.subscriptions.push(
      this.courseDataService.courseControllerDelete(this.courseId).subscribe((data) => {
        this.getAllCourses();
      }),
    );
  }

  public onDeleteCourse(courseId): void {
    this.courseId = courseId;
    this.btnOpenDeleteModal.nativeElement.click();
  }
}
