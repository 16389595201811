import { ChangeDetectorRef, Component, ElementRef, OnChanges, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataProviderService } from 'src/app/services/data-provider.service';
import {
  PatientDetail,
  CourseControllerService,
  Course,
  ResultsCourseWithRelations,
  QuestionnaireReplyControllerService,
  QuestionnaireReply,
  QuestionnaireControllerService,
  QuestionnaireDetailWithRelations,
  PatientControllerService,
  Grip,
  GripControllerService,
  WalkingControllerService,
  Walking,
  WalkingWithRelations,
  GripWithRelations,
  WeighingWithRelations,
  WeighingControllerService,
  QuestionnaireReplySummary,
  QuestionnaireReplyWithRelations,
  Weighing,
} from '@abilycare/dal-client';
import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { Location } from '@angular/common';
import { LongitudinalGraph } from 'src/app/modules/walk/models/longitudinal.model';
import { TimelineChartComponent } from 'src/app/view/common/timelineChart/timelineChart.component';

@Component({
  selector: 'app-course-synthesis',
  templateUrl: './course-synthesis.component.html',
  styleUrls: ['./course-synthesis.component.scss'],
  providers: [TimelineChartComponent],
})
export class CourseSynthesisComponent implements OnInit {
  @ViewChild('btnCloseCed', { static: true }) btnCloseCed: ElementRef;
  @ViewChild('btnCloseWeight', { static: true }) btnCloseWeight: ElementRef;
  @ViewChild('btnCloseQappa', { static: true }) btnCloseQappa: ElementRef;
  @ViewChild('btnCloseGrip', { static: true }) btnCloseGrip: ElementRef;
  @ViewChild('btnCloseWalk', { static: true }) btnCloseWalk: ElementRef;
  public course: any;

  // public readonly boardReady: Observable<boolean>;

  public patient: PatientDetail;

  // private state: State = new State();

  public returnTitle: string = i18nKey('Checkup.navbar.patient.back');
  public testName: string = ''; //i18nKey('Course.synthesis');
  public resultsCours: ResultsCourseWithRelations;
  public weightTest: ResultsCourseWithRelations;
  public questDetail11: QuestionnaireDetailWithRelations;
  public score: number;

  public newPage: string = '';

  public grip: Grip;
  public walking: Walking;
  public walkGraph: LongitudinalGraph;
  public gripGraph: LongitudinalGraph;
  public weightGraph: LongitudinalGraph;
  public questGraph: LongitudinalGraph;
  public walkingData: any;
  public gripData: any;
  public weightData: any;
  public questData: any;
  public qappaQuestionnaryReply: QuestionnaireReply;
  public hand: string = 'D';
  public showGraph: boolean;
  public minWeight: number;
  public maxWeight: number;

  constructor(
    private translateService: TranslateService,
    private dataService: DataProviderService,
    private location: Location,
    private courseDataService: CourseControllerService,
    private questionnaryService: QuestionnaireControllerService,
    private questionnaryReplyService: QuestionnaireReplyControllerService,
    private weightService: WeighingControllerService,
    private gripService: GripControllerService,
    private walkingService: WalkingControllerService,
    private patientService: PatientControllerService,
  ) {}

  async ngOnInit() {
    this.course = this.location.getState();
    this.patient = this.dataService.retrievePatient();
    this.courseDataService
      .courseControllerUpdateScoreAndStatus(this.patient.id, this.course.id)
      .subscribe((allResultsCourse) => {
        this.resultsCours = allResultsCourse;
      });
    this.score = parseInt(this.course.score);
    this.dataService.storeHeaderShowed(false);

    this.patientService.patientControllerFindById(this.course.patientId).subscribe((p) => {
      this.patient = p;
      this.hand = p.lateralization;
    });
    this.questionnaryService
      .questionnaireControllerFindById(11, this.translateService.currentLang)
      .subscribe((questDetail) => {
        this.questDetail11 = questDetail;
      });

    await this.weightService.weighingControllerFindAllWeight(this.patient.id).subscribe((allWeight: any) => {
      this.weightData = allWeight.filter(function(e) {
        return (
          new Date().getMonth() -
            new Date(e.date).getMonth() +
            12 * (new Date().getFullYear() - new Date(e.date).getFullYear()) <=
          24
        );
      });
      this.transformWeightToGraph(this.weightData);
    });

    await this.gripService.gripControllerFindGripByCourseId(this.course.id).subscribe((res) => {
      this.grip = res;
      if (this.hand == 'B') {
        const valueL = Math.max(res.leftHandV1, res.leftHandV2);
        const valueR = Math.max(res.rightHandV1, res.rightHandV2);
        if (valueL > valueR) this.hand = 'G';
        else this.hand = 'D';
      }
    });
    await this.gripService.gripControllerFindAllGrip(this.patient.id).subscribe((allGrip: any) => {
      this.gripData = allGrip.filter(function(e) {
        return (
          new Date().getMonth() -
            new Date(e.date).getMonth() +
            12 * (new Date().getFullYear() - new Date(e.date).getFullYear()) <=
          24
        );
      });
      this.transformGripToGraph(this.gripData);
    });
    await this.walkingService.walkingControllerFindWalkingByCourseId(this.course.id).subscribe((res) => {
      this.walking = res;
    });
    await this.walkingService.walkingControllerFindAllWalkingByPatient(this.patient.id).subscribe((allWalking: any) => {
      this.walkingData = allWalking.filter(function(e) {
        return (
          new Date().getMonth() -
            new Date(e.date).getMonth() +
            12 * (new Date().getFullYear() - new Date(e.date).getFullYear()) <=
          24
        );
      });
      this.transformWalkToGraph(this.walkingData);
    });
    await this.questionnaryReplyService
      .questionnaireReplyControllerFindQuestionnaryByCourseId(this.course.id, 12)
      .subscribe((res: any) => {
        this.qappaQuestionnaryReply = res;
      });

    await this.questionnaryReplyService
      .questionnaireReplyControllerFindByPatientId(this.patient.id)
      .subscribe((allQuest: any) => {
        this.questData = allQuest.filter(function(e) {
          return (
            e.questionnaireId == 12 &&
            new Date().getMonth() -
              new Date(e.date).getMonth() +
              12 * (new Date().getFullYear() - new Date(e.date).getFullYear()) <=
              24
          );
        });
        this.transformQuestToGraph(this.questData);
      });
    //await this.designGraph();
    this.showGraph = await true;
  }

  private transformWalkToGraph(bilans: WalkingWithRelations[]) {
    console.log('transformwalkToGraph ', bilans);
    bilans = bilans.sort((a, b) => {
      return +new Date(a.date) - +new Date(b.date);
    });
    this.walkGraph = new LongitudinalGraph(new Array<{ x: Date; y: number }>(), '');
    for (const bilan of bilans) {
      this.walkGraph.graph.push({ x: bilan.date, y: bilan.averageSpeed });
    }
  }

  private transformGripToGraph(bilans: GripWithRelations[]) {
    console.log('transformGripToGraph ', bilans);
    bilans = bilans.sort((a, b) => {
      return +new Date(a.date) - +new Date(b.date);
    });
    this.gripGraph = new LongitudinalGraph(new Array<{ x: Date; y: number }>(), '');
    for (const bilan of bilans) {
      this.gripGraph.graph.push({ x: bilan.date, y: this.hand == 'G' ? bilan.leftHandV1 : bilan.rightHandV1 });
    }
  }

  private transformWeightToGraph(bilans: WeighingWithRelations[]) {
    console.log('transformweightToGraph ', bilans);
    this.maxWeight = this.getMax(bilans);
    this.minWeight = this.getMin(bilans);
    bilans = bilans.sort((a, b) => {
      return +new Date(a.date) - +new Date(b.date);
    });
    this.weightGraph = new LongitudinalGraph(new Array<{ x: Date; y: number }>(), '');
    if (bilans.length == 1) this.weightGraph.graph.push({ x: this.patient.created, y: this.patient.weight });
    for (const bilan of bilans) {
      //if (this.resultsCours[0].state && (bilan.value == maxWeight || bilan.value == minWeight) ) {
      //this.weightGraph.graph.pointBorderColor[index] = '#FF0000';
      this.weightGraph.graph.push({ x: bilan.date, y: bilan.value });
      //} else
      //  this.weightGraph.graph.push({ x: bilan.date, y: bilan.value });
      //index++;
    }
  }

  private transformQuestToGraph(bilans: QuestionnaireReply[]) {
    console.log('transformquestToGraph ', bilans);
    bilans = bilans.sort((a, b) => {
      return +new Date(a.date) - +new Date(b.date);
    });
    this.questGraph = new LongitudinalGraph(new Array<{ x: Date; y: number }>(), '');
    for (const bilan of bilans) {
      this.questGraph.graph.push({ x: bilan.date, y: bilan.scores[0] });
    }
    console.log('this.questGraph ', this.questGraph);
  }

  getMax(datas: WeighingWithRelations[]) {
    return Math.max(...datas.map((data) => data.value));
  }

  getMin(datas: WeighingWithRelations[]) {
    return Math.min(...datas.map((data) => data.value));
  }

  public getAnserwsValue(questIndex: number, ansewerIndex: number) {
    const answers = this.questDetail11.questions[questIndex].answers;
    return answers[ansewerIndex].text;
  }

  onCloseModal() {
    this.btnCloseCed.nativeElement.click();
    this.btnCloseWeight.nativeElement.click();
    this.btnCloseQappa.nativeElement.click();
    this.btnCloseGrip.nativeElement.click();
    this.btnCloseWalk.nativeElement.click();
  }

  public onSelectPage(newPage: string) {
    this.newPage = newPage;
  }
}
