<nav class="navbar navbar-expand-lg sticky-top" style="background-color: #ffffff; border-bottom: 2px solid #dce2e7;">
  <div class="row" style="width: 100%;">
    <div class="col-md-3 container-title flex-column">
      <span *ngIf="course && course.id">{{ 'Patient.Profil.course_fragility' | translate }}</span>
    </div>
    <div class="col-md-3 container-title flex-column" *ngIf="testName">
      <span> {{ testName }}</span>
    </div>
    <div class="col-md-3 container-title flex-column" *ngIf="course && course.score >= 0">
      <!-- && course.status == 2 -->
      <span>{{ 'Patient.Profil.fragility_score' | translate }} : {{ course.score }}</span>
    </div>
    <div class="col-md-3 container-title flex-column" *ngIf="isWalking || fromSynthesis">
      <span>{{ 'doubleTask' | translate }} : {{ doubleTask ? doubleTask : 'N/A' }}</span>
    </div>
    <div class="col-md-3" *ngIf="course && (course.status == 1 || course.status == 0)">
      <div class="container-name">
        {{ 'Patient.Profil.course_end_before' | translate }} : &nbsp; {{ course.enddate | localdate: 'datetime' }}
      </div>
    </div>
  </div>
</nav>
