import { Component, OnInit, ViewChild } from '@angular/core';
import { WiiBoardService } from 'src/app/modules/force-platform';
import { GripMonitorComponent } from '../../courses/fried/grip/grip-monitor/grip-monitor.component';
import { InformationConnexionComponent } from '../../information-connexion/information-connexion.component';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss'],
  providers: [GripMonitorComponent, InformationConnexionComponent, WiiBoardService],
})
export class ConfigComponent implements OnInit {
  @ViewChild('gripMonitor', { static: true }) gripMonitor: GripMonitorComponent;

  constructor() {}

  ngOnInit() {}
}
