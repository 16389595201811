import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';

import { RemoteInteraction } from './remote-interaction.service';
import { AnalysisResults } from '../models/analysis-results.model';
import { catchError } from 'rxjs/operators';
import { HistoVersusConsultationService } from '../../consultation/services/histo-versus-consultation.service';

@Injectable()
export class AnalysisResultsRepository {
  public constructor(
    private remote: RemoteInteraction,
    private histoVsConsultationService: HistoVersusConsultationService,
  ) {}

  /**
   * TODO enable caching in here if needed (mainly for performance reasons)
   * @param recordingId recordingId
   */
  public async get(recordingId: string): Promise<AnalysisResults> {
    console.log('Write Mode', this.histoVsConsultationService.writeModeValue);
    // if (!this.histoVsConsultationService.writeModeValue) {
    //   return this.remote.getAnalysisResultsForSession('' + LocalStorageEx.currentBilan.Id);
    // } else {
    return this.remote.getAnalysisResultsForSession(recordingId);
    // }
  }

  public getOther(recordingId: string): Observable<AnalysisResults> {
    return from(this.remote.getAnalysisResultsForSession(recordingId)).pipe(catchError(() => of(null)));
  }
}
