import { BilansControllerService, PatientBrief, Practitioner } from '@abilycare/dal-client';
import { Location } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorage } from '@ng-idle/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { PatientDataService } from 'src/app/modules/abilycare-data-access/api/patient-data.service';
import { RolesCheckService } from 'src/app/modules/core/services/authentification/roles-check.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss'],
})
export class PatientListComponent implements OnInit, OnDestroy {
  public patients: Array<PatientBrief> = new Array<PatientBrief>();
  public patientsChecked: Array<any> = new Array<PatientBrief>();
  public id: number;
  public selectionTitle: string;
  @Output() selectPage: EventEmitter<string> = new EventEmitter<string>();
  @Input() searchPatient: string;

  @Output() patientSelected: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();
  @Output() patientDelegateListSelected: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();
  @Output() clickDeletePatient: EventEmitter<PatientBrief> = new EventEmitter<PatientBrief>();

  @ViewChildren('patientLinks') patientLinks: QueryList<ElementRef<HTMLElement>>;

  public canDelete: boolean = false;
  private unsubscribe = new Subject<void>();
  public patientListDelegate: Array<number> = new Array<number>();
  private subscriptions: Subscription[] = [];

  constructor(
    private patientData: PatientDataService,
    private dataService: DataProviderService,
    private rolesCheck: RolesCheckService,
    private location: Location,
    private router: Router,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private bilansService: BilansControllerService,
    private localStorage: LocalStorage,
  ) {}

  ngOnInit() {
    this.rolesCheck.hasManagePatient.pipe(takeUntil(this.unsubscribe)).subscribe((canDelete) => {
      this.canDelete = canDelete;
    });
  }

  ngOnDestroy() {
    this.patientListDelegate = null;
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public selectPatient(patientId: number) {
    this.getPatientDetail(patientId);
  }

  public selectunSelectAllRows() {
    //console.log("row in select unselect ", this.patients);
    this.patientsChecked.forEach((row) => {
      row.isChecked = !row.isChecked;
      if (row.isChecked) {
        this.selectionTitle = this.translateService.instant('deselect.all');
      } else {
        this.selectionTitle = this.translateService.instant('select.all');
      }
      this.addPatientToDelegateList(row.id, row.isChecked);
    });
  }

  public getPatientDetail(patientId: number) {
    this.id = patientId;
    this.subscriptions.push(
      this.patientData.getPatientById(patientId).subscribe(
        (patient) => {
          this.dataService.setPatient(patient);
          this.scrollOnPatientSelected(patient.id);
          //this.router.navigate(['Patient/', patientId]);
          //this.goTo(['/Patient/'+ patientId]);
          this.location.go('/Patient/' + patientId);
        },
        (error) => {
          console.error('PatientListComponent : Err-Patient-01 ', error);
          const errMsg = this.translateService.instant('Err-Patient-01');
          this.snackBarService.show(errMsg, 'danger');
        },
      ),
    );
  }

  public getAllPatients(patientId = null): void {
    this.subscriptions.push(
      this.patientData.getAllPatients().subscribe(
        (patients) => {
          this.patients = patients;
          this.createPatientsChecked();
          if (patientId) {
            this.selectPatientCreated(patientId);
          }
        },
        (error) => {
          console.error('Error getAllPatients : ', error);
        },
      ),
    );
  }

  public createPatientsChecked() {
    this.patientsChecked = [];
    this.patients.forEach((patient) => {
      const row = {
        id: patient.id,
        isChecked: false,
      };
      this.patientsChecked.push(row);
    });
  }

  public scrollOnPatientSelected(patientId) {
    this.patientLinks.toArray().forEach((item) => {
      if (item.nativeElement.id === patientId.toString()) {
        item.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    });
  }

  public addPatientToDelegateList(idPatient: number, checked: boolean) {
    if (checked) {
      this.patientListDelegate.push(idPatient);
    } else {
      this.patientListDelegate.forEach((patientId, index) => {
        if (idPatient === patientId) {
          this.patientListDelegate.splice(index, 1);
        }
      });
    }
    this.patientDelegateListSelected.emit(this.patientListDelegate);
  }

  public getPatientsWithFilter(tab: any, referringPractitioner: Practitioner, patientId = null): void {
    const delegateForTest: boolean = tab[0].checked;
    const delegateForResult: boolean = tab[1].checked;
    const isOwner: boolean = tab[2].checked;
    const allOtherUser: boolean = tab[3].checked;
    const referringPractitionner: boolean = tab[4].checked;
    let practionnerid: number = -1;
    if (referringPractitionner) practionnerid = referringPractitioner.id;

    this.subscriptions.push(
      this.patientData
        .filterPatient(delegateForTest, delegateForResult, isOwner, allOtherUser, practionnerid)
        .subscribe(
          (patients) => {
            this.patients = patients;
            this.createPatientsChecked();
            if (patientId) {
              this.selectPatientCreated(patientId);
            }
          },
          (error) => {
            console.error('Error patient : ', error);
          },
        ),
    );
  }

  public selectPatientCreated(patientId: number) {
    const result = this.patients.find((patient) => {
      return patient.id === patientId;
    });
    if (result) {
      this.selectPatient(patientId);
    }
  }

  public deletePatient(patient: PatientBrief): void {
    this.clickDeletePatient.emit(patient);
  }

  public isChecked(id: number): boolean {
    // if (this.patientDelegateListSelected)
    const found = this.patientListDelegate.find((patientId) => patientId === id);
    if (found) return true;
    return false;
  }

  goTo(page) {
    this.selectPage.emit(page);
    this.router.navigate([page]);
  }
}
