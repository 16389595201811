import {
  PatientDetail,
  QuestionnaireControllerService,
  QuestionnaireDetailWithRelations,
  QuestionnaireSummary,
} from '@abilycare/dal-client';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { QuestionnaryListComponent } from './questionnary-list/questionnary-list.component';
import { QuestionnaryStepsComponent } from './questionnary-steps/questionnary-steps.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-questionnary',
  templateUrl: './questionnary.component.html',
  styleUrls: ['./questionnary.component.scss'],
  // styleUrls: ['./questionnary.component.scss', '../shared/scss/common.scss'],
})
export class QuestionnaryComponent implements OnInit, OnDestroy {
  @ViewChild('questionnaryStep', { static: false }) questionnaryStep: QuestionnaryStepsComponent;
  @ViewChild('questionnaryList', { static: false }) questionnaryList: QuestionnaryListComponent;

  public patient: PatientDetail;
  public returnTitle: string = i18nKey('Checkup.navbar.patient.back');
  public questionnariesList: Array<QuestionnaireSummary> = new Array();
  public questionnaryDisplayed: QuestionnaireSummary;
  public startTest: boolean = false;
  private subscriptions: Subscription[] = [];
  public questionnaryDetails: QuestionnaireDetailWithRelations;
  private isAloneQuestionnary: boolean = false;
  public canDisplayStart: boolean = true;
  public testName: string = i18nKey('navbarQuestionnaries');
  public idQuestionnary: number;
  public currentPage: String;
  public showHtml: boolean;

  constructor(
    private dataService: DataProviderService,
    private questionnaryService: QuestionnaireControllerService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((params) => {
      if (params && params.get('questionnaryId')) {
        this.idQuestionnary = parseInt(params.get('questionnaryId').toString(), 10);
        this.currentPage = 'questionnary' + this.idQuestionnary;
        this.showHtml = true;
        const questToDisplay = this.getQuestionnary(this.idQuestionnary);
        if (this.idQuestionnary != undefined) this.onDisplayQuestionnary(this.questionnaryDetails);
      }
    });
    this.dataService.storeHeaderShowed(false);
    this.patient = this.dataService.retrievePatient();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
    this.questionnariesList = null;
    this.patient = null;
  }

  // On click on another page, we check if test is started and display message
  public canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.startTest) {
      this.questionnaryStep.btnOpenQuit.nativeElement.click();
      return false;
    } else return true;
  }

  @HostListener('window:unload', ['$event'])
  public unloadHandler(event): void {
    if (this.startTest) {
      this.questionnaryStep.btnOpenQuit.nativeElement.click();
    }
  }

  // Call when a questionnary is checked
  public onQuestionnarySelected(questionnariesList: Array<QuestionnaireSummary>): void {
    this.canDisplayStart = true;
    this.questionnariesList = questionnariesList;
    if (this.questionnariesList && this.questionnariesList.length > 1) {
      this.canDisplayStart = false;
    }
  }

  // Call when a questionnary is clicked
  public onDisplayQuestionnary(questionnary: QuestionnaireSummary): void {
    this.questionnaryDisplayed = questionnary;
  }

  public startQuestionnaries(): void {
    this.isAloneQuestionnary = false;
    this.getQuestionnary(this.questionnaryDisplayed.id);
  }

  public getQuestionnary(idQuestionnary: number): void {
    const lang = this.translateService.currentLang;
    this.startTest = false;

    this.subscriptions.push(
      this.questionnaryService.questionnaireControllerFindById(idQuestionnary, lang).subscribe(
        (questionnaryDetails) => {
          this.startTest = true;
          this.questionnaryDetails = questionnaryDetails;
        },
        (error) => {
          console.log('Error questionnaireControllerFindById', error);
        },
      ),
    );
  }

  public onStartQuestionnary(idQuestionnary: number): void {
    this.isAloneQuestionnary = true;
    this.getQuestionnary(idQuestionnary);
  }

  public onStopQuestionnary(): void {
    this.startTest = false;
    this.questionnaryDisplayed = null;
    this.questionnaryDetails = null;
    this.questionnaryList.refresh();
  }

  public onValidateQuestionnary(idQuestionnary: number): void {
    if (!this.isAloneQuestionnary) {
      const index = this.questionnariesList.findIndex((ques) => ques.id === idQuestionnary);
      if (index !== -1) {
        this.questionnariesList.splice(index, 1);
      }
      if (this.questionnariesList && this.questionnariesList.length > 0) {
        this.getQuestionnary(this.questionnariesList[0].id);
      } else {
        this.onStopQuestionnary();
      }
    } else {
      this.onStopQuestionnary();
    }
  }
}
