import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PercentageDisplayed } from '.';
import { FloatDisplayed } from './pipes/float-displayed.pipe';
import { MathService } from './services/math.service';

@NgModule({
  imports: [TranslateModule.forChild()],
  declarations: [PercentageDisplayed, FloatDisplayed],
  providers: [MathService, PercentageDisplayed, FloatDisplayed],
  exports: [PercentageDisplayed, FloatDisplayed],
})
export class MathModule {
  public static forRoot() {
    return {
      NgModule: MathModule,
      providers: [],
    };
  }
}
