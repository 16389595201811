import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';

export enum WalkOperation {
  // part 1
  Connexion,
  Installation,
  Protocol,
  Measure,
  Results,
  Assessment,
  // part 2
  Summary,
  Locogram,
  Rotagram,
  Comparison,
  Semiogram,
  Longitudinal,
  // others
  History,
}

export const WalkOperationPart1 = [
  WalkOperation.Connexion,
  WalkOperation.Installation,
  WalkOperation.Protocol,
  WalkOperation.Measure,
  WalkOperation.Results,
  WalkOperation.Assessment,
];

export const WalkOperationPart2 = [
  WalkOperation.Summary,
  WalkOperation.Locogram,
  WalkOperation.Rotagram,
  WalkOperation.Comparison,
  WalkOperation.Semiogram,
  WalkOperation.Longitudinal,
];

@Injectable({ providedIn: 'root' })
export class WalkOperationService {
  public currentOperation$: Observable<WalkOperation>;
  private currentOperation = new BehaviorSubject(WalkOperation.Connexion);

  public constructor() {
    this.currentOperation$ = this.currentOperation.asObservable();
  }

  public setCurrentOperation(operation: WalkOperation): void {
    this.currentOperation.next(operation);
  }
}
