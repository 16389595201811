import { Injectable } from '@angular/core';
import { Footwear, FootwearControllerService, WalkingAid, WalkingAidControllerService } from '@abilycare/dal-client';

@Injectable()
/**
 * this service manages grip acquisition, including the transformation of data to/from the DAL model.
 */
export class FootWearDataService {
  constructor(
    private footwearService: FootwearControllerService,
    private walkingAidService: WalkingAidControllerService,
  ) {}

  public async GetAllFootWears(local: string): Promise<Footwear[]> {
    return this.footwearService.footwearControllerAll(local).toPromise();
  }

  public async GetFootWearById(local: string, alias: string): Promise<Footwear> {
    return this.footwearService.footwearControllerFindByAlias(alias, local).toPromise();
  }

  public async GetWalkingAidById(local: string, id: number): Promise<WalkingAid> {
    return this.walkingAidService.walkingAidControllerFindById(id, local).toPromise();
  }

  public async GetAllWalkingAid(local: string): Promise<WalkingAid[]> {
    return this.walkingAidService.walkingAidControllerFind(local).toPromise();
  }
}
