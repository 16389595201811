<div class="main-container">
  <div class="msg">
    <p>(<span class="red-text">*</span>){{ 'Patient.Profil.mandatory_field' | translate }}</p>
  </div>

  <div class="stepper-title">
    <p>{{ 'Patient.Profil.health' | translate }}</p>
  </div>

  <form
    [formGroup]="healthFormGroup"
    class="form-group patient-form"
    [ngClass]="{ 'needs-validation': true, 'IMC-form': true, 'was-validated': !formValid }"
  >
    <div class="form-group formy col-sm-6">
      <label for="height">{{ 'Patient.Profil.height_cm' | translate }} <span class="red-text">*</span>:</label>
      <div class="input-group">
        <input
          type="number"
          class="form-control"
          (change)="onCalculImc()"
          id="height"
          placeholder=""
          formControlName="height"
          min="50"
          max="270"
          required
        />
        <p class="detail">IMC : {{ imc }}</p>
      </div>
      <div class="error-msg red-text">
        <p *ngIf="!isHeightValid">
          {{ 'Patient.Profil.borne.height' | translate }}
        </p>
      </div>
    </div>
    <div class="form-group formy col-sm-6">
      <label for="weight">{{ 'Patient.Profil.weight' | translate }} <span class="red-text">*</span>:</label>
      <div class="input-group">
        <input
          type="number"
          class="form-control"
          (change)="onCalculImc()"
          id="weight"
          placeholder=""
          formControlName="weight"
          min="20"
          max="200"
          required
        />
      </div>
      <div class="error-msg red-text">
        <p *ngIf="!isWeightValid">
          {{ 'Patient.Profil.borne.weight' | translate }}
        </p>
      </div>
    </div>
  </form>
  <div class="flex-row div-error-button">
    <div class="error-msg red-text">
      <p *ngIf="!formValid">{{ 'Patient.Profil.fill_all_Field' | translate }}</p>
    </div>
  </div>

  <div class="flex-row div-btn">
    <button class="btn btn-primary" (click)="validate()">{{ 'button.validation' | translate }}</button>
  </div>
</div>
