import { PatientDetail, UpdatePatient } from '@abilycare/dal-client';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subscription } from 'rxjs';
import { PatientDataService } from 'src/app/modules/abilycare-data-access/api/patient-data.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';

@Component({
  selector: 'app-patient-creation-personal-form',
  templateUrl: './patient-creation-personal-form.component.html',
  styleUrls: ['./patient-creation-personal-form.component.scss'],
})
export class PatientCreationPersonalFormComponent implements OnInit, OnDestroy {
  @Input() patient: PatientDetail;
  @Output() closeModal = new EventEmitter();

  public personalFormGroup: FormGroup;
  private subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private patientDataService: PatientDataService,
    private dataService: DataProviderService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.personalFormGroup = this.formBuilder.group({
      phoneNumber: [this.patient.phoneNumber],
      mobilePhoneNumber: [this.patient.mobilePhoneNumber],
      email: [this.patient.email],
      address: [this.patient.address],
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
  }

  public validate(): void {
    if (this.personalFormGroup.valid) {
      const form = this.personalFormGroup.value;
      const updatePatient: UpdatePatient = {
        id: this.patient.id,
        phoneNumber: form.phoneNumber ? form.phoneNumber : '',
        mobilePhoneNumber: form.mobilePhoneNumber ? form.mobilePhoneNumber : '',
        email: form.email ? form.email : '',
        address: form.address ? form.address : '',
      };

      this.subscriptions.push(
        forkJoin({
          data: this.patientDataService.updatePatient(updatePatient),
          patient: this.patientDataService.getPatientById(this.patient.id),
        }).subscribe(
          ({ data, patient }) => {
            this.snackBarService.show(this.translateService.instant('app.snack.update.patient.success'), 'success');
            this.dataService.setPatient(patient);
            this.closeModal.emit();
          },
          (err) => {
            console.error('PatientCreationPersonalFormComponent : Err-Patient-04 ', err);
            const errMsg = this.translateService.instant('Err-Patient-04');
            this.snackBarService.show(errMsg, 'danger');
          },
        ),
      );
    }
  }

  public cancel(): void {
    this.closeModal.emit();
  }
}
